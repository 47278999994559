import { createSlice } from '@reduxjs/toolkit'
import { JWT_KEY } from '../../constants/localStorageKeys'
import { userLogin } from './loginAction'

const userToken = localStorage.getItem(JWT_KEY) ? localStorage.getItem(JWT_KEY) : null

const initialState = {
  loading: false,
  userToken,
  error: null,
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state: any) => {
        state.loading = true
        state.error = null
      })
      .addCase(userLogin.fulfilled, (state: any, { payload }) => {
        state.loading = false
        state.userToken = payload.userToken
      })
      .addCase(userLogin.rejected, (state: any, { payload }) => {
        state.loading = false
        state.error = payload
      })
  },
})

export default authSlice.reducer