import { Dialog } from "primereact/dialog";
import { Resume } from "../../../interface";
import ApplicationForm from "../../ApplicationForm";

interface CandidateApplicationFormDialogProps {
  displayApplicationFormDialog: boolean
  resume: Resume
  setDisplayCandidateApplicationFormDialog: (data: boolean) => any
  updateResume: (resume: Resume, resumeToken: string) => any
  uploadResume: (resumeData: FormData) => any
}

const CandidateApplicationFormDialog = (props: CandidateApplicationFormDialogProps) => {

  const { displayApplicationFormDialog, resume, setDisplayCandidateApplicationFormDialog, updateResume, uploadResume } = props

  return (
    <div>
      {/* <ProgressSpinnerDialog text="Retrieving information..." visible={resumeGetStatus === ResumeGetStatus.loading} /> */}
      <Dialog
        visible={displayApplicationFormDialog}
        onHide={() => setDisplayCandidateApplicationFormDialog(false)} >
        <ApplicationForm 
          actionOnSubmit={updateResume}
          uploadResume={uploadResume} 
          resume={resume} 
          formHeader="Review your particulars" 
          submitButtonLabel="Submit" />
      </Dialog>
    </div>
  );
}

export default CandidateApplicationFormDialog