import React from 'react'
import * as homeAction from '../../store/homeInfo/homeAction'
import {connect} from 'react-redux'
import colors from "./colors";

interface HomeProps {

}

const Home = (homeProps: HomeProps) => {
  const { } = homeProps

  const data = [
    {
      mandant: "Chevrolet",
      ok: 89,
      pending: 10,
      error: 52
    },
    {
      mandant: "Dodge",
      ok: 94,
      pending: 57,
      error: 4
    },
    {
      mandant: "Mercedes-Benz",
      ok: 61,
      pending: 37,
      error: 79
    },
    {
      mandant: "Volkswagen",
      ok: 100,
      pending: 27,
      error: 25
    }
  ]

  const chartData = {
    labels: data.map((el) => el.mandant),
    datasets: [
      {
        data: data.map((el) => el.ok + el.pending + el.error),
        backgroundColor: colors.slice(0, data.length)
      }
    ]
  };

  const options = {
    legend: {
      display: false
    }
  };
  return (
    <div className="App">
      {/* <Chart type="pie" data={chartData} options={options} /> */}
      Admin Home Placeholder
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    name: state.homeInfo.name,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setName: (name: string) => dispatch(homeAction.setName(name))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)