import InterviewSetup from "../../../AdminPortal/scenes/Interview/InterviewSetup"
import JobManagement from "../../../AdminPortal/scenes/Job/JobManagement"
import UserManagement from "../../../AdminPortal/scenes/User/UserManagement"
import LookupManagement from "../../../AdminPortal/scenes/Lookup";
import TenantListing from "../../../AdminPortal/scenes/Tenant/TenantListing";
import ApplicationManagement from "../../../AdminPortal/scenes/Application/ApplicationManagement";
import ApplicationView from "../../../AdminPortal/scenes/Application/ApplicationView";
import InterviewManagement from "../../../AdminPortal/scenes/Interview/InterviewManagement";
import InterviewView from "../../../AdminPortal/scenes/Interview/InterviewView";
import InterviewFlowListing from "../../../AdminPortal/scenes/Interview/InterviewFlowListing";
import Home from "../../../admin/Home";
import Error from "../../../admin/Error";

import JobView from "../../../AdminPortal/scenes/Job/JobView";
import { SCOPES } from "../../scopes";
import TenantForm from "../../../AdminPortal/scenes/Tenant/components/TenantForm";
import UserForm from "../../../AdminPortal/scenes/User/components/UserForm";
import JobForm from "../../../AdminPortal/scenes/Job/components/JobForm";
import { retrieveJob } from "../../../AdminPortal/scenes/Job/JobAction";
import { retrieveInterview } from "../../../AdminPortal/scenes/Interview/InterviewAction";

export interface Breadcrumb {
  /** Name shown in breadcrumb */
  name: (state: any) => any
  /** 
   * Method that will be executed to retrieve data used in breadcrumbs name.
   * NOTE: This won't be called for the last level of childrens.
   * NOTE: This can be ignored if name is populated in children components.
   */
  init?: (params: any) => any
  /** 
   * Force init method to be called no matter what
   */
  forceInit?: boolean
}

export interface Routes {
  /** The path pattern for the component to render */
  path: string
  /** The final constructed path after flattening childrens */
  compiledPath?: string
  /** The component to render, if empty use <></> or <Redirect> */
  component: JSX.Element
  /** Exact path matching */
  exact: boolean
  /** Breadcrumbs info */
  breadcrumb: Breadcrumb,
  /** Allowed scopes */
  scopes?: SCOPES[],
  /** All our childrens */
  children?: Routes[]
}

export const ROUTES: Routes[] = [
  {
    path: '/home',
    component: <Home />,
    exact: false,
    breadcrumb: {
      name: () => 'Home'
    }
  },
  {
    path: '/error',
    component: <Error />,
    exact: false,
    breadcrumb: {
      name: () => 'Error'
    }
  },
  {
    path: '/jobs',
    component: <JobManagement />,
    exact: true,
    breadcrumb: {
      name: () => 'Job Listing'
    },
    scopes: [SCOPES.JOB_READ],
    children: [
      {
        path: '/create',
        component: <JobForm submitButtonLabel="Create" editable={true} />,
        exact: true,
        breadcrumb: {
          name: () => 'Create a new Job'
        },
        scopes: [SCOPES.USER_WRITE],
      },
      {
        path: '/:jobId',
        component: <JobView />,
        exact: true,
        breadcrumb: {
          name: (state: any) => `Job - ${state.job.job?.title}`,
          init: (params: any) => retrieveJob(params.jobId), // this can be ignored if already called in childrens
        },
        scopes: [SCOPES.JOB_READ],
        children: [
          {
            path: '/update',
            component: <JobForm submitButtonLabel="Update" editable={true} />,
            exact: true,
            breadcrumb: {
              name: () => 'Update Job'
            },
            scopes: [SCOPES.USER_WRITE],
          },
          {
            path: '/setup',
            component: <InterviewSetup />,
            exact: false,
            breadcrumb: {
              name: ()=>{}
              //name: (state: any) => state.interviewFlow.interviewFlow.name,
              // init: (params: any) => getInterviewFlowByJobId(params.jobId), // this is not needed unless you add another level of children
            },
            scopes: [SCOPES.JOB_WRITE],
          }
        ]
      }
    ]
  },
  {
    path: '/users',
    component: <UserManagement admin={false} />,
    exact: true,
    breadcrumb: {
      name: () => 'User Listing'
    },
    scopes: [SCOPES.USER_READ],
    children: [
      {
        path: '/create',
        component: <UserForm admin={false} submitButtonLabel="Create" editable={true} />,
        exact: true,
        breadcrumb: {
          name: () => 'Create a new User'
        },
        scopes: [SCOPES.USER_WRITE],
      },
      {
        path: '/:userId',
        component: <UserForm admin={false} submitButtonLabel="View" editable={false} />,
        exact: true,
        breadcrumb: {
          name: (state) => `User - ${state.user.user.name}`
        },
        scopes: [SCOPES.USER_WRITE],
        children: [
          {
            path: '/update',
            component: <UserForm admin={false} submitButtonLabel="Update" editable={true} />,
            exact: true,
            breadcrumb: {
              name: () => 'Update User'
            },
            scopes: [SCOPES.USER_WRITE],
          }
        ]
      }
    ]
  },
  {
    path: '/lookups',
    component: <LookupManagement />,
    exact: true,
    breadcrumb: {
      name: () => 'Lookup Management'
    },
    scopes: [SCOPES.LOOKUP_READ],
  },
  {
    path: '/tenants',
    component: <TenantListing />,
    exact: true,
    breadcrumb: {
      name: () => 'Tenant Listing'
    },
    scopes: [SCOPES.TENANT_READ],
    children: [
      {
        path: '/create',
        component: <TenantForm submitButtonLabel="Create" editable={true} />,
        exact: true,
        breadcrumb: {
          name: () => 'Create a new Tenant'
        },
        scopes: [SCOPES.TENANT_WRITE],
      },
      { 
        path: '/users',
        component: <UserManagement admin={true} />,
        exact: true,
        breadcrumb: {
          name: () => 'Tenants User Listing'
        },
        scopes: [SCOPES.PORTAL_ADMIN, SCOPES.USER_READ],
        children: [
          {
            path: '/create',
            component: <UserForm admin={true} submitButtonLabel="Create" editable={true} />,
            exact: true,
            breadcrumb: {
              name: () => 'Create a new User'
            },
            scopes: [SCOPES.USER_WRITE],
          },
          {
            path: '/:userId',
            component: <UserForm admin={true} submitButtonLabel="View" editable={false} />,
            exact: true,
            breadcrumb: {
              name: (state) => `User - ${state.user.user.name}`
            },
            scopes: [SCOPES.USER_WRITE],
            children: [
              {
                path: '/update',
                component: <UserForm admin={true} submitButtonLabel="Update" editable={true} />,
                exact: true,
                breadcrumb: {
                  name: () => 'Update User'
                },
                scopes: [SCOPES.USER_WRITE],
              }
            ]
          }
        ]
      },
      {
        path: '/:tenantId',
        component: <TenantForm submitButtonLabel="View" editable={false} />,
        exact: true,
        breadcrumb: {
          name: (state: any) =>  `Tenant - ${state.tenant.tenant.companyName}`
        },
        scopes: [SCOPES.TENANT_READ],
        children: [
          {
            path: '/update',
            component: <TenantForm submitButtonLabel="Update" editable={true} />,
            exact: true,
            breadcrumb: {
              name: (state: any) => `Updating Tenant`
            },
            scopes: [SCOPES.TENANT_WRITE],
          },
        ]
      },
    ]
  },
  {
    path: '/applications',
    component: <ApplicationManagement />,
    exact: true,
    breadcrumb: {
      name: () => 'Application Listing'
    },
    scopes: [SCOPES.APPLICATION_READ],
    children: [
      {
        path: '/:applicationId',
        component: <ApplicationView />,
        exact: true,
        breadcrumb: {
          name: (state: any) => `Application - ${state.application.application?.id} (${state.application.application?.resume?.fullName})`
        },
        scopes: [SCOPES.APPLICATION_READ],
      },
    ]
  },
  {
    path: '/interviews',
    component: <InterviewManagement />,
    exact: true,
    breadcrumb: {
      name: () => 'Interview Listing'
    },
    scopes: [SCOPES.INTERVIEW_READ],
    children: [
      {
        path: '/:interviewId',
        component: <InterviewView />,
        exact: true,
        breadcrumb: {
          name: (state: any) => `Interview - ${state.interview.interview.id} (${state.interview.interview.intervieweeName})`,
          init: (params: any) => retrieveInterview(params.interviewId), // this can be ignored if already called in childrens
        },
        scopes: [SCOPES.INTERVIEW_READ],
        children: [
          {
            path: '/setup',
            component: <InterviewSetup />,
            exact: true,
            breadcrumb: {
              name: ()=>{}
              //name: (state: any) => state.interviewFlow.interviewFlow.name
            },
            scopes: [SCOPES.INTERVIEW_WRITE],
          }
        ]
      }
    ]
  },
  {
    path: '/templates',
    component: <InterviewFlowListing />,
    exact: true,
    breadcrumb: {
      name: () => 'Interview Flow Listing'
    },
    scopes: [SCOPES.TENANT_ADMIN],
    children: [
      {
        path: '/:interviewFlowId',
        component: <InterviewSetup />,
        exact: true,
        breadcrumb: {
          name: ()=> {}
          //name: (state: any) => state.interviewFlow.interviewFlow.name
        },
        scopes: [SCOPES.TENANT_ADMIN, SCOPES.INTERVIEW_WRITE],
      }
    ]
  },
  {
    path: '*',
    component: <Error />,
    exact: false,
    breadcrumb: {
      name: () => 'Error 404'
    }
  },
]