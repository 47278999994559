import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import type { PayloadAction } from '@reduxjs/toolkit'
import { InterviewFlow } from '../interface'
import {
  getInterviewFlow,
  getAllCustomSlotTypesForTenant,
  createAndSaveInterviewFlow,
  updateInterviewFlow,
  createAndSaveCustomSlot,
  getInterviewNodesByFlow,
  getExistingFlowsForTenant,
  getInterviewFlowGlobalTemplateListing
} from "./InterviewFlowAction"
import { Branch, CustomSlotType, InterviewNode, QuestionType } 
  from "../../../../CandidatePortal/scenes/Interview/Chatbot/interface"

interface InterviewFlowState {
  loading: Boolean
  error: any | null
  customSlotTypes: CustomSlotType[]
  interviewFlow: InterviewFlow | undefined
  interviewNodeList: InterviewNode[]

  existingInterviewFlows: InterviewFlow[]
  interviewFlowGlobalTemplateListing: InterviewFlow[]
  interviewFlowGlobalTemplateTotalRecords: Number

  saveInterviewFlowStatus: ConstrainBoolean
}

const initialState = {
  loading: false,
  error: null,
  interviewFlow: undefined,
  customSlotTypes: [] as CustomSlotType[],
  interviewNodeList: [] as InterviewNode[],

  existingInterviewFlows: [] as InterviewFlow[],
  interviewFlowGlobalTemplateListing: [] as InterviewFlow[],
  interviewFlowGlobalTemplateTotalRecords: 0,
  
  saveInterviewFlowStatus: false
} as InterviewFlowState

const interviewFlowSlice = createSlice({
  name: 'interviewFlow',
  initialState,
  reducers: {
    updateInterviewNodeList: (state: any, action: PayloadAction<InterviewNode[]>) => {
      const newInterviewFlow = {...state.interviewFlow, interviewNodeList: action.payload}
      return {...state, interviewNodeList: action.payload, interviewFlow: newInterviewFlow}
    },
    deleteInterviewNode: (state: any, action: PayloadAction<InterviewNode>) => {
      let tempNodeList: InterviewNode[] = (JSON.parse(JSON.stringify(state.interviewNodeList)))    
      let indexToRemove = (tempNodeList.findIndex
        ((tempNode: InterviewNode) => tempNode.name === action.payload.name))
      tempNodeList.splice(indexToRemove, 1)
      tempNodeList.forEach((currentNode: InterviewNode) => {
        switch (currentNode.typeOfQuestion) { 
          case (QuestionType.ConfirmationQuestion):
            if (currentNode.confirmationSetting.flowToIfNo === action.payload.name)
              currentNode.confirmationSetting.flowToIfNo = ''
            if (currentNode.confirmationSetting.flowToIfYes === action.payload.name)
              currentNode.confirmationSetting.flowToIfYes = ''
            break
          case (QuestionType.OpenEndedQuestion):
            if (currentNode.slotElicitationSetting.successStep === action.payload.name)
              currentNode.slotElicitationSetting.successStep = ''
            break
          case (QuestionType.DelegatedQuestion):
            if (currentNode.confirmationSetting?.flowToIfYes === action.payload.name)
              currentNode.confirmationSetting.flowToIfYes = ''
            break
          case (QuestionType.ConditionalQuestion):
            if (currentNode.conditionalSetting.defaultStep === action.payload.name)
              currentNode.conditionalSetting.defaultStep = ''
            currentNode.conditionalSetting.branches.forEach((branch: Branch) => {
              if (branch.nextStep === action.payload.name)
                branch.nextStep = ''
            })
            break
        }
      })
      return {...state, interviewNodeList: tempNodeList}
    },
    updateNodeInNodeList: (state: any, action: PayloadAction<InterviewNode>) => {
      const newNode = action.payload
      let tempNodeList: InterviewNode[] = (JSON.parse(JSON.stringify(state.interviewNodeList))) 
      let updateIndex = state.interviewNodeList.findIndex((currentNode: InterviewNode) => currentNode.id === newNode.id)
      if (updateIndex >= 0)
        tempNodeList[updateIndex]= Object.assign(tempNodeList[updateIndex], newNode)
      else
        tempNodeList.concat(newNode)

      /*
      let tempNodeList = state.interviewNodeList.slice(0)
      tempNodeList[(state.interviewNodeList.findIndex((currentNode: InterviewNode) => currentNode.name === oldNode.name))] = node
      tempNodeList.forEach((currentNode: InterviewNode) => {
        switch (currentNode.typeOfQuestion) {
          case (QuestionType.ConfirmationQuestion):
            if (currentNode.confirmationSetting.flowToIfNo === oldNode.name)
              currentNode.confirmationSetting.flowToIfNo = node.name
            if (currentNode.confirmationSetting.flowToIfYes === oldNode.name)
              currentNode.confirmationSetting.flowToIfYes = node.name
            break
          case (QuestionType.OpenEndedQuestion):
            if (currentNode.slotElicitationSetting.successStep === oldNode.name)
              currentNode.slotElicitationSetting.successStep = node.name
            break
          case (QuestionType.DelegatedQuestion):
            if (currentNode.confirmationSetting?.flowToIfYes === oldNode.name)
              currentNode.confirmationSetting.flowToIfYes = node.name
            break
          case (QuestionType.ConditionalQuestion):
            if (currentNode.conditionalSetting.defaultStep === oldNode.name)
              currentNode.conditionalSetting.defaultStep = node.name
            currentNode.conditionalSetting.branches.forEach((branch: Branch) => {
              if (branch.nextStep === oldNode.name)
                branch.nextStep = node.name
            })
            break
        }
      })
      */

      let newInterviewFlow = {...state.interviewFlow, interviewNodeList: tempNodeList}
      return {...state, interviewFlow: newInterviewFlow, interviewNodeList: tempNodeList}
    },
    setInterviewFlowEnum: (state: any, action: PayloadAction<any>) => {
      state.interviewFlow.interviewFlowEnum = action.payload
    },
    addNodesToNodeList: (state: InterviewFlowState, action: PayloadAction<any>) => {
      let nodeList: InterviewNode[] = JSON.parse(JSON.stringify(action.payload))
      /*
      let interviewNodes = action.payload
      nodeList = interviewNodes.map((eachIntent: InterviewNode) => {
        return eachIntent
      })
      */
      let nameList: string[] = nodeList.map((eachIntent: InterviewNode) => {
        return eachIntent.name
      })
      nodeList.forEach((currentNode: InterviewNode) => {
        switch (currentNode.typeOfQuestion) {
          case (QuestionType.ConfirmationQuestion):
            if (!nameList.includes(currentNode.confirmationSetting.flowToIfNo))
              currentNode.confirmationSetting.flowToIfNo = ''
            if (!nameList.includes(currentNode.confirmationSetting.flowToIfYes))
              currentNode.confirmationSetting.flowToIfYes = ''
            break
          case (QuestionType.OpenEndedQuestion):
            if (!nameList.includes(currentNode.slotElicitationSetting.successStep))
              currentNode.slotElicitationSetting.successStep = ''
            break
          case (QuestionType.DelegatedQuestion):
            if (currentNode.confirmationSetting?.flowToIfYes && !nameList.includes(currentNode.confirmationSetting?.flowToIfYes))
              currentNode.confirmationSetting.flowToIfYes = ''
            else if (!nameList.includes(currentNode.slotElicitationSetting.successStep))
              currentNode.slotElicitationSetting.successStep = ''
            break
          case (QuestionType.ConditionalQuestion):
            if (!nameList.includes(currentNode.conditionalSetting.defaultStep))
              currentNode.conditionalSetting.defaultStep = ''
            currentNode.conditionalSetting.branches.forEach((branch: Branch) => {
              if (!nameList.includes(branch.nextStep))
                branch.nextStep = ''
            })
            break
        }
      })
      let concatenatedNodeList = state.interviewNodeList.concat(nodeList);
      return {...state, interviewNodeList: concatenatedNodeList}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInterviewFlow.pending, (state: any) => {
        state.loading = true
        state.error = null
      })
      .addCase(getInterviewFlow.fulfilled, (state: any, { payload }) => {
        // state.interviewFlow = payload

        // state.interviewFlow.interviewNodeList.forEach((node: InterviewNode) => {
        //   node.id = undefined //Remove all ID when importing
        // })

        // if (state.interviewFlow && state.interviewFlow.interviewNodeList && state.interviewFlow.interviewNodeList.length > 0) {
        //   let tempNodeList: InterviewNode[]
        //   tempNodeList = state.interviewFlow.interviewNodeList.map((eachIntent: InterviewNode) => {
        //     return eachIntent
        //   })
        //   state.interviewNodeList = tempNodeList
        // }
        // state.loading = false
      
        return {...state, interviewFlow: payload, interviewNodeList: payload.interviewNodeList}
      })
      .addCase(getInterviewFlow.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(getAllCustomSlotTypesForTenant.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllCustomSlotTypesForTenant.fulfilled, (state: InterviewFlowState, { payload }) => {
        state.customSlotTypes = payload.content
        state.loading = false
      })
      .addCase(getAllCustomSlotTypesForTenant.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(createAndSaveInterviewFlow.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.saveInterviewFlowStatus = false
        state.error = null
      })
      .addCase(createAndSaveInterviewFlow.fulfilled, (state: InterviewFlowState, { payload }) => {
        state.interviewFlow = payload
        state.saveInterviewFlowStatus = true
        state.loading = false
      })
      .addCase(createAndSaveInterviewFlow.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.saveInterviewFlowStatus = false
        state.error = payload
      })
      .addCase(updateInterviewFlow.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateInterviewFlow.fulfilled, (state: InterviewFlowState, { payload }) => {
        state.interviewNodeList = payload.content
        state.loading = false
      })
      .addCase(updateInterviewFlow.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.saveInterviewFlowStatus = false
        state.error = payload
      })
      .addCase(createAndSaveCustomSlot.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.error = null
      })
      .addCase(createAndSaveCustomSlot.fulfilled, (state: InterviewFlowState, { payload }) => {
        //not sure if something is supposed to be done here
        state.loading = false
      })
      .addCase(createAndSaveCustomSlot.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.saveInterviewFlowStatus = false
        state.error = payload
      })
      .addCase(getInterviewNodesByFlow.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getInterviewNodesByFlow.fulfilled, (state: InterviewFlowState, { payload }) => {
        let updatedExistingFlows = JSON.parse(JSON.stringify(state.existingInterviewFlows))
        let index = -1
        if (payload)
          index = updatedExistingFlows.findIndex((eachFlow:InterviewFlow) => eachFlow.id === payload[0].interviewFlowId)
          if (index > -1)
            updatedExistingFlows[index].interviewNodeList = payload
        
        return {...state, loading: false, existingInterviewFlows: updatedExistingFlows}
      })
      .addCase(getInterviewNodesByFlow.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(getExistingFlowsForTenant.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getExistingFlowsForTenant.fulfilled, (state: InterviewFlowState, { payload }) => {
        state.existingInterviewFlows = payload
        state.loading = false
      })
      .addCase(getExistingFlowsForTenant.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(getInterviewFlowGlobalTemplateListing.pending, (state: InterviewFlowState) => {
        state.loading = true
        state.error = null
      })
      .addCase(getInterviewFlowGlobalTemplateListing.fulfilled, (state: InterviewFlowState, { payload }) => {
        state.interviewFlowGlobalTemplateListing = payload.content
        state.interviewFlowGlobalTemplateTotalRecords = payload.totalElements
        state.loading = false
      })
      .addCase(getInterviewFlowGlobalTemplateListing.rejected, (state: InterviewFlowState, { payload }) => {
        state.loading = false
        state.error = payload
      })
  }
})

export const { updateInterviewNodeList, 
               deleteInterviewNode, 
               updateNodeInNodeList,
               setInterviewFlowEnum,
               addNodesToNodeList } = interviewFlowSlice.actions;

export default interviewFlowSlice.reducer