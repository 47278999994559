import { Card } from "primereact/card"
import { useParams } from "react-router-dom"

import JobForm from "../components/JobForm"

interface JobViewProps {
}

const JobView = (props: JobViewProps) => {
  const { jobId } = useParams<{ jobId: string }>()

  return (
    <div>
      <JobForm submitButtonLabel="View" editable={false} />
      <Card>
        <div className="flex formgrid grid">
          <div className="field col-6">
            test
          </div>
        </div>
      </Card>
    </div>
  )
}

export default JobView

