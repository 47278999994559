import { createSlice } from "@reduxjs/toolkit"
import { createTenant, retrieveAllTenants, retrieveTenant, retrieveTenants, updateTenant } from "./TenantAction"
import { Tenant } from './interface'

interface TenantState {
  tenant: Tenant
  tenantList: Tenant[]
  totalRecords: Number | undefined
}

const initialState = {
  tenant: {} as Tenant,
  tenantList: [],
  totalRecords: undefined
} as TenantState

const tenantSlicer = createSlice({
  name: 'tenant',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveTenants.fulfilled, (state: any, { payload }) => {
        state.tenantList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
      .addCase(retrieveAllTenants.fulfilled, (state: any, { payload }) => {
        state.tenantList = payload
      })
      .addCase(retrieveTenant.fulfilled, (state: any, { payload }) => {
        state.tenant = payload
      })
      .addCase(createTenant.fulfilled, (state: any, { payload }) => {
        state.tenant = payload
      })
      .addCase(updateTenant.fulfilled, (state: any, { payload }) => {
        state.tenant = payload
      })
  },
})

export default tenantSlicer.reducer