import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { JWT_KEY } from '../../constants/localStorageKeys'

const REST_AUTH_URL = process.env.REACT_APP_REST_AUTH_URL
const ADMIN_CLIENT_ID = process.env.REACT_APP_ADMIN_CLIENT_ID
const ADMIN_CLIENT_SECRET = process.env.REACT_APP_ADMIN_CLIENT_SECRET

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ username, password }: { username: string, password: string }, { rejectWithValue }) => {
    try {
      if (typeof (REST_AUTH_URL) !== "string") {
        throw new Error("no rest url found");
      }
      
      const { data } = await axios.request({
        url: REST_AUTH_URL,
        method: 'post',
        headers: {
          'Authorization': 'Basic ' + btoa(`${ADMIN_CLIENT_ID}:${ADMIN_CLIENT_SECRET}`),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          'grant_type': 'password',
          'username': username,
          'password': password
        }
      })
      localStorage.setItem(JWT_KEY, data.access_token)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)