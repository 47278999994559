import { ChatSession, InterviewNode, InterviewStatusEnum } from "../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import { ApplicationListing } from "../Application/interface"

export interface InterviewListing {
	id?: number
	intervieweeName: string
	intervieweeEmail: string
	startDateTime: string
	endDateTime: string
	status: InterviewStatusEnum
	type: InterviewType
}

export enum InterviewType {
	APPLICATION = "APPLICATION",
	PROMOTION = "PROMOTION"
}

export interface Interview {
	application: ApplicationListing
	createdBy: string
	createdDate: string
	endDateTime: string
	id: number
	intervieweeEmail: string
	intervieweeName: string
	startDateTime: string
	status: InterviewStatusEnum
	type: InterviewType
	updatedBy: string
	updatedDate: string
	chatSession: ChatSession
}

export interface InterviewFlow {
	id?: number
	name: string
	interviewNodeList?: InterviewNode[]
	jobId?: number
	interviewId?: number
	interviewFlowEnum: FlowType
	createdDate?: string
	foreignKeyName?: string
}

export enum FlowType {
	JOB = 'JOB',
	INTERVIEW = 'INTERVIEW',
	GLOBAL = 'GLOBAL'
}

export enum InterviewNodeCreateStatus {
	success = 'success',
	processing = 'processing',
	fail = 'fail',
	neutral = 'neutral'
}

export enum InterviewNodeDeleteStatus {
	success = 'success',
	fail = 'fail',
	neutral = 'neutral'
}

export enum InterviewNodeUpdateStatus {
	success = 'success',
	fail = 'fail',
	neutral = 'neutral'
}

export enum InterviewNodeListStatus {
	CREATED = 'CREATED',
	UPDATED = 'UPDATED',
	DELETED = 'DELETED',
	RETRIEVED = 'RETRIEVED',
	processing = 'processing',
	NEUTRAL = 'NEUTRAL'
}

export enum InterviewFlowGetStatus {
	success = 'success',
	fail = 'fail',
	neutral = 'neutral',
	import = 'import'
}

export enum InterviewFlowUpdateStatus {
	success = 'success',
	fail = 'fail',
	idle = 'idle',
	updating = 'updating'
}

export enum AutoLayoutState {
	COMPLETED = 'COMPLETED',
	SKIPPED = 'SKIPPED',
	NEUTRAL = 'NEUTRAL',
}

export enum AdminCreateInterviewStatus {
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
	NEUTRAL = "NEUTRAL"
}

export enum AdminCreateInterviewFlowStatus {
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
	NEUTRAL = "NEUTRAL"
}

export enum InterviewFlowCreateStatus {
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
	NEUTRAL = "NEUTRAL"
}

export enum ImportNodeStatus {
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
	NEUTRAL = "NEUTRAL"
}

export enum CustomSlotTypeCreateStatus {
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
	NEUTRAL = "NEUTRAL"
}