import { ScrollPanel } from 'primereact/scrollpanel'
import { useEffect } from 'react'
import { MessageDetails } from '../../interface'
import MessageBox from '../MessageBox'
import styles from './styles.module.css'

interface MessageListProps {
  messageDetailsList: MessageDetails[]
}

const MessageList = (props: MessageListProps) => {

  const { messageDetailsList } = props

  //Logic to auto scroll to latest message
  useEffect(() => {
    const scrollPanel = document.querySelector(".p-scrollpanel-content")
    if (scrollPanel) {
      scrollPanel.scrollTo({ top: scrollPanel.scrollHeight, behavior: 'smooth' })
    }
  }, [messageDetailsList])

  return (
    <div className={styles.chatbot}>
      <ScrollPanel id="scrollPanel" style={{ width: '100%', height: '60vh'}}>
        <div style={{ padding: '1em', lineHeight: '1.5' }}>
          {messageDetailsList.map((messageDetails, index) => {
            //Temporary unique key
            return <MessageBox key={index.toString()} header={messageDetails.header} timeStamp={messageDetails.timeStamp} message={messageDetails.message.content} type={messageDetails.message.contentType}></MessageBox>
          })}
        </div>
      </ScrollPanel>
    </div>
  )
}

export default MessageList