import PrimeReact from "primereact/api"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { MultiSelect } from "primereact/multiselect"
import { Toast } from 'primereact/toast'
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"
import { Field, Form } from "react-final-form"
import { connect, useDispatch, useSelector } from "react-redux"
import { LOOKUPS_KEY } from "../../../../../constants/localStorageKeys"
import { CategoryEnum } from "../../../Lookup/enums"
import { Job, JobStatusEnum } from "../../interface"
import { AppDispatch } from "../../../../../store"
import { Link, useParams } from "react-router-dom"
import { createJob, retrieveJob, updateJob } from "../../JobAction"
import LinkButton from "../../../../../constants/helpers/LinkButton"

interface JobFormProps {
  submitButtonLabel: string
  editable: boolean
  resettable?: boolean
}

const JobForm = (props: JobFormProps) => {
  const { submitButtonLabel, resettable = true, editable = false } = props

  PrimeReact.ripple = true
  const toast = useRef<any>(null)

  const { jobId } = useParams<{ jobId: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const { job } = useSelector((state: any) => state.job)

  const [minSalary, setMinSalary] = useState<number | null>(job ? job.minSalary : 0)
  const [maxSalary, setMaxSalary] = useState<number | null>(job ? job.maxSalary : 0)
  const [yearsOfExp, setYearsOfExp] = useState<number | null>(job ? job.yearsOfExp : 0)
  const [specializationList, setSpecializationList] = useState([] as any)
  const [careerList, setCareerList] = useState([] as any)
  const [employmentTypes, setEmploymentTypes] = useState([] as any)
  const [qualificationList, setQualificationList] = useState([] as any)
  const [skillList, setSkillList] = useState([] as any)
  const [contractTypeList, setContractTypeList] = useState([] as any)
  const [locationList, setLocationList] = useState([] as any)
  const [languageList, setLanguageList] = useState([] as any)
  const [maxRetries, setMaxRetries] = useState<number | null>(job ? job.maxRetries : 0)

  useEffect(() => {
    // load job from db
    if (jobId)
      dispatch(retrieveJob(Number(jobId)))

    const lookups = localStorage.getItem(LOOKUPS_KEY)
    if (lookups) {
      const lookupsJSON = JSON.parse(lookups)
      const categoryList = Object.keys(CategoryEnum).filter(allItems => isNaN(Number(allItems)))
      categoryList.forEach((categoryName => retrieveLookupValues(lookupsJSON, categoryName)))
    }
  }, [])

  useEffect(() => {
    // set inputnumber values
    if (job && jobId) {
      setMinSalary(job.minSalary)
      setMaxSalary(job.maxSalary)
      setYearsOfExp(job.yearsOfExp)
      setMaxRetries(job.maxRetries)
    }
  }, [job])

  let initialValues: Job = job && jobId ?
    {
      id: job.id,
      title: job.title,
      specialization: job.specialization,
      careerLevels: job.careerLevels,
      employmentType: job.employmentType,
      qualifications: job.qualifications,
      skills: job.skills,
      yearsOfExp: job.yearsOfExp,
      contractType: job.contractType,
      minSalary: job.minSalary,
      maxSalary: job.maxSalary,
      location: job.location,
      languages: job.languages,
      responsibilities: job.responsibilities,
      scopes: job.scopes,
      area: job.area,
      description: job.description,
      requirements: job.requirements,
      status: job.status,
      interviewable: job.interviewable,
      maxRetries: job.maxRetries
    } :
    {
      title: '',
      specialization: '',
      careerLevels: null,
      employmentType: '',
      qualifications: null,
      skills: null,
      yearsOfExp: 0,
      contractType: '',
      minSalary: 0,
      maxSalary: 0,
      location: '',
      languages: null,
      responsibilities: '',
      scopes: '',
      area: '',
      description: '',
      requirements: '',
      status: JobStatusEnum.AVAILABLE,
      interviewable: false,
      maxRetries: 0
    }

  const statuses = Object.keys(JobStatusEnum).filter(k => isNaN(Number(k)));
  const statusModel = statuses.map(status => ({ label: status, value: status }))
  const retrieveLookupValues = (lookups: any, categoryName: any) => {
    switch (categoryName) {
      case CategoryEnum[CategoryEnum.SPECIALIZATION]: {
        setSpecializationList(lookups[categoryName].map((specialization: any) => specialization.value))
        break
      }
      case CategoryEnum[CategoryEnum.COUNTRY]: {
        setLocationList(lookups[categoryName].map((country: any) => country.value))
        break
      }
      case CategoryEnum[CategoryEnum.CAREER_LEVEL]: {
        setCareerList(lookups[categoryName].map((careerLevel: any) => careerLevel.value))
        break
      }
      case CategoryEnum[CategoryEnum.CONTRACT_TYPE]: {
        setContractTypeList(lookups[categoryName].map((contractType: any) => contractType.value))
        break
      }
      case CategoryEnum[CategoryEnum.EMPLOYMENT_TYPE]: {
        setEmploymentTypes(lookups[categoryName].map((employmentType: any) => employmentType.value))
        break
      }
      case CategoryEnum[CategoryEnum.LANGUAGE]: {
        setLanguageList(lookups[categoryName].map((language: any) => language.value))
        break
      }
      case CategoryEnum[CategoryEnum.QUALIFICATION]: {
        setQualificationList(lookups[categoryName].map((qualification: any) => qualification.value))
        break
      }
      case CategoryEnum[CategoryEnum.SKILL]: {
        setSkillList(lookups[categoryName].map((skill: any) => skill.value))
        break
      }
    }
  }

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.title) {
      errors.title = 'Job title is required.'
    }

    if (!data.employmentType) {
      errors.employmentType = 'Employment type is required.'
    }

    if (!data.contractType) {
      errors.contractType = 'Contract type is required.'
    }

    if (data.minSalary && (data.minSalary <= 0 || data.minSalary.value <= 0)) {
      errors.minSalary = 'Minimum salary cannot be less than or equals to 0.'
    }

    if (data.maxSalary && data.maxSalary &&
      (data.maxSalary < data.minSalary || data.maxSalary.value < data.minSalary.value || data.maxSalary.value < data.minSalary)) {
      errors.maxSalary = 'Maximum salary cannot be less than minimum salary.'
    }

    if (!data.specialization) {
      errors.specialization = 'Please select a specialization.'
    }

    if (!data.description) {
      errors.description = 'Please enter a description.'
    }

    if (!data.skills || (!!data.skills && data.skills.length === 0)) {
      errors.skills = 'Please select at least one skill.'
    }

    if (!data.qualifications || (!!data.qualifications && data.qualifications.length === 0)) {
      errors.qualifications = 'Please select at least one qualification.'
    }

    if (!data.careerLevels || (!!data.careerLevels && data.careerLevels.length === 0)) {
      errors.careerLevels = 'Please select at least one career level.'
    }

    if (!data.languages || (!!data.languages && data.languages.length === 0)) {
      errors.languages = 'Please select at least one language.'
    }

    if (!data.location) {
      errors.location = 'Please select a location.'
    }

    if ((!data.maxRetries || data.maxRetries.value === 0) && data.interviewable) {
      errors.maxRetries = 'Maximum number of allowable retries cannot be 0.'
    }

    return errors;
  }

  const onSubmit = (data: any, form: any) => {
    let payload = {} as Job
    payload.id = data.id
    payload.title = data.title
    payload.specialization = data.specialization
    payload.careerLevels = data.careerLevels
    payload.employmentType = data.employmentType
    payload.qualifications = data.qualifications
    payload.skills = data.skills
    payload.yearsOfExp = !!data.yearsOfExp.value ? data.yearsOfExp.value : yearsOfExp
    payload.contractType = data.contractType
    payload.minSalary = !!data.minSalary.value ? data.minSalary.value : minSalary
    payload.maxSalary = !!data.maxSalary.value ? data.maxSalary.value : maxSalary
    payload.location = data.location
    payload.languages = data.languages
    payload.responsibilities = data.responsibilities
    payload.scopes = data.scopes
    payload.area = data.area
    payload.description = data.description
    payload.requirements = data.requirements
    payload.status = data.status
    payload.interviewable = data.interviewable
    payload.maxRetries = data.interviewable ? (!!data.maxRetries.value ? data.maxRetries.value : maxRetries) : 0

    if (jobId) {
      dispatch(updateJob(payload)).unwrap()
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Job Update Success',
            detail: `Job (${payload.title}) successfully updated.`,
            life: 3000
          })
        })
        .catch(() => {
          toast.current.show({
            severity: 'warn',
            summary: 'Job Update Error',
            detail: `Job (${payload.title}) update failed.`,
            life: 3000
          })
        })
    } else {
      dispatch(createJob(payload)).unwrap()
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Job Create Success',
            detail: `Job (${payload.title}) successfully created.`,
            life: 3000
          })
          form.restart()
          setMinSalary(0)
          setMaxSalary(0)
          setYearsOfExp(0)
          setMaxRetries(0)
        })
        .catch(() => {
          toast.current.show({
            severity: 'warn',
            summary: 'Job Create Error',
            detail: `Job (${payload.title}) creation failed.`,
            life: 3000
          })
        })
    }
  }

  const handleReset = (form: any) => {
    console.log('form:', form)
    form.restart()

    // reset inputnumbers
    if (job && jobId) {
      setMinSalary(job.minSalary)
      setMaxSalary(job.maxSalary)
      setYearsOfExp(job.yearsOfExp)
      setMaxRetries(job.maxRetries)
    } else {
      setMinSalary(0)
      setMaxSalary(0)
      setYearsOfExp(0)
      setMaxRetries(0)
    }
    toast.current.show({ severity: 'info', summary: 'Form Reset', detail: 'Form details have been cleared', life: 3000 })
  }

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  return (
    <div>
      <Toast ref={toast} />
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} render={({ form, handleSubmit, values, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="formgrid grid align-self-center mx-2 my-3">
            {jobId &&
              <div className="field col-12">
                {!editable ?
                  <>
                    <LinkButton className="p-button-primary" to={"/jobs/" + jobId + "/update"} icon="eye" label="Edit" />
                    <LinkButton className="p-button-primary ml-2" to={"/jobs/" + jobId + "/setup"} icon="eye" label="Setup Interview Flow" />
                  </>
                  :
                  <LinkButton to={"/jobs/" + jobId} icon="arrow-left" label="Back" />
                }
              </div>
            }

            <div className="col-12">
              <Card title="Administrative Details" className="my-3">
                <div className="grid align-self-center">
                  <Field name="title" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="titleInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Job Title*</label>
                      {editable ?
                        <InputText id="titleInput" {...input} autoFocus type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        : <div>{job.title}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="employmentType" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="employmentTypePicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Employment Type*</label>
                      {editable ?
                        <Dropdown id="employmentTypePicker" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={employmentTypes} placeholder="Select an Employment Type" />
                        : <div>{job.employmentType}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="contractType" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="contractTypePicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Contract Type*</label>
                      {editable ?
                        <Dropdown id="contractTypePicker" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={contractTypeList} placeholder="Select a Contract Type" />
                        : <div>{job.contractType}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="specialization" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="specializationInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Specialization*</label>
                      {editable ?
                        <Dropdown id="specializationInput" {...input} filter virtualScrollerOptions={{ itemSize: 38 }} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={specializationList} placeholder="Select a Specialization" />
                        : <div>{job.specialization}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="description" render={({ input, meta }) => (
                    <div className="field col-12">
                      <label htmlFor="descriptionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Job Description*</label>
                      {editable ?
                        <InputTextarea id="descriptionInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} rows={6} />
                        : <div>{job.description}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="minSalary" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="minSalary" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Min.Salary*</label>
                      {editable ?
                        <InputNumber id="minSalary" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} value={minSalary} onValueChange={(e) => setMinSalary(e.value || 0)} prefix="$" showButtons mode="currency" currency="SGD" min={0} />
                        : <div>{job.minSalary}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="maxSalary" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="maxSalary" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Max.Salary*</label>
                      {editable ?
                        <InputNumber id="maxSalary" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} value={maxSalary} onValueChange={(e) => setMaxSalary(e.value || 0)} prefix="$" showButtons mode="currency" currency="SGD" min={0} />
                        : <div>{job.maxSalary}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                </div>
              </Card>
            </div>
            <div className="col-12">
              <Card title="Requirement Details" className="my-3">
                <div className="grid align-self-center">
                  <Field name="requirements" render={({ input }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="requirements">Job Requirements</label>
                      {editable ?
                        <InputTextarea id="requirements" {...input} className="inputfield w-full" rows={4} />
                        : <div>{job.requirements}</div>
                      }
                    </div>
                  )} />
                  <Field name="yearsOfExp" render={({ input }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="yearsOfExp">Years of Experience</label>
                      {editable ?
                        <InputNumber id="yearsOfExp" {...input} className="inputfield w-full" suffix=" year(s)" showButtons value={yearsOfExp} onValueChange={(e) => setYearsOfExp(e.value || 0)} min={0} max={100} />
                        : <div>{job.yearsOfExp}</div>
                      }
                    </div>
                  )} />
                  <Field name="skills" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="skillPicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Skills*</label>
                      {editable ?
                        <MultiSelect id="skillPicker" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={skillList} showClear placeholder="Select Skill(s)" />
                        : <div>{job.skills?.join(', ')}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="qualifications" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="qualificationPicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Qualifications*</label>
                      {editable ?
                        <MultiSelect id="qualificationPicker" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={qualificationList} showClear placeholder="Select a Qualification Type" />
                        : <div>{job.qualifications?.join(', ')}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                </div>
              </Card>
            </div>
            <div className="col-12">
              <Card title="Miscellaneous Details" className="my-3">
                <div className="grid align-self-center">
                  <Field name="responsibilities" render={({ input }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="responsibilities">Responsibilities</label>
                      {editable ?
                        <InputTextarea id="responsibilities" {...input} className="inputfield w-full" rows={4} />
                        : <div>{job.responsibilities}</div>
                      }
                    </div>
                  )} />
                  <Field name="scopes" render={({ input }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="scopes">Scopes</label>
                      {editable ?
                        <InputTextarea id="scopes" {...input} className="inputfield w-full" rows={4} />
                        : <div>{job.scopes}</div>
                      }
                    </div>
                  )} />
                  <Field name="careerLevels" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="careerPicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Career Level*</label>
                      {editable ?
                        <MultiSelect id="careerPicker" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={careerList} showClear placeholder="Select Career Level(s)" />
                        : <div>{job.careerLevels?.join(', ')}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="languages" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="languagePicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Language(s)*</label>
                      {editable ?
                        <MultiSelect id="languagePicker" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={languageList} showClear placeholder="Select Language(s)" />
                        : <div>{job.languages?.join(', ')}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="location" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="locationPicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Location*</label>
                      {editable ?
                        <Dropdown id="locationPicker" {...input} filter virtualScrollerOptions={{ itemSize: 38 }} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={locationList} placeholder="Select a Location" />
                        : <div>{job.location}</div>
                      }
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="area" render={({ input }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="area">Area</label>
                      {editable ?
                        <InputText id="area" {...input} className="field w-full" />
                        : <div>{job.area}</div>
                      }
                    </div>
                  )} />
                  <Field name="status" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6">
                      <label htmlFor="status">Status</label>
                      {editable ?
                        <Dropdown id="status" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={statusModel} placeholder="Select a Status" />
                        : <div>{job.status}</div>
                      }
                    </div>
                  )} />
                  <Field name="interviewable" type="checkbox" render={({ input }) => (
                    <div className={classNames("col-12 md:col-2", { 'field': !editable, 'field-checkbox': editable })}>
                      <label htmlFor="interviewable" style={{ paddingRight: '0.5em' }}>Chat Interview?</label>
                      {editable ?
                        <Checkbox inputId="interviewable" {...input} checked={input.checked || false} id="interviewable" />
                        : <div>{job.interviewable ? 'Yes' : 'No'}</div>
                      }
                    </div>
                  )} />
                  {values.interviewable ?
                    <Field name="maxRetries" render={({ input, meta }) => (
                      <div className="scalein animation-duration-300 animation-iteration-1 field col-12 md:col-2">
                        <label htmlFor="maxRetries" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Max Retries*</label>
                        {editable ?
                          <InputNumber id="maxRetries" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} suffix=" attempt(s)" showButtons value={maxRetries} onValueChange={(e) => setMaxRetries(e.value || 0)} min={0} />
                          : <div>{job.maxRetries}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />
                    :
                    <></>}
                </div>
              </Card >
            </div>
            <div className="col-12">
              {editable &&
                <div className="flex justify-content-end align-items-end">
                  {resettable ? <Button className="p-button-outlined p-button-secondary mr-3" label="Reset" icon="pi pi-refresh" onClick={() => handleReset(form)} disabled={pristine}></Button> : false}
                  <Button type="submit" label={submitButtonLabel} icon="pi pi-check"></Button>
                </div>
              }
            </div>
          </div>
        </form>
      )} />
    </div>
  )
}

export default JobForm