import PrimeReact from "primereact/api"
import { Button } from "primereact/button"
import { Calendar } from 'primereact/calendar'
import { Card } from "primereact/card"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { Toast } from 'primereact/toast'
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"
import { Field, Form } from "react-final-form"
import { connect } from "react-redux"
import { GetUserStatus } from "../../../../../AdminPortal/scenes/User/enums"
import { SingpassUserDetails } from "../../../../../AdminPortal/scenes/User/interfaces"
import { RESUME_KEY } from "../../../../../constants/localStorageKeys"
import { Resume } from '../../interface'
import FileReuploader from "../FileReuploader/FileReuploader"

interface ApplicationFormProps {
  formHeader: string
  actionOnSubmit: any
  submitButtonLabel: string
  resettable?: boolean
  resume?: Resume
  uploadResume: (resumeData: FormData) => any

  // singpassUserDetails: SingpassUserDetails
  // singpassUserDetailsStatus: GetUserStatus
  // getSingpassUserDetails: (code: string, state: number) => {}
}

const ApplicationForm = (props: ApplicationFormProps) => {
  const { formHeader, actionOnSubmit, submitButtonLabel, resettable = true, resume, uploadResume } = props
  PrimeReact.ripple = true
  const toast = useRef<any>(null)
  const [currentSalary, setCurrentSalary] = useState<number | null>(resume && resume.hasOwnProperty("currentSalary") && !!resume.currentSalary ? resume.currentSalary : 0)
  const [expectedSalary, setExpectedSalary] = useState<number | null>(resume && resume.hasOwnProperty("expectedSalary") && !!resume.expectedSalary ? resume.expectedSalary : 0)
  const [yearsOfExp, setYearsOfExp] = useState<number | null>(resume && resume.hasOwnProperty("yearsOfExp") && !!resume.yearsOfExp ? resume.yearsOfExp : 0)
  const [dob, setDob] = useState<Date | Date[] | string | undefined | null>(resume?.dob || undefined)
  const [availStartDate, setAvailStartDate] = useState<Date | Date[] | string | undefined | null>(resume && resume.hasOwnProperty("availStartDate") && !!resume.availStartDate ? resume.availStartDate : undefined)
  const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false)
  const [retrievingFromSingpass, setRetrievingFromSingpass] = useState(false)
  const [payload, setPayload] = useState<Resume>()

  const singpassUserDetails = {} as any

  const resumeDetails = {
    id: resume?.id || undefined,
    fullName: singpassUserDetails?.fullName || resume?.fullName || '',
    contactNo: singpassUserDetails?.mobileNo || resume?.contactNo || '',
    email: singpassUserDetails?.email || resume?.email || '',
    idNum: singpassUserDetails?.nric || resume?.idNum || '',
    gender: singpassUserDetails?.sex || resume?.gender || '',
    dob: (dob && new Date(singpassUserDetails?.dob)) || resume?.dob || undefined,
    nationality: singpassUserDetails?.nationality || resume?.nationality || '',
    residence: singpassUserDetails?.residence || resume?.residence || '',
    address: singpassUserDetails?.address || resume?.address || '',
    postalCode: singpassUserDetails?.postalCode || resume?.postalCode || '',
    qualification: resume?.qualification || '',
    currentEmploymentStatus: resume?.currentEmploymentStatus || '',
    employmentType: resume?.employmentType || '',
    availStartDate: resume?.availStartDate || undefined,
    currentSalary: resume?.currentSalary || 0,
    expectedSalary: resume?.expectedSalary || 0,
    yearsOfExp: resume?.yearsOfExp || 0,
    pastEmployedCompanies: resume?.pastEmployedCompanies || '',
    professionalCerts: resume?.professionalCerts || ''
  }
  const [initialValues, setInitialValues] = useState<Resume>(resumeDetails)

  useEffect(() => {
    // when resume updated, re-set initial value
    setInitialValues(resumeDetails)
  }, [resume])

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.fullName) {
      errors.fullName = "Full Name is required."
    }
    if (!data.contactNo) {
      errors.contactNo = "Contact Number is required."
    }
    if (!data.email) {
      errors.email = "E-Mail is required."
    }
    else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data.email) !== true) {
      errors.email = "Please enter a valid E-Mail."
    }
    if (!data.idNum) {
      errors.idNum = "This field is required."
    }
    else if (/[0-9]{3}[A-Za-z]{1}/.test(data.idNum) !== true) {
      errors.idNum = "Please enter a valid NRIC/FIN."
    }
    if (!data.gender) {
      errors.gender = "Gender is required."
    }
    if (!data.dob) {
      errors.dob = "Date of Birth is required."
    }
    if (!data.nationality) {
      errors.nationality = "Nationality is required."
    }
    if (!data.residence) {
      errors.residence = "Residence is required."
    }
    if (!data.address) {
      errors.address = "Address is required."
    }
    if (!data.postalCode) {
      errors.postalCode = "Postal Code is required."
    }
    if (!data.qualification) {
      errors.qualification = "Qualification is required."
    }
    if (!data.currentEmploymentStatus) {
      errors.currentEmploymentStatus = "Current Employment Status is required."
    }
    if (!data.employmentType) {
      errors.employmentType = "Employment Type is required."
    }
    if (!data.availStartDate) {
      errors.availStartDate = "Available Start Date is required."
    }
    if (!data.currentSalary && data.currentSalary !== 0) {
      errors.currentSalary = "Current Salary is required."
    }
    if (!data.expectedSalary && data.expectedSalary !== 0) {
      errors.expectedSalary = "Expected Salary is required."
    }
    if (!data.yearsOfExp && data.yearsOfExp !== 0) {
      errors.yearsOfExp = "Years of Experience is required."
    }
    if (!data.pastEmployedCompanies) {
      errors.pastEmployedCompanies = "Past Employed Companies are required."
    }
    if (!data.professionalCerts) {
      errors.professionalCerts = "Professional Certificates are required."
    }
    return errors;
  }

  const onSubmit = (data: any, form: any) => {
    let payload = {} as Resume

    payload.fullName = data.fullName
    payload.contactNo = data.contactNo
    payload.email = data.email
    payload.idNum = data.idNum
    payload.gender = data.gender
    payload.dob = data.dob
    payload.nationality = data.nationality
    payload.residence = data.residence
    payload.address = data.address
    payload.postalCode = data.postalCode
    payload.qualification = data.qualification
    payload.currentEmploymentStatus = data.currentEmploymentStatus
    payload.employmentType = data.employmentType
    payload.availStartDate = data.availStartDate
    payload.currentSalary = !!data.currentSalary.value ? data.currentSalary.value : currentSalary
    payload.expectedSalary = !!data.expectedSalary.value ? data.expectedSalary.value : expectedSalary
    payload.yearsOfExp = !!data.yearsOfExp.value ? data.yearsOfExp.value : yearsOfExp
    payload.pastEmployedCompanies = data.pastEmployedCompanies
    payload.professionalCerts = data.professionalCerts
    setPayload(payload)
    setDisplayConfirmationDialog(true)
    console.log('test')
  }

  const submitApplication = () => {
    actionOnSubmit(payload, localStorage.getItem(RESUME_KEY))
  }

  const handleReset = (form: any) => {
    form.restart()
    setAvailStartDate(undefined)
    setDob(undefined)
    setCurrentSalary(0)
    setExpectedSalary(0)
    setYearsOfExp(0)
    toast.current.show({ severity: 'info', summary: 'Form Reset', detail: 'Form details have been cleared', life: 3000 })
  }

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  const monthNavigatorTemplate = (e: any) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />
  }

  const yearNavigatorTemplate = (e: any) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />
  }

  const retrieveUsingSingpass = () => {
    const clientId = 'STG2-MYINFO-DEMO-APP'
    const callbackUrl = 'http://localhost:3001/callback'
    const scopes = 'partialuinfin,name,aliasname,sex,race,nationality,dob,email,mobileno,residentialstatus,regadd,edulevel,occupation'
    const baseAuthUrl = 'https://test.api.myinfo.gov.sg/com/v3/authorise'
    const appPurpose = 'demonstrating MyInfo APIs'
    const state = Math.floor(Math.random() * 100000)

    const singpassAuthWindow = window.open(`${baseAuthUrl}?client_id=${clientId}&attributes=${scopes}&purpose=${appPurpose}&state=${state}&redirect_uri=${callbackUrl}`)
    if (singpassAuthWindow != null) {
      const singPassAuthInterval = setInterval(() => {
        if (singpassAuthWindow.closed) {
          clearInterval(singPassAuthInterval)

          // retrive auth code and call api to retrieve details
          const authCode = localStorage.getItem('authCode')
          const authState = Number(localStorage.getItem('authState'))
          if (authCode !== null && authState !== null) {
            // remove from localstorage immediately
            localStorage.removeItem('authCode')
            localStorage.removeItem('authState')

            // check if credentials matches using state
            if (state === authState) {
              setRetrievingFromSingpass(true)

              // call endpoint to retrieve details
              // todo new dispatch
              // getSingpassUserDetails(authCode, authState)
            }
          }
        }
      }, 1000)
    }
  }

  // useEffect(() => {
  //   setDob(new Date(singpassUserDetails.dob))
  // }, [singpassUserDetails])

  return (
    <div>
      <ConfirmDialog header="Confirmation" message="Have you reviewed that your information are correct?" icon="pi pi-exclamation-triangle"
        visible={displayConfirmationDialog} onHide={() => setDisplayConfirmationDialog(false)} accept={submitApplication} reject={() => setDisplayConfirmationDialog(false)}>
      </ConfirmDialog>
      <Toast ref={toast} />
      <Card>
        <div className="flex lg:justify-content-start align-items-center">
          {resume ?
            <FileReuploader uploadResume={uploadResume} headerText={"Reupload your resume here"} /> : <></>}
        </div>
        <p>We have managed to infer the following information from the uploaded resume, please review and fill in the remaining information.</p>

        <Form
          mutators={{
            // expect (field, value) args from the mutator
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            }
          }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ form, handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="formgrid grid">
                <div className="field col-6">
                  <h3>Personal Information</h3>
                  <h6>*Required fields</h6>
                </div>
                {/* <div className="col-6 text-right">
                <Button 
                  label="Fill personal information using Singpass"
                  className="p-button-danger"
                  loading={retrievingFromSingpass}
                  onClick={retrieveUsingSingpass} />
              </div> */}
              </div>
              <div className="formgrid grid">
                <Field name="fullName" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="fullNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Full Name*</label>
                    <InputText id="fullNameInput" {...input} type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="contactNo" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="contactNoInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Contact Number*</label>
                    <InputText id="contactNoInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} placeholder="98765432" keyfilter="pnum" />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="gender" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="genderPicker" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Gender*</label>
                    <Dropdown id="genderPicker" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={["Male", "Female"]} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="idNum" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="idNumInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Last 4 Characters of NRIC/FIN*</label>
                    <InputText id="idNumInput" maxLength={4} {...input} type="text" placeholder="123A" keyfilter="alphanum" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="email" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="emailInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>E-Mail*</label>
                    <InputText id="emailInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} placeholder="myEmail@mymail.com" keyfilter="email" />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="dob" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="dobInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Date of Birth*</label>
                    <Calendar id="dobInput" {...input} value={dob} onChange={(e) => { setDob(e.value); input.onChange(e.value) }} yearRange={"1950:" + new Date().getFullYear()} yearNavigator monthNavigator readOnlyInput showButtonBar monthNavigatorTemplate={monthNavigatorTemplate} yearNavigatorTemplate={yearNavigatorTemplate} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD" />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="nationality" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="nationalityInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Nationality*</label>
                    <InputText id="nationalityInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="residence" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="residenceInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Country of Residence*</label>
                    <InputText id="residenceInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="address" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="addressInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Address*</label>
                    <InputText id="addressInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="postalCode" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="postalCodeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Postal Code*</label>
                    <InputText id="postalCodeInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="qualification" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="qualificationInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Highest Qualification*</label>
                    <Dropdown id="qualificationInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={["Diploma", "Graduate", "Postgraduate"]} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <div className="field col-12">
                  <h3>Employment Details</h3>
                  <h6>*Required fields</h6>
                </div>
                <Field name="currentEmploymentStatus" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="currentEmploymentStatusInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Current Employment Status*</label>
                    <Dropdown id="currentEmploymentStatusInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={["Unemployed", "Employed", "Self-Employed", "Student"]} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="employmentType" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="employmentTypeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Employment Type*</label>
                    <Dropdown id="employmentTypeInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={["Full Time", "Part Time", "Contract", "Not Applicable"]} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="pastEmployedCompanies" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="pastEmployedCompaniesInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Previously Employed Companies*</label>
                    <InputText id="pastEmployedCompaniesInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="yearsOfExp" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="yearsOfExpInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Years of Experience*</label>
                    <InputNumber id="yearsOfExpInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} suffix=" year(s)" showButtons value={yearsOfExp} onValueChange={(e) => setYearsOfExp(e.value || 0)} min={0} max={100} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <div className="field col-12">
                  <h3>Administrative Details</h3>
                  <h6>*Required fields</h6>
                </div>
                <Field name="availStartDate" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="availStartDateInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Available Starting Date*</label>
                    <Calendar id="availStartDateInput" value={availStartDate} onChange={(e) => { setAvailStartDate(e.value); input.onChange(e.value) }} yearRange={new Date().getFullYear() + ":2030"} yearNavigator monthNavigator readOnlyInput monthNavigatorTemplate={monthNavigatorTemplate} showButtonBar yearNavigatorTemplate={yearNavigatorTemplate} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD" />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="currentSalary" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="currentSalaryInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Current Salary*</label>
                    <InputNumber id="currentSalaryInput" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} value={currentSalary} onValueChange={(e) => setCurrentSalary(e.value || 0)} prefix="$" mode="currency" currency="SGD" min={0} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="expectedSalary" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="expectedSalaryInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Expected Salary*</label>
                    <InputNumber id="expectedSalaryInput" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} value={expectedSalary} onValueChange={(e) => setExpectedSalary(e.value || 0)} prefix="$" mode="currency" currency="SGD" min={0} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="professionalCerts" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="professionalCertsInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Professional Certificates*</label>
                    <InputText id="professionalCertsInput" {...input} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
              </div>
              <div className="flex justify-content-end align-items-end">
                {resettable ? <Button className="p-button-outlined p-button-secondary mr-3" label="Reset" icon="pi pi-refresh" onClick={() => handleReset(form)} disabled={pristine}></Button> : false}
                <Button type="submit" label={submitButtonLabel} icon="pi pi-check"></Button>
              </div>
            </form>
          )} />
      </Card >
    </div>
  )
}

export default ApplicationForm