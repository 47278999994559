import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Routes } from "../../../../constants/routes/admin/routes"
import store from "../../../../store"
import { ProgressBar } from "primereact/progressbar"


interface BreadcrumbsProps {
  currentRoute: Routes
  parentRoutes?: Routes[]
  isParent?: boolean
}

const BreadCrumbsItem = (props: BreadcrumbsProps) => {
  const { currentRoute, isParent} = props
  const state = useSelector((state: any) => state)
  const [ name, setName ] = useState('')
  const params = useParams()

  // construct link
  let link = currentRoute.compiledPath || ''
  for(const [key, value] of Object.entries(params)) {
    link = link.replace(':' + key, String(value))
  }

  // if match asterik, ignore link as 404
  if('*' === link) {
    link = ''
  }

  useEffect(() => {
    setName(currentRoute.breadcrumb.name(state) || '-')
  }, [state])

  useEffect(() => {
    // init if is parent
    if(currentRoute.breadcrumb.init !== undefined &&
      (currentRoute.breadcrumb.forceInit === true || isParent === true)) {
      store.dispatch(currentRoute.breadcrumb.init(params));
    }
    
    // unsubscribe to redux state change on unmount
    // return () => subscriber()
  }, [])

  return (
    <li>
      <Link to={link} className="p-menuitem-link">
        <span className="p-menuitem-text">{name}</span>
      </Link>
    </li>
  )
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { currentRoute, parentRoutes } = props
  return (
    <>
      <nav className="p-breadcrumb p-component surface-50 border-none">
        <ul className="p-breadcrumb-list">
          <li className="p-breadcrumb-home">
            <Link to="/home" className="p-menuitem-link">
              <span className="p-menuitem-icon pi pi-home"></span>
            </Link>
          </li>
          <li className="p-menuitem-separator pi pi-chevron-right"></li>
          {parentRoutes && parentRoutes.map((route: Routes, index: number) => {
            return (
              <React.Fragment key={index}>
                <BreadCrumbsItem currentRoute={route} isParent={route.path !== currentRoute.path} />
                {index < parentRoutes.length - 1 && 
                  <li className="p-menuitem-separator pi pi-chevron-right"></li>
                }
              </React.Fragment>
            )
          })}
        </ul>
      </nav>
    </>
  )
}

export default Breadcrumbs