import PrimeReact from "primereact/api";
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Menubar } from "primereact/menubar";
import { Panel } from 'primereact/panel';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { CustomSlotType, InterviewNode, QuestionType } from "../../../../CandidatePortal/scenes/Interview/Chatbot/interface";
import useUnload from "../../../../constants/helpers/useUnload";
/*
import * as clearReducerAction from "../../../../store/interviewFlow/clearReducerAction";
import * as createCustomSlotTypeAction from "../../../../store/interviewFlow/createCustomSlotTypeAction";
import * as createInterviewFlowAction from "../../../../store/interviewFlow/createInterviewFlowAction";
import * as createInterviewNodeAction from "../../../../store/interviewFlow/createInterviewNodeAction";
import * as deleteInterviewNodeAction from "../../../../store/interviewFlow/deleteInterviewNodeAction";
import * as getCustomSlotTypeAction from "../../../../store/interviewFlow/getCustomSlotTypeAction";
import * as importNodeAction from "../../../../store/interviewFlow/importNodeAction";
import * as setLoadingAction from "../../../../store/interviewFlow/setLoadingAction";
import * as updateInterviewFlowAction from "../../../../store/interviewFlow/updateInterviewFlowAction";
import * as updateInterviewNodeAction from "../../../../store/interviewFlow/updateInterviewNodeAction";
*/
import CreateCustomSlotDialog from "../components/Dialogs/CreateCustomSlotDialog";
import ExistingFlowDialog from "../components/Dialogs/ExistingFlowDialog";
import FlowTemplateDialog from "../components/Dialogs/FlowTemplateDialog";
import InterviewNodeForm from "../components/InterviewNodeForm";
import InterviewReactFlow from "../components/InterviewReactFlow";
import { useSelector, useDispatch } from "react-redux"
import { AppDispatch } from "../../../../store";
import { updateInterviewNodeList, deleteInterviewNode, updateNodeInNodeList, 
         setInterviewFlowEnum, addNodesToNodeList } from './InterviewFlowSlice'
import * as interviewFlowAction from "./InterviewFlowAction"
import {
  AutoLayoutState, CustomSlotTypeCreateStatus, FlowType, ImportNodeStatus, InterviewFlow,
  InterviewFlowGetStatus, InterviewFlowUpdateStatus, InterviewNodeCreateStatus,
  InterviewNodeDeleteStatus, InterviewNodeListStatus, InterviewNodeUpdateStatus
} from "../interface"
import { typesOfId } from "./enums"

/*
interface InterviewSetupProps {
  loading: boolean
  interviewFlowUpdateStatus: InterviewFlowUpdateStatus
  interviewNodeListStatus: InterviewNodeListStatus
  interviewNodeCreateStatus: InterviewNodeCreateStatus
  interviewNodeUpdateStatus: InterviewNodeUpdateStatus
  interviewNodeDeleteStatus: InterviewNodeDeleteStatus
  importNodeStatus: ImportNodeStatus
  customSlotTypeCreateStatus: CustomSlotTypeCreateStatus
  customSlotTypes: CustomSlotType[]
  addInterviewNodeToNodeList: (node: InterviewNode, autosave: boolean) => any
  updateInterviewNodeToNodeList: (oldNode: InterviewNode, node: InterviewNode, autosave: boolean) => any
  updateInterviewNodeList: (newNodeList: InterviewNode[]) => any
  deleteInterviewNodeFromNodeList: (node: InterviewNode, autosave: boolean) => any
  createInterviewFlow: (interviewFlow: InterviewFlow) => any
  updateInterviewFlow: (interviewFlow: InterviewFlow) => any
  updateFlowInterviewNodeList: (interviewNodeList: InterviewNode[]) => any
  setInterviewNodeListStatus: (interviewNodeListStatus: InterviewNodeListStatus) => any
  setInterviewFlowLoading: (interviewFlowLoading: boolean) => any
  setInterviewNodeCreateStatus: (interviewNodeCreateStatus: InterviewNodeCreateStatus) => any
  setInterviewNodeUpdateStatus: (interviewNodeUpdateStatus: InterviewNodeUpdateStatus) => any
  setInterviewNodeDeleteStatus: (interviewNodeDeleteStatus: InterviewNodeDeleteStatus) => any
  setImportNodeStatus: (importNodeStatus: ImportNodeStatus) => any
  createCustomSlot: (customSlotType: CustomSlotType) => any
  setCustomSlotTypeCreateStatus: (customSlotTypeCreateStatus: CustomSlotTypeCreateStatus) => any
  getAllCustomSlotTypesForTenant: () => any
  clearReducer: () => any
}
*/

const InterviewSetup = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { loading, error, customSlotTypes, interviewFlow, interviewNodeList, 
          existingInterviewFlows, interviewFlowGlobalTemplateListing, interviewFlowGlobalTemplateTotalRecords } 
          = useSelector((state: any) => state.interviewFlow)

  /* const {
    loading, interviewFlowUpdateStatus,
    interviewNodeListStatus, interviewNodeCreateStatus, interviewNodeUpdateStatus,
    interviewNodeDeleteStatus, importNodeStatus, customSlotTypeCreateStatus, customSlotTypes,
    addInterviewNodeToNodeList, updateInterviewNodeToNodeList,
    updateInterviewNodeList, deleteInterviewNodeFromNodeList, updateInterviewFlow,
    updateFlowInterviewNodeList, createInterviewFlow, setInterviewNodeListStatus, setInterviewFlowLoading,
    setInterviewNodeCreateStatus, setInterviewNodeUpdateStatus,
    setInterviewNodeDeleteStatus, setImportNodeStatus, createCustomSlot, setCustomSlotTypeCreateStatus,
    getAllCustomSlotTypesForTenant, clearReducer } = props */
  const [displaySideBar, setDisplaySideBar] = useState<boolean>(false);
  const [intentNode, setIntentNode] = useState<InterviewNode>({} as InterviewNode);
  const [isUpdate, setIsUpdate] = useState<boolean>();
  const [autosave, setAutosave] = useState<boolean>(false);
  const [autolayout, setAutolayout] = useState<boolean>(false);
  const [autoLayoutState, setAutoLayoutState] = useState<AutoLayoutState>(AutoLayoutState.NEUTRAL);
  const [interviewFlowName, setInterviewFlowName] = useState<string>("");
  const [interpretedState, setInterpretedState] = useState<InterpretedSetupAction>();
  const { interviewFlowId, interviewId, jobId } = useParams<{
    interviewFlowId: string,
    interviewId: string, jobId: string
  }>();
  const [displayFlowTemplateDialog, setDisplayFlowTemplateDialog] = useState<boolean>(false);
  const [displayExistingFlowDialog, setDisplayExistingFlowDialog] = useState<boolean>(false);
  const [displayCreateCustomSlotDialog, setDisplayCreateCustomSlotDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String>("")
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [firstNode, setFirstNode] = useState<InterviewNode>({} as InterviewNode)
  const [lastNode, setLastNode] = useState<InterviewNode>({} as InterviewNode)

  const history = useHistory();
  interface dispatchGetInterviewFlowPayload {
    id: number,
    typeOfId: string
  }

  enum InterpretedSetupAction {
    //To indicate whether the save action is for creating or updating existing flow
    CREATE, UPDATE
  }

  const toast = useRef<any>();
  PrimeReact.ripple = true
  const saveTimeoutID = useRef<NodeJS.Timeout>();
  const flowRef = useRef<any>();

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    interviewFlowAction.getAllCustomSlotTypesForTenant(); //Feature: should get in one http request with flow?
    //Handle creating/updating interviewFlow for interviewId

    let payload: dispatchGetInterviewFlowPayload = { id: 0, typeOfId: "" }
    if (interviewId)
      payload = { id: Number(interviewId), typeOfId: typesOfId[typesOfId.InterviewId] }
    //Handle creating/updating interviewFlow for jobId
    else if (jobId)
      payload = { id: Number(jobId), typeOfId: typesOfId[typesOfId.JobId] }
    //Handle creating/updating interviewFlow for global
    else if (interviewFlowId)
      payload = { id: Number(interviewFlowId), typeOfId: typesOfId[typesOfId.Id] }

    dispatch(interviewFlowAction.getInterviewFlow(payload))
      .unwrap()
      .then(() => {
        // successs
        toast.current.show({
          severity: 'success',
          summary: 'Interview Flow retrieved',
          detail: `Interview Flow has been loaded successfully`,
          life: 3000
        })

        /*
        if (interviewFlow && interviewFlow.interviewNodeList && interviewFlow.interviewNodeList.length > 0) {
          let tempNodeList: InterviewNode[]
          tempNodeList = interviewFlow.interviewNodeList.map((eachIntent: InterviewNode) => {
            return eachIntent
          })

          setNodeList(tempNodeList)
        }

        
        setInterpretedState(InterpretedSetupAction.UPDATE)
        setInterviewFlowName(interviewFlow.name)
        //setInterviewNodeListStatus(InterviewNodeListStatus.RETRIEVED)
        flowRef.current.fitView();
        setChangesMade(true);
        */
      })
      .catch((error: any) => {
        toast.current.show({
          severity: 'warn',
          summary: 'Interview Flow Error',
          detail: `Interview flow failed to load.`,
          life: 3000
        })
        console.log(error)
        setInterpretedState(InterpretedSetupAction.CREATE)
        flowRef.current.redoLayout(true); //Programmatically call for re-draw on Flow after clearing state
        setInterviewFlowName('')
      })
  }

  //first and last node tracking
  const findAndSetFirstAndLastNodes = () => {
    setFirstNode({} as InterviewNode)
    setLastNode({} as InterviewNode)
    if (!(interviewFlow === undefined || interviewFlow === null)) {
      if (!(interviewFlow.interviewNodeList === undefined || interviewFlow.interviewNodeList === null))
        interviewFlow.interviewNodeList.map((node: any) => {
          if (node.isFirstQuestion)
            setFirstNode(node)
          else if (node.isLastQuestion)
            setLastNode(node)
        })
    }
  }


  useEffect(() => {
    if (interviewFlow) {
      setDisplaySideBar(false)
      findAndSetFirstAndLastNodes()
      setInterpretedState(InterpretedSetupAction.UPDATE)
      setInterviewFlowName(interviewFlow.name)
      // if (interviewFlow.interviewNodeList && interviewFlow.interviewNodeList.length > 0) {
      //   let tempNodeList: InterviewNode[] = []
      //   interviewFlow.interviewNodeList.forEach((eachIntent: InterviewNode) => {
      //     tempNodeList.push({...eachIntent})
      //   })
      //   setNodeList(tempNodeList)
      // }

      //setInterviewNodeListStatus(InterviewNodeListStatus.RETRIEVED)
      flowRef.current.fitView()
      setChangesMade(true)
    }
  }, [interviewFlow])


  //Effect for calling Elk layout on import of selected nodes
  /* to be changed ****
  useEffect(() => {
    if (importNodeStatus === ImportNodeStatus.SUCCESS) {
      flowRef.current.redoLayout(false);
      setChangesMade(true);
    }
    setImportNodeStatus(ImportNodeStatus.NEUTRAL);
  }, [importNodeStatus])
  */

  useEffect(() => {
    setDisplaySideBar(false)
  }, [interviewNodeList])

  const saveInterviewFlow = async (timeout: boolean) => {
    console.log("Saving interview flow...:", interviewFlow);

    await new Promise<void>(resolve => {
      if (!interviewFlow.name)
        setErrorMessage("Please provide a name for the interview flow.")
      else
        setErrorMessage("")

      if (errorMessage) {
        if (!autosave)
          toast.current.show({
            severity: 'error',
            summary: "Save Failed",
            detail: errorMessage,
            life: 3000,
            id: 'saveError'
          })
      }
      else {
        if (timeout && saveTimeoutID.current)
          clearTimeout(saveTimeoutID.current);

        if (interviewId) { //If interviewId exist in url params, interpreted as creating flow for interview
          setInterviewFlowEnum(FlowType.INTERVIEW)
        }
        else if (jobId) { //If jobId exist in url params, interpreted as creating flow for job
          setInterviewFlowEnum(FlowType.JOB)
          //interviewFlow.jobId = Number(jobId)
        }
        else //No id exist in url params, create template flow
          setInterviewFlowEnum(FlowType.GLOBAL)

        if (interviewId || jobId || interviewFlowId) {
          dispatch(interviewFlowAction.createAndSaveInterviewFlow(interviewFlow))
            .unwrap()
            .then(() => {
              setDisplaySideBar(false)
              findAndSetFirstAndLastNodes()
            })
            .catch((error: any) => {
              console.log(error)
            })
        }
        /*
      if (timeout)
        saveTimeoutID.current = setTimeout(() => {
          setInterviewFlowLoading(true);
          createInterviewFlow(interviewFlow);
          setChangesMade(false);
        }, 1000);

      else {
        setInterviewFlowLoading(true);
        createInterviewFlow(interviewFlow);
        setChangesMade(false);
      };
      */
        //  setInterpretedState(InterpretedSetupAction.UPDATE) //On successful create, action state should be update
        else {
          if (timeout)
            saveTimeoutID.current = setTimeout(() => {
              dispatch(interviewFlowAction.updateInterviewFlow(interviewFlow))
                .unwrap()
                .then(() => {
                  setDisplaySideBar(false)
                  findAndSetFirstAndLastNodes()
                })
                .catch((error: any) => { console.log(error) })
              setChangesMade(false)
            }, 1000)
          else {
            dispatch(interviewFlowAction.updateInterviewFlow(interviewFlow))
              .unwrap()
              .then(() => {
                setDisplaySideBar(false)
                findAndSetFirstAndLastNodes()
              })
              .catch((error: any) => { console.log(error) })
            setChangesMade(false)
          }
        }
      }
      resolve()
    })
  }

  //Update interviewNodeList within interviewFlow whenever layout for nodeList is done
  useEffect(() => {
    if (autoLayoutState !== AutoLayoutState.NEUTRAL && interviewNodeList.length > 0) {
      updateMappedNodeListToInterviewNodeList(interviewNodeList)
      if (autosave)
        saveInterviewFlow(true)
      setAutoLayoutState(AutoLayoutState.NEUTRAL)
    }
  }, [autoLayoutState])

  const updateMappedNodeListToInterviewNodeList = async (nodeList: InterviewNode[]) => {
    await new Promise<void>(resolve => {
      let flowInterviewNodeList = nodeList.map((eachNode: InterviewNode) => {
        return {
          ...eachNode,
          interviewFlowId: interviewFlow.id
        }
      });

      updateInterviewNodeList(flowInterviewNodeList)
      resolve();
    })
  }

  const menuItems = [
    {
      label: 'New Node',
      icon: 'pi pi-plus-circle',
      items: [
        {
          label: 'Question',
          items: [
            {
              label: 'ConfirmationQuestion',
              command: () => {
                setIntentNode({ typeOfQuestion: QuestionType.ConfirmationQuestion } as InterviewNode);
                setIsUpdate(false);
                setDisplaySideBar(true);
              }
            },
            {
              label: 'OpenEndedQuestion',
              command: () => {
                setIntentNode({ typeOfQuestion: QuestionType.OpenEndedQuestion } as InterviewNode);
                setIsUpdate(false);
                setDisplaySideBar(true);
              }
            },
            {
              label: 'ConditionalQuestion',
              command: () => {
                setIntentNode({ typeOfQuestion: QuestionType.ConditionalQuestion } as InterviewNode);
                setIsUpdate(false);
                setDisplaySideBar(true);
              }
            },
            {
              label: 'DelegatedQuestion',
              command: () => {
                setIntentNode({ typeOfQuestion: QuestionType.DelegatedQuestion } as InterviewNode);
                setIsUpdate(false);
                setDisplaySideBar(true);
              }
            }
          ]
        }
      ]
    },
    {
      label: 'Custom Slots',
      icon: 'pi pi-left',
      items: [
        {
          label: 'New Custom Slot',
          command: () => setDisplayCreateCustomSlotDialog(true)
        }
      ]
    },
    {
      label: 'Import',
      icon: 'pi pi-download',
      items: [
        {
          label: 'Flow Templates',
          command: () => setDisplayFlowTemplateDialog(true)
        },
        {
          label: 'From Existing Flows',
          command: () => setDisplayExistingFlowDialog(true)
        }
      ]
    },
    {
      label: 'Save',
      icon: 'pi pi-save',
      command: () => saveInterviewFlow(false)
    }
  ]

  const end = () => {
    return (
      <div className="save-changes-icon flex flex-wrap align-items-center">
        <Tooltip target=".first-node-warning-icon" />
        {Object.keys(firstNode).length === 0 ?
          <i className="first-node-warning-icon pi pi-info-circle"
            data-pr-tooltip="No first node is set"
            data-pr-position="bottom"
            style={{ paddingRight: '10px' }} /> : <i className="first-node-warning-icon" />}
        <div style={{ paddingRight: '10px' }}>
          <Tooltip target=".save-state-icon" />
          {loading && autosave ?
            <i className="save-state-icon pi pi-spin pi-spinner"
              style={{ fontSize: '1rem' }} /> :
            errorMessage || error ?
              <i className="save-state-icon pi pi-times" data-pr-tooltip="Save failed" data-pr-position="bottom"
                style={{ color: 'red' }}></i> :
              interviewFlow !== null || interviewFlow !== undefined ?
                changesMade ? <i className="save-state-icon pi pi-replay"
                  data-pr-tooltip="Changes pending save" data-pr-position="bottom" style={{ color: 'orange' }}></i> :
                  <i className="save-state-icon pi pi-check-circle" data-pr-tooltip="Saved"
                    data-pr-position="bottom" style={{ color: 'green' }}></i> :
                <div></div>}
        </div>
        <Checkbox inputId="autosaveCheckbox" checked={autosave} onChange={e => setAutosave(e.checked || false)} tooltip="Saves on every node update" tooltipOptions={{ position: 'bottom' }}></Checkbox>
        <label htmlFor="autosaveCheckbox" className="ml-2 mr-2 p-checkbox-label">Autosave</label>
        <Checkbox inputId="autolayoutCheckbox" checked={autolayout} onChange={e => setAutolayout(e.checked || false)} tooltip="Layout on every node update" tooltipOptions={{ position: 'bottom' }}></Checkbox>
        <label htmlFor="autolayoutCheckbox" className="ml-2 p-checkbox-label">Autolayout</label>
      </div>
    )
  }

  //Create effect
  /*
  useEffect(() => {
    setDisplaySideBar(false)
    findAndSetFirstAndLastNodes()
    if (interviewNodeCreateStatus === InterviewNodeCreateStatus.success) {
      setInterviewNodeCreateStatus(InterviewNodeCreateStatus.neutral);
    }
  }, [interviewNodeCreateStatus])
  */

  //Update effect
  /*
  useEffect(() => {
    setDisplaySideBar(false)
    findAndSetFirstAndLastNodes()
    if (interviewNodeUpdateStatus === InterviewNodeUpdateStatus.success) {
      setInterviewNodeUpdateStatus(InterviewNodeUpdateStatus.neutral);
    }
  }, [interviewNodeUpdateStatus])
  */

  //Delete effect
  /*
  useEffect(() => {
    setDisplaySideBar(false)
    if (interviewNodeDeleteStatus === InterviewNodeDeleteStatus.success) {
      setInterviewNodeDeleteStatus(InterviewNodeDeleteStatus.neutral);
    }
  }, [interviewNodeDeleteStatus])
  */

  //Create Custom Slot Type Effect
  /*
  useEffect(() => {
    if (customSlotTypeCreateStatus === CustomSlotTypeCreateStatus.SUCCESS) {
      setDisplayCreateCustomSlotDialog(false)
    }
    setCustomSlotTypeCreateStatus(CustomSlotTypeCreateStatus.NEUTRAL);
  }, [customSlotTypeCreateStatus])
  */

  const handleNodeClick = useCallback((nodeClicked: { data: { label: string; }; }) => {
    let selectedNode = interviewNodeList.find((node: InterviewNode) => node.name === nodeClicked.data.label);
    if (selectedNode && Object.keys(selectedNode).length !== 0) {
      setIntentNode(selectedNode);
      setIsUpdate(true);
      setDisplaySideBar(true);
    } else {
      toast.current.show({ severity: 'error', summary: 'Error getting node details', detail: "The node that you have clicked may have been corrupted, please reload the page.", life: 3000 });
    }
  }, [interviewNodeList])

  const handleAutoLayoutState = useCallback((layoutState: SetStateAction<AutoLayoutState>) => {
    setAutoLayoutState(layoutState);
  }, [])

  const updateInterviewFlowName = (e: any) => {
    setInterviewFlowName(e.target.value);
    interviewFlow.name = (e.target.value);
    setChangesMade(true);
  }

  const start = () => {
    return (
      <span className="p-input-icon-right">
        <i className="pi pi-pencil" />
        <InputText className={classNames({ 'p-invalid': !interviewFlowName })} id="interviewFlowName" placeholder="Enter name" autoFocus value={interviewFlowName} onChange={updateInterviewFlowName} ></InputText>
      </span>
    )
  }

  const updateSetChangesMade = useCallback((changesMade: boolean | ((prevState: boolean) => boolean)) => {
    setChangesMade(changesMade);
  }, [])

  useUnload((e: any) => {
    e.preventDefault();
    if (changesMade)
      e.returnValue = "You have unsaved changes, are you sure you want to leave this page?"
  });

  return (
    <div>
      <Toast ref={toast} />
      <Prompt when={changesMade} message="You have unsaved changes. Are you sure you want to leave?"></Prompt>
      <FlowTemplateDialog
        interviewFlowGlobalTemplateListing={interviewFlowGlobalTemplateListing}
        interviewFlowGlobalTemplateTotalRecords={interviewFlowGlobalTemplateTotalRecords}
        loading={loading}
        getInterviewFlowGlobalTemplateListing={interviewFlowAction.getInterviewFlowGlobalTemplateListing}
        displayFlowTemplateDialog={displayFlowTemplateDialog}
        importInterviewFlowByInterviewFlowId={interviewFlowAction.getInterviewFlow}
        onHide={() => setDisplayFlowTemplateDialog(false)} ></FlowTemplateDialog>
      <ExistingFlowDialog
        interviewFlow={interviewFlow}
        existingFlowDialogListing={existingInterviewFlows}
        loading={loading}
        getExistingFlowsForTenant={interviewFlowAction.getExistingFlowsForTenant}
        getInterviewNodesFor={interviewFlowAction.getInterviewNodesByFlow}
        displayExistingFlowDialog={displayExistingFlowDialog}
        importInterviewFlowByInterviewFlowId={interviewFlowAction.getInterviewFlow}
        importNodes={addNodesToNodeList}
        onHide={() => setDisplayExistingFlowDialog(false)}></ExistingFlowDialog>
      <CreateCustomSlotDialog
        displayCreateCustomSlotDialog={displayCreateCustomSlotDialog}
        onHide={() => setDisplayCreateCustomSlotDialog(false)}
        actionOnSubmit={interviewFlowAction.createAndSaveCustomSlot}
        customSlotTypeOptions={customSlotTypes}></CreateCustomSlotDialog>
      <Menubar model={menuItems} start={start} end={end} />
      <Sidebar className="p-sidebar-md" visible={displaySideBar} onHide={() => setDisplaySideBar(false)}>
        <InterviewNodeForm
          //actionOnSubmit={isUpdate ? updateInterviewNodeToNodeList : addInterviewNodeToNodeList}
          actionOnSubmit={interviewFlowAction.updateNode}
          actionOnDelete={deleteInterviewNode}
          isUpdate={isUpdate}
          intentNode={intentNode}
          firstNode={firstNode}
          lastNode={lastNode}
          intentNameList={interviewNodeList.map((node: InterviewNode) => node.name)}
          autosave={autosave}
          customSlotTypes={customSlotTypes} />
      </Sidebar>
      <Panel>
        <InterviewReactFlow
          nodeList={interviewNodeList}
          setChangesMade={updateSetChangesMade}
          handleNodeClick={handleNodeClick}
          handleAutoLayoutState={handleAutoLayoutState}
          updateInterviewNodeList={updateInterviewNodeList}
          autolayout={autolayout}
          ref={flowRef} />
      </Panel>
    </div>
  )
}

/*
const mapStateToProps = (state: any) => {
  return {
    loading: state.interviewFlow.loading,
    interviewFlowUpdateStatus: state.interviewFlow.interviewFlowUpdateStatus,
    interviewNodeListStatus: state.interviewFlow.interviewNodeListStatus,
    interviewNodeCreateStatus: state.interviewFlow.interviewNodeCreateStatus,
    interviewNodeUpdateStatus: state.interviewFlow.interviewNodeUpdateStatus,
    interviewNodeDeleteStatus: state.interviewFlow.interviewNodeDeleteStatus,
    importNodeStatus: state.interviewFlow.importNodeStatus,
    customSlotTypeCreateStatus: state.interviewFlow.customSlotTypeCreateStatus,
    customSlotTypes: state.interviewFlow.customSlotTypes, //Feature: Can get with flow in 1 API request?
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addInterviewNodeToNodeList: (node: InterviewNode, autosave: boolean) => dispatch(createInterviewNodeAction.addInterviewNodeToNodeList(node, autosave)),
    updateInterviewNodeToNodeList: (oldNode: InterviewNode, node: InterviewNode, autosave: boolean) => dispatch(updateInterviewNodeAction.updateInterviewNodeToNodeList(oldNode, node, autosave)),
    updateInterviewNodeList: (newNodeList: InterviewNode[]) => dispatch(updateInterviewNodeAction.updateInterviewNodeList(newNodeList)),
    deleteInterviewNodeFromNodeList: (node: InterviewNode, autosave: boolean) => dispatch(deleteInterviewNodeAction.deleteInterviewNodeFromNodeList(node, autosave)),
    createInterviewFlow: (interviewFlow: InterviewFlow) => dispatch(createInterviewFlowAction.createInterviewFlow(interviewFlow)),
    updateInterviewFlow: (interviewFlow: InterviewFlow) => dispatch(updateInterviewFlowAction.updateInterviewFlow(interviewFlow)),
    updateFlowInterviewNodeList: (interviewNodeList: InterviewNode[]) => dispatch(updateInterviewFlowAction.updateFlowInterviewNodeList(interviewNodeList)),
    setInterviewNodeListStatus: (interviewNodeListStatus: InterviewNodeListStatus) => dispatch(updateInterviewNodeAction.setInterviewNodeListStatus(interviewNodeListStatus)),
    setInterviewFlowLoading: (interviewFlowLoading: boolean) => dispatch(setLoadingAction.setInterviewFlowLoading(interviewFlowLoading)),
    setInterviewNodeCreateStatus: (interviewNodeCreateStatus: InterviewNodeCreateStatus) => dispatch(createInterviewNodeAction.setInterviewNodeCreateStatus(interviewNodeCreateStatus)),
    setInterviewNodeUpdateStatus: (interviewNodeUpdateStatus: InterviewNodeUpdateStatus) => dispatch(updateInterviewNodeAction.setInterviewNodeUpdateStatus(interviewNodeUpdateStatus)),
    setInterviewNodeDeleteStatus: (interviewNodeDeleteStatus: InterviewNodeDeleteStatus) => dispatch(deleteInterviewNodeAction.setInterviewNodeDeleteStatus(interviewNodeDeleteStatus)),
    setImportNodeStatus: (importNodeStatus: ImportNodeStatus) => dispatch(importNodeAction.setImportNodeStatus(importNodeStatus)),
    createCustomSlot: (customSlotType: CustomSlotType) => dispatch(createCustomSlotTypeAction.createCustomSlotType(customSlotType)),
    setCustomSlotTypeCreateStatus: (customSlotTypeCreateStatus: CustomSlotTypeCreateStatus) => dispatch(createCustomSlotTypeAction.setCustomSlotTypeCreateStatus(customSlotTypeCreateStatus)),
    getAllCustomSlotTypesForTenant: () => dispatch(getCustomSlotTypeAction.getAllTenantCustomSlotTypes()),
    clearReducer: () => dispatch(clearReducerAction.clearReducer())
  }
}
*/

export default InterviewSetup