import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Form, Field } from "react-final-form";
import { Lookup } from "../../interfaces";
import { LOOKUPS_KEY } from "../../../../../constants/localStorageKeys";
import { CategoryEnum } from "../../enums";

import { connect } from 'react-redux';

interface AdminLookupAddDialogProps {
  displayAddLookupDialog: boolean
  onHide: any
  category: CategoryEnum
  actionOnSubmit: any
}

interface LookupInitialValuesInterface {
  value: string,
  description?: string,
  indexNo: string
}

const AdminLookupAddDialog = (props: AdminLookupAddDialogProps) => {
  const { displayAddLookupDialog, onHide, category, actionOnSubmit } = props

  let allLookups = localStorage.getItem(LOOKUPS_KEY);
  let categoryLookups: LookupInitialValuesInterface[] = [];
  if (allLookups && category) {
    categoryLookups = JSON.parse(allLookups)[category]
    if (categoryLookups) {
      categoryLookups.forEach((element, index) => {
        element.value = "After " + element.value
        element.indexNo = String(index + 1)
      })
    } else {
      categoryLookups = [];
    }
    categoryLookups.splice(0, 0, { value: "Last", indexNo: "last" })
    categoryLookups.splice(1, 0, { value: "First", indexNo: "first" })
  }

  let initialValues: LookupInitialValuesInterface = {
    value: "",
    description: "",
    indexNo: ""
  }
  let isFormFieldValid = (meta: any) => !!(meta.touched && meta.error);
  let getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  }

  const onSubmit = (data: LookupInitialValuesInterface, form: any) => {

    let payloadLookup: Lookup = {
      category: category,
      value: data.value,
      description: data.description,
      indexNo: data.indexNo === "first" ? 0 : data.indexNo === "last" ? categoryLookups.length - 2 : Number(data.indexNo)
    }

    actionOnSubmit({ lookup: payloadLookup })
  }

  const validate = (data: LookupInitialValuesInterface) => {
    let errors = {} as any;

    if (!data.value) {
      errors.value = 'Value is required.';
    }

    if (!data.indexNo) {
      errors.indexNo = 'Place Lookup is required.';
    }

    return errors;
  }

  return (
    <Dialog visible={displayAddLookupDialog} onHide={onHide}>
      <div className="p-d-flex p-jc-center" style={{ padding: "8px" }}>
        <Form id="create-lookup-form"
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Card >
                <h1 style={{ textAlign: "center" }}>Add Lookup for {category}</h1>
                <div className="formgrid grid">
                  <Field name="value" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                      <span className="p-float-label" style={{ width: "100%" }}>
                        <InputText id="value" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        <label htmlFor="value" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Value*</label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                  <Field name="description" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                      <span className="p-float-label" style={{ width: "100%" }}>
                        <InputText id="description" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        <label htmlFor="description">Description</label>
                      </span>
                    </div>
                  )} />
                  <Field name="indexNo" render={({ input, meta }) => (
                    <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                      <span className="p-float-label" style={{ width: "100%" }}>
                        <Dropdown id="indexNo" {...input} options={categoryLookups} optionLabel="value" optionValue="indexNo" style={{ width: "100%" }} />
                        <label htmlFor="indexNo" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Place Lookup*</label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )} />
                </div>
                <div className="flex justify-content-end align-items-end">
                  <Button label="Add" icon="pi pi-check"></Button>
                </div>
              </Card >
            </form>
          )} />
      </div>
    </Dialog>
  )
}

export default AdminLookupAddDialog
