import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../utils/Api'
import URI from 'urijs'
import { Tenant } from './interface'
import { processParams } from '../../../utils/LazyParamsUtil'

const endpoint = process.env.REACT_APP_TENANT_API_URL

export const retrieveTenants = createAsyncThunk('tenant/retrieveTenants',
  async (lazyParams: any, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'tenant/allTenants', processParams(lazyParams), rejectWithValue)
)

export const retrieveAllTenants = createAsyncThunk('tenant/retrieveAlllTenants',
  async (_, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'tenants', undefined, rejectWithValue)
)

export const retrieveTenant = createAsyncThunk('tenant/retrieveTenant',
  async (tenantId: Number, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'tenant/' + tenantId, undefined, rejectWithValue)
)

export const createTenant = createAsyncThunk('tenant/createTenant',
  async (tenant: Tenant, { rejectWithValue }) => Api(ApiMethod.POST, endpoint, 'tenant', tenant, rejectWithValue)
)

export const updateTenant = createAsyncThunk('tenant/updateTenant',
  async (tenant: Tenant, { rejectWithValue }) => Api(ApiMethod.PUT, endpoint, 'tenant', tenant, rejectWithValue)
)

export const disableTenants = createAsyncThunk('tenant/disableTenants',
  async (tenants: Tenant[], { rejectWithValue }) => Api(ApiMethod.DELETE, endpoint, 'tenant/status/false', tenants, rejectWithValue)
)

export const enableTenants = createAsyncThunk('tenant/enableTenants',
  async (tenants: Tenant[], { rejectWithValue }) => Api(ApiMethod.DELETE, endpoint, 'tenant/status/true', tenants, rejectWithValue)
)