import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../utils/Api'
import { Job } from './interface'
import { processParams } from '../../../utils/LazyParamsUtil'

const endpoint = process.env.REACT_APP_JOB_API_URL

export const retrieveJobs = createAsyncThunk('job/retrieveJobs',
  async (lazyParams: any, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'jobs', processParams(lazyParams), rejectWithValue)
)

export const retrieveJob = createAsyncThunk('job/retrieveJob',
  async (jobId: number, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'job/' + jobId, undefined, rejectWithValue)
)

export const deleteJobs = createAsyncThunk('job/deleteJobs',
  async (jobs: Job[], { rejectWithValue }) => Api(ApiMethod.DELETE, endpoint, 'job', jobs, rejectWithValue)
)

export const createJob = createAsyncThunk('job/createJob',
  async (job: Job, { rejectWithValue }) => Api(ApiMethod.POST, endpoint, 'job', job, rejectWithValue)
)

export const updateJob = createAsyncThunk('job/updateJob',
  async (job: Job, { rejectWithValue }) => Api(ApiMethod.PUT, endpoint, 'job', job, rejectWithValue)
)

export const deleteJob = createAsyncThunk('job/deleteJob',
  async (job: Job, { rejectWithValue }) => Api(ApiMethod.DELETE, endpoint, 'job', job, rejectWithValue)
)
