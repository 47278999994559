import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { useDispatch, useSelector } from "react-redux";

import moment from 'moment';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { AppDispatch } from '../../../../store';
import { downloadResumeForAdmin, retrieveApplication } from '../ApplicationAction';
import { InterviewDTO } from "../interface";

interface ApplicationViewProps {
}

const ApplicationView = (props: ApplicationViewProps) => {
  const {  } = props
  const { applicationId } = useParams<{ applicationId: string }>()

  const dispatch = useDispatch<AppDispatch>()
  const { application } = useSelector((state: any) => state.application)

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    dispatch(retrieveApplication(Number(applicationId)))
  }

  const handleDownloadResume = (e: any) => {
    e.preventDefault()
    downloadResumeForAdmin(application.resume.token)
  }

  return (
    <div>
      {application && application.resume ?
        <Card>
          <h1>Resume Details</h1>
          <Fieldset toggleable legend={application.resume.fullName}>
            <div className="flex formgrid grid">
              <div className="field col-6">
                Full Name: {application.resume.fullName}
              </div>
              <div className="field col-6">
                Contact Number: {application.resume.contactNo}
              </div>
              <div className="field col-6">
                E-Mail: {application.resume.email}
              </div>
              <div className="field col-6">
                ID Number: {application.resume.idNum}
              </div>
              <div className="field col-6">
                Gender: {application.resume.gender}
              </div>
              <div className="field col-6">
                Date of Birth: {application.resume.dob?.toString().substring(0, 10)}
              </div>
              <div className="field col-6">
                Nationality: {application.resume.nationality}
              </div>
              <div className="field col-6">
                Residence: {application.resume.residence}
              </div>
              <div className="field col-6">
                Address: {application.resume.address}
              </div>
              <div className="field col-6">
                Postal Code: {application.resume.postalCode}
              </div>
              <div className="field col-6">
                Qualification: {application.resume.qualification}
              </div>
              <div className="field col-6">
                Current Employment Info: {application.resume.currentEmploymentInfo}
              </div>
              <div className="field col-6">
                Current Employment Status: {application.resume.currentEmploymentStatus}
              </div>
              <div className="field col-6">
                Employment Type: {application.resume.employmentType}
              </div>
              <div className="field col-6">
                Availability: {application.resume.availStartDate?.toString().substring(0, 10)}
              </div>
              <div className="field col-6">
                Current Salary: {application.resume.currentSalary}
              </div>
              <div className="field col-6">
                Expected Salary: {application.resume.expectedSalary}
              </div>
              <div className="field col-6">
                Past Employment Info: {application.resume.pastEmploymentInfo}
              </div>
              <div className="field col-6">
                Years of Experience: {application.resume.yearsOfExp}
              </div>
              <div className="field col-6">
                Past Employed Companies: {application.resume.pastEmployedCompanies}
              </div>
              <div className="field col-6">
                Professional Certifications: {application.resume.professionalCerts}
              </div>
            </div>
            <div>
              <Button label="Get Resume File" onClick={handleDownloadResume}></Button>
            </div>
          </Fieldset>

          <h1>Job Details</h1>
          <Fieldset toggleable legend={application.job.title}>
            <div className="flex formgrid grid">
              <div className="field col-6">
                Status: {application.job.status}
              </div>
              <div className="field col-6">
                <Link to={"/jobs/" + application.job.id}>Link to Job Details</Link>
              </div>
              <div className="field col-6">
                Job Specialization: {application.job.specialization}
              </div>
              <div className="field col-6">
                Skills: {application.job.skills}
              </div>
              <div className="field col-6">
                Years of Experience: {application.job.yearsOfExp}
              </div>
              <div className="field col-6">
                Requirements: {application.job.requirements}
              </div>
              <div className="field col-6">
                Contract Type: {application.job.contractType}
              </div>
              <div className="field col-6">
                Tenant ID: {application.job.tenantId}
              </div>
            </div>
          </Fieldset>

          <h1>Interview Details</h1>
          {Object.keys(application.interviews).length !== 0 ?
            application.interviews.map((interview: InterviewDTO, index: number) => {
              return (
                <div key={index.toString()}>
                  <Fieldset toggleable legend={"Interview Attempt: " + ++index}>
                    <div className="flex formgrid grid">
                      <div className="field col-6">
                        Interview ID: <Link to={"/interviews/" + interview.id}>{interview.id}</Link>
                      </div>
                      <div className="field col-6">
                        Chat Session UUID: {interview.chatSessionId}
                      </div>
                      <div className="field col-6">
                        Application ID: {application.id}
                      </div>
                      <div className="field col-6">
                        Status: {interview.status}
                      </div>
                      <div className="field col-6">
                        Start Date: {moment(interview.startDateTime).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                      </div>
                      <div className="field col-6">
                        End Date: {moment(interview.endDateTime).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                      </div>
                    </div>
                  </Fieldset>
                  <Divider />
                </div>
              )
            }) :
            <Fieldset toggleable legend="No Interviews">
              <div className="flex formgrid grid">
                No interviews found for this application
              </div>
            </Fieldset>
          }
        </Card>
        :
        <></>}
    </div >
  )
}

export default ApplicationView

