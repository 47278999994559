import arrayMutators from 'final-form-arrays'
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { Fieldset } from 'primereact/fieldset'
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { useState } from "react"
import { Field, Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { IntentDetails, InterviewNode, OpenEndedQuestion, QuestionType } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../../store'

import OnChange from "../../../../../../utils/OnChange"
import styles from "../styles.module.css"

interface OpenEndedFormProps {
  actionOnSubmit: any
  actionOnDelete: any
  intentNode: OpenEndedQuestion
  firstNode: InterviewNode
  lastNode:InterviewNode
  intentNameList?: string[]
  isUpdate?: boolean
  autosave: boolean
  onQuestionTypeChange: (newQuestionType: any) => void
}

const OpenEndedForm = (props: OpenEndedFormProps) => {
  const { actionOnSubmit, actionOnDelete, intentNode, firstNode, lastNode, intentNameList, 
    isUpdate = false, autosave, onQuestionTypeChange } = props
  // const [chips, setChips] = useState<string[]>(initialValues.chips ? initialValues.chips : [])

  let initialValues: OpenEndedQuestion = !!intentNode ? { ...intentNode, } : {} as OpenEndedQuestion

  const dispatch = useDispatch<AppDispatch>()

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.typeOfQuestion) {
      errors.typeOfQuestion = 'The type of question must be specified'
    }

    if (!data.name) {
      errors.name = 'Please specify the name of the node'
    } else if (!isUpdate && data.name) {
      if (intentNameList && intentNameList.length > 0 && intentNameList.find(element => element === data.name) !== undefined) {
        errors.name = 'This name is currently in use by another node'
      }
    }

    if (data.isFirstQuestion && Object.keys(firstNode).length !== 0 && !initialValues.isFirstQuestion)
        errors.isFirstQuestion = "First node already exists"
      if (data.isLastQuestion && Object.keys(lastNode).length !== 0  && !initialValues.isLastQuestion)
        errors.isLastQuestion = "Last node already exists"

    if (data.slotElicitationSetting?.slotElicitationQuestionPrompts === undefined || data.slotElicitationSetting?.slotElicitationQuestionPrompts.length === 0) {
      errors.questionPrompts = "Please input at least one elicitation prompt."
    }

    return errors;
  }

  const onSubmit = (data: any, form: any) => {
    let payload = {} as InterviewNode
    payload = data
    // payload.chips = chips
    payload.position = data.position ? data.position : { x: 0, y: 0 }
    if (isUpdate)
      actionOnSubmit(initialValues, payload, autosave)
    else
      actionOnSubmit(payload, autosave)
    console.log(payload)
    // setChips([])
    form.restart()
  }

  const deleteNode = (form: any) => {
    dispatch(actionOnDelete(initialValues, autosave))
    form.restart()
  }

  const getExcludedNameList = (values: any) => {
    if (!!values) {
      let tempIntentNameList = intentNameList?.slice(0)
      if (tempIntentNameList) {
        let valueIndex = tempIntentNameList.indexOf(values)
        if (valueIndex !== -1)
          tempIntentNameList.splice(valueIndex, 1)
      }
      return tempIntentNameList
    }
    return intentNameList
  }

  const required = (value: any) => value ? undefined : 'This is a required field'

  const isFormFieldValid = (meta: any) => !!((meta.touched || meta.modified) && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  const WhenLastQuestionIsChecked = ({ set, to }: { set: string, to: any }) => {
    return (
      <Field name={set} subscription={{}}>
        {(
          // No subscription. We only use Field to get to the change function
          { input: { onChange } }
        ) => {
          return (
            <OnChange name='isLastQuestion'>
              {(value: boolean) => {
                if (value === true) {
                  onChange(to);
                }
              }}
            </OnChange>
          )
        }}
      </Field>
    )
  }

  return (
    <div>
      <h3>{isUpdate ? "Update new Open Ended node" : "Create new Open Ended node"}</h3>
      <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">
        An Open Ended node type is intended to capture free-text as-is from the interviewee.
        <br></br>
        Note that for this type of node, no NLU (Natural Language Understanding) utility will be used.
      </p>

      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate} render={({ form: { mutators: { push, pop, remove } }, form, values, handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <WhenLastQuestionIsChecked set="slotElicitationSetting.successStep" to={undefined} />
            <Fieldset className={styles.nodeform} legend="Node Details" toggleable>
              <div className="formgrid grid">
                <Field name="typeOfQuestion" render={({ input, meta }) => (
                  <div className="field col-6">
                    <label htmlFor="typeOfQuestionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                    <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="name" render={({ input, meta }) => (
                  <div className="field col-6">
                    <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                    <InputText id="nameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                {/* <Field name="scoreIfNo" render={({ input }) => (
                  <div className="field col-6 md:col-6">
                    <label htmlFor="scoreIfNoInput">Score if Unfulfilled</label>
                    <InputText id="scoreIfNoInput" autoComplete="off" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                  </div>
                )} />
                <Field name="scoreIfYes" render={({ input }) => (
                  <div className="field col-6 md:col-6">
                    <label htmlFor="scoreIfYesInput">Score if Fulfilled</label>
                    <InputText id="scoreIfYesInput" autoComplete="off" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                  </div>
                )} /> */}
                <Field name="isFirstQuestion" type="checkbox" render={({ input, meta }) => (
                  <div className="field-checkbox field col-12 md:col-6">
                    <Checkbox inputId="isFirstQuestionInput" {...input} checked={input.checked || false} />
                    <label htmlFor="isFirstQuestionInput">Is this node the first question of the interview?</label>
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="isLastQuestion" type="checkbox" render={({ input, meta }) => (
                  <div className="field-checkbox field col-12 md:col-6">
                    <Checkbox inputId="isLastQuestionInput" {...input} checked={input.checked || false} />
                    <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
              </div>
            </Fieldset>

            <Fieldset className={styles.nodeform} legend="Elicitation Prompt" toggleable>
              <div className="formgrid grid">
                <Field name="questionPrompts" render={({ meta }) => (
                  <div className="col-12">
                    <div className="xl:col-7 md:col-12">
                      <span className={classNames({ 'p-error': isFormFieldValid(meta) })} style={{ display: "inline-block", marginBottom: "0.5rem" }}>Elicitation Prompts*</span>
                      <br></br>{getFormErrorMessage(meta)}
                    </div>
                    <div className="xl:col-4 md:col-12">
                      <Button className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} label="Add Prompt" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.slotElicitationSetting && values.slotElicitationSetting.slotElicitationQuestionPrompts && values.slotElicitationSetting.slotElicitationQuestionPrompts.length >= 3)} onClick={() => push('slotElicitationSetting.slotElicitationQuestionPrompts', "")}></Button>
                    </div>
                  </div>
                )} />
              </div>
              <FieldArray name="slotElicitationSetting.slotElicitationQuestionPrompts">
                {({ fields }) =>
                  fields.map((questionPrompt, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Prompt #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`slotElicitationSetting.slotElicitationQuestionPrompts[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="slotElicitationQuestionPromptsInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('slotElicitationSetting.slotElicitationQuestionPrompts', index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </Fieldset>

            <Fieldset className={styles.nodeform} legend="On Success" toggleable>
              <div className="formgrid grid">
                {/* <Field name="slotElicitationSetting.slotName" render={({ input, meta }) => (
                  <div className="field col-6">
                    <label htmlFor="slotNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Name*</label>
                    <InputText id="slotNameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} /> */}
                <Field name="slotElicitationSetting.successStep" render={({ input, meta }) => (
                  <div className="col-12">
                    <div className="field col-12">
                      <label htmlFor="successStepInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Next Step</label>
                      <Dropdown id="successStepInput" showClear disabled={values.isLastQuestion} tooltip="The next dialog action upon capturing a response" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />                    {getFormErrorMessage(meta)}
                    </div>
                    <div className="col-4">
                      <Button label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.slotElicitationSetting && values.slotElicitationSetting.successResponse && values.slotElicitationSetting.successResponse.length >= 3)} onClick={() => push('slotElicitationSetting.successResponse', "")}></Button>
                    </div>
                  </div>
                )} />

                <FieldArray name="slotElicitationSetting.successResponse">
                  {({ fields }) =>
                    fields.map((successResponse, index) => (
                      <div key={index.toString()} className="col-12">
                        <h3 className="col-12">Capture Response #{index + 1}</h3>
                        <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                          <Field name={`slotElicitationSetting.successResponse[${index}]`} validate={required} render={({ input, meta }) => (
                            <div>
                              <div>
                                <InputText id="successResponseInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                                {getFormErrorMessage(meta)} {/*Can remove validation and error class if optional*/}
                              </div>
                              <div>
                                <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('slotElicitationSetting.successResponse', index)}></Button>
                              </div>
                            </div>
                          )} />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
                {/* <Field name="slotType" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="slotTypeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Type*</label>
                  <InputText id="slotTypeInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} /> */}
              </div>
            </Fieldset>

            {/* <Card>
              <div className="formgrid grid">
                <Field name="typeOfQuestion" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="typeOfQuestionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                    <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="name" render={({ input, meta }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                    <InputText id="nameInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                    {getFormErrorMessage(meta)}
                  </div>
                )} />
                <Field name="slotElicitationSetting.questionPrompts" render={({ input }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="nextIntentInput">Next Node*</label>
                    <Dropdown id="nextIntentInput" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to upon finishing this intent" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                  </div>
                )} />
                <Field name="slotElicitationSetting.nextIntent" render={({ input }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="nextIntentInput">Next Node*</label>
                    <Dropdown id="nextIntentInput" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to upon finishing this intent" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                  </div>
                )} />
                <Field name="chips" render={({ input }) => (
                  <div className="field col-12 md:col-6">
                    <label htmlFor="chipsInput">Chips</label>
                    <Chips id="chipsInput" {...input} value={chips} onChange={(e) => setChips(e.value)} separator="," className="inputfield w-full" />
                  </div>
                )} />
                <Field name="multiChips" type="checkbox" render={({ input }) => (
                  <div className="field-checkbox col-12 md:col-6">
                    <Checkbox inputId="multiChipsInput" {...input} />
                    <label htmlFor="multiChipsInput">Allow multiple chips input?</label>
                  </div>
                )} />
                <Field name="isLastQuestion" type="checkbox" render={({ input }) => (
                  <div className="field-checkbox field col-12 md:col-6">
                    <Checkbox inputId="isLastQuestionInput" {...input} />
                    <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                  </div>
                )} />
              </div> */}
            <div className="flex justify-content-end align-items-end mt-2">
              <Button type="button" className="p-button-outlined p-button-danger mr-3" label="Delete" icon="pi pi-trash" onClick={() => deleteNode(form)} disabled={!isUpdate}></Button>
              <Button type="submit" label={isUpdate ? "Update" : "Create"} icon="pi pi-check" disabled={pristine}></Button>
            </div>
            {/* </Card> */}
          </form>
        )
      }} />
    </div>
  )
}

export default OpenEndedForm