import axios from "axios";
import { JWT_KEY } from '../constants/localStorageKeys'

export enum ApiMethod {
  GET = 'get',
  POST = 'post',
  DELETE = 'delete',
  PUT = 'put'
}

export async function Api(method: ApiMethod, endpoint: string | undefined, path: string, body?: any, rejectWithValue?: any) {
  if (!endpoint) {
    throw new Error("endpoint is not specified");
  }
  
  try {
    const isCandidate = endpoint.indexOf('candidate') !== -1 || path.indexOf('candidate') !== -1
    const isMultipart = body instanceof FormData
    const { data } = await axios.request({
      url: endpoint + '/' + path,
      method,
      params: method === ApiMethod.GET && body,
      data: body,
      headers: {
        ...!isCandidate && {'Authorization': 'Bearer ' + localStorage.getItem(JWT_KEY)},
        ...isMultipart && {'Content-Type': 'multipart/form-data'}
      }
    })
    return data
  } catch (error: any) {
    if (rejectWithValue !== undefined) {
      if (error.response) {
        if (error.response.data)
          return rejectWithValue(error.response.data)
        return rejectWithValue(error.response)
      } else
        return rejectWithValue('no response from server')
    }
  }
}