import { Dialog } from "primereact/dialog"
import { ProgressSpinner } from "primereact/progressspinner"

interface ProgressSpinnerDialogProps {
  visible: boolean
  style?: any
  text?: string
}

const ProgressSpinnerDialog = (props: ProgressSpinnerDialogProps) => {
  const { visible, style, text } = props

  return (
    <Dialog className="flex flex-column" visible={visible} style={!!style ? style : {}} draggable={false} closable={false} onHide={() => { }}>
      <ProgressSpinner className="flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }} />
      {text}
    </Dialog>
  )
}

export default ProgressSpinnerDialog
