import { DataTable, DataTableFilterMeta } from "primereact/datatable"
import { Toast } from "primereact/toast"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { InterviewListing, InterviewType } from "../interface"

import { FilterMatchMode } from "primereact/api"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Column } from "primereact/column"
import { Dropdown } from "primereact/dropdown"
import { Toolbar } from "primereact/toolbar"
import { InterviewStatusEnum } from "../../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import { ADMIN_ROUTES } from "../../../../constants/routes/admin/menus"
import { AppDispatch } from "../../../../store"
import { createInterview, retrieveInterviews } from "../InterviewAction"
import CreateInterviewDialog from "../components/Dialogs/CreateInterviewDialog"

interface InterviewManagementProps {
}

const InterviewManagement = (props: InterviewManagementProps) => {
  const {  } = props
  const toast = useRef<any>()
  const dt = useRef<any>(null)
  const history = useHistory()

  const dispatch = useDispatch<AppDispatch>()
  const { interviewList, totalRecords } = useSelector((state: any) => state.interview)

  let filters: DataTableFilterMeta = {
    intervieweeName: { value: null, matchMode: FilterMatchMode.EQUALS },
    intervieweeEmail: { value: null, matchMode: FilterMatchMode.EQUALS },
    startDateTime: { value: null, matchMode: FilterMatchMode.EQUALS },
    endDateTime: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdDate: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [rows, setRows] = useState(5)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: "createdDate",
    sortOrder: -1,
    filters
  })
  const [displayCreateInterviewDialog, setDisplayCreateInterviewDialog] = useState<boolean>(false)

  //Lazy loading
  useEffect(() => {
    getInterviews()
  }, [lazyParams])

  const getInterviews = () => {
    dispatch(retrieveInterviews(lazyParams))
  }

  const handleViewApplicationBtn = (rowData: any) => {
    history.push(`${ADMIN_ROUTES.ROUTE_INTERVIEWS_ADMIN}/${rowData.id}`)
  }

  const handleConfigureInterviewFlowBtn = (rowData: InterviewListing) => {
    history.push(`${ADMIN_ROUTES.ROUTE_INTERVIEWS_ADMIN}/${rowData.id}/setup`)
  }

  const statuses = Object.keys(InterviewStatusEnum).filter(k => isNaN(Number(k)))
  const types = Object.keys(InterviewType).filter(k => isNaN(Number(k)))

  // Custom Filter Details
  const [selectedCreateDate, setSelectedCreateDate] = useState(undefined)
  const [selectedStartDate, setSelectedStartDate] = useState(undefined)
  const [selectedEndDate, setSelectedEndDate] = useState(undefined)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedType, setSelectedType] = useState(null)

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams)
  }

  const onCreatedDateChange = (e: any) => {
    setSelectedCreateDate(e.value)
    if (e.value && e.value[0] != null && e.value[1] != null) {
      let startDate = `${e.value[0].getFullYear()}-${e.value[0].getMonth() + 1}-${e.value[0].getDate()}`
      let endDate = `${e.value[1].getFullYear()}-${e.value[1].getMonth() + 1}-${e.value[1].getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.createdDateLt = { value: startDate, matchMode: FilterMatchMode.EQUALS  }
      _lazyParams.filters.createdDateGt = { value: endDate , matchMode: FilterMatchMode.EQUALS }
      setLazyParams(_lazyParams)
    } else {
      let _lazyParams = { ...lazyParams }
      // _lazyParams.filters.createdDateLt = {}
      // _lazyParams.filters.createdDateGt = {}
      setLazyParams(_lazyParams)
    }
  }

  const onStartDateChange = (e: any) => {
    setSelectedStartDate(e.value)
    if (e.value) {
      let startDate = `${e.value.getFullYear()}-${e.value.getMonth() + 1}-${e.value.getDate()}`
      let endDate = `${e.value.getFullYear()}-${e.value.getMonth() + 1}-${e.value.getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.startDateTimeLt = { value: startDate, matchMode: FilterMatchMode.EQUALS  }
      _lazyParams.filters.startDateTimeGt = { value: endDate, matchMode: FilterMatchMode.EQUALS  }
      setLazyParams(_lazyParams)
    } else {
      let _lazyParams = { ...lazyParams }
      // _lazyParams.filters.startDateTimeLt = {}
      // _lazyParams.filters.startDateTimeGt = {}
      setLazyParams(_lazyParams)
    }
  }

  const onEndDateChange = (e: any) => {
    setSelectedEndDate(e.value)
    if (e.value) {
      let startDate = `${e.value.getFullYear()}-${e.value.getMonth() + 1}-${e.value.getDate()}`
      let endDate = `${e.value.getFullYear()}-${e.value.getMonth() + 1}-${e.value.getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.endDateTimeLt = { value: startDate, matchMode: FilterMatchMode.EQUALS  }
      _lazyParams.filters.endDateTimeGt = { value: endDate, matchMode: FilterMatchMode.EQUALS  }
      setLazyParams(_lazyParams)
    } else {
      let _lazyParams = { ...lazyParams }
      // _lazyParams.filters.endDateTimeLt = {}
      // _lazyParams.filters.endDateTimeGt = {}
      setLazyParams(_lazyParams)
    }
  }

  const onSelectedStatusChange = (e: any) => {
    setSelectedStatus(e.value)
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.status = { value: e.value, matchMode: FilterMatchMode.EQUALS  }
    setLazyParams(_lazyParams)
  }

  const onSelectedTypeChange = (e: any) => {
    setSelectedType(e.value)
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.type = { value: e.value, matchMode: FilterMatchMode.EQUALS  }
    setLazyParams(_lazyParams)
  }

  const dateFilter = <Calendar value={selectedCreateDate} showButtonBar readOnlyInput selectionMode="range" onChange={onCreatedDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>
  const startDateFilter = <Calendar value={selectedStartDate} showButtonBar maxDate={selectedEndDate} readOnlyInput selectionMode="single" onChange={onStartDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>
  const endDateFilter = <Calendar value={selectedEndDate} showButtonBar minDate={selectedStartDate} readOnlyInput selectionMode="single" onChange={onEndDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>
  const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onSelectedStatusChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const typeFilter = <Dropdown value={selectedType} options={types} onChange={onSelectedTypeChange} placeholder="All" className="p-column-filter" showClear></Dropdown>

  const handleResetFilters = () => {
    setSelectedCreateDate(undefined)
    setSelectedStartDate(undefined)
    setSelectedEndDate(undefined)
    setSelectedStatus(null)
    setSelectedType(null)
    dt.current.reset();
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters = {}
    setLazyParams(_lazyParams)
  }

  const header = (
    <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={handleResetFilters} />
    </div>
  )

  const createdDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.createdDate)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const startDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.startDateTime)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const endDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.endDateTime)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const viewAndConfigureInterviewTemplate = (rowData: any) => {
    return (
      <div>
        <Button icon="pi pi-cog" disabled={rowData.type === InterviewType.APPLICATION} tooltip="Configure a chatbot interview flow for this Interview." tooltipOptions={{position: 'left'}} className="p-button-secondary mr-2" onClick={() => handleConfigureInterviewFlowBtn(rowData)}></Button>
        <Button icon="pi pi-search" tooltip="View details for this interview." tooltipOptions={{position: 'left'}} className="p-button-secondary mr-2" onClick={() => handleViewApplicationBtn(rowData)}></Button>
      </div>
    )
  }

  const showCreateInterviewDialog = (e: any) => {
    e.preventDefault();
    setDisplayCreateInterviewDialog(true);
  }

  const toolbar = () => {
    return (
      <Button label="New" icon="pi pi-file" className="p-button-primary mr-2" onClick={showCreateInterviewDialog}></Button>
    )
  }

  const tenantCreateInterviewSubmit = (data: any) => {
    console.log(data)
    dispatch(createInterview(data)).unwrap()
      .then(() => {
        getInterviews()
        setDisplayCreateInterviewDialog(false)
        toast.current.show({ 
          severity: 'success', 
          summary: 'Create Success', 
          detail: `Interview for ${data.intervieweeName} (${data.intervieweeEmail}) successfully created.`, 
          life: 3000 
        })
      })
      .catch(() => {
        getInterviews()
        setDisplayCreateInterviewDialog(false)
        toast.current.show({ 
          severity: 'warn', 
          summary: 'Create Error', 
          detail: `Interview for ${data.intervieweeName} (${data.intervieweeEmail}) creation failed.`, 
          life: 3000 
        })
      })
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="datatable-doc-demo">
        <div className="card">
          <Toolbar className="p-mb-4" left={toolbar}></Toolbar>
          <CreateInterviewDialog displayCreateInterviewDialog={displayCreateInterviewDialog} onHide={() => setDisplayCreateInterviewDialog(false)} actionOnSubmit={tenantCreateInterviewSubmit} ></CreateInterviewDialog>
          <DataTable ref={dt} value={interviewList} first={lazyParams.first} rows={rows} totalRecords={totalRecords} loading={interviewList === undefined} header={header}
            onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
            onFilter={onFilter} filters={lazyParams.filters} filterDisplay="row"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            lazy paginator>
            <Column field="intervieweeName" header="Full Name" filter sortable></Column>
            <Column field="intervieweeEmail" header="E-Mail" filter sortable></Column>
            <Column field="startDateTime" header="Start Date" body={startDateBodyTemplate} filter sortable filterElement={startDateFilter}></Column>
            <Column field="endDateTime" header="End Date" body={endDateBodyTemplate} filter sortable filterElement={endDateFilter}></Column>
            <Column field="status" header="Status" filter filterElement={statusFilter} sortable></Column>
            <Column field="type" header="Type" filter filterElement={typeFilter} sortable></Column>
            <Column field="createdDate" header="Created Date" body={createdDateBodyTemplate} filter filterElement={dateFilter} sortable></Column>
            <Column body={viewAndConfigureInterviewTemplate} headerStyle={{ width: '12em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default InterviewManagement

