import { JWT_KEY } from "../../localStorageKeys"
import jwt_decode from "jwt-decode"

export interface JwtFormat {
  name: string
  tenantId: number
  tenantName: string
  scope: []
}

const decodeJwt = () => {
  let jwt = localStorage.getItem(JWT_KEY)
  let jwtDecoded: JwtFormat = { name: "", tenantId: 0, tenantName: "", scope: [] }
  if (!!jwt)
    jwtDecoded = jwt_decode(jwt)

  return jwtDecoded
}

export const getNameFromJwt = () => {
  let jwtDecoded = decodeJwt()
  if (!!jwtDecoded)
    return jwtDecoded.name
  else return ""
}

export const getScopesFromJwt = () => {
  let jwtDecoded = decodeJwt()
  if (!!jwtDecoded)
    return jwtDecoded.scope
  else return []
}

export const checkScopeExistsInJwt = (scope: string) => {
  let jwtDecoded = decodeJwt()
  if (!!jwtDecoded) {
    let jwtScopes: string[] = jwtDecoded.scope
    return jwtScopes.includes(scope)
  }
  else return false
}

export const getTenantNameFromJwt = () => {
  let jwtDecoded = decodeJwt()
  if (!!jwtDecoded)
    return jwtDecoded.tenantName
  else return ""
}

export const getTenantIdFromJwt = () => {
  let jwtDecoded = decodeJwt()
  if (!!jwtDecoded)
    return jwtDecoded.tenantId
  else return 0
}