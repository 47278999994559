import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import ProgressSpinnerDialog from "../../../constants/helpers/ProgressSpinnerDialog";
import { LOOKUPS_KEY } from "../../../constants/localStorageKeys";
import { CategoryEnum, LookupStatus } from "./enums";
import { Lookup } from "./interfaces";
import AdminLookupAddDialog from './Dialogs/LookupAddDialog';

import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from "../../../store";
import { createLookup, deleteLookup, deleteLookups, retrieveAllLookup, updateLookup } from "./LookupAction";

interface LookupProps {
}

interface LookupPayload {
  lookup: Lookup
}

interface LookupsPayload {
  lookups: Lookup[]
}

const LookupManagement = (props: LookupProps) => {
  const toast = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>()

  const [category, setCategory] = useState<any>(null);
  const [categoryLookups, setCategoryLookups] = useState<Lookup[]>([]);
  const [originalRows,] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLookups, setSelectedLookups] = useState([] as Lookup[]);
  const [lookup, setLookup] = useState<any>(null);

  const [displayAddLookupDialog, setDisplayAddLookupDialog] = useState(false);

  const [deleteLookupDialog, setDeleteLookupDialog] = useState(false);
  const [deleteLookupsDialog, setDeleteLookupsDialog] = useState(false);

  const categoryList = Object.keys(CategoryEnum).filter(k => isNaN(Number(k)));
  let allLookups = localStorage.getItem(LOOKUPS_KEY);

  const createLookupAction = async (payload: LookupPayload) => {
    dispatch(createLookup(payload.lookup)).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'Lookup Create Success', 
          detail: `Lookup (${payload.lookup.value}) successfully created`, 
          life: 3000
        })
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'error', 
          summary: 'Lookup Create Error', 
          detail: `Lookup (${payload.lookup.value}) creation failed`, 
          life: 3000
        })
      })
  }

  const onCategoryChange = async (event: any) => {
    setCategory(event.value);
    if (!allLookups) {
      setLoading(true);
      dispatch(retrieveAllLookup()).unwrap().then(() => {
        setLoading(false);
        allLookups = localStorage.getItem(LOOKUPS_KEY)
        if (!allLookups) {
          toast.current.show({ severity: 'error', summary: 'Fail to load Lookup table', life: 3000 });
          return;
        }
        setCategoryLookups(JSON.parse(allLookups)[event.value])
      })
    } else {
      setCategoryLookups(JSON.parse(allLookups)[event.value])
    }
  }

  const onRowReorder = (event: any) => {
    event.value.forEach((element: Lookup, index: number) => {
      element.indexNo = index;
    });
    setCategoryLookups(event.value);
    dispatch(updateLookup(event.value))
  }

  const onRowEditInit = (event: any) => {
    originalRows[event.index] = { ...categoryLookups[event.index] };
    setEditMode(true);
  }

  const onRowEditCancel = (event: any) => {
    let tempCategoryLookups = [...categoryLookups];
    tempCategoryLookups[event.index] = originalRows[event.index];
    delete originalRows[event.index];

    setCategoryLookups(tempCategoryLookups);
    let editCheck = false;
    originalRows.forEach((element: any) => {
      if (element) editCheck = true;
    });
    setEditMode(editCheck);
  }

  const onRowEditComplete = (event: any) => {
    let { newData, index } = event

    // validate
    if (!newData.value || newData.value.length <= 0) {
      onRowEditCancel(event);
      toast.current.show({ severity: 'error', summary: 'Value cannot be empty!', life: 3000 });
      return;
    }

    let _categoryLookups = [...categoryLookups]
    _categoryLookups[index] = newData

    // set back to state
    setCategoryLookups(_categoryLookups)

    // call api
    dispatch(updateLookup(newData)).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'Lookup Update Success', 
          detail: `Lookup (${event.data.value}) successfully updated`, 
          life: 3000
        })
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'error', 
          summary: 'Lookup Update Error', 
          detail: `Lookup (${event.data.value}) update failed`, 
          life: 3000
        })
      })

    delete originalRows[event.index];
    let editCheck = false;
    originalRows.forEach((element: any) => {
      if (element) editCheck = true;
    });
    setEditMode(editCheck);
  }

  const inputTextEditor = (options: any) => {
    return <InputText type="text" value={options.value || ''} onChange={(event) => options.editorCallback(event.target.value)} />;
  }

  const addNewLookup = () => {
    setDisplayAddLookupDialog(true)
  }

  const confirmDeleteSelected = () => {
    setDeleteLookupsDialog(true);
  }

  const confirmDeleteLookup = (lookup: Lookup) => {
    setLookup(lookup);
    setDeleteLookupDialog(true);
  }

  const deletingLookup = () => {
    dispatch(deleteLookup(lookup))
    setDeleteLookupDialog(false);
    setLookup(null);
  }

  const deleteSelectedLookups = () => {
    dispatch(deleteLookups(selectedLookups)).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'Lookup Delete Success', 
          detail: `${selectedLookups.length} lookups successfully deleted`, 
          life: 3000
        })
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'error', 
          summary: 'Lookup Delete Error', 
          detail: `${selectedLookups.length} lookups delete failed`, 
          life: 3000
        })
      })
    setDeleteLookupsDialog(false);
  }

  const deleteLookupDialogFooter = (
    <Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => { setDeleteLookupDialog(false) }} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletingLookup} />
    </Fragment>
  );

  const deleteLookupsDialogFooter = (
    <Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => { setDeleteLookupsDialog(false) }} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedLookups} />
    </Fragment>
  );

  const deleteBodyTemplate = (rowData: any) => {
    return (
      <Fragment>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-plain" onClick={() => confirmDeleteLookup(rowData)} disabled={editMode} />
      </Fragment>
    );
  }

  return (
    <div>
      <Toast ref={toast} />

      <div className="p-d-flex p-jc-center p-2">
        <Card>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <Dropdown id="category" options={categoryList} value={category} onChange={onCategoryChange} className="w-full" disabled={editMode} />
                <label htmlFor="category">Category</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <Button label="Add" icon="pi pi-plus" className="p-button-primary mr-2" onClick={addNewLookup} disabled={!(category && allLookups) || editMode} />
              <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedLookups || !selectedLookups.length || editMode} />
            </div>

            <div className="field col-12">
              <DataTable value={categoryLookups} onRowReorder={onRowReorder} 
              dataKey="id" editMode="row" onRowEditInit={onRowEditInit} 
              onRowEditComplete={onRowEditComplete} selection={selectedLookups} 
              onSelectionChange={(e) => {
                const lookUps = e.value as Lookup[]
                setSelectedLookups(lookUps)
              }}>
                <Column selectionMode="multiple" headerClassName="w-3rem"></Column>
                <Column rowReorder className="w-3rem" />
                <Column field="value" header="Value" editor={(options) => inputTextEditor(options)}></Column>
                <Column field="description" header="Description" editor={(options) => inputTextEditor(options)}></Column>
                <Column rowEditor headerClassName="w-7rem" bodyClassName="pr-0 text-right" ></Column>
                <Column body={deleteBodyTemplate} className="w-7rem" bodyClassName="pl-0"></Column>
              </DataTable>
            </div>
          </div>
        </Card>
      </div>
      <AdminLookupAddDialog displayAddLookupDialog={displayAddLookupDialog} onHide={() => setDisplayAddLookupDialog(false)} category={category} actionOnSubmit={createLookupAction} />
      <ProgressSpinnerDialog visible={loading} />
      <Dialog visible={deleteLookupDialog} header="Confirm" modal footer={deleteLookupDialogFooter} onHide={() => setDeleteLookupDialog(false)}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {lookup && <span>Are you sure you want to delete <b>{lookup.value}</b>?</span>}
        </div>
      </Dialog>

      <Dialog visible={deleteLookupsDialog} header="Confirm" modal footer={deleteLookupsDialogFooter} onHide={() => setDeleteLookupsDialog(false)}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {selectedLookups && <span>Are you sure you want to delete the selected lookups?</span>}
        </div>
      </Dialog>
    </div>
  )
}

export default LookupManagement