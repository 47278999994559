import moment from "moment";

export const formatDate = (date: any) => {
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month;
  }

  if (day < 10) {
    day = '0' + day;
  }

  return date.getFullYear() + '-' + month + '-' + day;
}

//For use by moments.js
export const dateFormats = [
  moment.ISO_8601,
  'Do MMMM Y', 'MMMM Do Y', 'Y MMMM Do', 'Y Do MMMM', //1st January 1994
  'Do MMM Y', 'MMM Do Y', 'Y MMM Do', 'Y Do MMM', //1st Jan 1994
  'D MMMM Y', 'MMMM D Y', 'Y MMMM D', 'Y D MMMM', //1 January 1994
  'D MMM Y', 'MMM D Y', 'Y MMM D', 'Y D MMM', //1 Jan 1994
  'Do-MMMM-Y', 'MMMM-Do-Y', 'Y-MMMM-Do', 'Y-Do-MMMM', //1st-January-1994
  'Do-MMM-Y', 'MMM-Do-Y', 'Y-MMM-Do', 'Y-Do-MMM', //1st-Jan-1994
  'D-MMMM-Y', 'MMMM-D-Y', 'Y-MMMM-D', 'Y-D-MMMM', //1-January-1994
  'D-MMM-Y', 'MMM-D-Y', 'Y-MMM-D', 'Y-D-MMM', //1-Jan-1994
  'D/M/Y', 'M/D/Y', 'Y/M/D', 'Y/D/M', //1/1/1994
  'D-M-Y', 'M-D-Y', 'Y-M-D', 'Y-D-M' //1-1-1994
]

export const getTimeNow = () => {
  return (new Date()).toTimeString().substr(0,5)
}
