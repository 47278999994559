export interface Job {
  createdBy?: string
  createdDate?: string
  updatedBy?: string
  updatedDate?: string
  id?: number
  status?: JobStatusEnum
  title: string
  specialization: string
  careerLevels: any
  employmentType: string
  qualifications: any
  skills: any
  yearsOfExp: number
  contractType: string
  minSalary: number
  maxSalary: number
  location: string
  languages: any
  responsibilities: string
  scopes: string
  area: string
  maxRetries: number
  interviewable: boolean
  description: string
  requirements: string
  tenantId?: number
}

export interface JobListing {
	id?: number
	title: string
}

export enum JobStatusEnum {
  AVAILABLE = 'AVAILABLE',
  REVIEWING = 'REVIEWING',
  TAKEN = 'TAKEN'
}

export enum JobCreateStatus {
  success = 'success',
  fail = 'fail',
  neutral = 'neutral'
}

export enum JobDeleteStatus { 
  success = 'success',
  fail = 'fail',
  neutral = 'neutral'
}

export enum JobUpdateStatus { 
  success = 'success',
  fail = 'fail',
  neutral = 'neutral'
}

export enum SetupQuestionsStatus {
  success = 'success',
  fail = 'fail',
  neutral = 'neutral'
}