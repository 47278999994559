import { link } from "fs"
import { ProgressBar } from "primereact/progressbar"
import { Tooltip } from "primereact/tooltip"
import { TooltipOptions } from "primereact/tooltip/tooltipoptions"
import { classNames } from "primereact/utils"
import { useSelector, shallowEqual } from "react-redux"
import { Link } from "react-router-dom"

interface LinkButtonProps {
  to: any
  className?: string
  icon?: string
  iconOnly?: boolean
  label?: string
  tooltip?: string
  tooltipOptions?: TooltipOptions
}

const LinkButton = (props: LinkButtonProps) => {
  const { to, className, icon, iconOnly, label, tooltip, tooltipOptions } = props
  const linkId = Math.random().toString(36).slice(-5);
  return (
    <>
      {tooltip && <Tooltip target={`[data-tooltip-id='${linkId}']`}  {...tooltipOptions} content={tooltip} />}
      <Link 
        to={to} 
        data-tooltip-id={linkId}
        className={classNames(`p-button p-component no-underline ${className}`, {
          'p-button-secondary': !className || className.indexOf('p-button-') === -1,
          'p-button-icon-only': iconOnly,
          'tooltip': !tooltip,
        })}
        style={{position: 'relative'}}
      >
        {icon && <span className={`p-button-icon p-c p-button-icon-left pi pi-${icon}`}></span>}
        <span className="p-button-label p-c">{label || '&nbsp;'}</span>
        <span role="presentation" className="p-ink" style={{height: '39.2px', width: '39.2px', top: '-1.10001px', left: '-0.400024px'}}></span>
      </Link>
    </>
  )
}

export default LinkButton