import { Dialog } from "primereact/dialog"
import { CustomSlotType } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import CreateCustomSlotForm from "../../Forms/CreateCustomSlotForm"

interface CreateCustomSlotDialogProps {
  displayCreateCustomSlotDialog: boolean
  customSlotTypeOptions: any
  onHide: any
  actionOnSubmit: any
}

const CreateCustomSlotDialog = (props: CreateCustomSlotDialogProps) => {

  const { displayCreateCustomSlotDialog, customSlotTypeOptions, onHide, actionOnSubmit } = props

  return (
    <Dialog className="sm:w-12 md:w-10 lg:w-8 xl:w-6 h-26rem" header="Create New Custom Slot" visible={displayCreateCustomSlotDialog} onHide={onHide} >
      <CreateCustomSlotForm formHeader={"Create Custom Slot Form"} customSlotTypeOptions={customSlotTypeOptions} actionOnSubmit={actionOnSubmit} submitButtonLabel={"Create"} initialValues={{} as CustomSlotType}></CreateCustomSlotForm>
    </Dialog>
  )
}

export default CreateCustomSlotDialog