import arrayMutators from 'final-form-arrays'
import PrimeReact from "primereact/api"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Chips } from 'primereact/chips'
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { Field, Form } from "react-final-form"
import { FieldArray } from 'react-final-form-arrays'
import { CustomSlotType } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"

interface CreateCustomSlotFormProps {
  formHeader: string
  actionOnSubmit: any
  submitButtonLabel: string
  initialValues: CustomSlotType
  customSlotTypeOptions: any
}

const CreateCustomSlotForm = (props: CreateCustomSlotFormProps) => {
  const { customSlotTypeOptions, actionOnSubmit, submitButtonLabel, initialValues } = props
  PrimeReact.ripple = true

  const validate = (data: CustomSlotType) => {
    let errors = {} as any

    if (!data.slotTypeName) {
      errors.slotTypeName = 'Please enter a name.'
    }
    else {
      customSlotTypeOptions.forEach((slotType: any, index: number) => {
        console.log(slotType)
        if (slotType.slotTypeName === data.slotTypeName) 
          errors.slotTypeName = 'A slot type of the same name already exists.'
      })
    }

    if (!data.customSlotTypeValues || data.customSlotTypeValues.length === 0) {
      errors.customSlotTypeValues = 'At least 1 slot type value is required.'
    }

    return errors
  }

  const onSubmit = (data: CustomSlotType, form: any) => {
    console.log({ data })
    actionOnSubmit(data)
  }

  const required = (value: any) => value ? undefined : 'This is a required field'

  const checkForDuplicate = (value: string[]) => {
    if (value && value.length > 0) {
      let tempArr = value.map(element => element.toLowerCase())
      if (new Set(tempArr).size !== value.length)
        return 'Please check if there are any duplicated synonym values';
      return undefined;
    }
    return undefined;
  }

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  return (
    <div>
      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate} render={({ form: { mutators: { push, pop } }, form, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid mt-1">
            <Field name="slotTypeName" render={({ input, meta }) => (
              <div className="field col-8">
                {/* <label htmlFor="slotTypeNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Type Name*</label> */}
                <InputText id="slotTypeNameInput" {...input} autoFocus type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} placeholder="Custom Slot Name" keyfilter="alphanum" />
                {getFormErrorMessage(meta)}
              </div>
            )} />
            <div className="field col-4">
              <Field name="customSlotTypeValues" render={({ meta }) => (
                <Button className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} label="Add Slot Type Value" type="button" icon="pi pi-plus" iconPos="right" onClick={() => push('customSlotTypeValues', "")}></Button>
              )} />
            </div>
          </div>
          <Card style={{ minHeight: "30vh" }}>
            <FieldArray name="customSlotTypeValues">
              {({ fields }) => {
                if (fields.length && fields.length > 0) {
                  return (
                    fields.map((customSlotTypeValue, index) => (
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field formgrid grid" key={index.toString()}>
                        <Field name={`${customSlotTypeValue}.slotTypeValue`} validate={required} render={({ input, meta }) => (
                          <div className="field col-5">
                            <InputText id="slotTypeValueInput" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} placeholder="Value" />
                            {getFormErrorMessage(meta)}
                          </div>
                        )} />
                        <Field name={`${customSlotTypeValue}.synonyms`} validate={checkForDuplicate} render={({ input, meta }) => (
                          <div className="field col-6">
                            <Chips id="synonymsInput" {...input} separator="," allowDuplicate={false} className="inputfield w-full" placeholder="Synonyms" />
                            {getFormErrorMessage(meta)}
                          </div>
                        )} />
                        <div>
                          <Button className="field p-button-danger" type="button" icon="pi pi-times" iconPos="right" onClick={() => fields.remove(index)}></Button>
                        </div>
                      </div>
                    ))
                  )
                }
                else {
                  return (
                    <Field name="customSlotTypeValues" render={({ meta }) => (
                      <div>
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />
                  )
                }
              }}
            </FieldArray>
            {/* <Field name="customSlotTypeValues.slotTypeValue" render={({ input, meta }) => (
                <div className="field col-12">
                  <label htmlFor="slotTypeNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Type Name*</label>
                  <InputText id="slotTypeNameInput" {...input} autoFocus type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} placeholder="Custom Slot Name" />
                  {getFormErrorMessage(meta)}
                </div>
              )} /> */}
          </Card>
          <div className="flex justify-content-end align-items-end mt-2">
            <Button type="submit" label={submitButtonLabel} icon="pi pi-check"></Button>
          </div>
          {/* </Card > */}
        </form>
      )} />
    </div >
  )
}

export default CreateCustomSlotForm