import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { connect } from "react-redux";

interface FileUploaderProps {
  headerText: string
  uploadResume: (resumeData: FormData) => any
}

const FileUploader = (props: FileUploaderProps) => {
  const { headerText, uploadResume, } = props

  const beginUpload = (file: Blob) => {
    let formData = new FormData();
    formData.append("file", file);
    console.log('file', file)
    console.log('formdata', formData)
    uploadResume(formData)
  }

  const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type })

  const fileUploadHandler = async (upload: FileUploadHandlerEvent) => {
    const [file] = upload.files
    beginUpload(await fileToBlob(file))
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-upload mt-3 p-5" style={{ 'fontSize': '3em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop Resume Here</span>
      </div>
    )
  }

  return (
    <div>
      <h4>{headerText}</h4>
      <p style={{ fontSize: "0.8em" }}>Accepted file formats: <em>(.jpeg, .png, .pdf, .tiff)</em></p>
      <p style={{ fontSize: "0.8em" }}>Max file size: <em>10MB</em></p>
      <FileUpload
        style={{ width: '25vw' }}
        accept=".JPEG, .PNG, .PDF, .TIFF"
        customUpload={true}
        uploadHandler={fileUploadHandler}
        auto={true}
        maxFileSize={10000000}
        emptyTemplate={emptyTemplate}
      />
    </div>
  )
}

export default FileUploader