import { ProgressBar } from "primereact/progressbar"
import { useSelector, shallowEqual } from "react-redux"

interface ProgressLoadingIndicatorProps {
}

const ProgressLoadingIndicator = (props: ProgressLoadingIndicatorProps) => {
  const { loading } = useSelector((state: any) => state.global)
  return (
    <ProgressBar mode="indeterminate" style={{ position: 'absolute', top: 0, width: '100%', height: '6px' }} hidden={!loading}></ProgressBar>
  )
}

export default ProgressLoadingIndicator