import { LexRuntimeV2Client, Message } from '@aws-sdk/client-lex-runtime-v2'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { InputTextarea } from 'primereact/inputtextarea'
import { useState } from 'react'
import { getTimeNow } from '../../../../../../constants/helpers/dateFormatter'
import { InterviewNode, MessageDetails } from '../../interface'

interface UserInputProps {
  client: LexRuntimeV2Client
  params: any
  currentIntent: InterviewNode
  dialogAction: any
  sendMessage: (client: LexRuntimeV2Client, messageDetails: MessageDetails, params: any) => any
}

const UserInput = (props: UserInputProps) => {

  const { client, params, currentIntent, dialogAction, sendMessage } = props
  const [text, setText] = useState('')
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false)

  const handleSend = async (e: any) => {
    setIsWaitingForResponse(true)
    let message: Message = { content: text, contentType: 'string' }
    let messageInfo = { message, header: 'Me', timeStamp: getTimeNow() }
    let utterParams = {
      ...params,
      inputStream: '',
      requestContentType: 'text/plain; charset=utf-8',
      responseContentType: 'text/plain; charset=utf-8',
    }
    await sendMessage(client, messageInfo, utterParams)
    setText('')
    setIsWaitingForResponse(false)
    document.getElementById("message")!.focus()
  }

  const chipSend = async (text: string) => {
    setIsWaitingForResponse(true)
    let message: Message = { content: text, contentType: 'string' }
    let messageInfo = { message, header: 'Me', timeStamp: getTimeNow() }
    let utterParams = {
      ...params,
      inputStream: '',
      requestContentType: 'text/plain; charset=utf-8',
      responseContentType: 'text/plain; charset=utf-8',
    }
    await sendMessage(client, messageInfo, utterParams)
    setText('')
    setIsWaitingForResponse(false)
    document.getElementById("message")!.focus()
  }

  const handlePress = async (e: any) => {
    const keyCode = e.keyCode || e.which
    const shiftHeld = e.nativeEvent.shiftKey

    if (keyCode === 13 && !shiftHeld) {
      if (isBlank(text)) {
        setText('')
      }
      else
        handleSend(e)
    }
  }

  const validateUserInput = async (e: string) => {
    if (!isBlank(e)) { //Only allow input of non-empty whitespace
      setText(e)
    } else if (e === '') { //Allow deletion of entire string
      setText(e)
    }
  }

  const isBlank = (str: string) => { //https://stackoverflow.com/questions/154059/how-can-i-check-for-an-empty-undefined-null-string-in-javascript
    return (!str || /^\s*$/.test(str))
  }

  const handleChipsClick = (e: any) => {
    if (currentIntent.multiChips) {
      if (isBlank(text))
        setText(e.target.innerText)
      else
        setText(text + " " + e.target.innerText)
    }
    else {
      chipSend(e.target.innerText)
    }
  }

  const renderChips = () => {
    if (dialogAction) {
      if (dialogAction.type === "ConfirmIntent") //NOTE: A secondary layer to render "Yes" and "No" chips for confirmation steps in addition to what is already configured in backend.
        return (
          <>
            <Button className="p-button-text p-button-rounded p-button-raised bg-white ml-2" label="Yes" onClick={handleChipsClick}></Button>
            <Button className="p-button-text p-button-rounded p-button-raised bg-white ml-2" label="No" onClick={handleChipsClick}></Button>
          </>
        )
      else if (dialogAction.type === "ElicitSlot") {
        return currentIntent.chips?.map((chip) => {
          return <Button key={chip} className="p-button-text p-button-rounded p-button-raised bg-white ml-2" label={chip} onClick={handleChipsClick}></Button>
        })
      }
      else
        return <div className="mb-2 pt-2" style={{ height: '30%' }}></div>
    }
  }

  return (
    <Card style={{ height: '20vh' }}>
      <div className="flex justify-content-center flex-wrap mb-2 pt-2" style={{ height: '30%' }}>
        {renderChips()}
      </div>
      <div className="flex justify-content-center align-content-center" style={{ height: '70%' }}>
        <InputTextarea id="message" autoFocus disabled={isWaitingForResponse} rows={2} autoResize value={text} onChange={(e) => validateUserInput(e.target.value)} placeholder="Type a message" onKeyPress={handlePress} style={{ width: '95%' }} />
        <Button icon="pi pi-send" disabled={isWaitingForResponse || isBlank(text)} className="justify-content-end align-self-center ml-3 p-button-lg p-button-rounded p-button-secondary p-button-text" onClick={handleSend} />
      </div>
    </Card>
  )
}

export default UserInput