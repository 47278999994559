import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../../utils/Api'
import { FlowType, InterviewFlow } from '../interface'
import { CustomSlotType, InterviewNode } from '../../../../CandidatePortal/scenes/Interview/Chatbot/interface'
import { updateNodeInNodeList } from './InterviewFlowSlice'
import URI from 'urijs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../store'

const interviewFlowEndpoint = process.env.REACT_APP_INTERVIEW_API_URL
const customSlotTypeEndpoint = process.env.REACT_APP_CHATBOT_API_URL
const customSlotEndpoint = process.env.REACT_APP_CHATBOT_API_URL

export const getInterviewFlow =
  createAsyncThunk('interviewFlow/',
    async ({ id, typeOfId }: { id: number, typeOfId: string }, { rejectWithValue }) =>
      Api(ApiMethod.GET, interviewFlowEndpoint, 'getInterviewFlowBy' + typeOfId + '/' + id, {}, rejectWithValue)
  )

export const getAllCustomSlotTypesForTenant =
  createAsyncThunk('/getCustomSlotTypes',
    async (_, { rejectWithValue }) =>
      Api(ApiMethod.GET, customSlotTypeEndpoint, '', {}, rejectWithValue)
  )

export const createAndSaveInterviewFlow =
  createAsyncThunk('createInterviewFlow/',
    async (interviewFlow: InterviewFlow, { rejectWithValue }) =>
      Api(ApiMethod.POST,
        interviewFlowEndpoint, 'createInterviewFlow', interviewFlow, rejectWithValue)
  )

export const updateInterviewFlow =
  createAsyncThunk('/interviewFlow',
    async (interviewFlow: InterviewFlow, { rejectWithValue }) =>
      Api(ApiMethod.PUT,
        interviewFlowEndpoint,
        interviewFlow.interviewFlowEnum === FlowType.GLOBAL ?
          "updateInterviewFlowTemplate" : "updateInterviewFlow",
        interviewFlow, rejectWithValue)
  )

export const createAndSaveCustomSlot =
  createAsyncThunk('/createCustomSlotType',
    async (customSlotType: CustomSlotType, { rejectWithValue }) =>
      Api(ApiMethod.POST,
        customSlotEndpoint, '', JSON.stringify(customSlotType), rejectWithValue)
  )

export const getInterviewNodesByFlow =
  createAsyncThunk('/getInterviewNodesFor/',
    async (interviewFlowId: number, { rejectWithValue }) =>
      Api(ApiMethod.GET,
        interviewFlowEndpoint, 'getInterviewNodesFor/' + interviewFlowId, {}, rejectWithValue)
  )

export const getExistingFlowsForTenant =
  createAsyncThunk('getExistingFlowsForTenant/',
    async (interviewFlowId: number, { rejectWithValue }) =>
      Api(ApiMethod.GET,
        interviewFlowEndpoint, 'getExistingFlowsForTenant/' + interviewFlowId, {}, rejectWithValue)
  )

export const getInterviewFlowGlobalTemplateListing =
  createAsyncThunk('getInterviewFlowGlobalTemplateListing/',
    async ({ lazyParams, interviewFlowId } : {lazyParams: any, interviewFlowId: number}, 
            { rejectWithValue }) =>
      Api(ApiMethod.GET,
        interviewFlowEndpoint, 
        new URI('getInterviewFlowGlobalTemplateListing/' + interviewFlowId)
        .addQuery('page', lazyParams.page)
        .addQuery('size', lazyParams.rows)
        .addQuery('sort', lazyParams.sortField + ',' + (lazyParams.sortOrder === 1 ? 'ASC' : 'DESC'))
        .toString(), 
        {}, rejectWithValue))
  
export const updateNode = (updatedNode: InterviewNode) => async () => {
  const dispatch = useDispatch<AppDispatch>()
  await dispatch(updateNodeInNodeList(updatedNode))
}