import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constants/routes/admin/menus";
import { AppDispatch } from "../../../../store";
import { createInterviewFlow, retrieveInterviewFlows } from "../InterviewAction";
import CreateInterviewFlowDialog from "../components/Dialogs/CreateInterviewFlowDialog";

interface InterviewFlowListingProps {
}

const InterviewFlowListing = (props: InterviewFlowListingProps) => {
  const { } = props
  const toast = useRef<any>()
  const history = useHistory();
  const dt = useRef<any>(null)

  const dispatch = useDispatch<AppDispatch>()
  const { interviewFlowList, totalRecords } = useSelector((state: any) => state.interview)

  let filters: DataTableFilterMeta = {
    name: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdDate: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [rows, setRows] = useState(5)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: "createdDate",
    sortOrder: -1,
    filters
  })
  const [displayCreateInterviewFlowDialog, setDisplayCreateInterviewFlowDialog] = useState<boolean>(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  //Lazy loading
  useEffect(() => {
    getInterviewFlows()
  }, [lazyParams])

  const getInterviewFlows = () => {
    dispatch(retrieveInterviewFlows(lazyParams))
  }

  // Custom Filter Details
  const [selectedCreateDate, setSelectedCreateDate] = useState(undefined)

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams)
  }

  const onCreatedDateChange = (e: any) => {
    setSelectedCreateDate(e.value)
    if (e.value && e.value[0] != null && e.value[1] != null) {
      let startDate = `${e.value[0].getFullYear()}-${e.value[0].getMonth() + 1}-${e.value[0].getDate()}`
      let endDate = `${e.value[1].getFullYear()}-${e.value[1].getMonth() + 1}-${e.value[1].getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.createdDateLt = { 'value': startDate, matchMode: FilterMatchMode.EQUALS }
      _lazyParams.filters.createdDateGt = { 'value': endDate, matchMode: FilterMatchMode.EQUALS }
      setLazyParams(_lazyParams)
    } else {
      let _lazyParams = { ...lazyParams }
      // _lazyParams.filters.createdDateLt = {}
      // _lazyParams.filters.createdDateGt = {}
      setLazyParams(_lazyParams)
    }
  }

  const dateFilter = <Calendar value={selectedCreateDate} showButtonBar readOnlyInput selectionMode="range" onChange={onCreatedDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>

  const handleResetFilters = () => {
    setSelectedCreateDate(undefined)
    dt.current.reset();
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters = {}
    setLazyParams(_lazyParams)
  }

  const header = (
    <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={handleResetFilters} />
    </div>
  )

  const createdDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.createdDate)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const viewAndConfigureInterviewFlowTemplate = (rowData: any) => {
    return (
      <div>
        <Button icon="pi pi-cog" tooltip="Configure a chatbot interview flow for this Interview." tooltipOptions={{position: 'left'}} className="p-button-secondary mr-2" onClick={() => handleConfigureInterviewFlowBtn(rowData)}></Button>
        {/* <Button icon="pi pi-search" tooltip="View details for this interview." className="p-button-secondary mr-2" onClick={() => handleViewApplicationBtn(rowData)}></Button> */}
      </div>
    )
  }

  const handleConfigureInterviewFlowBtn = (rowData: any) => {
    history.push(`${ADMIN_ROUTES.ROUTE_TEMPLATES_ADMIN}/${rowData.id}`)
  }

  const showCreateInterviewFlowDialog = (e: any) => {
    e.preventDefault();
    setDisplayCreateInterviewFlowDialog(true);
  }

  const toolbar = () => {
    return (
      <Button label="New" icon="pi pi-file" className="p-button-primary mr-2" onClick={showCreateInterviewFlowDialog}></Button>
    )
  }

  const createInterviewFlowSubmit = (data: any) => {
    dispatch(createInterviewFlow(data)).unwrap()
      .then(() => {
        getInterviewFlows()
        setDisplayCreateInterviewFlowDialog(false)
        toast.current.show({ 
          severity: 'success', 
          summary: 'Create Success', 
          detail: `Interview Flow (${data.name}) successfully created.`, 
          life: 3000 
        })
      })
      .catch(() => {
        getInterviewFlows()
        setDisplayCreateInterviewFlowDialog(false)
        toast.current.show({ 
          severity: 'warn', 
          summary: 'Create Error', 
          detail: `Interview Flow (${data.name}) creation failed.`, 
          life: 3000 
        })
      })
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="datatable-doc-demo">
        <div className="card">
          <Toolbar className="p-mb-4" left={toolbar}></Toolbar>
          {/* <ConfirmDialog visible={showConfirmDialog} onHide={() => setShowConfirmDialog(false)} message="Successfully created new interview flow, do you want to configure this interview flow now?" header="Confirmation" icon="pi pi-exclamation-triangle" reject={() => setShowConfirmDialog(false)} accept={() => history.push(`${ADMIN_ROUTES.ROUTE_TEMPLATES_ADMIN}/${createdInterviewFlowTemplate.id}`)}></ConfirmDialog> */}
          <CreateInterviewFlowDialog displayCreateInterviewFlowDialog={displayCreateInterviewFlowDialog} onHide={() => setDisplayCreateInterviewFlowDialog(false)} actionOnSubmit={createInterviewFlowSubmit} ></CreateInterviewFlowDialog>
          <DataTable ref={dt} value={interviewFlowList} first={lazyParams.first} rows={rows} totalRecords={totalRecords} loading={!interviewFlowList} header={header}
            onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
            onFilter={onFilter} filters={lazyParams.filters} filterDisplay="row"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            lazy paginator>
            <Column field="name" header="Flow Name" filter sortable></Column>
            <Column field="createdDate" header="Created Date" body={createdDateBodyTemplate} filter filterElement={dateFilter} sortable></Column>
            <Column body={viewAndConfigureInterviewFlowTemplate} headerStyle={{ width: '12em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default InterviewFlowListing