import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react"
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
//import * as getInterviewFlowAction from "../../../../../../store/interviewFlow/getInterviewFlowAction"
//import * as getInterviewFlowGlobalTemplateListingAction from "../../../../../../store/interviewFlow/getInterviewFlowGlobalTemplateListingAction"
import { InterviewFlow } from "../../../interface"
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import styles from './styles.module.css';
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store";
import { typesOfId } from "../../../InterviewSetup/enums";

interface FlowTemplateDialogProps {
  displayFlowTemplateDialog: boolean
  onHide: any
  interviewFlowGlobalTemplateListing: InterviewFlow[]
  interviewFlowGlobalTemplateTotalRecords: number
  loading: boolean
  getInterviewFlowGlobalTemplateListing: (lazyParams: any, interviewFlowId: number) => any
  importInterviewFlowByInterviewFlowId: ({ id, typeOfId }: { id: number, typeOfId: string }) => any
}

const FlowTemplateDialog = (props: FlowTemplateDialogProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const {
    displayFlowTemplateDialog, onHide, interviewFlowGlobalTemplateListing, 
    interviewFlowGlobalTemplateTotalRecords, loading,
    getInterviewFlowGlobalTemplateListing, importInterviewFlowByInterviewFlowId } = props
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [layout, setLayout] = useState<'list' | 'grid'>('grid')
  const [showConfirmImportDialog, setShowConfirmImportDialog] = useState(false)
  const [rows, setRows] = useState(6)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: "createdDate",
    sortOrder: -1,
  })

  const sortOptions = [
    { label: 'Newest', value: -1 },
    { label: 'Oldest', value: 1 },
  ];

  const { interviewFlowId } = useParams<{ interviewFlowId: string }>();
  const [sortKey, setSortKey] = useState<number>(-1);

  //Lazy loading
  useEffect(() => {
    if (displayFlowTemplateDialog) {
      if (interviewFlowId !== undefined)
        dispatch(getInterviewFlowGlobalTemplateListing(lazyParams, (Number)(interviewFlowId)))
      else
        dispatch(getInterviewFlowGlobalTemplateListing(lazyParams, -1))
    }
  }, [lazyParams, displayFlowTemplateDialog])

  const renderListItem = (interviewFlow: InterviewFlow) => {
    return (
      <div className="col-12">
        <div className="product-list-item">
          <div className="product-list-detail">
            <div className="product-name">
              {interviewFlow.name}
            </div>
            <div className="product-description">
              Created: {interviewFlow.createdDate?.substring(0, 10)} {interviewFlow.createdDate?.substring(11, 16)}
            </div>
          </div>
          <div className="product-list-action">
            <Button 
              icon="pi pi-download" 
              label="Import" 
              onClick={() => handleImport(interviewFlow)}></Button>
            <ConfirmDialog /> 
          </div>
        </div>
      </div>
    )
  }

  const renderGridItem = (interviewFlow: InterviewFlow) => {
    return (
      <div className="col-12 md:col-4">
        <div className="product-grid-item">
          <div className="product-grid-item-top">
            <i className="pi pi-sitemap product-category-icon"></i>
            <div className="product-name">
              <span className="product-category">{interviewFlow.name}</span>
            </div>
          </div>
          <div className="product-grid-item-top text-center mb-2">
            Created: <br></br> {interviewFlow.createdDate?.substring(0, 10)} {interviewFlow.createdDate?.substring(11, 16)}
          </div>
          <div className="product-grid-item-bottom">
          <Button 
              icon="pi pi-download" 
              label="Import" 
              onClick={() => handleImport(interviewFlow)}></Button>
            <ConfirmDialog />
          </div>
        </div>
      </div>
    )
  }

  const onSortChange = (event: any) => {
    setSortOrder(event.value);
    setSortKey(event.value);
  }

  useEffect(() => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.sortOrder = sortOrder
    setLazyParams(_lazyParams)
  }, [sortOrder])

  const renderHeader = () => {
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: 'left' }}>
          <Dropdown 
            options={sortOptions} 
            value={sortKey}
            optionLabel="label" 
            placeholder="Sort By Created Date" 
            onChange={onSortChange} />
        </div>
        <div className="col-6" style={{ textAlign: 'right' }}>
          <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value === 'grid' ? 'grid' : 'list')} />
        </div>
      </div>
    );
  }
  const header = renderHeader();

  const itemTemplate = (interviewFlow: InterviewFlow) => {
    if (!interviewFlow)
      return;
    if (layout === 'list')
      return renderListItem(interviewFlow);
    else if (layout === 'grid')
      return renderGridItem(interviewFlow);
  }

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const handleImport = (selectedFlow: InterviewFlow) => {
    confirmDialog({
      className: "w-5",
      message: "You are about to import " + selectedFlow.name + 
        ", this will overwrite your current flow. Are you sure you want to proceed?",
      header: "Confirm import",
      icon: "pi pi-exclamation-triangle",
      accept: () => { confirmImport(selectedFlow)},
      reject: () => { }
    })
  }

  const confirmImport = (interviewFlow: InterviewFlow) => {
    dispatch(importInterviewFlowByInterviewFlowId
      ({id: Number(interviewFlow.id), typeOfId: typesOfId[typesOfId.Id]}))
    onHide()
  }

  return (
    <Dialog 
      className="w-6" 
      visible={displayFlowTemplateDialog} 
      onHide={onHide} 
      header="Import Flow from Templates">
      <div className={styles.myDataview}>
        <div className="card">
          <DataView 
            value={interviewFlowGlobalTemplateListing} 
            itemTemplate={itemTemplate} 
            paginatorPosition={'both'} 
            lazy 
            paginator 
            rows={rows}
            header={header} 
            totalRecords={interviewFlowGlobalTemplateTotalRecords} 
            first={lazyParams.first} 
            onPage={onPage} 
            loading={loading} />
        </div>
      </div>
    </Dialog >
  )
}

/*
const mapStateToProps = (state: any) => {
  return {
    interviewFlowGlobalTemplateListing: state.interviewFlow.interviewFlowGlobalTemplateListing,
    interviewFlowGlobalTemplateTotalRecords: state.interviewFlow.interviewFlowGlobalTemplateTotalRecords,
    interviewFlowGlobalTemplateLoaded: state.interviewFlow.interviewFlowGlobalTemplateLoaded,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getInterviewFlowGlobalTemplateListing: (lazyParams: any, interviewFlowId: number) => dispatch(getInterviewFlowGlobalTemplateListingAction.getInterviewFlowGlobalTemplateListing(lazyParams, interviewFlowId)),
    importInterviewFlowByInterviewFlowId: (interviewFlowId: number) => dispatch(getInterviewFlowAction.importInterviewFlowByInterviewFlowId(interviewFlowId)),
  }
}
*/

export default FlowTemplateDialog