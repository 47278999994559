import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from 'react';

import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as jwtDecoder from "../../../../constants/helpers/admin/jwtDecoder";
import { formatDate } from '../../../../constants/helpers/dateFormatter';
import { SCOPES } from '../../../../constants/scopes';
import { AppDispatch } from '../../../../store';
import { disableUsers, enableUsers, retrieveUsers } from '../UserAction';
import { User } from '../interfaces';
import './styles.module.css';
import { FilterMatchMode } from 'primereact/api';
import LinkButton from '../../../../constants/helpers/LinkButton';

enum SortDirection {
  ascending = "asc",
  descending = "desc"
}

interface AdminUserManagementProps {
  admin: boolean
}

const AdminUserManagement = (props: AdminUserManagementProps) => {
  const toast = useRef<any>(null);
  const { admin = false } = props
  
  const dispatch = useDispatch<AppDispatch>()
  const { userList, totalRecords } = useSelector((state: any) => state.user)

  const rowsPerPageOptions = [5, 10, 15, 25]

  const [selectedUser, setSelectedUser] = useState({} as User)
  const [selectedUsers, setSelectedUsers] = useState([] as User[]);

  // Dialog settings
  const [displayEnableUserDialog, setDisplayEnableUserDialog] = useState(false)
  const [displayDeleteUsertDialog, setDisplayDeleteUserDialog] = useState(false)
  const [displayDeleteMultiUserDialog, setDisplayDeleteMultiUserDialog] = useState(false)

  let filters: DataTableFilterMeta = {
    name: { value: null, matchMode: FilterMatchMode.EQUALS },
    email: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdDate: { value: null, matchMode: FilterMatchMode.EQUALS },
    enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
    adminUser: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [dateFilter, setDateFilter] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const dt = useRef<any>(null)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "name",
    sortOrder: 1,
    filters
  })

  const getAllUsers = () => {
    dispatch(retrieveUsers({
      admin, 
      lazyParams: {
        page: lazyParams.page,
        rows: lazyParams.rows,
        sortField: lazyParams.sortField,
        sortOrder: lazyParams.sortOrder,
        filters: lazyParams.filters
      }
    }))
  }

  useEffect(() => {
    getAllUsers()
  }, [lazyParams])

  const onPage = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onSort = (event: any) => {
    console.log('onsort', event)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0
    setLazyParams(_lazyParams)
  }

  const statuses = [
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' }
  ]

  const userType = [
    { label: 'Administrator', value: true },
    { label: 'Candidate', value: false },
  ]

  const handleResetFilters = () => {
    setSelectedStatus(null)
    setDateFilter(null)
    setSelectedUserType(null)
    dt.current.reset();
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters = {}
    setLazyParams(_lazyParams)
  }

  const renderHeader = () => {
    return (
      <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={handleResetFilters} />
      </div>
    );
  }

  const handleMultiDeleteUserButton = (e: any) => {
    e.preventDefault()
    setDisplayDeleteMultiUserDialog(true)
  }

  const confirmMultiDelete = () => {
    dispatch(disableUsers({admin, users: selectedUsers})).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'User Disabled Success', 
          detail: `${selectedUsers.length} was successfully disabled.`, 
          life: 3000
        })
        getAllUsers()
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'warn', 
          summary: 'User Disabled Error', 
          detail: `${selectedUsers.length} was not disabled.`, 
          life: 3000
        })
      })
    setSelectedUsers([])
  }

  const confirmSingleDisable = () => {
    dispatch(disableUsers({admin, users: [selectedUser]})).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'User Disabled Success', 
          detail: `${selectedUser.name} was successfully disabled.`, 
          life: 3000
        })
        getAllUsers()
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'warn', 
          summary: 'User Disabled Error', 
          detail: `${selectedUser.name} was not disabled.`, 
          life: 3000
        })
      })
  }

  const confirmSingleEnable = () => {
    dispatch(enableUsers({admin, users: [selectedUser]})).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'User Enabled Success', 
          detail: `${selectedUser.name} was successfully enabled.`, 
          life: 3000
        })
        getAllUsers()
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'warn', 
          summary: 'User Enabled Error', 
          detail: `${selectedUser.name} was not enabled.`, 
          life: 3000
        })
      })
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <LinkButton to={(admin ? '/tenants' : '') + "/users/create"} icon="file" label="New" className="p-button-primary mr-2" />
        {(selectedUsers.length > 0) ? <Button label="Delete" icon="pi pi-trash" className="p-button-secondary" onClick={handleMultiDeleteUserButton}></Button> : <Button label="Delete" icon="pi pi-trash" className="p-button-secondary" disabled></Button>}
      </React.Fragment>
    )
  }

  const configRowTemplate = (rowData: User) => {
    return (
      <div>
        <LinkButton to={(admin ? '/tenants' : '') + "/users/" + rowData.id} className="mr-2"
          icon="eye" iconOnly={true}
          tooltip="View User" tooltipOptions={{ position: 'top' }} />
        {jwtDecoder.getNameFromJwt() !== rowData.name && 
          <LinkButton to={(admin ? '/tenants' : '') + "/users/" + rowData.id + "/update"} className="mr-2"
            icon="pencil" iconOnly={true}
            tooltip="Modify User" tooltipOptions={{ position: 'top' }} />
        }
        {rowData.enabled ?
          <Button 
            onClick={() => {
              setSelectedUser(rowData)
              setDisplayDeleteUserDialog(true)
            }} 
            severity="secondary"
            tooltip="Delete User"
            tooltipOptions={{ position: 'left' }}
            icon="pi pi-trash" 
            aria-label="Delete" />
          :
          <Button 
            onClick={() => {
              setSelectedUser(rowData)
              setDisplayEnableUserDialog(true)
            }} 
            severity="secondary"
            tooltip="Activate User"
            tooltipOptions={{ position: 'left' }}
            icon="pi pi-replay" 
            aria-label="Activate" />
        }
      </div>
    )
  }

  const statusBodyTemplate = (rowData: any) => {
    if (rowData.enabled === true) {
      return (
        <span style={{ color: '#256029', backgroundColor: '#c8e6c9', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>active</span>
      )
    }
    else {
      return (
        <span style={{ color: '#c63737', backgroundColor: '#ffcdd2', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>inactive</span>
      )
    }
  }

  const adminUserBodyTemplate = (rowData: User) => {
    let userTypeString = rowData.adminUser ? "Administrator" : "Candidate"
    return (
      <span className={classNames('customer-badge', 'adminUser-' + userTypeString)}>{userTypeString}</span>
    )
  }

  const nameBodyTemplate = (rowData: User) => {
    return (
      <React.Fragment>
        {rowData.name}
      </React.Fragment>
    )
  }

  const emailBodyTemplate = (rowData: User) => {
    let email = rowData.email;

    return (
      <React.Fragment>
        <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{email}</span>
      </React.Fragment>
    );
  }

  const dateBodyTemplate = (rowData: any) => {
    let time = new Date(rowData.createdDate).toLocaleString()
    return (
      <React.Fragment>
        <span>{time}</span>
      </React.Fragment>
    )
  }

  const renderDateFilter = () => {
    return (
      <Calendar
        value={dateFilter}
        onChange={onDateFilterChange}
        readOnlyInput
        placeholder="Registration Date"
        selectionMode="range"
        dateFormat="yy-mm-dd"
        className="p-column-filter" />
    )
  }

  const onDateFilterChange = (event: any) => {
    setDateFilter(event.value)
    if (event.value[0] != null && event.value[1] != null) {
      let startDate = `${event.value[0].getFullYear()}-${event.value[0].getMonth() + 1}-${event.value[0].getDate()}`
      let endDate = `${event.value[1].getFullYear()}-${event.value[1].getMonth() + 1}-${event.value[1].getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.createdDateLt = { value: startDate, matchMode: FilterMatchMode.EQUALS }
      _lazyParams.filters.createdDateGt = { value: endDate, matchMode: FilterMatchMode.EQUALS }
      setLazyParams(_lazyParams)
    }

  }


  const filterDate = (value: any, filter: any) => {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === formatDate(filter);
  }

  const renderStatusFilter = () => {
    return (
      <Dropdown value={selectedStatus} options={statuses} style={{ width: "100%" }} onChange={onStatusFilterChange}
        showClear placeholder="All" className="p-column-filter" />
    )
  }

  const renderUserTypeFilter = () => {
    return (
      <Dropdown value={selectedUserType} options={userType} style={{ width: "100%" }} onChange={onUserTypeFilterChange}
        showClear placeholder="All" className="p-column-filter" />
    )
  }

  const onStatusFilterChange = (event: any) => {
    dt.current.filter(event.value, 'status', 'equals')
    setSelectedStatus(event.value)
  }

  const onUserTypeFilterChange = (event: any) => {
    dt.current.filter(event.value, 'adminUser', 'equals')
    setSelectedUserType(event.value)
  }
  const header = renderHeader();
  const dateFilterElement = renderDateFilter()
  const statusFilterElement = renderStatusFilter()
  const adminUserFilterElement = renderUserTypeFilter()
  return (
    <div>
      <Toast ref={toast} />
      <div className="datatable-doc-demo">
        <div className="card">
          <Toolbar className="p-mb-4" start={leftToolbarTemplate}></Toolbar>
          <ConfirmDialog visible={displayDeleteMultiUserDialog} onHide={() => setDisplayDeleteMultiUserDialog(false)} message={`Are you sure you want to delete ${selectedUsers.length} selected tenants?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmMultiDelete} reject={() => setDisplayDeleteMultiUserDialog(false)}  />
          <ConfirmDialog visible={displayDeleteUsertDialog} onHide={() => setDisplayDeleteUserDialog(false)} message={`Are you sure you want to delete the user: ${selectedUser.name}?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmSingleDisable} reject={() => setDisplayDeleteUserDialog(false)}  />
          <ConfirmDialog visible={displayEnableUserDialog} onHide={() => setDisplayEnableUserDialog(false)} message={`Are you sure you want to re-enable the user: ${selectedUser.name}?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmSingleEnable} reject={() => setDisplayEnableUserDialog(false)}  />
          
          <DataTable ref={dt} value={userList} lazy
            totalRecords={totalRecords} onPage={onPage} first={lazyParams.first}
            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
            header={header} className="p-datatable-customers" dataKey="id" rowHover
            onFilter={onFilter} filters={lazyParams.filters} filterDisplay='row'
            selection={selectedUsers} onSelectionChange={e => {
              const users = e.value as User[]
              setSelectedUsers(users)
            }}
            paginator rows={lazyParams.rows} emptyMessage="No users found" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={rowsPerPageOptions}>
            <Column selectionMode="multiple" style={{ width: '3em' }} />
            <Column field="name" header="Name" body={nameBodyTemplate} sortable filter filterPlaceholder="Search by name" />
            <Column field="email" sortField="email" header="Email" body={emailBodyTemplate} sortable filter filterMatchMode="contains" filterPlaceholder="Search by email" />
            <Column field="createdDate" header="Date Created" body={dateBodyTemplate} sortable filter filterMatchMode="custom" filterFunction={filterDate} filterElement={dateFilterElement} />
            <Column field="enabled" header="Status" body={statusBodyTemplate} sortable filter filterElement={statusFilterElement} />
            <Column field="adminUser" header="User Type" body={adminUserBodyTemplate} sortable filter filterElement={adminUserFilterElement} />
            {jwtDecoder.checkScopeExistsInJwt(SCOPES.USER_WRITE) ?
              <Column body={configRowTemplate} style={{ width: '12em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
              : false}
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default AdminUserManagement