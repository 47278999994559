import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { Interview, InterviewFlow } from './interface'
import { retrieveInterview, retrieveInterviewFlows, retrieveInterviews } from "./InterviewAction"

interface InterviewState {
  interview: Interview
  interviewList: Interview[]
  interviewFlowList: InterviewFlow[]
  totalRecords: Number | undefined
}

const initialState = {
  interview: {} as Interview,
  interviewList: [],
  interviewFlowList: [],
  totalRecords: undefined
} as InterviewState

const applicationSlicer = createSlice({
  name: 'interview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveInterviews.fulfilled, (state: any, { payload }) => {
        state.interviewList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
      .addCase(retrieveInterview.fulfilled, (state: any, { payload }) => {
        state.interview = payload
      })

      .addCase(retrieveInterviewFlows.fulfilled, (state: any, { payload }) => {
        state.interviewFlowList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
  },
})

export default applicationSlicer.reducer