import arrayMutators from 'final-form-arrays'
import { Button } from 'primereact/button'
import { Checkbox } from "primereact/checkbox"
import { Chips } from "primereact/chips"
import { Dropdown } from "primereact/dropdown"
import { Fieldset } from 'primereact/fieldset'
import { InputText } from "primereact/inputtext"
import { Tooltip } from 'primereact/tooltip'
import { classNames } from "primereact/utils"
import { useState } from 'react'
import { Field, Form } from "react-final-form"
import { FieldArray } from 'react-final-form-arrays'
import { AmazonLexInbuiltSlotType, Branch, ConditionalQuestion, ConditionArithmeticOperatorEnum, ConditionEnum, ConditionQuantifierOperatorEnum, ConfirmationSetting, CustomSlotType, ExpressionQuery, IntentDetails, InterviewNode, QuestionType } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"

import OnChange from "../../../../../../utils/OnChange"
import styles from "../styles.module.css"

interface ConditionalFormProps {
  actionOnSubmit: any
  actionOnDelete: any
  intentNode: ConditionalQuestion
  intentNameList?: string[]
  firstNode: InterviewNode
  lastNode: InterviewNode
  isUpdate?: boolean
  autosave: boolean
  customSlotTypeOptions: CustomSlotType[]
  onQuestionTypeChange: (newQuestionType: any) => void
}

// interface SlotElicitationErrorValidation {
//   slotType: string
// }

// interface QueriesErrorValidation {
//   condition: string
//   conditionOperator: string
//   value: string
// }

// interface ExpressionErrorValidation {
//   queries: QueriesErrorValidation
// }

// interface BranchErrorValidation {
//   name: string
//   expression: ExpressionErrorValidation[]
// }

// interface ConditionalSettingErrorValidation {
//   branches: BranchErrorValidation[]
// }

// interface ErrorValidation {
//   typeOfQuestion: string
//   name: string
//   slotElicitationSetting: SlotElicitationErrorValidation
//   questionPrompts: string
//   conditionalSetting: ConditionalSettingErrorValidation
// }

const ConditionalForm = (props: ConditionalFormProps) => {
  const { actionOnSubmit, actionOnDelete, intentNode, firstNode, lastNode, intentNameList, isUpdate = false, autosave,
    customSlotTypeOptions, onQuestionTypeChange } = props
  // const [chips, setChips] = useState<string[]>(initialValues.chips ? initialValues.chips : [])
  const [showValidateCandidateInputFields, setShowValidateCandidateInputFields] = useState<boolean>
    (!!intentNode.validateCandidateInput ? intentNode.validateCandidateInput : false);

  const allSlotKeys = Object.keys(AmazonLexInbuiltSlotType) as string[]
  const allSlotValues = Object.values(AmazonLexInbuiltSlotType) as string[]
  const allTenantCustomSlotTypes = customSlotTypeOptions.map((eachSlot: CustomSlotType) => eachSlot.slotTypeName)
  const allTenantSlotTypeOptions = [
    {
      label: "Default Types",
      items: allSlotKeys.map((value: string, index: number) => {
        return { label: value, value: allSlotValues[index] }
      })
    },
    {
      label: "Custom Types",
      items: [...allTenantCustomSlotTypes]
    }
  ]

  // const remapSlotTypeValueToKey = (slotTypeValue: string) => {
  //   if (slotTypeValue !== undefined && slotTypeValue.length > 0) {
  //     const allInBuiltSlotValues = Object.values(AmazonLexInbuiltSlotType) as string[]
  //     const index = allInBuiltSlotValues.indexOf(slotTypeValue)
  //     if (index !== -1) { //Is an in-built slot type value, map back to in-built enum key
  //       return Object.keys(AmazonLexInbuiltSlotType)[index]
  //     }
  //   }
  //   return slotTypeValue
  // }
  const allConditionArithmeticOperatorKeys = Object.keys(ConditionArithmeticOperatorEnum) as string[]
  const allConditionArithmeticOperatorValues = Object.values(ConditionArithmeticOperatorEnum) as string[]
  const allConditionQuantifierOperatorKeys = Object.keys(ConditionQuantifierOperatorEnum) as string[]
  const allConditionQuantifierOperatorValues = Object.values(ConditionQuantifierOperatorEnum) as string[]
  const operatorTypeOptions = [
    {
      label: "Arithmetic Operators",
      items: allConditionArithmeticOperatorKeys.map((value: string, index: number) => {
        return { label: value, value: allConditionArithmeticOperatorValues[index] }
      })
    },
    {
      label: "Quantifier Operators",
      items: allConditionQuantifierOperatorKeys.map((value: string, index: number) => {
        return { label: value, value: allConditionQuantifierOperatorValues[index] }
      })
    }
  ]

  // const operatorOptions = [
  //   {
  //     label: "Comparison Operators",
  //     items: [
  //       { label: "Equals", value: "=" },
  //       { label: "Not equals", value: "!=" },
  //       { label: "Less than", value: "<" },
  //       { label: "Less than or equals", value: "<=" },
  //       { label: "Greater than", value: ">" },
  //       { label: "Greater than or equals", value: ">=" }
  //     ]
  //   },
  //   {
  //     label: "Quantifier Operator",
  //     items: [
  //       { label: "Contains", value: "CONTAINS" }
  //     ]
  //   }
  // ]

  const groupedItemTemplate = (option: any) => {
    return (
      <div className="text-lg font-bold text-blue-600">{option.label}</div>
    );
  }

  //Mutating initialValues from enum value (for backend use) to enum key representation for dropdown options UI
  let initialValues: ConditionalQuestion = !!intentNode ? { ...intentNode, } : {} as ConditionalQuestion

  // let tempValues = { ...initialValues }
  // if (tempValues.slotElicitationSetting && Object.keys(tempValues.slotElicitationSetting).length > 0) {
  //   let slotTypeEnumKey = remapSlotTypeValueToKey(tempValues.slotElicitationSetting?.slotType)
  //   tempValues.slotElicitationSetting.slotType = slotTypeEnumKey; //Mutating
  // }

  // if (intentNode.conditionalSetting && intentNode.conditionalSetting.branches && intentNode.conditionalSetting.branches.length > 0) {
  //   //Need to breakdown expression into corresponding dropdown box/value input box respectively
  // }

  // initialValues = { ...tempValues }

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.typeOfQuestion) {
      errors.typeOfQuestion = 'The type of question must be specified'
    }

    if (!data.name) {
      errors.name = 'Please specify the name of the node'
    } else if (!isUpdate && data.name) {
      if (intentNameList && intentNameList.length > 0 && intentNameList.find(element => element === data.name) !== undefined) {
        errors.name = 'This name is currently in use by another node'
      }
    }

    //Validation for only allowing 1 first and 1 last node
    if (data.isFirstQuestion && Object.keys(firstNode).length !== 0 && !initialValues.isFirstQuestion)
      errors.isFirstQuestion = "First node already exists"
    if (data.isLastQuestion && Object.keys(lastNode).length !== 0 && !initialValues.isLastQuestion)
      errors.isLastQuestion = "Last node already exists"

    if (!data.slotElicitationSetting) { //If slotElicitationSetting is uninitialized
      errors.slotElicitationSetting = {}
      errors.slotElicitationSetting.slotType = 'Please select a slot type'
    } else if (data.slotElicitationSetting) { //If slotElicitationSetting is initialized but slot type is not initialized
      errors.slotElicitationSetting = {}
      if (!data.slotElicitationSetting.slotType) {
        errors.slotElicitationSetting.slotType = 'Please select a slot type'
      }
    }

    if (data.slotElicitationSetting?.slotElicitationQuestionPrompts === undefined || data.slotElicitationSetting?.slotElicitationQuestionPrompts.length === 0) {
      errors.questionPrompts = "Please input at least one elicitation prompt."
    }

    if (data.conditionalSetting?.branches === undefined || data.conditionalSetting?.branches.length === 0) {
      errors.branchesText = "Please create at least one branch."
    }
    else {
      data.conditionalSetting.branches.forEach((branch: any, index: number) => {
        if (!branch.expression)
          errors.branchesText = "Please include at least 1 expression for " + branch.name
      })
    }


    // if (data.conditionalSetting && data.conditionalSetting.branches && data.conditionalSetting.branches.length > 0) {
    //   data.conditionalSetting.branches.forEach((branch: any, index: number) => {
    //     if (Object.keys(branch).length === 0) {
    //       errors.conditionalSetting = {}
    //       errors.conditionalSetting.branches = []
    //       errors.conditionalSetting.branches[index] = {}
    //       errors.conditionalSetting.branches[index].name = "Please input a name"
    //     }
    //   })
    // }

    // if (data.flowToIfUnfulfilled && !data.messageIfUnfulfilled) {
    //   errors.messageIfUnfulfilled = 'Please provide the unfulfillment message'
    // }

    // if (data.flowToIfFulfilled && !data.messageIfFulfilled) {
    //   errors.messageIfFulfilled = 'Please provide the fulfillment message'
    // }

    // if (!data.conditionDetails || !data.conditionDetails.conditionType) {
    //   if (!errors.conditionDetails) {
    //     errors.conditionDetails = {}
    //   }
    //   errors.conditionDetails.conditionType = 'Please specify the type of condition'
    // }

    // if (!data.conditionDetails || !data.conditionDetails.expectedValue) {
    //   if (!errors.conditionDetails) {
    //     errors.conditionDetails = {}
    //   }
    //   errors.conditionDetails.expectedValue = 'Please specify the expected value to fulfil the condition'
    // }

    // if (!data.conditionDetails || !data.conditionDetails.slotNameToCheck) {
    //   if (!errors.conditionDetails) {
    //     errors.conditionDetails = {}
    //   }
    //   errors.conditionDetails.slotNameToCheck = 'Please specify the corresponding slot name in Lex'
    // }

    return errors;
  }

  const onSubmit = (data: any, form: any) => {
    let payload = {} as InterviewNode
    payload = data
    payload.position = data.position ? data.position : { x: 0, y: 0 }
    // payload.chips = chips
    // payload.position = data.position ? data.position : { x: 0, y: 0 }
    if (payload.typeOfQuestion === QuestionType.ConditionalQuestion) { //Type narrowing
      // if (payload.scoreIfUnfulfilled === undefined)
      //   payload.scoreIfUnfulfilled = 0; //Programmatically mutating score to 0 if user left InputText field blank
      // else
      //   payload.scoreIfUnfulfilled = +payload.scoreIfUnfulfilled; //Parse InputText component from string to number type
      // if (payload.scoreIfFulfilled === undefined)
      //   payload.scoreIfFulfilled = 0;
      // else
      //   payload.scoreIfFulfilled = +payload.scoreIfFulfilled;

      if (!payload.validateCandidateInput) {
        payload.confirmationSetting = {} as ConfirmationSetting
      } else {
        payload.slotElicitationSetting.successResponse = []
      }
    }
    console.log({ payload })
    if (isUpdate)
      actionOnSubmit(initialValues, payload, autosave)
    else
      actionOnSubmit(payload, autosave)
    // setChips([])
    form.restart()
  }

  const deleteNode = (form: any) => {
    actionOnDelete(initialValues, autosave)
    form.restart()
  }

  const getExcludedNameList = (values: any) => {
    if (!!values) {
      let tempIntentNameList = intentNameList?.slice(0)
      if (tempIntentNameList) {
        let valueIndex = tempIntentNameList.indexOf(values)
        if (valueIndex !== -1)
          tempIntentNameList.splice(valueIndex, 1)
      }
      return tempIntentNameList
    }
    return intentNameList
  }

  const required = (value: any) => value ? undefined : 'This is a required field'

  const checkForDuplicate = (value: string[]) => {
    if (value && value.length > 0) {
      let tempArr = value.map(element => element.toLowerCase())
      if (new Set(tempArr).size !== value.length)
        return 'Please check if there are any duplicated chip values';
      return undefined;
    }
    return undefined;
  }

  const isFormFieldValid = (meta: any) => !!((meta.touched || meta.modified) && meta.error)

  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  const WhenLastQuestionIsChecked = ({ set, to }: { set: string, to: any }) => {
    return (
      <Field name={set} subscription={{}}>
        {(
          // No subscription. We only use Field to get to the change function
          { input: { onChange } }
        ) => {
          return (
            <OnChange name='isLastQuestion'>
              {(value: boolean) => {
                if (value === true) {
                  onChange(to);
                }
              }}
            </OnChange>
          )
        }}
      </Field>
    )
  }

  return (
    <div>
      <h3>{isUpdate ? "Update new Conditional node" : "Create new Conditional node"}</h3>
      <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">
        A Conditional node is intended to capture a candidate's response, subsequently interpreted through a condition to be fulfilled before
        flowing to the next node.
        <br></br>
        Such as a conditional node asking "How many years of working experience do you have?" with a condition set to check for &gt;= 3 years,
        <br></br>
        would flow the conversation to the unfulfilled path if candidate were to respond correspondingly.
      </p>
      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate} render={({ form: { mutators: { push, pop, remove } }, form, values, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          {/* <WhenLastQuestionIsChecked set="flowToIfUnfulfilled" to={undefined} /> */}
          {/* <WhenLastQuestionIsChecked set="flowToIfFulfilled" to={undefined} /> */}
          {/* <WhenLastQuestionIsChecked set="messageIfUnfulfilled" to={undefined} /> */}
          {/* <WhenLastQuestionIsChecked set="messageIfFulfilled" to={undefined} /> */}
          {/* <WhenLastQuestionIsChecked set="scoreIfUnfulfilled" to={0} /> */}
          {/* <WhenLastQuestionIsChecked set="scoreIfFulfilled" to={0} /> */}
          {/* <WhenLastQuestionIsChecked set="" to={undefined} /> */}
          <Fieldset className={styles.nodeform} legend="Node Details" toggleable>
            <div className="formgrid grid">
              <Field name="typeOfQuestion" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="typeOfQuestionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                  <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="name" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                  <InputText id="nameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="chips" validate={checkForDuplicate} render={({ input, meta }) => (
                <div className="field col-12">
                  <label htmlFor="chipsInput">Chips</label>
                  <Chips id="chipsInput" {...input} allowDuplicate={false} separator="," className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="multiChips" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-12 md:col-6">
                  <Checkbox inputId="multiChipsInput" {...input} checked={input.checked || false} />
                  <label htmlFor="multiChipsInput">Allow multiple chips input?</label>
                </div>
              )} />
              <Field name="isFirstQuestion" type="checkbox" render={({ input, meta }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isFirstQuestionInput" {...input} checked={input.checked || false} />
                  <label htmlFor="isFirstQuestionInput">Is this node the first question of the interview?</label>
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="isLastQuestion" type="checkbox" render={({ input, meta }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isLastQuestionInput" {...input} checked={input.checked || false} />
                  <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                  {getFormErrorMessage(meta)}
                </div>
              )} />
            </div>
          </Fieldset>

          <Fieldset className={styles.nodeform} legend="Elicitation Settings" toggleable>
            <div className="formgrid grid">
              <Field name="questionPrompts" render={({ meta }) => (
                <div className="xl:col-7 md:col-12">
                  <span className={classNames({ 'p-error': isFormFieldValid(meta) })} style={{ display: "inline-block", marginBottom: "0.5rem" }}>Elicitation Prompts*</span>
                  <br></br>{getFormErrorMessage(meta)}
                </div>
              )} />
              {/*Sharing field name with FieldArray to listen to the meta state of the field array to disable button conditionally in runtime*/}
              <Field name="slotElicitationSetting.slotElicitationQuestionPrompts" render={({ meta }) => (
                <div className="xl:col-4 xl:col-offset-1 md:col-12 md:col-offset-0">
                  <Button className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} label="Add Prompt" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(meta.length && meta.length >= 3)} onClick={() => push('slotElicitationSetting.slotElicitationQuestionPrompts', "")}></Button>
                </div>
              )} />
              <FieldArray name="slotElicitationSetting.slotElicitationQuestionPrompts">
                {({ fields }) =>
                  fields.map((questionPrompt, index) => (
                    <div key={`questionPrompt${index.toString()}`} className="col-12">
                      <h3 className="col-12">Prompt #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12">
                        <Field name={`slotElicitationSetting.slotElicitationQuestionPrompts[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="slotElicitationQuestionPromptsInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('slotElicitationSetting.slotElicitationQuestionPrompts', index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
              <Field name="slotElicitationSetting.slotType" render={({ input, meta }) => (
                <div className="field col-7">
                  <label htmlFor="slotTypeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Type*</label>
                  <Dropdown id="slotTypeInput" {...input} options={allTenantSlotTypeOptions} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} optionGroupChildren="items" optionGroupLabel="label" optionGroupTemplate={groupedItemTemplate} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="validateCandidateInput" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-6">
                  <label htmlFor="validateCandidateInput">Validate Candidate Input</label>
                  <Checkbox inputId="validateCandidateInput" {...input} checked={input.checked || false} />
                  <Tooltip target=".validate-candidate-input-icon" />
                  <i className="validate-candidate-input-icon ml-2 pi pi-question-circle"
                    data-pr-tooltip="By checking this field, X-Interviewer will confirm with the Candidate the interpreted input to verify if it is correct."></i>
                </div>
              )} />

              {/* Probably no need this, can use multipleChips boolean also. */}
              {/* <Field name="slotElicitationSetting.multipleValues" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-6">
                  <Checkbox inputId="multipleValuesInput" {...input} />
                  <label htmlFor="multipleValuesInput">Capture multiple slot values?</label>
                </div>
              )} /> */}
            </div>
          </Fieldset>

          {/* Builds confirmation settings if validation is enabled, else will just capture slot value as it is without any validation */}
          {values.validateCandidateInput ?
            <Fieldset className={styles.nodeform} legend="Confirmation Settings" toggleable>
              <div className="formgrid grid">
                <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">The response and dialog action to be taken upon candidate's confirmation of their input.</p>
                <Field name="confirmationSetting.flowToIfYes" render={({ input }) => (
                  <div className="col-12">
                    {/* <div className="field col-12">
                      <label htmlFor="flowToIfYes">Next Step</label>
                      <Dropdown id="flowToIfYes" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if confirmed" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                    </div> */}
                    <div className="col-4">
                      <Button id="addConfirmationResponseBtn" label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.confirmationSetting && values.confirmationSetting.responseIfYes && values.confirmationSetting.responseIfYes.length >= 3)} onClick={() => push('confirmationSetting.responseIfYes', "")}></Button>
                    </div>
                  </div>
                )} />
                <FieldArray name="confirmationSetting.responseIfYes">
                  {({ fields }) =>
                    fields.map((responseIfYes, index) => (
                      <div key={`responseIfYes${index.toString()}`} className="col-12">
                        <h3 className="col-12">Confirmation Response #{index + 1}</h3>
                        <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12">
                          <Field name={`confirmationSetting.responseIfYes[${index}]`} validate={required} render={({ input, meta }) => (
                            <div>
                              <div>
                                <InputText id="responseIfYesInput" {...input} inputMode="text" className="inputfield w-full" />
                              </div>
                              <div>
                                <Button id="removeConfirmationResponseBtn" className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('confirmationSetting.responseIfYes', index)}></Button>
                              </div>
                            </div>
                          )} />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
            </Fieldset>
            :
            <Fieldset className={styles.nodeform} legend="Slot Capture Settings" toggleable>
              <div className="formgrid grid">
                <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">The response and dialog action to be taken upon successful slot capture of candidate's input.</p>
                <Field name="slotElicitationSetting.successStep" render={({ input, meta }) => (
                  <div className="col-12">
                    {/* <div className="field col-12">
                      <label htmlFor="successStepInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Next Step</label>
                      <Dropdown id="successStepInput" showClear disabled={values.isLastQuestion} tooltip="The next dialog action upon capturing a response" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                      {getFormErrorMessage(meta)}
                    </div> */}
                    <div className="col-4">
                      <Button id="addSuccessResponseBtn" label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.slotElicitationSetting && values.slotElicitationSetting.successResponse && values.slotElicitationSetting.successResponse.length >= 3)} onClick={() => push('slotElicitationSetting.successResponse', "")}></Button>
                    </div>
                  </div>
                )} />
                <FieldArray name="slotElicitationSetting.successResponse">
                  {({ fields }) =>
                    fields.map((successResponse, index) => (
                      <div key={`successResponse${index.toString()}`} className="col-12">
                        <h3 className="col-12">Capture Response #{index + 1}</h3>
                        <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12">
                          <Field name={`slotElicitationSetting.successResponse[${index}]`} validate={required} render={({ input, meta }) => (
                            <div>
                              <div>
                                <InputText id="successResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                              </div>
                              <div>
                                <Button id="removeSuccessResponseBtn" className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('slotElicitationSetting.successResponse', index)}></Button>
                              </div>
                            </div>
                          )} />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
            </Fieldset>
          }

          <Fieldset className={styles.nodeform} legend="Default Branch" toggleable>
            <div className="formgrid grid">
              <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">The default response given by the bot if user answer does not meet any of the set conditions.</p>
              <Field name="conditionalSetting.defaultStep" render={({ input, meta }) => (
                <>
                  <div className="field col-6">
                    <label htmlFor="defaultStepInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Default Step</label>
                    <Dropdown id="defaultStepInput" showClear disabled={values.isLastQuestion} tooltip="The default step when no conditions are matched" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                    {getFormErrorMessage(meta)}
                  </div><Field name="conditionalSetting.defaultBranchScore" render={({ input }) => (
                    <div className="field col-6">
                      <label htmlFor="defaultBranchScoreInput">Default Branch Score</label>
                      <InputText id="defaultBranchScoreInput" autoComplete="off" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                    </div>
                  )} />
                  <div className="col-4">
                    <Button id="addDefaultBranchResponseBtn" label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.conditionalSetting && values.conditionalSetting.defaultBranchResponse && values.conditionalSetting.defaultBranchResponse.length >= 3)} onClick={() => push('conditionalSetting.defaultBranchResponse', "")}></Button>
                  </div>
                </>
              )} />

            </div>
            <FieldArray name="conditionalSetting.defaultBranchResponse">
              {({ fields }) =>
                fields.map((defaultBranchResponse, index) => (
                  <div key={`defaultBranchResponse${index.toString()}`} className="col-12">
                    <h3 className="col-12">Default Response #{index + 1}</h3>
                    <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12">
                      <Field name={`conditionalSetting.defaultBranchResponse[${index}]`} validate={required} render={({ input, meta }) => (
                        <div>
                          <div>
                            <InputText id="defaultBranchResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                          </div>
                          <div>
                            <Button id="removeDefaultBranchResponseBtn" className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('conditionalSetting.defaultBranchResponse', index)}></Button>
                          </div>
                        </div>
                      )} />
                    </div>
                  </div>
                ))
              }
            </FieldArray>
          </Fieldset>

          <Fieldset className={styles.nodeform} legend="Branch Settings" toggleable>
            <div className="formgrid grid">
              <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">Branching responses to user inputs. Please create at least one.</p>
              <Field name="branchesText" render={({ input, meta }) => (
                <div className="field col-7">
                  <label htmlFor="branchesText" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Branches</label>
                  <div />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <div className="field col-12">
                <Button label="Add Branch" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.conditionalSetting && values.conditionalSetting.branches && values.conditionalSetting.branches.length >= 3)} onClick={() => push('conditionalSetting.branches', {} as Branch)}></Button>
              </div>
              <FieldArray name="conditionalSetting.branches">
                {({ fields }) =>
                  fields.map((branch, branchIndex) => (
                    <Fieldset className={`${styles.nodeform} fadeinleft animation-duration-500 animation-iteration-1`} key={`branch${(branchIndex + 1).toString()}`} legend={`Branch ${branchIndex + 1}`} toggleable>
                      <div className="formgrid grid">
                        <div className="col-12 text-right">
                          <Button className="p-button-danger p-button-outlined" label="Remove Branch" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('conditionalSetting.branches', branchIndex)}></Button>
                        </div>
                        <Field name={`${branch}.name`} validate={required} render={({ input, meta }) => (
                          <div className="field col-6">
                            <label htmlFor="branchNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Branch*</label>
                            <InputText id="branchNameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} keyfilter="alphanum" />
                            {getFormErrorMessage(meta)}
                          </div>
                        )} />
                        <Field name={`${branch}.nextStep`} render={({ input }) => (
                          <div className="field col-12 md:col-6">
                            <label htmlFor="branchNextStepInput">Next Step</label>
                            <Dropdown id="branchNextStepInput" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the condition of this node is fulfilled" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                          </div>
                        )} />
                        <WhenLastQuestionIsChecked set={`${branch}.nextStep`} to={undefined} />
                        <Field name={`${branch}.branchScore`} render={({ input }) => (
                          <div className="field col-12 md:col-6">
                            <label htmlFor="branchScoreInput">Score</label>
                            <InputText id="branchScoreInput" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                          </div>
                        )} />
                        <div className="field col-12">
                          <Button id="addBranchResponseBtn" label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!values.conditionalSetting && values.conditionalSetting.branches && values.conditionalSetting.branches.length > branchIndex && values.conditionalSetting.branches[branchIndex].responses && values.conditionalSetting.branches[branchIndex].responses.length >= 3} onClick={() => { push(`${branch}.responses`, "") }}></Button>
                        </div>
                        <FieldArray name={`${branch}.responses`}>
                          {({ fields }) =>
                            fields.map((branchResponse, branchResponseIndex) => (
                              <div key={`branchResponse${branchResponseIndex.toString()}`} className="col-12">
                                <h3 className="col-12">Branch Response #{branchResponseIndex + 1}</h3>
                                <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12">
                                  <Field name={`${branch}.responses[${branchResponseIndex}]`} render={({ input }) => (
                                    <div>
                                      <div>
                                        <InputText id="branchResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                                      </div>
                                      <div>
                                        <Button id="removeBranchResponseBtn" className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove(`${branch}.responses`, branchResponseIndex)}></Button>
                                      </div>
                                    </div>
                                  )} />
                                </div>
                              </div>
                            ))
                          }
                        </FieldArray>
                        <div className="field col-12 md:col-12">
                          <Button className="text-xs p-button-outlined" label="New Expression" type="button" onClick={() => push(`${branch}.expression.queries`, {} as ExpressionQuery)}></Button>
                          {/* <Button className="text-xs p-button-outlined mr-2" label="AND" type="button" disabled={false} onClick={() => push(`${branch}.expression`, { condition: ConditionEnum.AND } as Expression)}></Button> */}
                          {/* <Button className="text-xs p-button-outlined" label="OR" type="button" disabled={false} onClick={() => push(`${branch}.expression`, { condition: ConditionEnum.OR } as Expression)}></Button> */}
                        </div>
                        <Field name={`${branch}.expression.condition`} render={({ input }) => (
                          <div className="field col-12">
                            <label htmlFor="expressionConditionInput">Expression Condition</label>
                            <Dropdown id="expressionConditionInput" tooltip="The conditional expression conjoining each expression query statement" {...input} options={Object.keys(ConditionEnum)} className="inputfield w-full" />
                          </div>
                        )} />
                        <FieldArray name={`${branch}.expression.queries`}>
                          {({ fields }) =>
                            fields.map((expressionQuery, expressionIndex) => (
                              <div className="fadeinleft animation-duration-500 animation-iteration-1 col-12 formgrid grid" key={`expressionQuery${(expressionIndex + 1).toString()}`}>
                                <h4 className="col-12">Expression Query: {expressionIndex + 1}</h4>
                                {/* {expressionIndex !== 0 ?
                              <Field name={`${expression}.condition`} validate={required} render={({ input, meta }) => (
                                <div className="field col-4">
                                  <label htmlFor="conditionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Condition*</label>
                                  <Dropdown id="conditionInput" tooltip="The conditional expression conjoining each expression query statement" {...input} options={Object.keys(ConditionEnum)} className="inputfield w-full" />
                                  {getFormErrorMessage(meta)}
                                </div>
                              )} />
                              : <></>} */}
                                <Field name={`${expressionQuery}.conditionOperator`} validate={required} render={({ input, meta }) => (
                                  <div className="field col-4">
                                    <label htmlFor="expressionQueryConditionOperatorInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Operand*</label>
                                    <Dropdown id="expressionQueryConditionOperatorInput" tooltip="The conditional operator used for this expression" {...input} options={operatorTypeOptions} className="inputfield w-full" optionGroupChildren="items" optionGroupLabel="label" optionGroupTemplate={groupedItemTemplate} />
                                    {getFormErrorMessage(meta)}
                                  </div>
                                )} />
                                <Field name={`${expressionQuery}.value`} validate={required} render={({ input, meta }) => (
                                  <div className="field col-3">
                                    <label htmlFor="expressionQueryValueInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Value*</label>
                                    <InputText id="expressionQueryValueInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                                    {getFormErrorMessage(meta)}
                                  </div>
                                )} />
                                <div>
                                  <Button className="p-button-danger p-button-outlined" type="button" icon="pi pi-times" onClick={() => remove(`${branch}.expression`, expressionIndex)}></Button>
                                </div>
                              </div>
                            ))}

                        </FieldArray>
                        {/* <FieldArray name={`${branch}.conditionBuilderField`}>
                      {({ fields }) =>
                        fields.map((conditions, index) => (
                          <div className="fadeinleft animation-duration-500 animation-iteration-1 grid formgrid" key={`condition${index.toString()}`}>
                            <Field name={`${branch}.${conditions}.operator`} validate={required} render={({ input, meta }) => (
                              <div className="field col-5">
                                <label htmlFor="branchOperatorInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Operator Type*</label>
                                <Dropdown id="branchOperatorInput" showClear tooltip="The operand to be used against the slot value for conditional checking" {...input} options={operatorTypeOptions} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} optionLabel="label" optionGroupChildren="items" optionGroupLabel="label" optionGroupTemplate={groupedItemTemplate} />
                                {getFormErrorMessage(meta)}
                              </div>
                            )} />
                            <Field name={`${branch}.${conditions}.expectedValue`} validate={required} render={({ input, meta }) => (
                              <div className="field col-5">
                                <label htmlFor="expectedValueInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Expected Value*</label>
                                <InputText id="expectedValueInput" tooltip="The expected value to fulfil against the operand for conditional checking" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                                {getFormErrorMessage(meta)}
                              </div>
                            )} />
                            <div className="field col-2 align-self-center">
                              <Button className="text-xs p-button-outlined p-button-danger" icon="pi pi-times" iconPos="right" type="button" disabled={`${branch}.conditionBuilderField`.length === 1} onClick={() => remove('conditionBuilderField', index)}></Button>
                            </div>
                          </div>
                        ))}
                    </FieldArray> */}
                        {/* <Field name={`${branch}.expression`} validate={required} render={({ input, meta }) => (
                      <div className="field col-12 md:col-6">
                        <label htmlFor="branchExpressionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Condition*</label>
                        <Dropdown id="branchExpressionInput" showClear tooltip="The type of condition being used" {...input} options={Object.values(Conditions)} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        {getFormErrorMessage(meta)}
                      </div>
                    )} /> */}
                        {/* <WhenLastQuestionIsChecked set={`${branch}.scoreIfFulfilled`} to={0} />
                    <Field name={`${branch}.conditionDetails.conditionType`} validate={required} render={({ input, meta }) => (
                      <div className="field col-12 md:col-6">
                        <label htmlFor="conditionTypeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Condition*</label>
                        <Dropdown id="conditionTypeInput" showClear tooltip="The type of condition being used" {...input} options={Object.values(Conditions)} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />
                    <Field name={`${branch}.conditionDetails.expectedValue`} validate={required} render={({ input, meta }) => (
                      <div className="field col-12 md:col-6">
                        <label htmlFor="expectedValueInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Expected Value*</label>
                        <InputText id="expectedValueInput" tooltip="The expected value to fulfil this condition" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />
                    <Field name={`${branch}.conditionDetails.slotNameToCheck`} render={({ input, meta }) => (
                      <div className="field col-12 md:col-6">
                        <label htmlFor="slotNameToCheckInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Corresponding Slot Name in Lex*</label>
                        <InputText id="slotNameToCheckInput" tooltip="The name of the slot in Lex to check the expected value against" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                        {getFormErrorMessage(meta)}
                      </div>
                    )} /> */}
                      </div>
                    </Fieldset>
                  ))
                }
              </FieldArray>
            </div>
          </Fieldset>
          <div className="flex justify-content-end align-items-end mt-2">
            <Button type="button" className="p-button-outlined p-button-danger mr-3" label="Delete" icon="pi pi-trash" onClick={() => deleteNode(form)} disabled={!isUpdate}></Button>
            <Button type="submit" label={isUpdate ? "Update" : "Create"} icon="pi pi-check" disabled={pristine}></Button>
          </div>
        </form >
      )
      } />
    </div >
  )
}

export default ConditionalForm