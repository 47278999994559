import PrimeReact from "primereact/api"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { useState } from "react"
import { Field, Form } from "react-final-form"
import { InterviewStatusEnum } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import { InterviewListing, InterviewType } from "../../../interface"

interface CreateInterviewFormProps {
  formHeader: string
  actionOnSubmit: any
  submitButtonLabel: string
}

const CreateInterviewForm = (props: CreateInterviewFormProps) => {
  const { formHeader, actionOnSubmit, submitButtonLabel } = props
  PrimeReact.ripple = true
  const [startDateTime, setStartDateTime] = useState<Date | undefined>(undefined);
  const [endDateTime, setEndDateTime] = useState<Date | undefined>(undefined);

  let initialValues: InterviewListing = {
    intervieweeName: "",
    intervieweeEmail: "",
    startDateTime: "",
    endDateTime: "",
    status: InterviewStatusEnum.INACTIVE,
    type: InterviewType.PROMOTION
  }

  const onStartDateTimeChange = (e: any) => {
    setStartDateTime(e.value);
  }

  const onEndDateTimeChange = (e: any) => {
    setEndDateTime(e.value);
  }

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.intervieweeName) {
      errors.intervieweeName = 'Interviewee Name is required.'
    }

    if (!data.intervieweeEmail) {
      errors.intervieweeEmail = 'Interviewee E-Mail is required.'
    }

    if (!data.startDateTime) {
      errors.startDateTime = 'Please indicate an interview start date/time.'
    }

    if (!data.endDateTime) {
      errors.endDateTime = 'Please indicate an interview end date/time.'
    }

    return errors
  }

  const onSubmit = (data: any, form: any) => {
    console.log({ data });
    let createInterviewPayload = { ...data }
    createInterviewPayload.startDateTime = createInterviewPayload.startDateTime.toISOString();
    createInterviewPayload.endDateTime = createInterviewPayload.endDateTime.toISOString();
    actionOnSubmit(createInterviewPayload)
  }

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} render={({ form, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <div className="formgrid grid">
              <Field name="intervieweeName" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="intervieweeNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Interviewee Name*</label>
                  <InputText id="intervieweeNameInput" {...input} autoFocus type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} placeholder="John Smith" />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="intervieweeEmail" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="intervieweeEmailInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Interviewee E-Mail*</label>
                  <InputText id="intervieweeEmailInput" {...input} type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} placeholder="john.smith@gmail.com" />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="startDateTime" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="startDateTimeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Start Date/Time*</label>
                  <Calendar id="startDateTimeInput" value={startDateTime} maxDate={endDateTime} readOnlyInput selectionMode="single" onChange={(e) => { onStartDateTimeChange(e); input.onChange(e.value) }} showTime dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD" />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="endDateTime" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="endDateTimeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>End Date/Time*</label>
                  <Calendar id="endDateTimeInput" value={endDateTime} minDate={startDateTime} readOnlyInput selectionMode="single" onChange={(e) => { onEndDateTimeChange(e); input.onChange(e.value) }} showTime dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD" />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
            </div>
            <div className="flex justify-content-end align-items-end">
              <Button type="submit" label={submitButtonLabel} icon="pi pi-check"></Button>
            </div>
          </Card >
        </form>
      )} />
    </div>
  )
}

export default CreateInterviewForm