import { Dialog } from "primereact/dialog"
import CreateInterviewForm from "../../Forms/CreateInterviewForm"

interface CreateInterviewDialogProps {
  displayCreateInterviewDialog: boolean
  onHide: any
  actionOnSubmit: any
}

const CreateInterviewDialog = (props: CreateInterviewDialogProps) => {

  const { displayCreateInterviewDialog, onHide, actionOnSubmit } = props

  return (
    <Dialog className="w-6 h-26rem" header="Create New Interview" visible={displayCreateInterviewDialog} onHide={onHide} >
      <CreateInterviewForm formHeader={"Create Interview Form"} actionOnSubmit={actionOnSubmit} submitButtonLabel={"Create"}></CreateInterviewForm>
    </Dialog>
  )
}

export default CreateInterviewDialog