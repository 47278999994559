import PrimeReact from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Tenant } from "../../interface";
import { imageUploader } from "../../../../../utils/ImageUploader";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AppDispatch } from "../../../../../store";
import { createTenant, retrieveTenant, updateTenant } from "../../TenantAction";
import { CategoryEnum } from "../../../Lookup/enums";
import { LOOKUPS_KEY } from "../../../../../constants/localStorageKeys";
import LinkButton from "../../../../../constants/helpers/LinkButton";

interface TenantFormProps {
  editable: boolean;
  submitButtonLabel: string;
  resettable?: boolean;
}
const TenantForm = (props: TenantFormProps) => {
  const {
    editable,
    submitButtonLabel,
    resettable = true,
  } = props
  const [companySizeList, setCompanySizeList] = useState([] as any);
  const [industryList, setIndustryList] = useState([] as any);

  const { tenantId } = useParams<{ tenantId: string }>()
  const { tenant } = useSelector((state: any) => state.tenant)
  const dispatch = useDispatch<AppDispatch>()

  const uploadComponent = useRef<any>();
  const [logo, setLogo] = useState<string>(tenant.logo)

  PrimeReact.ripple = true
  const toast = useRef<any>(null)

  useEffect(() => {
    if (tenantId)
      dispatch(retrieveTenant(Number(tenantId)))
  }, [])

  useEffect(() => {
    setLogo(tenant.logo)
  }, [tenant])

  let initialValues: Tenant = tenant && tenantId
    ? {
      id: tenant.id,
      companyName: tenant.companyName,
      companyCode: tenant.companyCode,
      description: tenant.description,
      logo,
      registrationNo: tenant.registrationNo,
      companySize: tenant.companySize,
      industry: tenant.industry,
      benefits: tenant.benefits,
      enabled: tenant.enabled,
      createdBy: tenant.createdBy
    } :
    {
      companyName: '',
      companyCode: '',
      description: '',
      logo: '',
      registrationNo: '',
      companySize: '',
      industry: '',
      benefits: '',
      enabled: true,
      createdBy: ''
    };

  useEffect(() => {
    const lookups = localStorage.getItem(LOOKUPS_KEY);
    if (lookups) {
      const lookupsJSON = JSON.parse(lookups);
      const categoryList = Object.keys(CategoryEnum).filter(allItems => isNaN(Number(allItems)))
      categoryList.forEach((categoryName => retrieveLookupValues(lookupsJSON, categoryName)))
    }
  }, []);

  const retrieveLookupValues = (lookups: any, categoryName: any) => {
    switch (categoryName) {
      case CategoryEnum[CategoryEnum.COMPANY_SIZE]: {
        setCompanySizeList(lookups[categoryName].map((size: any) => size.value))
        break
      }
      case CategoryEnum[CategoryEnum.INDUSTRY]: {
        setIndustryList(lookups[categoryName].map((industry: any) => industry.value))
        break
      }
    }
  }

  const statuses = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ]

  const validate = (data: any) => {
    let errors = {} as any;

    if (!data.companyName) {
      errors.companyName = "Company Name is required.";
    }

    if (!data.companyCode) {
      errors.companyCode = "Company Code is required.";
    }
    return errors;
  };

  const onSubmit = (data: any, form: any) => {
    let payload = {} as Tenant;
    payload.id = data.id;
    payload.companyName = data.companyName;
    payload.companyCode = data.companyCode;
    payload.logo = logo;
    payload.description = data.description;
    payload.registrationNo = data.registrationNo;
    if (data.companySize === "")
      payload.companySize = "UNDEFINED";
    else
      payload.companySize = data.companySize;
    payload.industry = data.industry;
    payload.benefits = data.benefits;
    payload.enabled = data.enabled;
    console.log({ payload });

    // update or create
    if (tenantId) {
      dispatch(updateTenant(payload)).unwrap()
        .then((tenant: Tenant) => {
          // successs
          toast.current.show({
            severity: 'success',
            summary: 'Tenant Update Success',
            detail: `Tenant: ' ${payload.companyName} was updated successfully.`,
            life: 3000
          })
        })
        .catch((error: any) => {
          toast.current.show({
            severity: 'warn',
            summary: 'Tenant Update Error',
            detail: `Tenant: ' ${payload.companyName} was not updated.`,
            life: 3000
          })
        })
    } else {
      dispatch(createTenant(payload)).unwrap()
        .then((tenant: Tenant) => {
          // successs
          toast.current.show({
            severity: 'success',
            summary: 'Tenant Create Success',
            detail: `Tenant: ' ${payload.companyName} was created successfully.`,
            life: 3000
          })
        })
        .catch((error: any) => {
          toast.current.show({
            severity: 'warn',
            summary: 'Tenant Create Error',
            detail: `Tenant: ' ${payload.companyName} was not created.`,
            life: 3000
          })
        })
    }
  };

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta: any) => {
    return (
      isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
    );
  };

  const customUploadEvent = async (event: any) => {
    let base64Result = await imageUploader(event)
    setLogo(base64Result.toString());
    uploadComponent.current.clear();
  }

  const handleFormReset = (form: any) => {
    form.setConfig('keepDirtyOnReinitialize', false)
    form.restart()
    form.setConfig('keepDirtyOnReinitialize', true)
  }

  return (
    <div>
      <Toast ref={toast} />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ form, handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="formgrid grid align-self-center mx-2 my-3">
              <div className="col-12">
                {tenantId &&
                  <div className="field">
                    {!editable ?
                      <LinkButton className="p-button-primary" to={"/tenants/" + tenantId + "/update"} icon="eye" label="Edit" />
                      :
                      <LinkButton to={"/tenants/" + tenantId} icon="arrow-left" label="Back" />
                    }
                  </div>
                }
              </div>
              <div className="col-12">
                <Card title="Tenant Information" className="mb-3">
                  <div className="grid align-self-center">
                    <Field
                      name="companyName"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="companyNameInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Company Name:*
                          </label>
                          {editable ?
                            <InputText
                              id="companyNameInput"
                              {...input}
                              autoFocus
                              type="text"
                              className={classNames("inputfield w-full", {
                                "p-invalid": isFormFieldValid(meta),
                              })}
                            /> : <div>{tenant.companyName}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="companyCode"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="companyCodeInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Company Code:*
                          </label>
                          {editable ?
                            <InputText
                              id="companyCodeInput"
                              {...input}
                              type="text"
                              className={classNames("inputfield w-full", {
                                "p-invalid": isFormFieldValid(meta),
                              })}
                            /> : <div>{tenant.companyCode}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="description"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="descriptionInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Company Description:
                          </label>
                          {editable ?
                            <InputTextarea
                              id="descriptionInput"
                              {...input}
                              className={classNames("inputfield w-full", {
                                "p-invalid": isFormFieldValid(meta),
                              })}
                              rows={6}
                            /> : <div>{tenant.description}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <div className="field col-12 md:col-6">
                      <label htmlFor="uploadImage">Profile Picture:</label>
                      {editable &&
                        <FileUpload id="uploadImage" ref={uploadComponent}
                          className={classNames("inputfield w-full imageUploader")} customUpload auto
                          mode="basic" accept="image/*" maxFileSize={5000000} uploadHandler={customUploadEvent} />
                      }
                      <div className="mt-3">
                        {logo && logo.length > 0 &&
                          <img src={logo} alt="Display uploaded logo" style={{ width: '100px' }}></img>
                        }
                      </div>
                    </div>
                    <Field
                      name="registrationNo"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="registrationNoInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Company Registration No.:
                          </label>
                          {editable ?
                            <InputText
                              id="registrationNoInput"
                              {...input}
                              type="text"
                              className={classNames("inputfield w-full", {
                                "p-invalid": isFormFieldValid(meta),
                              })}
                            /> : <div>{tenant.registrationNo}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="companySize"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="companySizeInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Company Size:
                          </label>
                          {editable ?
                            <Dropdown
                              id="companySizePicker"
                              {...input} filter
                              className={classNames("inputfield w-full", {
                                "p-error": isFormFieldValid(meta),
                              })}
                              options={companySizeList}
                              placeholder="Select Company Size"
                            /> : <div>{tenant.companySize}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="industry"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="industryPicker"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Industry:
                          </label>
                          {editable ?
                            <Dropdown
                              id="industryPicker"
                              {...input} filter
                              className={classNames("inputfield w-full", {
                                "p-error": isFormFieldValid(meta),
                              })}
                              options={industryList}
                              placeholder="Select Industry"
                            /> : <div>{tenant.industry}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="benefits"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="benefitsInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Benefits:
                          </label>
                          {editable ?
                            <InputTextarea
                              id="benefitsInput"
                              {...input}
                              className={classNames("inputfield w-full", {
                                "p-error": isFormFieldValid(meta),
                              })}
                              rows={6}
                            /> : <div>{tenant.benefits}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                    <Field
                      name="enabled"
                      render={({ input, meta }) => (
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="enabledInput"
                            className={classNames({
                              "p-error": isFormFieldValid(meta),
                            })}
                          >
                            Status:
                          </label>
                          {editable ?
                            <Dropdown
                              id="statusenabledInputInput"
                              {...input}
                              options={statuses}
                              showClear
                              placeholder="Select a Status"
                              className={classNames("inputfield w-full", {
                                "p-error": isFormFieldValid(meta),
                              })}
                            /> : <div>{tenant.status ? 'Active' : 'In-active'}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )}
                    />
                  </div>
                </Card>
              </div>
              <div className="col-12">
                {editable &&
                  <div className="flex justify-content-end align-items-end">
                    {resettable ?
                      <Button
                        className="p-button-outlined p-button-secondary mr-3"
                        label="Reset"
                        icon="pi pi-refresh"
                        onClick={(e) => { handleFormReset(form); e.preventDefault() }}
                        disabled={pristine}></Button>
                      : false
                    }
                    <Button label={submitButtonLabel} icon="pi pi-check"></Button>
                  </div>
                }
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default TenantForm;