import { createSlice } from "@reduxjs/toolkit"
import { createJob, retrieveJob, retrieveJobs, updateJob } from "./JobAction"
import { Job } from "./interface"

const initialState = {
  job: {} as Job,
  jobList: [],
  totalRecords: undefined,
}

const jobSlicer = createSlice({
  name: 'job',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveJobs.fulfilled, (state: any, { payload }) => {
        state.jobList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
      .addCase(retrieveJob.fulfilled, (state: any, { payload }) => {
        state.job = payload
      })
      .addCase(createJob.fulfilled, (state: any, { payload }) => {
        state.job = payload
      })
      .addCase(updateJob.fulfilled, (state: any, { payload }) => {
        state.job = payload
      })
  },
})

export default jobSlicer.reducer