import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { createReduxHistoryContext } from "redux-first-history";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit'

import homeInfoReducer from './homeInfo/reducer'

import authReducer from '../admin/Login/loginSlice'
import LookupSlicer from '../AdminPortal/scenes/Lookup/LookupSlicer';
import TenantSlicer from '../AdminPortal/scenes/Tenant/TenantSlicer';
import UserSlicer from '../AdminPortal/scenes/User/UserSlicer';
import JobSlicer from '../AdminPortal/scenes/Job/JobSlicer';
import ApplicationSlicer from '../AdminPortal/scenes/Application/ApplicationSlice';
import InterviewSlicer from '../AdminPortal/scenes/Interview/InterviewSlicer';
import InterviewFlowSlice from '../AdminPortal/scenes/Interview/InterviewSetup/InterviewFlowSlice'
import CandidateJobSlice from '../CandidatePortal/scenes/company/job/CandidateJobSlice';
import CandidateApplicationSlice from '../CandidatePortal/scenes/company/application/CandidateApplicationSlice';
import CandidateInterviewSlice from '../CandidatePortal/scenes/Interview/CandidateInterviewSlice';
import CandidateChatbotSlice from '../CandidatePortal/scenes/Interview/Chatbot/CandidateChatbotSlice';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const store = configureStore({
  reducer: combineReducers({
    global: (_, action) => {
      return action.type.endsWith('pending') ? { loading: true } : { loading: false }
    },

    auth: authReducer,
    router: routerReducer,

    lookup: LookupSlicer,
    tenant: TenantSlicer,
    user: UserSlicer,
    job: JobSlicer,
    application: ApplicationSlicer,
    interview: InterviewSlicer,
    interviewFlow: InterviewFlowSlice,

    candidateJob: CandidateJobSlice,
    candidateApplication: CandidateApplicationSlice,
    candidateInterview: CandidateInterviewSlice,
    candidateChatbot: CandidateChatbotSlice,

    homeInfo: homeInfoReducer

    // interview: interviewReducer,
    // application: applicationReducer,
  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware),
  enhancers: (defaultEnhancers) => 
    defaultEnhancers.prepend(composeWithDevTools(applyMiddleware(routerMiddleware, thunk)))
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const history = createReduxHistory(store);

export default store