import classes from './styles.module.css'

import { Button } from 'primereact/button'
import { Card } from 'primereact/card'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import PrimeReact from 'primereact/api'

import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from './loginAction'
import { AppDispatch } from '../../store'
import { useHistory } from "react-router-dom"
import { ADMIN_ROUTES } from "../../constants/routes/admin/menus"
import { Form, Field, FieldMetaState } from 'react-final-form'
import ProgressSpinnerDialog from '../../constants/helpers/ProgressSpinnerDialog'
import { retrieveAllLookup } from '../../AdminPortal/scenes/Lookup/LookupAction'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'

const AdminLogin = () => {
  const history = useHistory()

  const { loading, error } = useSelector((state: any) => state.auth)
  const dispatch = useDispatch<AppDispatch>()
  const toastRef = useRef<any>()

  PrimeReact.ripple = true

  const handleLogin = (values: any) => {
    const username = values.username
    const password = values.password
    dispatch(userLogin({ username, password }))
      .unwrap().then(async () => {
        history.push(ADMIN_ROUTES.ROUTE_HOMEPAGE_ADMIN)

        // retrieve lookup
        dispatch(retrieveAllLookup()).unwrap().then(() => {
        }).catch((error) => {
          // TODO find a way to show error
          console.log('error', error)
        })
      })
      .catch(() => {
        // TODO show error in form
      })
  }

  function isFormFieldValid(meta: FieldMetaState<any>) {
    throw new Error('Function not implemented.')
  }

  return (
    <Form
      onSubmit={handleLogin}
      initialValues={{ username: '', password: '' }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Toast ref={toastRef} position="top-right" />
          <div className={classes.Container}>
            <Card style={{ width: "35vw", borderRadius: "black solid 1px !important" }}>
              <div className="p-card-title" style={{ top: 0 }}>
                <FontAwesomeIcon icon={faCoffee} style={{ paddingRight: "16px" }} />
                Sign in
              </div>
              <div>
                Sign in to your X-Interviewer administrative account.
              </div>
              {error ?
                <small id="username2-help" className="p-error p-d-block">
                  Invalid username or password.</small> : false}
              
              <div>
              <h5>Email Address</h5>
                <Field name="username" render={({ input, meta }) => (
                  <div>
                    <span className="p-float-label p-input-icon-right" style={{ width: "100%" }}>
                      <InputText id="username" {...input} className= "inputfield w-full" />
                    </span>
                  </div>
                )} />
              </div>
              <div>
              <h5>Password</h5>
                <Field name="password" render={({ input, meta }) => (
                  <div>
                    <span className="p-float-label p-input-icon-right" style={{ width: "100%" }}>
                      <InputText id="password" type="password" {...input} className= "inputfield w-full" />
                    </span>
                  </div>
                )} />
              </div>
              <div className={classes.SubmitButton}>
                <Button
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={submitting || pristine}
                  label="Submit" >
                </Button>
              </div>
              <div className={classes.ResetPasswordButton}>
                <Button
                  style={{ width: "100%", textAlign: 'center' }}
                  className="p-button-secondary"
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                  label="Reset your password" >
                </Button>
              </div>
            </Card>
          </div>
          <ProgressSpinnerDialog visible={loading} />
        </form>
      )}
    />
  )
}


export default AdminLogin
