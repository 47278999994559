import { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import PrimeReact from 'primereact/api'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Job, JobStatusEnum } from '../../../../AdminPortal/scenes/Job/interface'
import { AppDispatch } from '../../../../store'
import { candidateRetrieveJobs } from '../job/CandidateJobAction'
import LinkButton from '../../../../constants/helpers/LinkButton'

interface CompanyJobProps {
}

const ViewJobs = (props: CompanyJobProps) => {
  const { } = props
  const history = useHistory()
  const { companyCode } = useParams<{ companyCode: string }>()
  
  const dispatch = useDispatch<AppDispatch>()
  const { jobList, totalRecords } = useSelector((state: any) => state.candidateJob)

  const dt = useRef<any>(null);
  let filters: Record<string, any> = {}
  const [rows, setRows] = useState(5)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: "createdDate",
    sortOrder: -1,
    filters
  })

  //Lazy loading
  useEffect(() => {
    // getCompanyJobAction.getAllCompanyJobs(lazyParams, companyCode)
    dispatch(candidateRetrieveJobs({lazyParams, companyCode}))
  }, [lazyParams])

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams)
  }

  // Custom Filter Details
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedEmploymentType, setSelectedEmploymentType] = useState(null)
  const [selectedContractType, setSelectedContractType] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedDate, setSelectedDate] = useState(undefined)

  const handleResetFilters = () => {
    setSelectedStatus(null)
    setSelectedEmploymentType(null)
    setSelectedLocation(null)
    setSelectedContractType(null)
    setSelectedDate(undefined)
    dt.current.reset();
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters = {}
    setLazyParams(_lazyParams)
  }

  const onStatusChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.status = { 'value': e.value }
    setLazyParams(_lazyParams)
    setSelectedStatus(e.value)
  }

  const onEmploymentTypeChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.employmentType = { 'value': e.value }
    setLazyParams(_lazyParams)
    setSelectedEmploymentType(e.value)
  }

  const onContractTypeChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.contractType = { 'value': e.value }
    setLazyParams(_lazyParams)
    setSelectedContractType(e.value)
  }

  const onLocationChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.location = { 'value': e.value }
    setLazyParams(_lazyParams)
    setSelectedLocation(e.value)
  }

  const onDateChange = (e: any) => {
    setSelectedDate(e.value)
    if (e.value[0] != null && e.value[1] != null) {
      let startDate = `${e.value[0].getFullYear()}-${e.value[0].getMonth() + 1}-${e.value[0].getDate()}`
      let endDate = `${e.value[1].getFullYear()}-${e.value[1].getMonth() + 1}-${e.value[1].getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.createdDateLt = { 'value': startDate }
      _lazyParams.filters.createdDateGt = { 'value': endDate }
      setLazyParams(_lazyParams)
    }
  }

  const statuses = Object.keys(JobStatusEnum).filter(k => isNaN(Number(k)));
  
  const employmentTypes = [
    'Full Time',
    'Part Time',
    'Trainee/Internship'
  ]

  const contractTypes = [
    'Permanent Staff',
    'Temporary',
    'Contractual',
  ]

  const locations = [
    'Singapore',
    'Malaysia',
    'Indonesia'
  ]

  const dateFilter = <Calendar value={selectedDate} readOnlyInput selectionMode="range" onChange={onDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>
  const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const employmentFilter = <Dropdown value={selectedEmploymentType} options={employmentTypes} onChange={onEmploymentTypeChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const contractFilter = <Dropdown value={selectedContractType} options={contractTypes} onChange={onContractTypeChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const locationFilter = <Dropdown value={selectedLocation} options={locations} onChange={onLocationChange} placeholder="All" className="p-column-filter" showClear></Dropdown>

  const createdDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.createdDate)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const header = (
    <div className="table-header" style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={handleResetFilters} />
    </div>
  )

  const actionBodyTemplate = (rowData: Job) => {
    return (
      <LinkButton to={`${companyCode}/${rowData.id}`} className="mr-2"
        icon="eye" iconOnly={true} label="View" />
    )
  }

  return (
    <div>
      <Card>
        <DataTable ref={dt} value={jobList} first={lazyParams.first} rows={rows} totalRecords={totalRecords} header={header}
          onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
          onFilter={onFilter} filters={lazyParams.filters}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25]}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          lazy paginator>
          <Column field="title" header="Title" filter sortable></Column>
          <Column field="employmentType" header="Employment" filter filterElement={employmentFilter} sortable></Column>
          <Column field="contractType" header="Contract" filter filterElement={contractFilter} sortable></Column>
          <Column field="location" header="Location" filter filterElement={locationFilter} sortable></Column>
          <Column field="status" header="Status" filter filterElement={statusFilter} sortable></Column>
          <Column field="createdDate" header="Created Date" body={createdDateBodyTemplate} filter filterElement={dateFilter} sortable></Column>
          <Column
            body={actionBodyTemplate}
            headerStyle={{ width: '12em', textAlign: 'center' }}
            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
          />
        </DataTable>
      </Card>
    </div>
  )
}

export default ViewJobs