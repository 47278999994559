import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { Interview } from "../../../AdminPortal/scenes/Interview/interface"
import { candidateRetrieveInterview } from "./CandidateInterviewAction"

interface ApplicationState {
  interview: Interview
}

const initialState = {
  interview: {},
} as ApplicationState

const applicationSlicer = createSlice({
  name: 'candidate-interview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateRetrieveInterview.fulfilled, (state: any, { payload }) => {
        state.interview = payload
      })
  },
})

export default applicationSlicer.reducer