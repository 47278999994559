import { createSlice } from "@reduxjs/toolkit"
import { candidateRetrieveJob, candidateRetrieveJobs, candidateRetriveResume } from "./CandidateJobAction"
import { Job } from "../../../../AdminPortal/scenes/Job/interface"
import { Resume } from "../interface"

const initialState = {
  job: {} as Job,
  jobList: [],
  totalRecords: undefined,
  resume: {} as Resume,
}

const jobSlicer = createSlice({
  name: 'candidate-job',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateRetrieveJobs.fulfilled, (state: any, { payload }) => {
        state.jobList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
      .addCase(candidateRetrieveJob.fulfilled, (state: any, { payload }) => {
        state.job = payload
      })
      .addCase(candidateRetriveResume.fulfilled, (state: any, { payload }) => {
        state.resume = payload
      })
  },
})

export default jobSlicer.reducer