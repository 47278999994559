import { useLocation } from "react-router-dom"

interface SingpassCallbackProps {
}

const SingpassCallback = (singpassCallbackProps: SingpassCallbackProps) => {
  const { } = singpassCallbackProps
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const code = params.get('code')
  const state = params.get('state')

  if(code !== null && state !== null) {
    localStorage.setItem('authCode', code)
    localStorage.setItem('authState', state)
  }

  window.close()

  return (
    <></>
  )
}

export default SingpassCallback