import { Message } from "@aws-sdk/client-lex-runtime-v2"
import moment from "moment"
import { Card } from "primereact/card"
import { Fieldset } from "primereact/fieldset"
import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import MessageList from "../../../../CandidatePortal/scenes/Interview/Chatbot/components/MessageList"
import { MessageDetails } from "../../../../CandidatePortal/scenes/Interview/Chatbot/interface"

import { AppDispatch } from "../../../../store"
import { retrieveInterview } from "../InterviewAction"

interface InterviewViewProps {
}

const InterviewView = (props: InterviewViewProps) => {
  const { } = props
  const { interviewId } = useParams<{ interviewId: string }>()
  const [loaded, setLoaded] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const { interview } = useSelector((state: any) => state.interview)

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    dispatch(retrieveInterview(Number(interviewId)))
  }

  return (
    <div>
      {interview && interview.chatSession ?
        <Card>
          <div className="flex formgrid grid">
            <div className="field col-6">
              <h1>Interview Details</h1>
              <Fieldset toggleable legend={"Interview ID: " + interview.id}>
                <div className="flex formgrid grid">
                  <div className="field col-6">
                    Interviewee: {interview.intervieweeName}
                  </div>
                  <div className="field col-6">
                    E-Mail: {interview.intervieweeEmail}
                  </div>
                  {interview.application ?
                    <>
                      <div className="field col-6">
                        Job: {interview.application.job.title}
                      </div>
                      <div className="field col-6">
                        Application ID: <Link to={"/applications/" + interview.application.id}>{interview.application.id}</Link>
                      </div>
                    </>
                    :
                    <></>
                  }
                  <div className="field col-6">
                    Start Date/Time:<br />{moment(interview.startDateTime).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                  </div>
                  <div className="field col-6">
                    End Date/Time:<br />{moment(interview.endDateTime).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                  </div>
                  <div className="field col-6">
                    Status: {interview.status}
                  </div>
                  <div className="field col-6">
                    Type: {interview.type}
                  </div>
                  <div className="field col-6">
                    Created By: {interview.createdBy}
                  </div>
                  <div className="field col-6">
                    Created Date: {moment(interview.createdDate).format("Do MMMM YYYY")}
                  </div>
                </div>
              </Fieldset>
            </div>

            <div className="field col-6">
              <h1>Chat Details</h1>
              <Fieldset toggleable legend={"Chat Session UUID: " + interview.chatSession.id}>
                {interview.chatSession.chatLogs && interview.chatSession.chatLogs.length > 0 ?
                  <MessageList
                    messageDetailsList={interview.chatSession.chatLogs!.map((chatLog: any) => {
                      return {
                        message: {
                          content: chatLog.message,
                          contentType: 'string'
                        } as Message,
                        header: chatLog.name,
                        timeStamp: chatLog.timestamp
                      } as MessageDetails
                    }
                    )} />
                  :
                  <h3>No chatlogs found</h3>
                }
              </Fieldset>
            </div>
          </div>
        </Card>
        : <></>
      }
    </div>
  )
}

export default InterviewView

