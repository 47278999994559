export async function imageUploader(event: any):Promise<string>{
    return await convertImageToBase64(event);
}

const convertImageToBase64 = async (event: any):Promise<string> =>{
    // console.log(event);
    const imageFile = event.files[0];
    return await new Promise(async(resolve, reject) => {
    const fileReader = new FileReader();
    let blobFile = await fetch(imageFile.objectURL).then((r) => r.blob());
    
    fileReader.readAsDataURL(blobFile);
    fileReader.onloadend = function () {
        if (fileReader.result !== null) {
            let base64Result = (fileReader.result.toString());
            resolve(base64Result);
        }
    };
})
}