import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../utils/Api'
import URI from 'urijs'
import { Interview, InterviewFlow } from './interface'
import { processParams } from '../../../utils/LazyParamsUtil'

const endpoint = process.env.REACT_APP_INTERVIEW_API_URL

export const retrieveInterviews = createAsyncThunk('interview/retrieveInterviews',
  async (lazyParams: any, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'getInterviewListing', processParams(lazyParams), rejectWithValue)
)

export const retrieveInterview = createAsyncThunk('interview/retrieveInterview',
  async (interviewId: Number, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'getInterview/' + interviewId, undefined, rejectWithValue)
)

export const createInterview = createAsyncThunk('interview/createInterview',
  async (interview: Interview, { rejectWithValue }) => Api(ApiMethod.POST, endpoint, 'tenantCreateInterview', interview, rejectWithValue)
)

export const createCandidateInterview = createAsyncThunk('interview/candidate/createInterview',
  async ({interviewId, token}: {interviewId: Number, token: string}, { rejectWithValue }) => Api(ApiMethod.POST, endpoint, `candidate/interview/createInterview?interviewId=${interviewId}&token=${token}`, undefined, rejectWithValue)
)

export const retrieveInterviewFlows = createAsyncThunk('interview/retrieveInterviewFlows',
  async (lazyParams: any, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'getInterviewFlowListing', processParams(lazyParams), rejectWithValue)
)

export const createInterviewFlow = createAsyncThunk('interview/createInterviewFlow',
  async (interviewFlow: InterviewFlow, { rejectWithValue }) => Api(ApiMethod.POST, endpoint, 'createInterviewFlow', interviewFlow, rejectWithValue)
)