import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import JobContent from "./JobContent"
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Toast } from 'primereact/toast'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Job } from '../../../../AdminPortal/scenes/Job/interface'
import ProgressSpinnerDialog from '../../../../constants/helpers/ProgressSpinnerDialog'
import { RESUME_KEY } from '../../../../constants/localStorageKeys'
import { ChatSession, CreateInterviewStatus } from '../../Interview/Chatbot/interface'
import CandidateApplicationFormDialog from "../components/Dialogs/CandidateApplicationFormDialog"
import ResumeUploadDialog from "../components/Dialogs/ResumeUploadDialog"
import { CreateApplicationStatus, Resume, ResumeGetStatus, ResumeUpdateStatus, ResumeUploadStatus } from '../interface'
import { AppDispatch } from '../../../../store'
import { candidateRetrieveJob, candidateRetriveResume, candidateUpdateResume, candidateUploadResume } from '../job/CandidateJobAction'
import { candidateCreateApplication, candidateRetrieveApplication } from '../application/CandidateApplicationAction'
import { candidateCreateInterview } from '../../Interview/CandidateInterviewAction'

interface CompanySpecificJobProps {
}

interface PathParamsDefinition {
  companyCode: string
  jobId: string
}

const ViewSpecificJob = (props: CompanySpecificJobProps) => {
  // Retrieve required params responsible for this page view
  const { } = props

  const { companyCode, jobId } = useParams<PathParamsDefinition>()
  const toast = useRef<any>()
  // const resumeToken = localStorage.getItem(RESUME_KEY)

  const dispatch = useDispatch<AppDispatch>()
  const { job, resume } = useSelector((state: any) => state.candidateJob)
  const { jobApplied, remainingAttempts } = useSelector((state: any) => state.candidateApplication)

  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState("")

  const [resumeToken, setResumeToken] = useState<string | null>(localStorage.getItem(RESUME_KEY))
  const [displayResumeUploadDialog, setDisplayResumeUploadDialog] = useState(false)
  const [displayApplicationFormDialog, setDisplayApplicationFormDialog] = useState(false)

  const history = useHistory()

  //Init effect
  useEffect(() => {
    dispatch(candidateRetrieveJob({ jobId: Number(jobId), companyCode })).unwrap()
      .then(() => {
        if (job && resumeToken) {
          // retrieve application details
          dispatch(candidateRetrieveApplication({ jobId: Number(jobId), token: resumeToken }))
        }
      })
  }, [])

  const applyForJob = () => {
    if (resumeToken) {
      //Already have resume in database
      getResumeWithToken(resumeToken)
    }
    else {
      //First application 
      setDisplayResumeUploadDialog(true)
    }
  }

  const getResumeWithToken = (token: string) => {
    // retrieve personal particulars from resume token
    dispatch(candidateRetriveResume(token)).unwrap()
      .then(() => {
        // toast.current.show({ severity: 'success', summary: 'Retrieved resume', detail: 'resumeGetMessage', life: 3000 })
        setDisplayApplicationFormDialog(true)
      })
      .catch(() => {
        toast.current.show({ severity: 'warn', summary: 'Failed to retrieve resume', detail: 'There was an error retriving your resume, please re-upload and try again.', life: 3000 })
        setDisplayResumeUploadDialog(true)

        // remove resume if exist
        localStorage.removeItem(RESUME_KEY)
        setResumeToken(null)
      })
  }

  const retryInterview = () => {
    setLoadingMessage("Preparing your interview...")
    setLoading(true)
    createInterview()
  }

  const uploadResume = (resumeData: FormData) => {
    setLoading(true)
    dispatch(candidateUploadResume({ resumeData, token: resumeToken })).unwrap()
      .then((data: any) => {
        if (data.token) {
          toast.current.show({ severity: 'success', summary: 'Uploaded to server', detail: 'Resume successfully uploaded.', life: 3000 })
          setDisplayResumeUploadDialog(false)

          // set and retrieve resume details
          localStorage.setItem(RESUME_KEY, data.token)
          setResumeToken(data.token)

          // retrieve interview details and show application form
          getResumeWithToken(data.token)
        } else {
          toast.current.show({ severity: 'warn', summary: 'No token found', detail: "Server was unable to provide resume token.", life: 3000 })
        }
      })
      .catch(() => {
        toast.current.show({ severity: 'warn', summary: 'Failed to upload resume', detail: 'Resume uploading failed, please try again.', life: 3000 })
        setDisplayResumeUploadDialog(false)
      })
      .finally(() => setLoading(false))
  }

  const updateResume = (resume: Resume) => {
    if (resumeToken) {
      dispatch(candidateUpdateResume({ resume, token: resumeToken })).unwrap()
        .then(() => {
          toast.current.show({ severity: 'success', summary: 'Updated particulars', detail: 'Resume updated successfully.', life: 3000 })
          setDisplayApplicationFormDialog(false)

          // create application and interview record
          dispatch(candidateCreateApplication({ jobId: job.id, token: resumeToken })).unwrap()
            .then(() => {
              toast.current.show({ severity: 'success', summary: 'Job applied', detail: 'We have received your application.', life: 3000 })
              if (job.interviewable) {
                // create interview and redirect
                createInterview()
              }
            })
            .catch(() => {
              toast.current.show({ severity: 'warn', summary: 'Failed to apply for job', detail: 'Application creation failed, please try again.', life: 3000 })
            })
        })
        .catch(() => {
          toast.current.show({ severity: 'warn', summary: 'Failed to update particulars', detail: 'Resume update failed, please try again.', life: 3000 })
        })
    } else {
      toast.current.show({ severity: 'warn', summary: 'No token found', detail: "Unable to find resume token", life: 3000 })
    }
  }

  const createInterview = () => {
    if (resumeToken)
      dispatch(candidateCreateInterview({ jobId: job.id, token: resumeToken })).unwrap()
        .then((chatSession: ChatSession) => {
          toast.current.show({ severity: 'success', summary: 'Interview created', detail: 'Interview successfully created, you will be redirected.', life: 3000 })
          history.push("/interview/" + chatSession.id)
        })
        .catch(() => {
          toast.current.show({ severity: 'warn', summary: 'Failed to create interview', detail: 'Interview creation failed, please try again.', life: 3000 })
        })
  }

  //TODO in menu bar for candidate portal
  // const handleDownloadResume = (e: any) => {
  //   console.log(e)
  //   e.preventDefault()
  //   getResumeAction.downloadResumeWith(resumeToken!)
  // }

  return (
    <div>
      <ProgressSpinnerDialog text={loadingMessage} visible={loading}></ProgressSpinnerDialog>
      <Toast ref={toast} />
      <Card>
        <div className="flex formgrid">
          <div className="field col-6 md:col-6">
            <div className="field col-6 md:col-6">
              <Button className="p-button-outlined p-button-lg" type="button" label="Back to List" iconPos='left' icon="pi pi-arrow-left" onClick={() => history.replace('/' + companyCode)} />
            </div>
            {/* <div className="field col-6 md:col-6">
              <Button className="p-button-outlined p-button-lg" type="button" label="Download Resume" icon="pi pi-download" disabled={!resumeToken} onClick={handleDownloadResume} />
            </div> */}
          </div>
          {jobApplied ?
            <div className="field col-6 md:col-6">
              <div className="field col-6 md:col-6">
                <Button id="appliedBtn" type="button" label="Applied" disabled />
              </div>
              <div className="field col-6 md:col-6">
                <Button id="retryInterviewBtn" type="button" label="Retry Interview" onClick={() => retryInterview()} disabled={remainingAttempts <= 0 || !job.interviewable} />
                {job.interviewable ?
                  <label htmlFor="retryInterviewBtn">You have {remainingAttempts} attempts remaining.</label>
                  :
                  <label htmlFor="retryInterviewBtn">This job does not have have any interviews available.</label>}
              </div>
            </div>
            :
            <div className="field col-6 md:col-6">
              <Button id="applyBtn" className="p-button-lg" type="button" label="Apply Now" onClick={() => applyForJob()} />
            </div>
          }
        </div>
        <div>
          <form>
            <JobContent job={job} />
          </form>
        </div>
      </Card >

      <ResumeUploadDialog
        uploadResume={uploadResume}
        displayResumeUploadDialog={displayResumeUploadDialog}
        setDisplayResumeUploadDialog={setDisplayResumeUploadDialog} />
      <CandidateApplicationFormDialog
        resume={resume}
        updateResume={updateResume}
        uploadResume={uploadResume}
        displayApplicationFormDialog={displayApplicationFormDialog}
        setDisplayCandidateApplicationFormDialog={setDisplayApplicationFormDialog} />
    </div >
  )
}

export default ViewSpecificJob
