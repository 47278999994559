import { Dialog } from "primereact/dialog"
import CreateInterviewFlowForm from "../../Forms/CreateInterviewFlowForm"

//Only used for PORTAL_ADMIN for creating template flows
interface CreateInterviewFlowDialogProps {
  displayCreateInterviewFlowDialog: boolean
  onHide: any
  actionOnSubmit: any
}

const CreateInterviewFlowDialog = (props: CreateInterviewFlowDialogProps) => {
  const { displayCreateInterviewFlowDialog, onHide, actionOnSubmit } = props

  return (
    <div>
      <Dialog className="w-4 h-21rem" visible={displayCreateInterviewFlowDialog} onHide={onHide} header="Create New Flow" >
        <CreateInterviewFlowForm formHeader={"Create Interview Flow Form"} actionOnSubmit={actionOnSubmit} submitButtonLabel={"Create"}></CreateInterviewFlowForm>
      </Dialog>
    </div>
  )
}

export default CreateInterviewFlowDialog