import Elk, { ElkNode, ElkExtendedEdge } from 'elkjs';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactFlow, {
  Background, ConnectionLineType, ControlButton, Controls, Edge,
  EdgeTypes, Node, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow
} from "react-flow-renderer";
import { IntentDetails, InterviewNode } from "../../../../../CandidatePortal/scenes/Interview/Chatbot/interface";
import { AutoLayoutState, InterviewNodeListStatus } from "../../interface";

import FloatingEdge from './helpers/FloatingEdge';
import NodeElements from "./helpers/NodeElements";
import styles from "./styles.module.css";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../store';
/*
* Constants
*/
const nodeWidth = 300;
const nodeHeight = 50;
const nodeTypes = {};
const edgeTypes: EdgeTypes = {
  floating: FloatingEdge,
};
const graphStyles = { width: "100%", height: "95vh" };

interface InterviewReactFlowProps {
  nodeList: InterviewNode[]
  autolayout: boolean
  interviewNodeListStatus?: InterviewNodeListStatus
  handleAutoLayoutState: (autoLayoutState: AutoLayoutState) => void
  handleNodeClick: (nodeClicked: any) => void
  updateInterviewNodeList: (nodeList: InterviewNode[]) => any
  setInterviewNodeListStatus?: (interviewNodeListStatus: InterviewNodeListStatus) => any
  setChangesMade: (changesMade: boolean) => void
}

const InterviewReactFlow = forwardRef((props: InterviewReactFlowProps, ref: any) => {
  const { nodeList, autolayout, interviewNodeListStatus,
    handleAutoLayoutState, handleNodeClick, updateInterviewNodeList, setInterviewNodeListStatus, setChangesMade } = props

  useImperativeHandle(ref, () => ({
    fitView: () => {
      flowRef.current.fitView()
    },
    redoLayout: (clear: boolean) => {
      flowRef.current.redoLayout(clear)
    }
  }));

  const flowRef = useRef<any>()

  const CustomControls = () => {
    return (
      <Controls showInteractive={false}>
        <ControlButton onClick={() => flowRef.current.redoLayout(false)}>
          <i className="pi pi-sitemap" />
        </ControlButton>
      </Controls >
    )
  }

  return (
    <div className={styles.layoutflow}>
      <ReactFlowProvider>
        <Flow nodeList={nodeList}
          handleAutoLayoutState={handleAutoLayoutState}
          updateInterviewNodeList={updateInterviewNodeList}
          handleNodeClick={handleNodeClick}
          ref={flowRef}
          autolayout={autolayout}
          setChangesMade={setChangesMade} />
        <Background />
        <CustomControls />
      </ReactFlowProvider>
    </div>
  )
})

/*
* Use Elkjs to calculate the coords of nodes
*/
const createGraphLayout = async (nodes: Node[], edges: Edge[], direction = 'DOWN'): Promise<Array<Node>> => {
  const elkNodes: ElkNode[] = []
  const elkEdges: ElkExtendedEdge[] = []

  nodes.forEach((node) => {
    // only needed if edge type is not floating 
    // node.targetPosition = direction === 'DOWN' ? Position.Top : Position.Left;
    // node.sourcePosition = direction === 'DOWN' ? Position.Bottom : Position.Right;

    elkNodes.push({
      id: node.id,
      width: node.width ?? nodeWidth,
      height: node.height ?? nodeHeight
    })
  });

  edges.forEach((edge) => {
    elkEdges.push({
      id: edge.id,
      targets: [edge.target],
      sources: [edge.source]
    })
  });

  // Elks properties, references: https://www.eclipse.org/elk/reference/options.html
  const elk = new Elk({
    defaultLayoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': direction,
      'elk.spacing.nodeNode': '20',
      'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS',
      'elk.layered.layering.strategy': 'COFFMAN_GRAHAM',
      'elk.layered.spacing.nodeNodeBetweenLayers': '30',
      'elk.layered.spacing.edgeNodeBetweenLayers': '30',
      'elk.layered.spacing.edgeEdgeBetweenLayers': '30'
    }
  });

  const newGraph = await elk.layout({
    id: 'root',
    children: elkNodes,
    edges: elkEdges
  });

  // map the new coords to existing nodes
  return nodes.map((node: Node) => {
    const elkNode = newGraph?.children?.find((n: any) => n.id === node.id)
    if (elkNode?.x && elkNode?.y && elkNode?.width && elkNode?.height) {
      // console.log(elkNode);
      node.position = {
        x: elkNode.x - elkNode.width / 2 + Math.random() / 1000,
        y: elkNode.y - elkNode.height / 2
      }
    }
    return node;
  });
}

interface FlowProps {
  nodeList: InterviewNode[]
  autolayout: boolean
  interviewNodeListStatus?: InterviewNodeListStatus
  handleAutoLayoutState: (autoLayoutState: AutoLayoutState) => void
  updateInterviewNodeList: (nodeList: InterviewNode[]) => any
  handleNodeClick: (nodeClicked: any) => void
  setInterviewNodeListStatus?: (interviewNodeListStatus: InterviewNodeListStatus) => any
  setChangesMade: (changesMade: boolean) => void
}

const Flow = forwardRef((props: FlowProps, ref: any) => {

  const { nodeList, autolayout, interviewNodeListStatus, handleNodeClick, updateInterviewNodeList, handleAutoLayoutState, setInterviewNodeListStatus, setChangesMade } = props
  // get all nodes and edges
  const { nodes: flowNodes, edges: flowEdges } = NodeElements(nodeList);
  // initialize necessary react flow components

  const dispatch = useDispatch<AppDispatch>()
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(flowNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(flowEdges);
  //Manual click of layout button (will call for ElkJS)
  useImperativeHandle(ref, () => ({
    redoLayout: (clear: boolean) => {
      createGraphLayout(flowNodes, flowEdges)
        .then(async (els) => {
          setNodes(els)
          els.forEach((nodeDetails) => {
            let currentNode = nodeList.find((node: InterviewNode) => node.name === nodeDetails.id)
            if (currentNode !== undefined)
              currentNode.position = nodeDetails.position
          })
          await new Promise<void>(resolve => {
            updateInterviewNodeList(nodeList) //Update nodeList with the newly mapped position
            resolve();
          })
          fitView()
          handleAutoLayoutState(AutoLayoutState.COMPLETED) //Let setup page know that layouting is completed
        })
        .catch((err) => console.error(err))
      setEdges(flowEdges)
      if (!clear)
        setChangesMade(true);
    },
    fitView: () => {
      setTimeout(() => fitView(), 1000)
    }
  }));

  //Trigger on every end of node drag event
  const onNodeDragStop = async (event: any, node: Node, nodes: Node[]) => {
    let tempNodes = JSON.parse(JSON.stringify(nodeList))
    nodes.forEach((nodeDetails: any) => {
      let currentNode = tempNodes.find((anode: InterviewNode) => anode.name === nodeDetails.id)
      if (currentNode !== undefined) {
        currentNode.position = nodeDetails.position
      }
    })
    await new Promise<void>(resolve => {
      dispatch(updateInterviewNodeList(tempNodes))
      resolve();
    });
    setChangesMade(true);
    handleAutoLayoutState(AutoLayoutState.SKIPPED) //Let setup page know that layouting is completed
  };

  useEffect(() => {
    if (nodeList)
      if (true)
        doLayout();
      else {
        doLayout();
        setChangesMade(true);
      }

  }, [nodeList])

  //Do the corresponding layout functionality accordingly
  const doLayout = () => {
    //Auto-layouting is enabled which requires ElkJS to reposition all nodes before saving
    if (autolayout) {
      createGraphLayout(flowNodes, flowEdges)
        .then(async (els) => {
          setNodes(els) //Set nodes for react-flow to draw and render the graph first
          //Updating the positions of each node in nodeList with ElkJS position
          els.forEach((nodeDetails) => {
            let currentNode = nodeList.find((node: InterviewNode) => node.name === nodeDetails.id)
            if (currentNode !== undefined)
              currentNode.position = nodeDetails.position
          })

          await new Promise<void>(resolve => {
            dispatch(updateInterviewNodeList(nodeList)) //Update nodeList with the newly mapped position
            resolve();
          })
          fitView()
          handleAutoLayoutState(AutoLayoutState.COMPLETED) //Let setup page know that layouting is completed
        })
        .catch((err) => console.error(err))
    }
    else {
      setNodes(flowNodes)
      handleAutoLayoutState(AutoLayoutState.SKIPPED) //No layouting done in this block, set to completed immediately
    }
    setEdges(flowEdges)
  };

  const onNodeClick = (event: any, node: Node) => {
    handleNodeClick(node)
  };

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={!autolayout}
      style={graphStyles}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={onNodeClick}
      connectionLineType={ConnectionLineType.SmoothStep}
      onNodeDragStop={onNodeDragStop}
      zoomOnScroll={false}
      preventScrolling={false}
    />
  );
})

export default InterviewReactFlow