import { Card } from "primereact/card"
import styles from '../../styles.module.css'

interface MessageProps {
  key: string
  header: string
  timeStamp: string
  message?: string
  type?: string
}

const MessageBox = (props: MessageProps) => {

  const { header, timeStamp, message } = props
  let userMsgBoolean = header === 'Me'

  const MsgHeader = userMsgBoolean ? <div className="font-semibold text-green-600 pt-3 pl-3">{header}</div> : <div className="font-semibold text-blue-600 pt-3 pl-3">{header}</div>
  const MsgFooter = <div className="text-right text-xs">{timeStamp}</div>
  const MsgBody = <div className="text-left">{message}</div>

  return (
    <div className="m-2 scalein animation-ease-in animation-duration-500 animation-iteration-1">
      {header !== 'system' &&
        <div className={styles.chatbot}>
          {userMsgBoolean ?
            <div className="flex justify-content-end">
              <Card header={MsgHeader} footer={MsgFooter} style={{ width: 'fit-content', minWidth: '100px', backgroundColor: '#d4ecd5', borderRadius: '8px'}}>
                <div className="p-0">
                  {MsgBody}
                </div>
              </Card>
            </div>
            :
            <div className="flex justify-content-start">
              <Card className="justify-content-start" header={MsgHeader} footer={MsgFooter} style={{ width: 'fit-content', minWidth: '100px', backgroundColor: '#cae6fc', borderRadius: '8px' }}>
                <div className="p-0">
                  {MsgBody}
                </div>
              </Card>
            </div>}
        </div>
      }
    </div>
  )
}

export default MessageBox