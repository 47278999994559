import arrayMutators from 'final-form-arrays'
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { Fieldset } from 'primereact/fieldset'
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { Field, Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { ConfirmationQuestion, IntentDetails, InterviewNode, QuestionType } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"

import OnChange from "../../../../../../utils/OnChange"
import styles from "../styles.module.css"
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../../store'

interface ConfirmationFormProps {
  actionOnSubmit: any
  actionOnDelete: any
  intentNode: ConfirmationQuestion
  firstNode: InterviewNode
  lastNode:InterviewNode
  intentNameList?: string[]
  isUpdate?: boolean
  autosave: boolean
  onQuestionTypeChange: (newQuestionType: any) => void
}

const ConfirmationForm = (props: ConfirmationFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { actionOnSubmit, actionOnDelete, intentNode, firstNode, lastNode, intentNameList, isUpdate = false, autosave, onQuestionTypeChange } = props

  let initialValues: ConfirmationQuestion = !!intentNode ? { ...intentNode, } : {} as ConfirmationQuestion

  const validate = (data: InterviewNode) => {
    let errors = {} as any

    if (data.typeOfQuestion === QuestionType.ConfirmationQuestion) {

      if (!data.typeOfQuestion) {
        errors.typeOfQuestion = 'The type of question must be specified'
      }

      if (!data.name) {
        errors.name = 'Please specify the name of the node'
      } else if (!isUpdate && data.name) {
        if (intentNameList && intentNameList.length > 0 && intentNameList.find(element => element === data.name) !== undefined) {
          errors.name = 'This name is currently in use by another node'
        }
      }
      if (data.confirmationSetting?.confirmationSettingQuestionPrompts === undefined || data.confirmationSetting?.confirmationSettingQuestionPrompts.length === 0) {
        errors.questionPrompts = "Please input at least one confirmation prompt."
      }

      //Validation for only allowing 1 first and 1 last node
      if (data.isFirstQuestion && Object.keys(firstNode).length !== 0 && !initialValues.isFirstQuestion)
        errors.isFirstQuestion = "First node already exists"
      if (data.isLastQuestion && Object.keys(lastNode).length !== 0  && !initialValues.isLastQuestion)
        errors.isLastQuestion = "Last node already exists"
      // if (data.confirmationSetting?.questionPrompts.length >= 3) {
      //   errors.questionPrompts = "Only up to three prompts are allowed."
      // }
    }
    return errors;
  }

  const onSubmit = (data: any, form: any) => {
    let payload = {} as InterviewNode
    payload = data
    payload.chips = ["Yes", "No"] //Confirmation question should always have yes/no chips only
    payload.multiChips = false //Confirmation question should not allow multi chips entry
    payload.position = data.position ? data.position : { x: 0, y: 0 }
    if (payload.typeOfQuestion === QuestionType.ConfirmationQuestion) { //Type narrowing
      if (payload.scoreIfNo === undefined)
        payload.scoreIfNo = 0; //Programmatically mutating score to 0 if user left InputText field blank
      else
        payload.scoreIfNo = +payload.scoreIfNo; //Parse InputText component from string to number type
      if (payload.scoreIfYes === undefined)
        payload.scoreIfYes = 0;
      else
        payload.scoreIfYes = +payload.scoreIfYes;
    }
    if (isUpdate) {
      console.log(payload);
      actionOnSubmit(initialValues, payload, autosave)
    }
    else {
      console.log(payload);
      actionOnSubmit(payload, autosave)
    }
    // setChips([])
    form.restart()
  }

  const deleteNode = (form: any) => {
    dispatch(actionOnDelete(initialValues, autosave))
    form.restart()
  }

  const getExcludedNameList = (values: any) => {
    if (!!values) {
      let tempIntentNameList = intentNameList?.slice(0)
      if (tempIntentNameList) {
        let valueIndex = tempIntentNameList.indexOf(values)
        if (valueIndex !== -1)
          tempIntentNameList.splice(valueIndex, 1)
      }
      return tempIntentNameList
    }
    return intentNameList
  }

  const required = (value: any) => value ? undefined : 'This is a required field'

  const isFormFieldValid = (meta: any) => !!((meta.touched || meta.modified) && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  const WhenLastQuestionIsChecked = ({ set, to }: { set: string, to: any }) => {
    return (
      <Field name={set} subscription={{}}>
        {(
          // No subscription. We only use Field to get to the change function
          { input: { onChange } } 
        ) => {
          return (
            <OnChange name='isLastQuestion'>
              {(value: boolean) => {
                if (value === true) {
                  onChange(to);
                }
              }}
            </OnChange>
          )
        }}
      </Field>
    )
  }

  return (
    <div>
      <h1>{isUpdate ? "Update Confirmation Node" : "Create Confirmation Node"}</h1>
      <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">
        A Confirmation node is intended to capture a typical Yes or No response from a candidate.
        <br></br>
        Such as when given the question "Do you have any working experience?", a candidate would respond typically with "Yes", or "No".
      </p>
      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate} render={({ form: { mutators: { push, pop, remove } }, form, values, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <WhenLastQuestionIsChecked set="confirmationSetting.flowToIfNo" to={undefined} />
          <WhenLastQuestionIsChecked set="confirmationSetting.flowToIfYes" to={undefined} />

          <Fieldset className={styles.nodeform} legend="Node Details" toggleable>
            <div className="formgrid grid">
              <Field name="typeOfQuestion" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="typeOfQuestionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                  <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="name" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                  <InputText id="nameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="scoreIfNo" render={({ input }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="scoreIfNoInput">Score if Unfulfilled</label>
                  <InputText id="scoreIfNoInput" autoComplete="off" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                </div>
              )} />
              <Field name="scoreIfYes" render={({ input }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="scoreIfYesInput">Score if Fulfilled</label>
                  <InputText id="scoreIfYesInput" autoComplete="off" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                </div>
              )} />
              <Field name="isFirstQuestion" type="checkbox" render={({ input, meta }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isFirstQuestionInput" {...input} checked={input.checked || false} className={classNames({'p-error': isFormFieldValid(meta)})}/>
                  <label htmlFor="isFirstQuestionInput">Is this node the first question of the interview?</label> 
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="isLastQuestion" type="checkbox" render={({ input, meta }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isLastQuestionInput" {...input} checked={input.checked || false} className={classNames({'p-error': isFormFieldValid(meta)})}/>
                  <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                  {getFormErrorMessage(meta)}
                </div>
              )} />
            </div>
          </Fieldset>

          <Fieldset className={styles.nodeform} legend="Confirmation Prompt" toggleable>
            <div className="formgrid grid">
              <Field name="questionPrompts" render={({ meta }) => (
                <div className="col-12">
                  <div className="xl:col-7 md:col-12">
                    <span className={classNames({ 'p-error': isFormFieldValid(meta) })} style={{ display: "inline-block", marginBottom: "0.5rem" }}>Confirmation Prompts*</span>
                    <br></br>{getFormErrorMessage(meta)}
                  </div>
                  <div className="xl:col-4 md:col-12">
                    <Button className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} label="Add Prompt" type="button" icon="pi pi-plus" 
                    iconPos="right" disabled={!!(values.confirmationSetting && values.confirmationSetting.confirmationSettingQuestionPrompts && values.confirmationSetting.confirmationSettingQuestionPrompts.length >= 3)} onClick={() => push('confirmationSetting.confirmationSettingQuestionPrompts', "")}></Button>
                  </div>
                </div>
              )} />
            </div>
            <FieldArray name="confirmationSetting.confirmationSettingQuestionPrompts">
              {({ fields }) =>
                fields.map((questionPrompt, index) => (
                  <div key={index.toString()} className="col-12">
                    <h3 className="col-12">Prompt #{index + 1}</h3>
                    <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                      <Field name={`confirmationSetting.confirmationSettingQuestionPrompts[${index}]`} validate={required} render={({ input, meta }) => (
                        <div>
                          <div>
                            <InputText id="confirmationSettingQuestionPromptsInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                            {getFormErrorMessage(meta)}
                          </div>
                          <div>
                            <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('confirmationSetting.confirmationSettingQuestionPrompts', index)}></Button>
                          </div>
                        </div>
                      )} />
                    </div>
                  </div>
                ))
              }
            </FieldArray>
          </Fieldset>

          <Fieldset className={styles.nodeform} legend="On Confirmation" toggleable>
            <div className="formgrid grid">
              <Field name="confirmationSetting.flowToIfYes" render={({ input }) => (
                <div className="col-12">
                  <div className="field col-12">
                    <label htmlFor="flowToIfYes">Confirmation Step</label>
                    <Dropdown id="flowToIfYes" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if confirmed" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                  </div>
                  <div className="col-4">
                    <Button label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.confirmationSetting && values.confirmationSetting.responseIfYes && values.confirmationSetting.responseIfYes.length >= 3)} onClick={() => push('confirmationSetting.responseIfYes', "")}></Button>
                  </div>
                </div>
              )} />

              <FieldArray name="confirmationSetting.responseIfYes">
                {({ fields }) =>
                  fields.map((responseIfYes, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Confirmation Response #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`confirmationSetting.responseIfYes[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="responseIfYesInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)} {/*Can remove validation and error class if optional*/}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('confirmationSetting.responseIfYes', index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
          </Fieldset>

          <Fieldset className={styles.nodeform} legend="On Declination" toggleable>
            <div className="formgrid grid">
              <Field name="confirmationSetting.flowToIfNo" render={({ input }) => (
                <div className="col-12">
                  <div className="field col-12">
                    <label htmlFor="flowToIfNo">Declination Step</label>
                    <Dropdown id="flowToIfNo" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the condition of this node is declined" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                  </div>
                  <div className="col-4">
                    <Button label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.confirmationSetting && values.confirmationSetting.responseIfNo && values.confirmationSetting.responseIfNo.length >= 3)} onClick={() => push('confirmationSetting.responseIfNo', "")}></Button>
                  </div>
                </div>
              )} />

              <FieldArray name="confirmationSetting.responseIfNo">
                {({ fields }) =>
                  fields.map((responseIfNo, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Declination Response #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`confirmationSetting.responseIfNo[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="responseIfNoInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)} {/*Can remove validation and error class if optional*/}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('confirmationSetting.responseIfNo', index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
          </Fieldset>

          {/*
          //Optional to have this fieldset, can default failure to go back to confirm intent and respond with default failure message
          <Fieldset className={styles.nodeform} legend="On Failure" toggleable collapsed>
            <div className="formgrid grid">
              <Field name="confirmationSetting.flowToIfFail" render={({ input }) => (
                <div className="field col-12">
                  <label htmlFor="flowToIfFail">Failure Step</label>
                  <Dropdown id="flowToIfFail" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the interviewee failed to respond with yes/no" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              //Sharing field name with FieldArray to listen to the meta state of the field array to disable button conditionally in runtime
              <Field name="confirmationSetting.responseIfFail" render={({ meta }) => (
                <div className="col-4">
                  <Button label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(meta.length && meta.length >= 3)} onClick={() => push('confirmationSetting.responseIfFail', "")}></Button>
                </div>
              )} />
              <FieldArray name="confirmationSetting.responseIfFail">
                {({ fields }) =>
                  fields.map((responseIfFail, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Declination Response #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`confirmationSetting.responseIfFail[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="responseIfFailInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)} //Can remove validation and error class if optional
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => fields.remove(index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>
          </Fieldset>
          */}
          <div className="flex justify-content-end align-items-end mt-2">
            <Button type="button" className="p-button-outlined p-button-danger mr-3" label="Delete" icon="pi pi-trash" onClick={() => deleteNode(form)} disabled={!isUpdate}></Button>
            <Button type="submit" label={isUpdate ? "Update" : "Create"} icon="pi pi-check" disabled={pristine}></Button>
          </div>
          {/* <Card>
            <div className="formgrid grid">
              <h2 className="col-12">Node Details</h2>
              <Field name="typeOfQuestion" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="typeOfQuestionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                  <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="name" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                  <InputText id="nameInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <h2 className="col-12">Confirmation Settings</h2>
              <div className="field col-12 md:col-6">
                <Field name="questionPrompts" render={({ meta }) => (
                  <div>
                    <span className={classNames({ 'p-error': isFormFieldValid(meta) })} style={{ display: "inline-block", marginBottom: "0.5rem" }}>Confirmation Prompts*</span>
                    <br></br>{getFormErrorMessage(meta)}
                  </div>
                )} />

                <Field name="confirmationSetting.questionPrompts" render={({ meta }) => (
                  <div>
                    <Button className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} label="Add Prompt" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(meta.length && meta.length >= 3)} onClick={() => push('confirmationSetting.questionPrompts', "")}></Button>
                  </div>
                )} />
              </div>
              <FieldArray name="confirmationSetting.questionPrompts">
                {({ fields }) =>
                  fields.map((questionPrompt, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Prompt #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`confirmationSetting.questionPrompts[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="questionPromptsInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => fields.remove(index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
              <h3 className="col-12">On Confirmation</h3>
              <Field name="confirmationSetting.flowToIfYes" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="flowToIfYes">Next Node if Fulfilled</label>
                  <Dropdown id="flowToIfYes" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if confirmed" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              <div className="field col-12 md:col-12">
                <Button label="Add Response" type="button" icon="pi pi-plus" iconPos="right" onClick={() => push('confirmationSetting.responseIfYes', "")}></Button>
              </div>
              <FieldArray name="confirmationSetting.responseIfYes">
                {({ fields }) =>
                  fields.map((responseIfYes, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Confirmation Response #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`confirmationSetting.responseIfYes[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="responseIfYesInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => fields.remove(index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
              <h2 className="col-12">On Declination</h2>
              <Field name="confirmationSetting.flowToIfNo" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="flowToIfNo">Next Node if Unfulfilled</label>
                  <Dropdown id="flowToIfNo" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the condition of this node is declined" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              <div className="field col-12 md:col-12">
                <Button label="Add Response" type="button" icon="pi pi-plus" iconPos="right" onClick={() => push('confirmationSetting.responseIfYes', "")}></Button>
              </div>
              <FieldArray name="confirmationSetting.responseIfYes">
                {({ fields }) =>
                  fields.map((responseIfYes, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Declination Response #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`confirmationSetting.responseIfYes[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="responseIfYesInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => fields.remove(index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
              <Field name="responseIfYes" render={({ meta }) => (
                <div className="col-12">
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="confirmationSetting.responseIfYes" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="confirmationResponseInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Confirmation Response</label>
                  <InputText id="confirmationResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                </div>
              )} />
              <Field name="confirmationSetting.flowToIfYes" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="flowToIfYes">Next Node if Fulfilled</label>
                  <Dropdown id="flowToIfYes" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the condition of this node is fulfilled" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              <Field name="confirmationSetting.responseIfNo" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="declinationResponseInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Declination Response</label>
                  <InputText id="declinationResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                </div>
              )} />
              <Field name="confirmationSetting.flowToIfNo" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="flowToIfNoInput">Next Node if Unfulfilled</label>
                  <Dropdown id="flowToIfNoInput" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the condition of this node is unfulfilled" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              <Field name="confirmationSetting.responseIfFail" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="failureResponseInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Failure Response</label>
                  <InputText id="failureResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                </div>
              )} />
              <Field name="confirmationSetting.flowToIfFail" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="flowToIfFailInput">Next Node if Unfulfilled</label>
                  <Dropdown id="flowToIfFailInput" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if the condition of this node is unfulfilled" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              <Field name="scoreIfNo" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="scoreIfNoInput">Score if Unfulfilled</label>
                  <InputText id="scoreIfNoInput" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                </div>
              )} />
              <Field name="scoreIfYes" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="scoreIfYesInput">Score if Fulfilled</label>
                  <InputText id="scoreIfYesInput" disabled={values.isLastQuestion} keyfilter="pint" tooltip="The score to be given for the candidate for fulfilling this branch condition" {...input} className="inputfield w-full" />
                </div>
              )} />
              <Field name="chips" render={({ input }) => (
                <div className="field col-12 md:col-12">
                  <label htmlFor="chipsInput">Chips</label>
                  <Chips id="chipsInput" {...input} value={chips} onChange={(e) => setChips(e.value)} separator="," className="inputfield w-full" />
                </div>
              )} />
              <Field name="multiChips" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-12 md:col-6">
                  <Checkbox inputId="multiChipsInput" {...input} />
                  <label htmlFor="multiChipsInput">Allow multiple chips input?</label>
                </div>
              )} />
              <Field name="isLastQuestion" type="checkbox" render={({ input }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isLastQuestionInput" {...input} />
                  <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                </div>
              )} />
            </div>
            <div className="flex justify-content-end align-items-end">
              <Button type="button" className="p-button-outlined p-button-danger mr-3" label="Delete" icon="pi pi-trash" onClick={() => deleteNode(form)} disabled={!isUpdate}></Button>
              <Button type="submit" label={isUpdate ? "Update" : "Create"} icon="pi pi-check" disabled={pristine}></Button>
            </div>
          </Card> */}
        </form >
      )
      } />
    </div >
  )
}

export default ConfirmationForm