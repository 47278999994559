import React, { FC, useCallback } from 'react';
import { useStore, EdgeProps, getBezierPath, getBezierEdgeCenter, EdgeText } from 'react-flow-renderer';

import { getEdgeParams } from './interviewReactFlowUtils.js';

const FloatingEdge: FC<EdgeProps> = ({ id, source, target, label, markerEnd, style }) => {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }
  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const d = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const [centerX, centerY] = getBezierEdgeCenter({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <g className="react-flow__connection">
      <path id={id} className="react-flow__edge-path" d={d} markerEnd={markerEnd} style={style} />
      <EdgeText
        x={centerX}
        y={centerY}
        label={label}
        labelBgPadding={[2, 4]}
      />
    </g>
  );
}

export default FloatingEdge;
