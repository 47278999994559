import { Dialog } from "primereact/dialog";
import FileUploader from "../../FileUploader";

interface ResumeUploadDialogProps {
  displayResumeUploadDialog: boolean
  uploadResume: (resumeData: FormData) => any
  setDisplayResumeUploadDialog: (showDialog: boolean) => any
}

const ResumeUploadDialog = (props: ResumeUploadDialogProps) => {
  const { displayResumeUploadDialog, setDisplayResumeUploadDialog, uploadResume } = props

  return (
    <div>
      {/* <ProgressSpinnerDialog text="Uploading and extracting information from your resume..." visible={resumeUploadStatus === ResumeUploadStatus.loading} /> */}
      <Dialog visible={displayResumeUploadDialog} onHide={() => setDisplayResumeUploadDialog(false)}>
        <FileUploader headerText="Upload Resume" uploadResume={uploadResume} />
      </Dialog>
    </div>
  )
}

export default ResumeUploadDialog
