export enum SCOPES {
  JOB_READ = "JOB_READ",
  JOB_WRITE = "JOB_WRITE",
  USER_READ = "USER_READ",
  USER_WRITE = "USER_WRITE",
  TENANT_READ = "TENANT_READ",
  TENANT_WRITE = "TENANT_WRITE",
  LOOKUP_READ = "LOOKUP_READ",
  LOOKUP_WRITE = "LOOKUP_WRITE",
  PORTAL_ADMIN = "PORTAL_ADMIN",
  TENANT_ADMIN = "TENANT_ADMIN",
  RESUME_READ = "RESUME_READ",
  RESUME_WRITE = "RESUME_WRITE",
  APPLICATION_READ = "APPLICATION_READ",
  APPLICATION_WRITE = "APPLICATION_WRITE",
  INTERVIEW_READ = "INTERVIEW_READ",
  INTERVIEW_WRITE = "INTERVIEW_WRITE"
}