export enum CategoryEnum {
  SKILL,
  LANGUAGE,
  COUNTRY,
  SPECIALIZATION,
  CAREER_LEVEL,
  QUALIFICATION,
  INDUSTRY,
  EMPLOYMENT_TYPE,
  CONTRACT_TYPE,
  COMPANY_SIZE
}

export enum LookupStatus {
  IDLE,
  LOADING,
  SUCCESS,
  FAIL
}