import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { LOOKUPS_KEY } from '../../../constants/localStorageKeys'
import { createLookup, deleteLookup, deleteLookups, retrieveAllLookup, updateLookup } from "./LookupAction"
import { Lookup } from "./interfaces"

const initialState = {
}

const updateLookupLocalStorage = (response: { [key: string]: Lookup[] }) => {
  let allLookupsStr = localStorage.getItem(LOOKUPS_KEY);
  if (allLookupsStr) {
    let allLookupsJson = JSON.parse(allLookupsStr)
    Object.keys(response).forEach((element) => {
      allLookupsJson[element] = response[element]
    })
    localStorage.setItem(LOOKUPS_KEY, JSON.stringify(allLookupsJson))
  }
}

const lookupSlicer = createSlice({
  name: 'lookup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          retrieveAllLookup.fulfilled,
          deleteLookups.fulfilled,
          createLookup.fulfilled,
          updateLookup.fulfilled,
          deleteLookup.fulfilled
        ),
        (state: any, { payload }) =>  {
          if(localStorage.getItem(LOOKUPS_KEY) === null) {
            localStorage.setItem(LOOKUPS_KEY, JSON.stringify(payload))
          } else {
            updateLookupLocalStorage(payload)
          }
        }
      )
  },
})

export default lookupSlicer.reducer