import { Fieldset } from "primereact/fieldset"
import { Job } from '../../../../AdminPortal/scenes/Job/interface'

interface PropsDefinition {
  job: Job
}

const JobContent = (props: PropsDefinition) => {
  return (
    props.job === undefined ?
      <Fieldset legend="No record" /> :
      <Fieldset legend={props.job.title}>

        <div className="formgrid grid">

          {/* <div className="field col-12 md:col-6">
            <div>Created By:</div>
            <div>{props.job.createdBy}</div>
          </div> */}

          <div className="field col-12 md:col-6">
            <div>Created Date:</div>
            <div>{props.job.createdDate?.substring(0, 10)}</div>
          </div>

          {/* <div className="field col-12 md:col-6">
            <div>Updated By:</div>
            <div>{props.job.updatedBy}</div>
          </div> */}

          <div className="field col-12 md:col-6">
            <div>Updated Date:</div>
            <div>{props.job.updatedDate?.substring(0, 10)}</div>
          </div>

          {/* <div className="field col-12 md:col-6">
            <div>Id:</div>
            <div>{props.job.id}</div>
          </div> */}

          {/* <div className="field col-12 md:col-6">
            <div>Status:</div>
            <div>{props.job.status}</div>
          </div> */}

          <div className="field col-12 md:col-6">
            <div>Title:</div>
            <div>{props.job.title}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Specialization:</div>
            <div>{props.job.specialization}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Career Levels:</div>
            <div>{props.job.careerLevels?.join(', ')}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Employment Type:</div>
            <div>{props.job.employmentType}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Qualifications:</div>
            <div>{props.job.qualifications?.join(', ')}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Skills:</div>
            <div>{props.job.skills?.join(', ')}</div>
          </div>

          <div className="field col-12">
            <div>Description:</div>
            <div>{props.job.description}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Requirements:</div>
            <div>{props.job.requirements}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Years of experience:</div>
            <div>{props.job.yearsOfExp}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Contract type:</div>
            <div>{props.job.contractType}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Minimum Salary:</div>
            <div>{props.job.minSalary}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Maximum Salary:</div>
            <div>{props.job.maxSalary}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Location:</div>
            <div>{props.job.location}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Area:</div>
            <div>{props.job.area}</div>
          </div>

          <div className="field col-12 md:col-6">
            <div>Language:</div>
            <div>{props.job.languages}</div>
          </div>

          <div className="field col-12">
            <div>Responsibilities:</div>
            <div>{props.job.responsibilities}</div>
          </div>

          <div className="field col-12">
            <div>Scopes:</div>
            <div>{props.job.scopes}</div>
          </div>

          {/* <div className="field col-12 md:col-6">
            <div>Tenant ID:</div>
            <div>{props.job.tenantId}</div>
          </div> */}
        </div>
      </Fieldset>
  )
}

export default JobContent