import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { Panel } from "primereact/panel";
import { useState } from "react";

interface FileReuploaderProps {
  headerText: string
  uploadResume: (resumeData: FormData) => any
}

const FileReuploader = (props: FileReuploaderProps) => {

  const { headerText, uploadResume } = props
  const [reuploaded, setReuploaded] = useState(false)
  
  const beginUpload = (file: Blob) => {
    console.log("beginUpload():", file)

    let formData = new FormData();
    formData.append("file", file);

    uploadResume(formData)
  }

  const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type })

  const fileUploadHandler = async (upload: FileUploadHandlerEvent) => {
    const [file] = upload.files
    beginUpload(await fileToBlob(file))
    setReuploaded(true)
  }

  const panelHeaderTemplate = (options: any) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
    const className = `${options.className} flex justify-content-start`
    const titleClassName = `${options.titleClassName} pl-1`
    return (
      <div className={className}>
        <button className={options.togglerClassName} onClick={options.onTogglerClick}>
          <span className={toggleIcon}></span>
        </button>
        <span className={titleClassName}>
          Got an updated resume?
        </span>
      </div>
    )
  }

  return (
    <Panel toggleable collapsed headerTemplate={panelHeaderTemplate} style={{ width: '20%' }}>
      <div>
        <h3>{headerText}</h3>
        <FileUpload
          mode='basic'
          accept=".JPEG, .PNG, .PDF, .TIFF"
          customUpload={true}
          uploadHandler={fileUploadHandler}
          auto={true}
          maxFileSize={10000000}
          // disabled={reuploaded}
        />
        <p style={{ fontSize: "0.8em", marginBottom: "0" }}>Accepted file formats: <em>(.jpeg, .png, .pdf, .tiff)</em></p>
        <p style={{ fontSize: "0.8em", margin: "0" }}>Max file size: <em>10MB</em></p>
        <p className="pi pi-info-circle" style={{ fontSize: "0.8em", margin: "0" }}><em> Did you know?</em></p>
        <p style={{ fontSize: "0.7em", margin: "0" }}><em>Updating your resume regularly increases employability.</em></p>
      </div>
    </Panel>
  )
}

export default FileReuploader