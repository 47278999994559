import React from 'react';

import "primereact/resources/themes/mdc-light-indigo/theme.css";  
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import './App.css';

import axios from 'axios'
import {JWT_KEY, LOOKUPS_KEY} from './constants/localStorageKeys'
import { ADMIN_ROUTES } from "./constants/routes/admin/menus"

import AdminRoutes from "./router/AdminRoutes";
import CandidateRoutes from "./router/CandidateRoutes";
import { shallowEqual, useSelector } from 'react-redux';
import ProgressLoadingIndicator from './constants/helpers/ProgressLoadingIndicator';

const App = () => {
  const portal = process.env.REACT_APP_PORTAL

  const resolveRoute = (portal: any) => {
    if (portal === "Admin") {
      // global axios interceptor, logout user if api return 401
      axios.interceptors.response.use(function (ifResponse) {
        return ifResponse
      }, function (error) {
        if (error.response.status === 401) {
          // console.log(error.response.status);
          localStorage.removeItem(JWT_KEY)
          localStorage.removeItem(LOOKUPS_KEY)
          window.location.href = ADMIN_ROUTES.ROUTE_LOGIN_ADMIN;
        }
      
        throw error
      });

      return (
        <AdminRoutes />
      )
    }
    else if (portal === "Candidate") {
      return (
        <CandidateRoutes />
      )
    }
  }

  return (
    <React.Fragment>
      <ProgressLoadingIndicator />
      {resolveRoute(portal)}
    </React.Fragment>
  )
}

export default App;


