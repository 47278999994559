import PrimeReact from "primereact/api"
import { useCallback, useEffect, useState } from "react"
import { AmazonLexInbuiltSlotType, ConditionalQuestion, ConditionArithmeticOperatorEnum, ConditionQuantifierOperatorEnum, ConfirmationQuestion, CustomSlotType, DelegatedQuestion, IntentDetails, InterviewNode, OpenEndedQuestion, QuestionType } from "../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import ConditionalForm from "./components/ConditionalForm"
import ConfirmationForm from "./components/ConfirmationForm"
import DelegatedForm from "./components/DelegatedForm"
import OpenEndedForm from "./components/OpenEndedForm"

interface InterviewNodeFormProps {
  actionOnSubmit: any
  actionOnDelete: any
  intentNode: InterviewNode
  firstNode: InterviewNode
  lastNode:InterviewNode
  intentNameList?: string[]
  isUpdate?: boolean
  autosave: boolean
  customSlotTypes: CustomSlotType[]
}

const InterviewNodeForm = (props: InterviewNodeFormProps) => {

  const { actionOnSubmit, actionOnDelete, intentNode, firstNode, lastNode, intentNameList, isUpdate, autosave, customSlotTypes } = props
  PrimeReact.ripple = true
  const [formType, setFormType] = useState<QuestionType>()

  // const allSlotKeys = Object.keys(AmazonLexInbuiltSlotType) as string[]
  // const allSlotValues = Object.values(AmazonLexInbuiltSlotType) as string[]
  // const allTenantCustomSlotTypes = customSlotTypes.map((eachSlot: CustomSlotType) => eachSlot.slotTypeName)
  // const allTenantSlotTypeOptions = [
  //   {
  //     label: "Default Types",
  //     items: allSlotKeys.map((value: string, index: number) => {
  //       return { label: value, value: allSlotValues[index] }
  //     })
  //   },
  //   {
  //     label: "Custom Types",
  //     items: [...allTenantCustomSlotTypes]
  //   }
  // ]

  useEffect(() => {
    setFormType(QuestionType[intentNode.typeOfQuestion])
  }, [])

  const handleFormChange = useCallback((newQuestionType: { value: QuestionType }) => {
    setFormType(newQuestionType.value)
    intentNode.typeOfQuestion = newQuestionType.value
  }, [])

  const renderForm = () => {
    switch (formType) {
      case QuestionType.ConfirmationQuestion:
        return <ConfirmationForm actionOnSubmit={actionOnSubmit} actionOnDelete={actionOnDelete} isUpdate={isUpdate} firstNode = {firstNode} lastNode = {lastNode}
                intentNode={intentNode as ConfirmationQuestion} onQuestionTypeChange={handleFormChange} intentNameList={intentNameList} autosave={autosave}></ConfirmationForm>
      case QuestionType.OpenEndedQuestion:
        return <OpenEndedForm actionOnSubmit={actionOnSubmit} actionOnDelete={actionOnDelete} isUpdate={isUpdate} firstNode = {firstNode} lastNode = {lastNode}
        intentNode={intentNode as OpenEndedQuestion} onQuestionTypeChange={handleFormChange} intentNameList={intentNameList} autosave={autosave}></OpenEndedForm>
      case QuestionType.ConditionalQuestion:
        return <ConditionalForm actionOnSubmit={actionOnSubmit} actionOnDelete={actionOnDelete} isUpdate={isUpdate} firstNode = {firstNode} lastNode = {lastNode}
         intentNode={intentNode as ConditionalQuestion} onQuestionTypeChange={handleFormChange} intentNameList={intentNameList} autosave={autosave} customSlotTypeOptions={customSlotTypes}></ConditionalForm>
      // case QuestionType.BranchingQuestion:
      //   return <BranchingForm actionOnSubmit={actionOnSubmit} actionOnDelete={actionOnDelete} isUpdate={isUpdate} initialValues={intentNode} onQuestionTypeChange={handleFormChange} intentNameList={intentNameList} autosave={autosave}></BranchingForm>
      case QuestionType.DelegatedQuestion:
        return <DelegatedForm actionOnSubmit={actionOnSubmit} actionOnDelete={actionOnDelete} isUpdate={isUpdate} firstNode = {firstNode} lastNode = {lastNode}
         intentNode={intentNode as DelegatedQuestion} onQuestionTypeChange={handleFormChange} intentNameList={intentNameList} autosave={autosave} customSlotTypeOptions={customSlotTypes}></DelegatedForm>
    }
  }

  return (
    <div>
      {renderForm()}
    </div>
  )
}

export default InterviewNodeForm