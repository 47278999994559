import PrimeReact, { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Job, JobStatusEnum } from '../interface';

import { Toolbar } from 'primereact/toolbar';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from '../../../../store';
import { deleteJobs, retrieveJobs, updateJob } from '../JobAction';
import { LOOKUPS_KEY } from '../../../../constants/localStorageKeys';
import { CategoryEnum } from '../../Lookup/enums';
import LinkButton from '../../../../constants/helpers/LinkButton';

interface JobManagementProps {
}

const JobManagement = (props: JobManagementProps) => {
  PrimeReact.ripple = true
  const toast = useRef<any>()

  const { } = props

  const dispatch = useDispatch<AppDispatch>()
  const { jobList, totalRecords } = useSelector((state: any) => state.job)

  const dt = useRef<any>(null);
  const history = useHistory();

  // init
  useEffect(() => {
    const lookups = localStorage.getItem(LOOKUPS_KEY)
    if (lookups) {
      const lookupsJSON = JSON.parse(lookups)
      const categoryList = Object.keys(CategoryEnum).filter(allItems => isNaN(Number(allItems)))
      categoryList.forEach((categoryName => retrieveLookupValues(lookupsJSON, categoryName)))
      // setlookupOptionsLoaded(true)
    }
  }, [])

  let filters: DataTableFilterMeta = {
    title: { value: null, matchMode: FilterMatchMode.EQUALS },
    employmentType: { value: null, matchMode: FilterMatchMode.EQUALS },
    contractType: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdDate: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [rows, setRows] = useState(5)
  const [currentJob, setCurrentJob] = useState({} as Job)
  const [selectedJobs, setSelectedJobs] = useState([] as Job[])
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: "createdDate",
    sortOrder: -1,
    filters
  })

  // Dialog settings
  const [displayDeleteJobDialog, setDisplayDeleteJobDialog] = useState(false)
  const [displayDeleteMultiJobDialog, setDisplayDeleteMultiJobDialog] = useState(false)

  //Lazy loading
  useEffect(() => {
    dispatch(retrieveJobs(lazyParams))
  }, [lazyParams])

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
    setSelectedJobs([])
  }

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
    setSelectedJobs([])
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams)
    setSelectedJobs([])
  }

  // Custom Filter Details
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedEmploymentType, setSelectedEmploymentType] = useState(null)
  const [selectedContractType, setSelectedContractType] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedDate, setSelectedDate] = useState(undefined)

  // Lookups
  const [employmentTypes, setEmploymentTypes] = useState([] as any)
  const [contractTypeList, setContractTypeList] = useState([] as any)
  const [locationList, setLocationList] = useState([] as any)

  const retrieveLookupValues = (lookups: any, categoryName: any) => {
    switch (categoryName) {
      case CategoryEnum[CategoryEnum.COUNTRY]: {
        setLocationList(lookups[categoryName].map((country: any) => country.value))
        break
      }
      case CategoryEnum[CategoryEnum.CONTRACT_TYPE]: {
        setContractTypeList(lookups[categoryName].map((contractType: any) => contractType.value))
        break
      }
      case CategoryEnum[CategoryEnum.EMPLOYMENT_TYPE]: {
        setEmploymentTypes(lookups[categoryName].map((employmentType: any) => employmentType.value))
        break
      }
    }
  }

  const statuses = Object.keys(JobStatusEnum).filter(k => isNaN(Number(k)));
  const statusModel = statuses.map(status => ({ label: status, value: status }))

  const onStatusChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.status = { value: e.value, matchMode: FilterMatchMode.EQUALS }
    setLazyParams(_lazyParams)
    setSelectedStatus(e.value)
    setSelectedJobs([])
  }

  const onEmploymentTypeChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.employmentType = { value: e.value, matchMode: FilterMatchMode.EQUALS }
    setLazyParams(_lazyParams)
    setSelectedEmploymentType(e.value)
    setSelectedJobs([])
  }

  const onContractTypeChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.contractType = { value: e.value, matchMode: FilterMatchMode.EQUALS }
    setLazyParams(_lazyParams)
    setSelectedContractType(e.value)
    setSelectedJobs([])
  }

  const onLocationChange = (e: any) => {
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.location = { value: e.value, matchMode: FilterMatchMode.EQUALS }
    setLazyParams(_lazyParams)
    setSelectedLocation(e.value)
    setSelectedJobs([])
  }

  const onDateChange = (e: any) => {
    setSelectedDate(e.value)
    if (e.value[0] != null && e.value[1] != null) {
      let startDate = `${e.value[0].getFullYear()}-${e.value[0].getMonth() + 1}-${e.value[0].getDate()}`
      let endDate = `${e.value[1].getFullYear()}-${e.value[1].getMonth() + 1}-${e.value[1].getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.createdDateLt = { value: startDate, matchMode: FilterMatchMode.EQUALS }
      _lazyParams.filters.createdDateGt = { value: endDate, matchMode: FilterMatchMode.EQUALS }
      setLazyParams(_lazyParams)
      setSelectedJobs([])
    }
  }

  const dateFilter = <Calendar value={selectedDate} readOnlyInput selectionMode="range" onChange={onDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>
  const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const employmentFilter = <Dropdown value={selectedEmploymentType} options={employmentTypes} onChange={onEmploymentTypeChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const contractFilter = <Dropdown value={selectedContractType} options={contractTypeList} onChange={onContractTypeChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const locationFilter = <Dropdown value={selectedLocation} options={locationList} filter virtualScrollerOptions={{ itemSize: 38 }} onChange={onLocationChange} placeholder="All" className="p-column-filter" showClear></Dropdown>

  const handleResetFilters = () => {
    setSelectedStatus(null)
    setSelectedEmploymentType(null)
    setSelectedLocation(null)
    setSelectedContractType(null)
    setSelectedDate(undefined)
    dt.current.reset();
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters = {}
    setLazyParams(_lazyParams)
  }

  const updateStatus = (newStatus: any, rowData: any) => {
    let payload = {} as Job
    payload = { ...rowData }
    payload.status = newStatus
    dispatch(updateJob(payload))
  }

  // Custom templating
  const statusBodyTemplate = (rowData: any) => {
    return (
      <Dropdown style={{ width: "-webkit-fill-available" }} value={rowData.status} options={statusModel} onChange={(e: any) => updateStatus(e.value, rowData)} />
    )
  }

  const getStatusStyling = (rowData: any) => {
    if (rowData.status === JobStatusEnum.AVAILABLE) {
      return (
        <span style={{ color: '#256029', backgroundColor: '#c8e6c9', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>{rowData.status}</span>
      )
    }
    else if (rowData.status === JobStatusEnum.REVIEWING) {
      return (
        <span style={{ color: '#8a5340', backgroundColor: '#feedaf', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>{rowData.status}</span>
      )
    }
    else if (rowData.status === JobStatusEnum.TAKEN) {
      return (
        <span style={{ color: '#c63737', backgroundColor: '#ffcdd2', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>{rowData.status}</span>
      )
    }
  }

  const createdDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.createdDate)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const configJobTemplate = (rowData: any) => {
    return (
      <div>
        <LinkButton to={"/jobs/" + rowData.id} className="mr-2"
          icon="eye" iconOnly={true}
          tooltip="View Job" tooltipOptions={{ position: 'top' }} />
        <LinkButton to={"/jobs/" + rowData.id + "/update"} className="mr-2"
          icon="pencil" iconOnly={true}
          tooltip="Modify Job" tooltipOptions={{ position: 'top' }} />
        <LinkButton to={"/jobs/" + rowData.id + "/setup"} className="mr-2"
          icon="cog" iconOnly={true}
          tooltip="Setup Interview Flow" tooltipOptions={{ position: 'top' }} />
        <Button
          onClick={() => {
            handleSingleDeleteJobBtn(rowData)
            // setDisplayDeleteUserDialog(true)
          }}
          severity="secondary"
          tooltip="Delete Job"
          tooltipOptions={{ position: 'left' }}
          icon="pi pi-trash" 
          aria-label="Delete" />
      </div>
    )
  }

  const handleSingleDeleteJobBtn = (rowData: Job) => {
    setCurrentJob(rowData)
    setDisplayDeleteJobDialog(true)
  }

  const confirmSingleDelete = () => {
    let jobsToDelete = []
    jobsToDelete.push(currentJob)
    dispatch(deleteJobs([currentJob])).unwrap()
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Job Delete Success',
          detail: `Job (${currentJob.title}) successfully deleted.`,
          life: 3000
        })
      })
      .catch(() => {
        toast.current.show({
          severity: 'warn',
          summary: 'Job Delete Error',
          detail: `Job (${currentJob.title}) deletion failed.`,
          life: 3000
        })
      })
  }

  const handleMultiDeleteJobButton = (e: any) => {
    e.preventDefault()
    setDisplayDeleteMultiJobDialog(true)
  }

  const confirmMultiDelete = () => {
    dispatch(deleteJobs(selectedJobs)).unwrap()
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Job Delete Success',
          detail: `${selectedJobs.length} jobs successfully deleted.`,
          life: 3000
        })
      })
      .catch(() => {
        toast.current.show({
          severity: 'warn',
          summary: 'Job Delete Error',
          detail: `${selectedJobs.length} jobs deletion failed`,
          life: 3000
        })
      })
    setSelectedJobs([])
  }

  const header = (
    <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={handleResetFilters} />
    </div>
  )

  const toolbar = () => {
    return (
      <>
        <LinkButton to={"/jobs/create"} icon="file" label="New" className="p-button-primary mr-2" />
        {(selectedJobs.length > 0) ? <Button label="Delete" icon="pi pi-trash" className="p-button-secondary" onClick={handleMultiDeleteJobButton}></Button> : <Button label="Delete" icon="pi pi-trash" className="p-button-secondary" disabled></Button>}
      </>
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="datatable-doc-demo">
        <div className="card">
          <Toolbar className="p-mb-4" left={toolbar}></Toolbar>
          <ConfirmDialog visible={displayDeleteJobDialog} onHide={() => setDisplayDeleteJobDialog(false)} message={`Are you sure you want to delete the job: ${currentJob.title}?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmSingleDelete} reject={() => setDisplayDeleteJobDialog(false)} />
          <ConfirmDialog visible={displayDeleteMultiJobDialog} onHide={() => setDisplayDeleteMultiJobDialog(false)} message={`Are you sure you want to delete ${selectedJobs.length} selected jobs?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmMultiDelete} reject={() => setDisplayDeleteMultiJobDialog(false)} />
          <DataTable ref={dt} value={jobList} first={lazyParams.first} rows={rows} totalRecords={totalRecords} header={header}
            onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
            onFilter={onFilter} filters={lazyParams.filters} filterDisplay='row'
            selection={selectedJobs} onSelectionChange={e => {
              const jobs = e.value as Job[]
              setSelectedJobs(jobs)
            }}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            lazy paginator>
            <Column selectionMode="multiple" style={{ width: '3em', flexGrow: 0 }}></Column>
            <Column field="title" header="Title" filter sortable></Column>
            <Column field="employmentType" header="Employment" filter filterElement={employmentFilter} sortable></Column>
            {/* <Column field="contractType" header="Contract" filter filterElement={contractFilter} sortable></Column> */}
            <Column field="location" header="Location" filter filterElement={locationFilter} sortable></Column>
            <Column field="status" header="Status" body={getStatusStyling} filter filterElement={statusFilter} sortable></Column>
            <Column field="createdDate" header="Created Date" body={createdDateBodyTemplate} filter filterElement={dateFilter} sortable></Column>
            <Column body={configJobTemplate} style={{ width: '14em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default JobManagement