import { createSlice } from "@reduxjs/toolkit"
import { createUser, retrieveAllScopes, retrieveUser, retrieveUsers, updateUser } from "./UserAction"
import { Scope, User } from "./interfaces"

interface UserState {
  user: User
  userList: User[]
  totalRecords: Number | undefined
  scopes: Scope[]
}

const initialState = {
  user: {} as User,
  userList: [],
  totalRecords: undefined,
  scopes: []
} as UserState

const userSlicer = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUsers.fulfilled, (state: any, { payload }) => {
        state.userList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
      .addCase(retrieveUser.fulfilled, (state: any, { payload }) => {
        state.user = payload
      })
      .addCase(retrieveAllScopes.fulfilled, (state: any, { payload }) => {
        state.scopes = payload
      })
      .addCase(createUser.fulfilled, (state: any, { payload }) => {
        state.user = payload
      })
      .addCase(updateUser.fulfilled, (state: any, { payload }) => {
        state.user = payload
      })
  },
})

export default userSlicer.reducer