import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../utils/Api'
import URI from 'urijs'
import { User } from './interfaces'
import { processParams } from '../../../utils/LazyParamsUtil'

const endpoint = process.env.REACT_APP_USER_API_URL;

export const retrieveUsers = createAsyncThunk('user/retrieveUsers',
  async ({admin, lazyParams}: {admin: boolean, lazyParams: any}, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, (!admin ? 'tenant/' : '') + 'users', processParams(lazyParams), rejectWithValue)
)

export const retrieveUser = createAsyncThunk('user/retrieveUser',
  async ({admin, userId}: {admin: boolean, userId: Number}, { rejectWithValue }) => 
    Api(ApiMethod.GET, endpoint, (!admin ? 'tenant/' : '') + 'user/' + userId, undefined, rejectWithValue)
)

export const createUser = createAsyncThunk('user/createUser',
  async ({admin, user}: {admin: boolean, user: User}, { rejectWithValue }) => 
    Api(ApiMethod.POST, endpoint, (!admin ? 'tenant/' : '') + 'user', user, rejectWithValue)
)

export const updateUser = createAsyncThunk('user/updateTenant',
  async ({admin, user}: {admin: boolean, user: User}, { rejectWithValue }) => 
    Api(ApiMethod.PUT, endpoint, (!admin ? 'tenant/' : '') + 'user', user, rejectWithValue)
)

export const disableUsers = createAsyncThunk('user/disableUsers',
  async ({admin, users}: {admin: boolean, users: User[]}, { rejectWithValue }) => 
    Api(ApiMethod.DELETE, endpoint, (!admin ? 'tenant/' : '') + 'users/status/false', users, rejectWithValue)
)

export const enableUsers = createAsyncThunk('user/enableUsers',
  async ({admin, users}: {admin: boolean, users: User[]}, { rejectWithValue }) => 
    Api(ApiMethod.DELETE, endpoint, (!admin ? 'tenant/' : '') + 'users/status/true', users, rejectWithValue)
)

export const retrieveAllScopes = createAsyncThunk('user/retrieveAllScopes',
  async (_, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'user/scopes?clientId=' + process.env.REACT_APP_ADMIN_CLIENT_ID, {}, rejectWithValue)
)