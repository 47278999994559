import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { Application } from './interface'
import { downloadResumeForAdmin, retrieveApplication, retrieveApplications } from "./ApplicationAction"

interface ApplicationState {
  application: Application
  applicationList: Application[]
  totalRecords: Number | undefined

  // candidate-stuff
  applicationExist: boolean
  jobApplied: boolean
  remainingAttempts: Number | undefined
}

const initialState = {
  application: {} as Application,
  applicationList: [],
  totalRecords: undefined,

  applicationExist: false,
  jobApplied: false,
  remainingAttempts: undefined
} as ApplicationState

const applicationSlicer = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(downloadResumeForAdmin.fulfilled, (state: any, { payload }) => {
      window.location.href = `data:application/pdf;base64,${payload.blobData}`;
    })
      .addCase(retrieveApplications.fulfilled, (state: any, { payload }) => {
        state.applicationList = payload.content
        state.totalRecords = Number(payload.totalElements)
      })
      .addCase(retrieveApplication.fulfilled, (state: any, { payload }) => {
        state.application = payload
      })
  },
})

export default applicationSlicer.reducer