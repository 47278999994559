import arrayMutators from 'final-form-arrays'
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { Chips } from "primereact/chips"
import { Dropdown } from "primereact/dropdown"
import { Fieldset } from 'primereact/fieldset'
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { Tooltip } from 'primereact/tooltip';
import { useState } from "react"
import { Field, Form } from "react-final-form"
import { FieldArray } from 'react-final-form-arrays'
import { AmazonLexInbuiltSlotType, ConfirmationSetting, CustomSlotType, DelegatedQuestion,
   IntentDetails, InterviewNode, QuestionType } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface"
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../../store'

import OnChange from "../../../../../../utils/OnChange"
import styles from "../styles.module.css"

interface DelegatedFormProps {
  actionOnSubmit: any
  actionOnDelete: any
  intentNode: DelegatedQuestion
  firstNode: InterviewNode
  lastNode:InterviewNode
  intentNameList?: string[]
  isUpdate?: boolean
  autosave: boolean
  customSlotTypeOptions: CustomSlotType[]
  onQuestionTypeChange: (newQuestionType: any) => void
}

const DelegatedForm = (props: DelegatedFormProps) => {
  const { actionOnSubmit, actionOnDelete, intentNode, firstNode, lastNode, intentNameList, isUpdate = false, autosave, 
    customSlotTypeOptions, onQuestionTypeChange } = props
  // const [chips, setChips] = useState<string[]>(intentNode.chips ? intentNode.chips : [])

  const dispatch = useDispatch<AppDispatch>()

  const [showValidateCandidateInputFields, setShowValidateCandidateInputFields] = 
  useState<boolean>(!!intentNode.validateCandidateInput ? intentNode.validateCandidateInput : false);

  const allSlotKeys = Object.keys(AmazonLexInbuiltSlotType) as string[]
  const allSlotValues = Object.values(AmazonLexInbuiltSlotType) as string[]
  const allTenantCustomSlotTypes = customSlotTypeOptions.map((eachSlot: CustomSlotType) => eachSlot.slotTypeName)
  const allTenantSlotTypeOptions = [
    {
      label: "Default Types",
      items: allSlotKeys.map((value: string, index: number) => {
        return { label: value, value: allSlotValues[index] }
      })
    },
    {
      label: "Custom Types",
      items: [...allTenantCustomSlotTypes]
    }
  ]

  // const remapSlotTypeValueToKey = (slotTypeValue: string) => {
  //   if (slotTypeValue !== undefined && slotTypeValue.length > 0) {
  //     const allInBuiltSlotValues = Object.values(AmazonLexInbuiltSlotType) as string[]
  //     const index = allInBuiltSlotValues.indexOf(slotTypeValue)
  //     if (index !== -1) { //Is an in-built slot type value, map back to in-built enum key
  //       return Object.keys(AmazonLexInbuiltSlotType)[index]
  //     }
  //   }
  //   return slotTypeValue
  // }

  const groupedItemTemplate = (option: any) => {
    return (
      <div className="text-lg font-bold text-blue-600">{option.label}</div>
    );
  }

  //Mutating initialValues from enum value (for backend use) to enum key representation for dropdown options UI
  let initialValues: DelegatedQuestion = !!intentNode ? { ...intentNode, } : {} as DelegatedQuestion

  // let tempValues = { ...initialValues }
  // if (tempValues.slotElicitationSetting && Object.keys(tempValues.slotElicitationSetting).length > 0) {
  //   let slotTypeEnumKey = remapSlotTypeValueToKey(tempValues.slotElicitationSetting?.slotType)
  //   tempValues.slotElicitationSetting.slotType = slotTypeEnumKey; //Mutating
  // }
  // initialValues = { ...tempValues }

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.typeOfQuestion) {
      errors.typeOfQuestion = 'The type of question must be specified'
    }

    if (!data.name) {
      errors.name = 'Please specify the name of the node'
    } else if (!isUpdate && data.name) {
      if (intentNameList && intentNameList.length > 0 && intentNameList.find(element => element === data.name) !== undefined) {
        errors.name = 'This name is currently in use by another node'
      }
    }

    // if (!data.slotName) {
    //   errors.slotName = 'Please specify a slot name'
    // }
    
    if (data.isFirstQuestion && Object.keys(firstNode).length !== 0 && !initialValues.isFirstQuestion)
        errors.isFirstQuestion = "First node already exists"
    if (data.isLastQuestion && Object.keys(lastNode).length !== 0  && !initialValues.isLastQuestion)
        errors.isLastQuestion = "Last node already exists"

    if (!data.slotElicitationSetting) { //If slotElicitationSetting is uninitialized
      errors.slotElicitationSetting = {}
      errors.slotElicitationSetting.slotType = 'Please select a slot type'
    } else if (data.slotElicitationSetting) { //If slotElicitationSetting is initialized but slot type is not initialized
      errors.slotElicitationSetting = {}
      if (!data.slotElicitationSetting.slotType) {
        errors.slotElicitationSetting.slotType = 'Please select a slot type'
      }
    }

    if (data.slotElicitationSetting?.slotElicitationQuestionPrompts === undefined || data.slotElicitationSetting?.slotElicitationQuestionPrompts.length === 0) {
      errors.questionPrompts = "Please input at least one elicitation prompt."
    }

    return errors;
  }

  const onSubmit = (data: any, form: any) => {
    let payload = {} as InterviewNode
    payload = data
    // payload.chips = chips
    payload.position = data.position ? data.position : { x: 0, y: 0 }
    if (payload.typeOfQuestion === QuestionType.DelegatedQuestion) { //Type narrowing
      //Map to relevant Amazon Lex in-built slot type, else it is a custom slot type.
      // let keyIndex = Object.keys(AmazonLexInbuiltSlotType).indexOf(payload.slotElicitationSetting?.slotType)
      // if (keyIndex !== -1) {
      //   let values = Object.values(AmazonLexInbuiltSlotType)
      //   let value = values[keyIndex].toString()
      //   payload.slotElicitationSetting.slotType = value
      // }

      if (!payload.validateCandidateInput) {
        payload.confirmationSetting = {} as ConfirmationSetting
      } else {
        payload.slotElicitationSetting.successResponse = []
      }
      payload.validateCandidateInput = showValidateCandidateInputFields
    }

    console.log(payload)
    // payload.position = data.position ? data.position : { x: 0, y: 0 }
    if (isUpdate)
      actionOnSubmit(initialValues, payload, autosave)
    else
      actionOnSubmit(payload, autosave)
    // setChips([])
    form.restart()
  }

  const deleteNode = (form: any) => {
    dispatch(actionOnDelete(initialValues, autosave))
    form.restart()
  }

  const getExcludedNameList = (values: any) => {
    if (!!values) {
      let tempIntentNameList = intentNameList?.slice(0)
      if (tempIntentNameList) {
        let valueIndex = tempIntentNameList.indexOf(values)
        if (valueIndex !== -1)
          tempIntentNameList.splice(valueIndex, 1)
      }
      return tempIntentNameList
    }
    return intentNameList
  }

  const required = (value: any) => value ? undefined : 'This is a required field'

  const checkForDuplicate = (value: string[]) => {
    if (value && value.length > 0) {
      let tempArr = value.map(element => element.toLowerCase())
      if (new Set(tempArr).size !== value.length)
        return 'Please check if there are any duplicated chip values';
      return undefined;
    }
    return undefined;
  }

  const isFormFieldValid = (meta: any) => !!((meta.touched || meta.modified) && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  const WhenLastQuestionIsChecked = ({ set, to }: { set: string, to: any }) => {
    return (
      <Field name={set} subscription={{}}>
        {(
          // No subscription. We only use Field to get to the change function
          { input: { onChange } }
        ) => {
          return (
            <OnChange name='isLastQuestion'>
              {(value: boolean) => {
                if (value === true) {
                  onChange(to);
                }
              }}
            </OnChange>
          )
        }}
      </Field>
    )
  }

  return (
    <div>
      <h3>{isUpdate ? "Update new Delegated node" : "Create new Delegated node"}</h3>
      <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">
        A Delegated node type is intended to capture a particular response from a user given a specific domain.
        <br></br>
        This domain is not explicitly provided, but instead, interpreted from a given set of example responses.
        <br></br>
        Such as a Delegated node type to capture preferred programming language of a candidate would be given a set of examples: "Java, JavaScript, C++".
      </p>
      <p className="text-xs font-italic font-normal line-height-3 text-500 hover:text-700">
        *Note that chips are important for this node type to aid candidates in their response and to narrow down the kind of response they should give.
      </p>
      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate} render={({ form: { mutators: { push, pop, remove } }, form, values, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <WhenLastQuestionIsChecked set="confirmationSetting.flowToIfYes" to={undefined} />
          <WhenLastQuestionIsChecked set="slotElicitationSetting.successStep" to={undefined} />
          <Fieldset className={styles.nodeform} legend="Node Details" toggleable>
            <div className="formgrid grid">
              <Field name="typeOfQuestion" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="typeOfQuestionInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                  <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="name" render={({ input, meta }) => (
                <div className="field col-6 md:col-6">
                  <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                  <InputText id="nameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="chips" validate={checkForDuplicate} render={({ input, meta }) => (
                <div className="field col-12">
                  <label htmlFor="chipsInput">Chips</label>
                  <Chips id="chipsInput" {...input} allowDuplicate={false} separator="," className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="multiChips" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-12 md:col-6">
                  <Checkbox inputId="multiChipsInput" {...input} checked={input.checked || false} />
                  <label htmlFor="multiChipsInput">Allow multiple chips input?</label>
                </div>
              )} />
              <Field name="isFirstQuestion" type="checkbox" render={({ input, meta }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isFirstQuestionInput" {...input} checked={input.checked || false} />
                  <label htmlFor="isFirstQuestionInput">Is this node the first question of the interview?</label>
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="isLastQuestion" type="checkbox" render={({ input, meta }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isLastQuestionInput" {...input} checked={input.checked || false} />
                  <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                  {getFormErrorMessage(meta)}
                </div>
              )} />
            </div>
          </Fieldset>

          <Fieldset className={styles.nodeform} legend="Elicitation Settings" toggleable>
            <div className="formgrid grid">
              {/* <Field name="slotName" render={({ input, meta }) => (
                <div className="field col-6">
                  <label htmlFor="slotNameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Name*</label>
                  <InputText id="slotNameInput" autoComplete="off" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} /> */}
              <Field name="questionPrompts" render={({ meta }) => (
                <div className="xl:col-7 md:col-12">
                  <span className={classNames({ 'p-error': isFormFieldValid(meta) })} style={{ display: "inline-block", marginBottom: "0.5rem" }}>Elicitation Prompts*</span>
                  <br></br>{getFormErrorMessage(meta)}
                </div>
              )} />
              {/*Sharing field name with FieldArray to listen to the meta state of the field array to disable button conditionally in runtime*/}
              <Field name="slotElicitationSetting.slotElicitationQuestionPrompts" render={({ meta }) => (
                <div className="xl:col-4 xl:col-offset-1 md:col-12 md:col-offset-0">
                  <Button className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} label="Add Prompt" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(meta.length && meta.length >= 3)} onClick={() => push('slotElicitationSetting.slotElicitationQuestionPrompts', "")}></Button>
                </div>
              )} />
              <FieldArray name="slotElicitationSetting.slotElicitationQuestionPrompts">
                {({ fields }) =>
                  fields.map((questionPrompt, index) => (
                    <div key={index.toString()} className="col-12">
                      <h3 className="col-12">Prompt #{index + 1}</h3>
                      <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                        <Field name={`slotElicitationSetting.slotElicitationQuestionPrompts[${index}]`} validate={required} render={({ input, meta }) => (
                          <div>
                            <div>
                              <InputText id="slotElicitationQuestionPromptsInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                              {getFormErrorMessage(meta)}
                            </div>
                            <div>
                              <Button className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('slotElicitationSetting.slotElicitationQuestionPrompts', index)}></Button>
                            </div>
                          </div>
                        )} />
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
              <Field name="slotElicitationSetting.slotType" render={({ input, meta }) => (
                <div className="field col-7">
                  <label htmlFor="slotTypeInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Slot Type*</label>
                  <Dropdown id="slotTypeInput" {...input} options={allTenantSlotTypeOptions} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} optionGroupChildren="items" optionGroupLabel="label" optionGroupTemplate={groupedItemTemplate} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="validateCandidateInput" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-6">
                  <label htmlFor="validateCandidateInput">Validate Candidate Input</label>
                  <Checkbox inputId="validateCandidateInput" {...input} checked={input.checked || false} />
                  <Tooltip target=".validate-candidate-input-icon" />
                  <i className="validate-candidate-input-icon ml-2 pi pi-question-circle"
                    data-pr-tooltip="By checking this field, X-Interviewer will confirm with the Candidate the interpreted input to verify if it is correct."></i>
                </div>
              )} />
              {/* Probably no need this, can use multipleChips boolean also. */}
              {/* <Field name="slotElicitationSetting.multipleValues" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-6">
                  <Checkbox inputId="multipleValuesInput" {...input} />
                  <label htmlFor="multipleValuesInput">Capture multiple slot values?</label>
                </div>
              )} /> */}
            </div>
          </Fieldset>

          {/* Builds confirmation settings if validation is enabled, else will just capture slot value as it is without any validation */}
          {values.validateCandidateInput ?
            <Fieldset className={styles.nodeform} legend="Confirmation Settings" toggleable>
              <div className="formgrid grid">
                <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">The response and dialog action to be taken upon candidate's confirmation of their input.</p>
                <Field name="confirmationSetting.flowToIfYes" render={({ input }) => (
                  <div className="col-12">
                    <div className="field col-12">
                      <label htmlFor="flowToIfYes">Next Step</label>
                      <Dropdown id="flowToIfYes" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to if confirmed" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                    </div>
                    <div className="col-4">
                      <Button id="addConfirmationResponseBtn" label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.confirmationSetting && values.confirmationSetting.responseIfYes && values.confirmationSetting.responseIfYes.length >= 3)} onClick={() => push('confirmationSetting.responseIfYes', "")}></Button>
                    </div>
                  </div>
                )} />
                <FieldArray name="confirmationSetting.responseIfYes">
                  {({ fields }) =>
                    fields.map((responseIfYes, index) => (
                      <div key={`responseIfYes${index.toString()}`} className="col-12">
                        <h3 className="col-12">Confirmation Response #{index + 1}</h3>
                        <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                          <Field name={`confirmationSetting.responseIfYes[${index}]`} validate={required} render={({ input, meta }) => (
                            <div>
                              <div>
                                <InputText id="responseIfYesInput" {...input} inputMode="text" className="inputfield w-full" />
                              </div>
                              <div>
                                <Button id="removeConfirmationResponseBtn" className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('confirmationSetting.responseIfYes', index)}></Button>
                              </div>
                            </div>
                          )} />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
            </Fieldset>
            :
            <Fieldset className={styles.nodeform} legend="Slot Capture Settings" toggleable>
              <div className="formgrid grid">
                <p className="text-sm font-italic font-normal line-height-3 text-500 hover:text-700">The response and dialog action to be taken upon successful slot capture of candidate's input.</p>
                <Field name="slotElicitationSetting.successStep" render={({ input, meta }) => (
                  <div className="col-12">
                    <div className="field col-12">
                      <label htmlFor="successStepInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Next Step</label>
                      <Dropdown id="successStepInput" showClear disabled={values.isLastQuestion} tooltip="The next dialog action upon capturing a response" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                      {getFormErrorMessage(meta)}
                    </div>
                    <div className="col-4">
                      <Button id="addSuccessResponseBtn" label="Add Response" type="button" icon="pi pi-plus" iconPos="right" disabled={!!(values.slotElicitationSetting && values.slotElicitationSetting.successResponse && values.slotElicitationSetting.successResponse.length >= 3)} onClick={() => push('slotElicitationSetting.successResponse', "")}></Button>
                    </div>
                  </div>
                )} />
                <FieldArray name="slotElicitationSetting.successResponse">
                  {({ fields }) =>
                    fields.map((successResponse, index) => (
                      <div key={`successResponse${index.toString()}`} className="col-12">
                        <h3 className="col-12">Capture Response #{index + 1}</h3>
                        <div className="fadeinleft animation-duration-500 animation-iteration-1 field col-12" key={index.toString()}>
                          <Field name={`slotElicitationSetting.successResponse[${index}]`} validate={required} render={({ input, meta }) => (
                            <div>
                              <div>
                                <InputText id="successResponseInput" {...input} inputMode="text" className="inputfield w-full" />
                              </div>
                              <div>
                                <Button id="removeSuccessResponseBtn" className="mt-2" label="Remove" type="button" icon="pi pi-minus" iconPos="right" onClick={() => remove('slotElicitationSetting.successResponse', index)}></Button>
                              </div>
                            </div>
                          )} />
                        </div>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>
            </Fieldset>
          }
          {/* Can just make this a checkbox field "Validate Slot Capture?" which defaults
          to verifying candidate with their input value for the slot. If omitted, backend
          build differently at run time. 
          Checked field will dynamically render confirmation fields, else no render*/}

          {/* <Card> */}
          {/* <div className="formgrid grid">
              <Field name="typeOfQuestion" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="typeOfQuestionInput" className= classNames({ 'p-error': isFormFieldValid(meta) })}>Type of Question*</label>
                  <Dropdown id="typeOfQuestionInput" {...input} disabled={isUpdate} options={Object.values(QuestionType)} onChange={onQuestionTypeChange} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="name" render={({ input, meta }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name of Node*</label>
                  <InputText id="nameInput" {...input} inputMode="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
              <Field name="nextIntent" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="nextIntentInput">Next Node</label>
                  <Dropdown id="nextIntentInput" showClear disabled={values.isLastQuestion} tooltip="The next node to flow to upon finishing this intent" {...input} options={!!intentNameList ? getExcludedNameList(values.name) : []} className="inputfield w-full" />
                </div>
              )} />
              <Field name="chips" render={({ input }) => (
                <div className="field col-12 md:col-6">
                  <label htmlFor="chipsInput">Chips</label>
                  <Chips id="chipsInput" {...input} value={chips} onChange={(e) => setChips(e.value)} separator="," className="inputfield w-full" />
                </div>
              )} />
              <Field name="multiChips" type="checkbox" render={({ input }) => (
                <div className="field-checkbox col-12 md:col-6">
                  <Checkbox inputId="multiChipsInput" {...input} />
                  <label htmlFor="multiChipsInput">Allow multiple chips input?</label>
                </div>
              )} />
              <Field name="isLastQuestion" type="checkbox" render={({ input }) => (
                <div className="field-checkbox field col-12 md:col-6">
                  <Checkbox inputId="isLastQuestionInput" {...input} />
                  <label htmlFor="isLastQuestionInput">Is this node the last question of the interview?</label>
                </div>
              )} />
            </div> */}
          {/* <div className="flex justify-content-end align-items-end">
              <Button type="button" className="p-button-outlined p-button-danger mr-3" label="Delete" icon="pi pi-trash" onClick={() => deleteNode(form)} disabled={!isUpdate}></Button>
              <Button type="submit" label={isUpdate ? "Update" : "Create"} icon="pi pi-check" disabled={pristine}></Button>
            </div>
          </Card> */}
          <div className="flex justify-content-end align-items-end mt-2">
            <Button type="button" className="p-button-outlined p-button-danger mr-3" label="Delete" icon="pi pi-trash" onClick={() => deleteNode(form)} disabled={!isUpdate}></Button>
            <Button type="submit" label={isUpdate ? "Update" : "Create"} icon="pi pi-check" disabled={pristine}></Button>
          </div>
        </form>
      )
      } />
    </div >
  )
}

export default DelegatedForm