import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { Application } from "../../../../AdminPortal/scenes/Application/interface"
import { candidateRetrieveApplication } from "./CandidateApplicationAction"

interface ApplicationState {
  jobApplied: boolean
  remainingAttempts: Number | undefined
}

const initialState = {
  jobApplied: false,
  remainingAttempts: undefined
} as ApplicationState

const applicationSlicer = createSlice({
  name: 'candidate-application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateRetrieveApplication.fulfilled, (state: any, { payload }) => {
        state.jobApplied = payload.jobApplied
        state.remainingAttempts = Number(payload.remainingRetryCount)
      })
  },
})

export default applicationSlicer.reducer