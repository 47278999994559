import {
  Node,
  Edge,
  MarkerType
} from "react-flow-renderer";
import { Branch, IntentDetails, InterviewNode } from "../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface";

const position = { x: 0, y: 0 };
const edge = {
  type: 'floating', // floating or simplebezier
  animated: false,
  markerEnd: {
    type: MarkerType.ArrowClosed
  }
};

const NodeElements = (intents: InterviewNode[]) => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  intents.forEach((intent: InterviewNode) => {
    nodes.push({
      id: intent.name,
      data: {
        label: intent.name
      },
      position: !!intent.position ? intent.position : position
    });

    if (!intent.isLastQuestion)
      switch (intent.typeOfQuestion) {
        case 'DelegatedQuestion':
          if (intent.confirmationSetting && intent.confirmationSetting.flowToIfYes) {
            edges.push({
              id: intent.name + 'delegate',
              source: intent.name,
              target: intent.confirmationSetting.flowToIfYes,
              ...edge
            });
          } else if (intent.slotElicitationSetting && intent.slotElicitationSetting.successStep) {
            edges.push({
              id: intent.name + 'delegate',
              source: intent.name,
              target: intent.slotElicitationSetting.successStep,
              ...edge
            });
          }
          break;
        case 'OpenEndedQuestion':
          if (intent.slotElicitationSetting.successStep && intent.slotElicitationSetting.successStep.length > 0)
            edges.push({
              id: intent.name + 'delegate',
              source: intent.name,
              target: intent.slotElicitationSetting.successStep,
              ...edge
            });
          break;
        case 'ConfirmationQuestion':
          if (intent.confirmationSetting.flowToIfYes && intent.confirmationSetting.flowToIfYes.length > 0)
            edges.push({
              id: intent.name + 'yes',
              label: 'Yes',
              source: intent.name,
              target: intent.confirmationSetting.flowToIfYes,
              ...edge
            });
          if (intent.confirmationSetting.flowToIfNo && intent.confirmationSetting.flowToIfNo.length > 0)
            edges.push({
              id: intent.name + 'no',
              label: 'No',
              source: intent.name,
              target: intent.confirmationSetting.flowToIfNo,
              ...edge
            });
          break;
        case 'ConditionalQuestion':
          if (intent.conditionalSetting.branches && intent.conditionalSetting.branches.length > 0) {
            intent.conditionalSetting.branches.forEach((branch: Branch, index: number) => {
              if (branch.nextStep && branch.nextStep.length > 0)
                edges.push({
                  id: `${intent.name} ${index} ${branch.name} ${branch.nextStep}`,
                  label: branch.name,
                  source: intent.name,
                  target: branch.nextStep,
                  ...edge
                });
            });
          }
          if (intent.conditionalSetting && intent.conditionalSetting.defaultStep && intent.conditionalSetting.defaultStep.length > 0)
            edges.push({
              id: intent.name + 'default',
              label: 'default',
              source: intent.name,
              target: intent.conditionalSetting.defaultStep,
              ...edge
            })
          break;
        //   case 'BranchingQuestion':
        //     intent.branches.forEach((branch: any, index: Number) => {
        //       if (branch.flowToIfFulfilled && branch.flowToIfFulfilled.length > 0)
        //         edges.push({
        //           id: intent.name + index,
        //           label: branch.conditionDetails.expectedValue,
        //           source: intent.name,
        //           target: branch.flowToIfFulfilled,
        //           ...edge
        //         });
        //     });
        //     break;
      }
  });

  return { nodes, edges };
}

export default NodeElements