interface ErrorProps {}

const Error = (errorProps: ErrorProps) => {
  const {} = errorProps

  return (
    <div className="m-3 text-center">
      Are you sure you're in the right place?
    </div>
  )
}

export default Error;