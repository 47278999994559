import PrimeReact, { FilterMatchMode } from 'primereact/api'
import React, { useRef, useState, useEffect } from 'react'
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Tenant, TenantCreateStatus, TenantDeleteStatus, TenantPayLoad, TenantUpdateStatus } from '../interface';
import { AppDispatch } from '../../../../store';
import { createTenant, disableTenants, enableTenants, retrieveTenants, updateTenant } from '../TenantAction';
import { Link } from 'react-router-dom';
import LinkButton from '../../../../constants/helpers/LinkButton';

interface TenantListingProps {
}

const TenantListing = (props: TenantListingProps) => {
  PrimeReact.ripple = true
  const toast = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>()
  const { tenantList, totalRecords } = useSelector((state: any) => state.tenant)

  let filters: DataTableFilterMeta = {
    companyName: { value: null, matchMode: FilterMatchMode.EQUALS },
    companyCode: { value: null, matchMode: FilterMatchMode.EQUALS },
    registrationNo: { value: null, matchMode: FilterMatchMode.EQUALS },
    enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [currentTenant, setCurrentTenant] = useState({} as Tenant)
  const [selectedTenants, setSelectedTenants] = useState([] as Tenant[]);
  const [lazyParams, setLazyParams] = useState({
    first: first,
    rows: rows,
    page: 0,
    sortField: "enabled",
    sortOrder: 0,
    filters
  })
  const [selectedStatus, setSelectedStatus] = useState(null)

  // Dialog settings
  const [displayEnableTenantDialog, setDisplayEnableTenantDialog] = useState(false)
  const [displayDeleteTenantDialog, setDisplayDeleteTenantDialog] = useState(false)
  const [displayDeleteMultiTenantDialog, setDisplayDeleteMultiTenantDialog] = useState(false)

  useEffect(() => {
    getAllTenants(lazyParams)
    console.log(lazyParams)
  }, [lazyParams]);

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
    setSelectedTenants([])
    console.log(_lazyParams)
  }

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
    setSelectedTenants([])
    console.log(_lazyParams)
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams)
    setSelectedTenants([])
    console.log(_lazyParams)
  }

  const onRowSelect = (e: any) => {
    setCurrentTenant(e.data)
    console.log(currentTenant)
  }

  const statuses = [
    {label: 'Active', value: true},
    {label: 'Inactive', value: false},
  ]

  const onStatusChange = (e: any) => {
    //console.log(e)
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters.enabled = { value: e.value, matchMode: FilterMatchMode.EQUALS }
    //console.log(_lazyParams)
    setLazyParams(_lazyParams)
    setSelectedStatus(e.value)
    setSelectedTenants([])
  }

  const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusChange} placeholder="All" className="p-column-filter" showClear></Dropdown>
  const statusBodyTemplate = (rowData: any) => {
    if (rowData.enabled === true) {
      return (
        <span style={{ color: '#256029', backgroundColor: '#c8e6c9', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>active</span>
      )
    }
    else {
      return (
        <span style={{ color: '#c63737', backgroundColor: '#ffcdd2', textTransform: 'uppercase', borderRadius: '2px', padding: '.25em .5rem', fontWeight: 700, fontSize: '12px' }}>inactive</span>
      )
    }
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <LinkButton to={"/tenants/create"} icon="file" label="New" className="p-button-primary mr-2" />
        {(selectedTenants.length > 0) ? <Button label="Delete" icon="pi pi-trash" className="p-button-secondary" onClick={handleMultiDeleteTenantButton}></Button> : <Button label="Delete" icon="pi pi-trash" className="p-button-secondary" disabled></Button>}
      </React.Fragment>
    )
  }

  const configTenantTemplate = (rowData: any) => {
    return (
      <div>
        <LinkButton to={"/tenants/" + rowData.id} className="mr-2"
          icon="eye" iconOnly={true}
          tooltip="View Tenant" tooltipOptions={{ position: 'top' }} />
        <LinkButton to={"/tenants/" + rowData.id + "/update"} className="mr-2"
          icon="pencil" iconOnly={true}
          tooltip="Modify Tenant" tooltipOptions={{ position: 'top' }} />
        {rowData.enabled ?
          <Button 
            onClick={() => {
              setCurrentTenant(rowData)
              setDisplayDeleteTenantDialog(true)
            }} 
            severity="secondary"
            tooltip="Delete Tenant"
            tooltipOptions={{ position: 'left' }}
            icon="pi pi-trash" 
            aria-label="Delete" />
          :
          <Button 
            onClick={() => {
              setCurrentTenant(rowData)
              setDisplayEnableTenantDialog(true)
            }} 
            severity="secondary"
            tooltip="Activate Tenant"
            tooltipOptions={{ position: 'left' }}
            icon="pi pi-replay" 
            aria-label="Activate" />
        }
      </div>
    )
  }

  const handleMultiDeleteTenantButton = (e: any) => {
    e.preventDefault()
    setDisplayDeleteMultiTenantDialog(true)
  }

  const getAllTenants = (lazyParams: any) => {
    dispatch(retrieveTenants(lazyParams))
  }

  const confirmSingleEnable = () => {
    dispatch(enableTenants([currentTenant])).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'Tenant Enabled Success', 
          detail: `${currentTenant.companyName} was successfully enabled.`, 
          life: 3000
        })
        getAllTenants(lazyParams)
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'warn', 
          summary: 'Tenant DisaEnabledbled Error', 
          detail: `${currentTenant.companyName} was not enabled.`, 
          life: 3000
        })
      })
  }

  const confirmSingleDelete = () => {
    dispatch(disableTenants([currentTenant])).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'Tenant Disabled Success', 
          detail: `${currentTenant.companyName} was successfully disabled.`, 
          life: 3000
        })
        getAllTenants(lazyParams)
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'warn', 
          summary: 'Tenant Disabled Error', 
          detail: `${currentTenant.companyName} was not disabled.`, 
          life: 3000
        })
      })
  }

  const confirmMultiDelete = () => {
    dispatch(disableTenants(selectedTenants)).unwrap()
      .then(() => {
        toast.current.show({ 
          severity: 'success', 
          summary: 'Tenant Disabled Success', 
          detail: `${selectedTenants.length} was successfully disabled.`, 
          life: 3000
        })
        getAllTenants(lazyParams)
      })
      .catch((error) => {
        toast.current.show({ 
          severity: 'warn', 
          summary: 'Tenant Disabled Error', 
          detail: `${selectedTenants.length} was not disabled.`, 
          life: 3000
        })
      })
    setSelectedTenants([])
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="datatable-doc-demo">
        <div className="card">
          <ConfirmDialog visible={displayEnableTenantDialog} onHide={() => setDisplayEnableTenantDialog(false)} message={`Are you sure you want to re-enable the tenant: ${currentTenant.companyName}?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmSingleEnable} reject={() => setDisplayEnableTenantDialog(false)} />
          <ConfirmDialog visible={displayDeleteTenantDialog} onHide={() => setDisplayDeleteTenantDialog(false)} message={`Are you sure you want to delete the tenant: ${currentTenant.companyName}?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmSingleDelete} reject={() => setDisplayDeleteTenantDialog(false)} />
          <ConfirmDialog visible={displayDeleteMultiTenantDialog} onHide={() => setDisplayDeleteMultiTenantDialog(false)} message={`Are you sure you want to delete ${selectedTenants.length} selected tenants?`} header="Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={confirmMultiDelete} reject={() => setDisplayDeleteMultiTenantDialog(false)} />

          <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

          <DataTable value={tenantList} first={lazyParams.first} rows={rows} totalRecords={totalRecords} onPage={onPage}
            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
            onFilter={onFilter} filters={lazyParams.filters} filterDisplay='row'
            selection={selectedTenants} onSelectionChange={(e) => {
              const tenants = e.value as Tenant[]
              setSelectedTenants(tenants)
            }} onRowSelect={onRowSelect}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[10, 20, 50]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            lazy paginator>
            <Column selectionMode="multiple" style={{ width: '3rem', flexGrow: 0 }}></Column>
            <Column field="companyName" header="Company Name" filter sortable></Column>
            <Column field="companyCode" header="Company Code" filter sortable></Column>
            <Column field="registrationNo" header="Registration No" filter sortable></Column>
            <Column field="enabled" header="Enabled" body={statusBodyTemplate} filter filterElement={statusFilter} sortable></Column>
            <Column body={configTenantTemplate} style={{ width: '12em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default TenantListing