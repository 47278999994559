import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { LexRuntimeV2Client, Message, PutSessionCommand, PutSessionCommandInput, RecognizeUtteranceCommand, SessionState } from "@aws-sdk/client-lex-runtime-v2";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Job } from "../../../../AdminPortal/scenes/Job/interface";
import { getTimeNow } from "../../../../constants/helpers/dateFormatter";

import MessageList from "./components/MessageList";
import UserInput from './components/UserInput';
import { ChatbotDetails, ChatLog, ChatSession, InterviewDetailsStatus, InterviewNode, MessageDetails } from "./interface";

import ProgressSpinnerDialog from "../../../../constants/helpers/ProgressSpinnerDialog";
import styles from './styles.module.css';
import { AppDispatch } from "../../../../store";
import { candidateArchiveChatbot, candidateCreateChatbot } from "./CandidateChatbotAction";
import { Base64 } from "js-base64";
import pako from "pako";

interface ChatProps {
}

//AWS information (retrieved from AWS console)
const region = 'ap-southeast-1'
const identityPoolId = 'ap-southeast-1:cbb98eb8-cc49-4c49-8726-9c0ef32bbcae'

const lexClient = new LexRuntimeV2Client({
  region,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region }),
    identityPoolId: identityPoolId
  }),
})

const Chat = (props: ChatProps) => {
  const { } = props

  const history = useHistory() //Currently removed, can be used for defaulting users back to homepage if necessary (e.g.: Unverified user, direct entry to URL is prohibited)
  const { interviewId } = useParams<{ interviewId: string }>()

  const dispatch = useDispatch<AppDispatch>()
  const { chatbotDetails } = useSelector((state: any) => state.candidateChatbot)

  const [messageDetailsList, setMessageDetailsList] = useState<MessageDetails[]>([])
  const [interviewCompleted, setInterviewCompleted] = useState(false) //NOTE: Currently not wired correctly, can implement a check on Lex dialogAction to determine if interview ended, or look through chatBotDetails.interviewNodeList to see if currentIntent isLastQuestion
  const [currentIntent, setCurrentIntent] = useState({} as InterviewNode)
  const [response, setResponse] = useState<any>({})

  const putSessionCommandInput: PutSessionCommandInput = {
    botId: chatbotDetails.botId,
    botAliasId: chatbotDetails.botAliasId,
    localeId: chatbotDetails.localeId,
    sessionId: chatbotDetails.botId + 'test' + interviewId,
    sessionState: {} as SessionState
  }

  //This is the initial message to send to Lex to trigger WelcomeIntent 
  let message: Message = {
    content: "Hello",
    contentType: 'string'
  }

  // page load
  useEffect(() => {
    // retrieve chatbot details
    dispatch(candidateCreateChatbot(interviewId)).unwrap()
      .catch(() => {
        // error retrieving chatbot details, redirect out!
        history.push("/interview/" + interviewId)
      })
  }, [])

  //Upon chatbotDetails loaded.
  useEffect(() => {
    if (Object.keys(chatbotDetails).length > 0) {
      //Do init stuffs here
      init()
    }
  }, [chatbotDetails])

  //Everytime a response is received from Lex, let system know which intent it is currently on
  useEffect(() => {
    if (Object.keys(response).length !== 0) {
      let currentIntent = response.sessionState?.intent
      let foundIntentNode = chatbotDetails.interviewNodeList.find((node: any) => node.name === currentIntent.name)
      if (foundIntentNode) {
        setCurrentIntent(foundIntentNode)
      }
      let currentDialogAction = response.sessionState.dialogAction.type;
      if (currentDialogAction && currentDialogAction === "Close")
        setInterviewCompleted(true)
    }
  }, [response])

  //TODO: End of interview need to archive conversation, delete bot, 
  //Logic for saving conversation
  useEffect(() => {
    if (interviewCompleted) {
      let message = { content: "That concludes the end of this interview. Thank you", contentType: 'string' }
      setMessage(message, "Stitch", getTimeNow())

      let chatLog: ChatLog[] = []
      //Archive conversation using messageList
      messageDetailsList.forEach((msg: MessageDetails) => {
        chatLog.push({
          name: msg.header,
          message: msg.message.content || '',
          timestamp: msg.timeStamp,
          chatSessionId: interviewId
        })
      })

      // archiveConversationAction.archiveConversation(chatLog)
      dispatch(candidateArchiveChatbot(chatLog)).unwrap()
        .then(() => {
          console.log('archived')
        })
    }
  }, [interviewCompleted])

  const init = async () => {
    const initialUtterReqParams = {
      ...putSessionCommandInput,
      requestContentType: 'text/plain; charset=utf-8',
      responseContentType: 'text/plain; charset=utf-8',
      inputStream: '',
      sessionState: {}
    }
    await putSession(lexClient, putSessionCommandInput ?? {} as PutSessionCommandInput)
    //NTOE: Sender tagged under 'system' are not displayed in the UI, refer to MessageBox.tsx
    sendMessage(lexClient, { message, header: 'system', timeStamp: getTimeNow() }, initialUtterReqParams)
  }

  const putSession = async (lexClient: LexRuntimeV2Client, putSessionCommandInput: PutSessionCommandInput) => {
    try {
      const putSessionCommand = new PutSessionCommand(putSessionCommandInput)
      const response = await lexClient.send(putSessionCommand)
      // console.log("Put Session Response: ", response)
      if (response.messages) {
        var decodedMsg = Base64.toUint8Array(response.messages)
        var decompressedMsg = JSON.parse(pako.inflate(decodedMsg, { to: 'string' }))
        await decompressedMsg.forEach((msg: Message) => setMessage(msg, 'Stitch', getTimeNow()))
      }
    } catch (error) {
      //TODO error
      console.log("Put Session Error: ", error)
    }
  }

  const setMessage = (message: Message, sender: string, timeStamp: string) => {
    setMessageDetailsList(list => [...list, { message: message, header: sender, timeStamp: timeStamp }])
  }

  const sendMessage = async (client: LexRuntimeV2Client, messageDetails: MessageDetails, params: any) => {
    try {
      setMessage(messageDetails.message, messageDetails.header, getTimeNow())
      params.inputStream = messageDetails.message.content

      //Compressing session state then Base64 encoding it altogether before sending
      var compressedState = pako.gzip(JSON.stringify(params.sessionState))
      params.sessionState = Base64.fromUint8Array(compressedState)

      // console.log(params)
      const recognizeUtteranceCommand = new RecognizeUtteranceCommand(params)
      const response = await client.send(recognizeUtteranceCommand)

      if (response.messages) {
        var decodedMsg = Base64.toUint8Array(response.messages)
        var decompressedMsg = JSON.parse(pako.inflate(decodedMsg, { to: 'string' }))
        // console.log('decompressedMsg', decompressedMsg)
        await decompressedMsg.forEach((msg: Message) => setMessage(msg, 'Stitch', getTimeNow()))
      }

      if (response.sessionState) {
        var decodedState = Base64.toUint8Array(response.sessionState)
        var decompressedState = JSON.parse(pako.inflate(decodedState, { to: 'string' }))
        response.sessionState = decompressedState
      }

      // console.log(response)
      setResponse(response)
    } catch (error) {
      //TODO
      console.log(error)
    }
  }

  return (
    <div className={styles.chatbot}>
      <ProgressSpinnerDialog text="Please hold on while we set up X-Interviewer..." visible={Object.keys(chatbotDetails).length === 0}></ProgressSpinnerDialog>
      <div className="flex flex-column align-items-stretch" style={{ height: '100vh' }}>
        <div>
          <div className="flex justify-content-center align-items-center m-2" style={{ height: '5vh' }}>
            {interviewCompleted ? <div className="font-medium text-xl text-green-500">{/* jobName.current */} Session Ended</div> : <div className="font-medium text-xl">{/* jobName.current */} Session in Progress</div>}
          </div>
        </div>
        <MessageList messageDetailsList={messageDetailsList}></MessageList>
        {!interviewCompleted && (
          <UserInput client={lexClient} params={putSessionCommandInput} sendMessage={sendMessage} currentIntent={currentIntent} dialogAction={Object.keys(response).length > 0 ? response.sessionState?.dialogAction : undefined}></UserInput>
        )}
      </div>
    </div>
  )
}

export default Chat