import { createAsyncThunk } from "@reduxjs/toolkit"
import { Job } from "../../../../AdminPortal/scenes/Job/interface"
import { Api, ApiMethod } from "../../../../utils/Api"

const endpoint = process.env.REACT_APP_INTERVIEW_API_URL

export const candidateRetrieveApplication = createAsyncThunk('candidate-application/retrieveApplication',
  async ({jobId, token}: {jobId: number, token: string}, { rejectWithValue }) => 
    Api(ApiMethod.GET, endpoint, 'candidate/application/details', {jobId, token}, rejectWithValue)
)

export const candidateCreateApplication = createAsyncThunk('candidate-application/createApplication',
  async ({jobId, token}: {jobId: number, token: string}, { rejectWithValue }) => 
    Api(ApiMethod.POST, endpoint, `candidate/application?jobId=${jobId}&token=${token}`, undefined, rejectWithValue)
)

