import {Redirect, Route, Switch} from "react-router-dom";

import Home from "../../candidate/Home";
import ChatBot from "../../CandidatePortal/scenes/Interview/Chatbot"
import ViewJobs from "../../CandidatePortal/scenes/company/ViewJobs"
import ViewSpecificJob from "../../CandidatePortal/scenes/company/ViewSpecificJob"
import BeginInterview from "../../CandidatePortal/scenes/Interview/BeginInterview";
import { CANDIDATE_ROUTES } from "../../constants/routes/candidate/routes";
import SingpassCallback from "../../CandidatePortal/scenes/SingpassCallback";

interface PropsDefinition {
}

const CandidateRoutes = (props: PropsDefinition) => {
    return (
      <Switch>
          {/* Candidate routes */}
          <Route path={CANDIDATE_ROUTES.ROUTE_HOMEPAGE_CANDIDATE} component={() => <Home />}/>
          <Route exact path={CANDIDATE_ROUTES.ROUTE_SINGPASS_CALLBACK} component={() => <SingpassCallback />}/>
          <Route exact path={CANDIDATE_ROUTES.ROUTE_BEGIN_EXACT_INTERVIEW} component={() => <BeginInterview />}/>
          <Route exact path ={CANDIDATE_ROUTES.ROUTE_CHAT_BOT_INTERVIEW} component={() => <ChatBot />}/>
          <Route exact path={CANDIDATE_ROUTES.ROUTE_VIEW_COMPANY_JOBS} component={() => <ViewJobs />}/>
          <Route exact path={CANDIDATE_ROUTES.ROUTE_VIEW_COMPANY_SPECIFIC_JOB} component={() => <ViewSpecificJob />}/>
          <Route path={"/"}>
            {<Redirect to={CANDIDATE_ROUTES.ROUTE_HOMEPAGE_CANDIDATE} />}
          </Route>
      </Switch>
  ) 
}

export default CandidateRoutes