export function processParams(params: any) {
  console.log('process', params)

  const filters = Object.entries(params.filters).reduce((f1, f2: any) => {
    return { ...f1, ...{ [f2[0]]: f2[1].value } }
  }, {})

  return {
    ...filters,
    page: params.page,
    size: params.row,
    sort: params.sortField + ',' + (params.sortOrder === 1 ? 'ASC' : 'DESC'),
  }
}