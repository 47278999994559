import PrimeReact from "primereact/api"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { FileUpload } from "primereact/fileupload"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { MultiSelect } from "primereact/multiselect"
import { Password } from "primereact/password"
import { ToggleButton } from "primereact/togglebutton"
import { Toast } from "primereact/toast"
import { classNames } from "primereact/utils"
import { useRef, useState, useEffect } from "react"
import { Form, Field } from "react-final-form"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { LOOKUPS_KEY } from "../../../../../constants/localStorageKeys"
import { SCOPES } from "../../../../../constants/scopes"
import { AppDispatch } from "../../../../../store"
import { imageUploader } from "../../../../../utils/ImageUploader"
import { CategoryEnum } from "../../../Lookup/enums"
import { retrieveAllTenants } from "../../../Tenant/TenantAction"
import { retrieveUser, retrieveAllScopes, updateUser, createUser } from "../../UserAction"
import { User, Scope } from "../../interfaces"
import * as jwtDecoder from "../../../../../constants/helpers/admin/jwtDecoder";
import { Divider } from "primereact/divider"
import LinkButton from "../../../../../constants/helpers/LinkButton"

interface UserFormProps {
  admin: boolean
  submitButtonLabel: string
  editable: boolean
  resettable?: boolean
}

const UserForm = (props: UserFormProps) => {
  const { submitButtonLabel, editable, resettable = true, admin = false } = props

  const { userId } = useParams<{ userId: string }>()
  const { user, scopes } = useSelector((state: any) => state.user)
  const { tenantList } = useSelector((state: any) => state.tenant)
  const dispatch = useDispatch<AppDispatch>()

  const uploadComponent = useRef<any>()
  const [logo, setLogo] = useState<string>(user.logo)

  const [skillList, setSkillList] = useState([] as any)
  const [languageList, setLanguageList] = useState([] as any)
  const [locationList, setLocationList] = useState([] as any)
  const [specializationList, setSpecializationList] = useState([] as any)
  // const [lookupOptionsLoaded, setlookupOptionsLoaded] = useState(false)
  const [contractTypeList, setContractTypeList] = useState([] as any)
  const [employmentTypes, setEmploymentTypes] = useState([] as any)
  const [adminToggle, setAdminToggle] = useState(user.adminUser)

  PrimeReact.ripple = true
  const toast = useRef<any>(null)

  useEffect(() => {
    // load user from db
    if (userId)
      dispatch(retrieveUser({ admin, userId: Number(userId) }))

    // retrieve scopes
    dispatch(retrieveAllScopes())

    // retrieve tenants
    dispatch(retrieveAllTenants())

    // load lookups from localstorage
    const lookups = localStorage.getItem(LOOKUPS_KEY)
    if (lookups) {
      const lookupsJSON = JSON.parse(lookups)
      const categoryList = Object.keys(CategoryEnum).filter(allItems => isNaN(Number(allItems)))
      categoryList.forEach((categoryName => retrieveLookupValues(lookupsJSON, categoryName)))
    }
  }, [])

  useEffect(() => {
    setLogo(user.logo)
    setAdminToggle(user.adminUser)
  }, [user])

  let initialValues: User =
    user && userId ? {
      id: user.id,
      name: user.name,
      password: undefined,
      email: user.email,
      jobTitle: user.jobTitle,
      tenant: user.tenant,
      logo,
      adminUser: user.adminUser,
      userDetails: user.userDetails,
      scopes: user.scopes
    } :
      {
        id: undefined,
        name: '',
        email: '',
        password: undefined,
        tenant: undefined,
        logo: '',
        adminUser: false,
        jobTitle: '',
        userDetails: {
          about: '',
          specialisation: '',
          skills: [],
          languages: [],
          preferredLocation: '',
          preferredArea: '',
          preferredEmploymentType: '',
          preferredContractType: '',
        },
        scopes: []
      };

  const retrieveLookupValues = (lookups: any, categoryName: any) => {
    switch (categoryName) {
      case CategoryEnum[CategoryEnum.SPECIALIZATION]: {
        setSpecializationList(lookups[categoryName].map((specialization: any) => specialization.value))
        break
      }
      case CategoryEnum[CategoryEnum.COUNTRY]: {
        setLocationList(lookups[categoryName].map((country: any) => country.value))
        break
      }
      case CategoryEnum[CategoryEnum.CONTRACT_TYPE]: {
        setContractTypeList(lookups[categoryName].map((contractType: any) => contractType.value))
        break
      }
      case CategoryEnum[CategoryEnum.LANGUAGE]: {
        setLanguageList(lookups[categoryName].map((language: any) => language.value))
        break
      }
      case CategoryEnum[CategoryEnum.SKILL]: {
        setSkillList(lookups[categoryName].map((skill: any) => skill.value))
        break
      }
      case CategoryEnum[CategoryEnum.EMPLOYMENT_TYPE]: {
        setEmploymentTypes(lookups[categoryName].map((employmentType: any) => employmentType.value))
        break
      }
    }
  }

  let isFormFieldValid = (meta: any) => !!(meta.touched && meta.error)
  let getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  const handleFormReset = (form: any) => {
    form.setConfig('keepDirtyOnReinitialize', false)
    form.restart()
    form.setConfig('keepDirtyOnReinitialize', true)
  }

  const onSubmit = (data: any, form: any) => {
    console.log('data', data)

    let payloadUser: User = {
      id: !!user ? user.id : -1,
      name: data.name,
      email: data.email,
      password: data.password,
      jobTitle: adminToggle ? data?.jobTitle : undefined,
      scopes: data.scopes,
      logo: logo,
      tenant: {
        id: data.tenant.id
      },
      adminUser: adminToggle,
      userDetails: !adminToggle ? data.userDetails : undefined
    }

    if (userId) {
      dispatch(updateUser({ admin, user: payloadUser })).unwrap()
        .then((user: User) => {
          // successs
          toast.current.show({
            severity: 'success',
            summary: 'User Update Success',
            detail: `User: ' ${payloadUser.name} was updated successfully.`,
            life: 3000
          })
        })
        .catch((error: any) => {
          toast.current.show({
            severity: 'warn',
            summary: 'User Update Error',
            detail: `User: ' ${payloadUser.name} was not updated.`,
            life: 3000
          })
        })
    } else {
      dispatch(createUser({ admin, user: payloadUser })).unwrap()
        .then((user: User) => {
          // successs
          toast.current.show({
            severity: 'success',
            summary: 'User Update Success',
            detail: `User: ' ${payloadUser.name} was created successfully.`,
            life: 3000
          })
        })
        .catch((error: any) => {
          toast.current.show({
            severity: 'warn',
            summary: 'User Update Error',
            detail: `User: ' ${payloadUser.name} was not created.`,
            life: 3000
          })
        })
    }
  }

  /* Form validation methods */
  const validate = (data: any) => {
    let errors = {} as any

    if (!data.name) {
      errors.name = 'Username is required.'
    }

    if (!data.email) {
      errors.email = 'Email is required.'
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = 'Invalid email address. E.g. example@email.com'
    }

    if (userId === undefined) {
      if (!data.password) {
        errors.password = 'Password is required.'
      }

      if (!/^(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&]){1,}).{8,20}$/g.test(data.password)) {
        errors.password = 'At least one lowercase, uppercase, numerical character and symbol is required, character should be 8 - 20 characters long.'
      }
    }

    return errors
  }

  /* Image uploader library methods */
  const customUploadEvent = async (event: any) => {
    let base64Result = await imageUploader(event)
    setLogo(base64Result.toString())
    uploadComponent.current.clear()
  }

  const permissionField = (permissionKey: string, permissionName: string) => {
    return (
      <Field name="scopes" key={permissionKey} value={permissionKey} type="checkbox" render={({ input, meta }) => (
        <div className={classNames("col-2 md:col-1 mb-0 flex align-items-center", { 'field-checkbox': editable })}>
          {editable ?
            <Checkbox
              inputId={permissionKey}
              {...input}
              checked={input.checked || false}
              className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
            : user.scopes && user.scopes.indexOf(permissionKey) !== -1 ?
              <span className="pi pi-check-circle mr-2"></span> : <span className="pi pi-times-circle mr-2"></span>
          }
          <label htmlFor={permissionKey} className={classNames({ 'p-error': isFormFieldValid(meta) })}>{permissionName.toLowerCase()}</label>
        </div>
      )} />
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        keepDirtyOnReinitialize
        render={({ form, handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="formgrid grid align-self-center mx-2 my-3">
              {userId &&
                <div className="col-12 mb-4">
                  {!editable ?
                    jwtDecoder.getNameFromJwt() !== initialValues.name &&
                    <LinkButton className="p-button-primary" to={(admin ? '/tenants' : '') + "/users/" + userId + "/update"} icon="eye" label="Edit" />
                    :
                    <LinkButton to={(admin ? '/tenants' : '') + "/users/" + userId} icon="arrow-left" label="Back" />
                  }
                </div>
              }
              <div className="col-12">
                <Card title="Account Details" className="mb-3">
                  <div className="grid align-self-center">
                    <Field name="name" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Name*</label>
                        {editable ?
                          <InputText id="name" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                          : <div>{user.name}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />
                    <Field name="email" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Email*</label>
                        {editable ?
                          <InputText id="email" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                          : <div>{user.email}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />
                    {admin && jwtDecoder.checkScopeExistsInJwt(SCOPES.PORTAL_ADMIN) &&
                      <Field name="tenant.id" render={({ input, meta }) => (
                        <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                          <label htmlFor="tenant" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Tenant*</label>
                          {editable ?
                            <Dropdown id="tenant" {...input} filter options={tenantList} optionLabel="companyName" optionValue="id" style={{ width: "100%" }} />
                            : <div>{user.tenant?.companyName}</div>
                          }
                          {getFormErrorMessage(meta)}
                        </div>
                      )} />
                    }
                    {<Field name="password" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Password</label>
                        {editable ?
                          <Password id="password" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                          : <div>*********</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}

                    <div className="field col-12 md:col-6">
                      <label htmlFor="uploadImage">Profile Picture:</label>
                      {editable &&
                        <FileUpload id="uploadImage" ref={uploadComponent}
                          className={classNames("inputfield w-full imageUploader")} customUpload auto
                          mode="basic" accept="image/*" maxFileSize={5000000} uploadHandler={customUploadEvent} />
                      }
                      {logo && logo.length > 0 &&
                        <div className={classNames({ "mt-2": editable })}>
                          <img src={logo} alt="Display uploaded logo" style={{ width: '100px' }}></img>
                        </div>
                      }
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-12">
                <Card title="Personal Details" className="my-3">
                  <div className="grid align-self-center">
                    {editable &&
                      <Field name="adminUser" render={({ input, meta }) => (
                        <div className="col-12" style={{ paddingTop: "12px", paddingBottom: "12px" }}>
                          <ToggleButton id="adminUser" checked={adminToggle} onChange={(e) => setAdminToggle(e.value)} onIcon="pi pi-user-plus" offIcon="pi pi-users" onLabel="Administrator" offLabel="Candidate" aria-label="Confirmation" />
                          {getFormErrorMessage(meta)}
                        </div>
                      )} />
                    }

                    {adminToggle && <Field name="jobTitle" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="jobTitle" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Job Title</label>
                        {editable ?
                          <InputText id="jobTitle" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                          : <div>{user.jobTitle}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}

                    {!adminToggle && <Field name="userDetails.about" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="about" className={classNames({ 'p-error': isFormFieldValid(meta) })}>About</label>
                        {editable ?
                          <InputTextarea id="about" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} rows={5} cols={78} />
                          : <div>{user.userDetails?.about}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}

                    {!adminToggle && <Field name="userDetails.specialisation" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="specialisation" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Specialisation</label>
                        {editable ?
                          <Dropdown id="specialisation" {...input} filter virtualScrollerOptions={{ itemSize: 38 }} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={specializationList} placeholder="Select a Specialization" />
                          : <div>{user.userDetails?.specialisation}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                    {!adminToggle && <Field name="userDetails.skills" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="skill" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Skill(s)</label>
                        {editable ?
                          <MultiSelect id="skill" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={skillList} showClear placeholder="Select Skill(s)" />
                          : <div>{user.userDetails?.skills.join(', ')}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                    {!adminToggle && <Field name="userDetails.languages" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="languages" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Language(s)</label>
                        {editable ?
                          <MultiSelect id="languages" {...input} filter virtualScrollerOptions={{ itemSize: 38 }} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={languageList} showClear placeholder="Select Language(s)" />
                          : <div>{user.userDetails?.languages.join(', ')}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                    {!adminToggle && <Field name="userDetails.preferredLocation" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="preferredLocation" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Location</label>
                        {editable ?
                          <Dropdown id="preferredLocation" {...input} filter virtualScrollerOptions={{ itemSize: 38 }} className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={locationList} placeholder="Select a Location" />
                          : <div>{user.userDetails?.preferredLocation}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                    {!adminToggle && <Field name="userDetails.preferredArea" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="preferredArea" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Preferred Area</label>
                        {editable ?
                          <InputText id="preferredArea" {...input} className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} />
                          : <div>{user.userDetails?.preferredArea}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                    {!adminToggle && <Field name="userDetails.preferredEmploymentType" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="preferredEmploymentType" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Preferred Employment Type</label>
                        {editable ?
                          <Dropdown id="preferredEmploymentType" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={employmentTypes} placeholder="Select an Employment Type" />
                          : <div>{user.userDetails?.preferredEmploymentType}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                    {!adminToggle && <Field name="userDetails.preferredContractType" render={({ input, meta }) => (
                      <div className="field col-12 md:col-6" style={{ paddingTop: "12px" }}>
                        <label htmlFor="preferredContractType" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Preferred Contract Type</label>
                        {editable ?
                          <Dropdown id="preferredContractType" {...input} filter className={classNames("inputfield w-full", { 'p-error': isFormFieldValid(meta) })} options={contractTypeList} placeholder="Select a Contract Type" />
                          : <div>{user.userDetails?.preferredContractType}</div>
                        }
                        {getFormErrorMessage(meta)}
                      </div>
                    )} />}
                  </div>
                </Card>
              </div>
              <div className="col-12">
                <Card title="Permissions" className="my-3">
                  <div className="grid align-self-center">
                    <div id="scopes" className="field col-12" style={{ paddingTop: "12px" }}>
                      <label>Scopes</label>
                      <div className="grid align-items-center mt-2">
                        <p className="field col-4 md:col-2">Administrative</p>
                        {permissionField('TENANT_ADMIN', 'tenant')}
                        {admin && permissionField('PORTAL_ADMIN', 'portal')}
                      </div>
                      {scopes !== undefined && Object.keys(scopes).length !== 0 ?
                        scopes.map((scope: Scope) => {
                          return (
                            <>
                              <Divider type="dashed" className="mt-1" />
                              <div key={scope.name} className="grid align-items-center">
                                <p className="field col-4 md:col-2">{scope.name.toLowerCase()}</p>
                                {scope.permissions.map((permission: string) => {
                                  const scopeName = scope.name.concat("_").concat(permission)
                                  return permissionField(scopeName, permission.toLowerCase())
                                })}
                              </div>
                            </>
                          )
                        }) :
                        <div className="flex formgrid grid">
                          No scopes found...
                        </div>
                      }
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-12">
                {editable &&
                  <div className="flex justify-content-end align-items-end">
                    {resettable ?
                      <Button
                        className="p-button-outlined p-button-secondary mr-3"
                        label="Reset"
                        icon="pi pi-refresh"
                        onClick={() => { handleFormReset(form) }}
                        disabled={pristine}></Button>
                      : false
                    }
                    {jwtDecoder.getNameFromJwt() !== initialValues.name ?
                      <Button label={submitButtonLabel} icon="pi pi-check"></Button>
                      : false
                    }
                  </div>
                }
              </div>
            </div>
          </form>
        )} />
    </div>
  )
}

export default UserForm