import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api, ApiMethod } from "../../../../utils/Api"
import { ChatLog } from "./interface"

const endpoint = process.env.REACT_APP_CHATBOT_API_URL

export const candidateCreateChatbot = createAsyncThunk('candidate-chatbot/createChatbot',
  async (interviewId: string, { rejectWithValue }) => 
    Api(ApiMethod.POST, endpoint, `candidate/createBot`, {interviewId}, rejectWithValue)
)

export const candidateArchiveChatbot = createAsyncThunk('candidate-chatbot/archiveChatbot',
  async (chatLogs: ChatLog[], { rejectWithValue }) => 
    Api(ApiMethod.POST, endpoint, `candidate/interview/end`, chatLogs, rejectWithValue)
)
