import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../../admin/Login";
import AdminAuthenticatedRoute from "../../AdminPortal/AuthenticatedRoute";
import { ADMIN_ROUTES } from "../../constants/routes/admin/menus";
import { Routes, ROUTES } from "../../constants/routes/admin/routes";

interface PropsDefinition {
}

interface FlattenedRoutes {
  currentRoute: Routes
  parentRoutes: Routes[]
}

const AdminRoutes = (props: PropsDefinition) => {
  const flattenedRoutes: FlattenedRoutes[] = []

  // recursive method to flatten routes
  const recursiveAdminRoute = (routes: Routes[] = ROUTES, parentRoutes: Routes[] = []) => {
    routes.forEach((route: Routes, index: number) => {
      const paths: string[] = [];

      // initialize and add current routes to array
      const consolidatedParentRoutes = [...parentRoutes || [], route];

      // loop through routes to construct path
      consolidatedParentRoutes.forEach(parentRoute => paths.push(parentRoute.path))

      // set constructed path to route object to be used in authenticated route and breadcrumbs
      route.compiledPath = paths.join('')

      // push to array
      flattenedRoutes.push({
        currentRoute: route,
        parentRoutes: consolidatedParentRoutes
      })
      
      if(route.children) recursiveAdminRoute(route.children, consolidatedParentRoutes)
    })
  }
  
  // recursive flatten routes
  recursiveAdminRoute()

  return (
    <Switch>
      /* Admin routes */
      <Route exact path={ADMIN_ROUTES.ROUTE_LOGIN_ADMIN} component={() => <Login />} />
      <Route path={"/"} exact={true}>
        {<Redirect to={ADMIN_ROUTES.ROUTE_HOMEPAGE_ADMIN} />}
      </Route>
      {flattenedRoutes && flattenedRoutes.map((route: FlattenedRoutes, index: number) => 
        <AdminAuthenticatedRoute 
          key={index} 
          path={route.currentRoute.compiledPath || ''} 
          exact={route.currentRoute.exact || false}
          currentRoute={route.currentRoute} 
          parentRoutes={route.parentRoutes} />
      )}
    </Switch>
  )
}

export default AdminRoutes