import React, { useRef } from "react"
import { Redirect, Route, useHistory, useLocation } from "react-router-dom"
import { JWT_KEY } from "../../constants/localStorageKeys"

import PrimeReact from 'primereact/api'
import classes from './styles.module.css'

import { Avatar } from 'primereact/avatar'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Menu } from 'primereact/menu'
import { Menubar } from 'primereact/menubar'
import { Toast } from 'primereact/toast'

import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from "axios"
import { Button } from "primereact/button"
import * as jwtDecoder from "../../constants/helpers/admin/jwtDecoder"
import { ADMIN_ROUTES, avatarMenuItems, menuItems } from "../../constants/routes/admin/menus"
import { Routes } from "../../constants/routes/admin/routes"
import Breadcrumbs from "./components/Breadcrumbs"

export interface AuthenticatedRouteProps {
  path: string
  exact: boolean
  currentRoute: Routes
  parentRoutes: Routes[]
}

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { currentRoute, parentRoutes } = props
  const history = useHistory()
  const toastRef = useRef<any>()
  PrimeReact.ripple = true
  const menu = useRef(null)
  const mavenTreeIconStyle = {
    width: "60px",
    paddingRight: "8px",
  }

  const jwt = localStorage.getItem(JWT_KEY)
  const userName: string | null = jwtDecoder.getNameFromJwt()
  const tenantName: string | null = jwtDecoder.getTenantNameFromJwt()
  const userScopes: string[] = jwtDecoder.getScopesFromJwt();

  const menuItemsHistory = menuItems(history)
  const avatarMenuItemsHistory = avatarMenuItems(history)

  // Task #65, additional authorization check
  if(currentRoute.scopes) {
    const missingUserScopes = currentRoute.scopes.filter((s: string) => userScopes.indexOf(s) === -1);
    if(missingUserScopes && missingUserScopes.length > 0) {
      // redirect to homepage
      history.push('/error');
    }
  }

  const menuBarStart = () => {
    return (
      <div className={classes.MenuBarStart}>
        <FontAwesomeIcon icon={faCoffee} style={mavenTreeIconStyle} />
        <div>Dashboard</div>
      </div>
    )
  }

  const menuBarEnd = () => {
    return (
      <div className={classes.MenuBarEnd} onClick={(e: any) => (menu.current as any).toggle(e)} aria-controls="popup_menu" aria-haspopup >
        <Menu model={avatarMenuItemsHistory} popup ref={menu} id="popup_menu" />
        <div className={classes.UserBriefDetails}>
          <div>{userName}</div>
          <div>{tenantName}</div>
        </div>
        {/* <Button label="Test create bot" onClick={fireBotRequest}></Button> */}
        <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: 'black', color: 'white' }} size="large" shape="circle" />
      </div>
    )
  }

  const handlePageRender = (innerProps: any) => {
    if (!!jwt) {
      // retrieve component
      const Component = () => currentRoute.component || <></>
      return (
        <React.Fragment>
          <div>
            <Toast ref={toastRef} position="top-right" />
            <Menubar
              model={menuItemsHistory}
              start={menuBarStart}
              end={menuBarEnd}
            />
            <Breadcrumbs currentRoute={currentRoute} parentRoutes={parentRoutes} />
          </div>
          <Component {...innerProps} />
        </React.Fragment>
      )
    }
    else {
      return (
        <Redirect to={ADMIN_ROUTES.ROUTE_LOGIN_ADMIN} />
      )
    }
  }

  return (
    <Route exact={currentRoute.exact} path={currentRoute.compiledPath} render={handlePageRender} />
  )
}

export default AuthenticatedRoute