import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api, ApiMethod } from "../../../utils/Api"

const endpoint = process.env.REACT_APP_INTERVIEW_API_URL

export const candidateRetrieveInterview = createAsyncThunk('candidate-interview/retrieveInterview',
  async ({interviewId, token}: {interviewId: string, token: string}, { rejectWithValue }) => 
    Api(ApiMethod.GET, endpoint, 'candidate/interview/' + interviewId, {token}, rejectWithValue)
)

export const candidateCreateInterview = createAsyncThunk('candidate-interview/createInterview',
  async ({jobId, token}: {jobId: number, token: string}, { rejectWithValue }) => 
    Api(ApiMethod.POST, endpoint, `candidate/interview?jobId=${jobId}&token=${token}`, undefined, rejectWithValue)
)
