import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../utils/Api'
import URI from 'urijs'
import { Application } from './interface'
import { processParams } from '../../../utils/LazyParamsUtil'
import { Job } from '../Job/interface'

const interviewEndpoint = process.env.REACT_APP_INTERVIEW_API_URL
const jobEndpoint = process.env.REACT_APP_JOB_API_URL

export const downloadResumeForAdmin = createAsyncThunk('application/downloadResumeForAdmin',
  async (token: String, { rejectWithValue }) => 
    Api(ApiMethod.GET, jobEndpoint, "/download-resume?token=" + token, rejectWithValue)
)

export const retrieveApplications = createAsyncThunk('application/retrieveApplications',
  async (lazyParams: any, { rejectWithValue }) => Api(ApiMethod.GET, interviewEndpoint, 'getApplicationListing', processParams(lazyParams), rejectWithValue)
)

export const retrieveApplication = createAsyncThunk('application/retrieveApplication',
  async (applicationId: Number, { rejectWithValue }) => Api(ApiMethod.GET, interviewEndpoint, 'getApplication/' + applicationId, undefined, rejectWithValue)
)