import PrimeReact from "primereact/api"
import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { Field, Form } from "react-final-form"
import { FlowType, InterviewFlow } from "../../../interface"

interface CreateInterviewFlowFormProps {
  formHeader: string
  actionOnSubmit: any
  submitButtonLabel: string
}

const CreateInterviewFlowForm = (props: CreateInterviewFlowFormProps) => {
  const { formHeader, actionOnSubmit, submitButtonLabel } = props
  PrimeReact.ripple = true

  let initialValues: InterviewFlow = {
    name: "",
    interviewFlowEnum: FlowType.GLOBAL
  }

  const validate = (data: any) => {
    let errors = {} as any

    if (!data.name) {
      errors.name = 'Interview Flow Name is required.'
    }

    return errors
  }

  const onSubmit = (data: any, form: any) => {
    actionOnSubmit(data)
  }

  const isFormFieldValid = (meta: any) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta: any) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} render={({ form, handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <div className="formgrid grid">
              <Field name="name" render={({ input, meta }) => (
                <div className="field col-12">
                  <label htmlFor="nameInput" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Interview Flow Name*</label>
                  <InputText id="nameInput" {...input} autoFocus type="text" className={classNames("inputfield w-full", { 'p-invalid': isFormFieldValid(meta) })} placeholder="Java Developer Flow" />
                  {getFormErrorMessage(meta)}
                </div>
              )} />
            </div>
            <div className="flex justify-content-end align-items-end">
              <Button type="submit" label={submitButtonLabel} icon="pi pi-check"></Button>
            </div>
          </Card >
        </form>
      )} />
    </div>
  )
}

export default CreateInterviewFlowForm