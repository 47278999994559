import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { ChatbotDetails } from "./interface"
import { candidateCreateChatbot } from "./CandidateChatbotAction"

interface ApplicationState {
  chatbotDetails: ChatbotDetails
}

const initialState = {
  chatbotDetails: {},
} as ApplicationState

const applicationSlicer = createSlice({
  name: 'candidate-chatbot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateCreateChatbot.fulfilled, (state: any, { payload }) => {
        state.chatbotDetails = payload
      })
  },
})

export default applicationSlicer.reducer