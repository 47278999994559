import * as homeAction from './homeAction'

interface AuthenState {
  name: string;
}

const initialState: AuthenState = {
  name: ""
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case homeAction.SET_NAME:
      return setName(state, action.name)
    default:
      return state
  }
}

const setName = (state: AuthenState, name: string) => {
  const updatedState = { ...state }
  updatedState.name = name
  return updatedState;
}

export default reducer;