import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import ProgressSpinnerDialog from "../../../../constants/helpers/ProgressSpinnerDialog"
import { RESUME_KEY } from "../../../../constants/localStorageKeys"

import { InterviewStatusEnum } from "../Chatbot/interface"
import { AppDispatch } from "../../../../store"
import { candidateRetrieveInterview } from "../CandidateInterviewAction"

interface InterviewProps {
}

const BeginInterview = (props: InterviewProps) => {
  const {} = props
  const { interviewId } = useParams<{ interviewId: string }>()
  const history = useHistory()
  
  const dispatch = useDispatch<AppDispatch>()
  const { interview } = useSelector((state: any) => state.candidateInterview)

  const [loadingMessage, setLoadingMessage] = useState("")
  const resumeToken = localStorage.getItem(RESUME_KEY)

  //Initial effect
  useEffect(() => {
    setLoadingMessage("Preparing your interview...")
    if (resumeToken)
      // chatbotAction.getInterviewDetails(interviewId, resumeToken)
      dispatch(candidateRetrieveInterview({interviewId, token: resumeToken})).unwrap()
      .catch(() => {
        // if interview not found, redirect out!
        console.log("Failed to validate interview, please check resume token and interview id.")
        history.push("/home")
      })
  }, [])

  const beginInterview = () => {
    history.push("/interview/" + interviewId + "/session")
  }

  const title = "Hi " + interview?.fullName
  const subtitle = (interview?.status === InterviewStatusEnum.ACTIVE) ?
    "You are interviewing for the " + interview?.job?.title + " role."
    :
    "You have either already completed the interview for the " + interview?.job?.title + " role, or the interview has already expired."
  const body = (interview?.status === InterviewStatusEnum.ACTIVE) ?
    <div>
      <p>This virtual interview will take about 5 minutes to complete. <br></br>
        Please click on the button below to begin the interview session with our virtual interviewer. <br></br>
        You may copy the URL in your address bar to attend this interview at a more convenient time, but note that this interview will expire in 1 day.<br></br>
      </p>
    </div>
    :
    <div>
      <p>If you wish to re-attempt the interview, please contact the company administrator.</p>
    </div>
  const footer = <Button label="Start Interview" onClick={beginInterview} disabled={interview?.status === InterviewStatusEnum.COMPLETED}></Button>

  return (
    <div>
      <div>
        <ProgressSpinnerDialog text={loadingMessage} visible={interview === undefined}></ProgressSpinnerDialog>
      </div>
      <div className="flex justify-content-center align-items-center h-24rem">
        <div className="flex justify-content-center">
          <Card className="flex justify-content-center" title={title} subTitle={subtitle} footer={footer}>
            {body}
          </Card>
        </div>
      </div>
    </div>
  )
}

export default BeginInterview