import { Button } from 'primereact/button'
import { useHistory } from "react-router-dom"

interface HomeProps {
}

const Home = (homeProps: HomeProps) => {
  const { } = homeProps
  const history = useHistory()

  const handleViewMavenTree = () => {
    history.push("/MavenTree")
  }

  return (
    <div className="App">
      <h2>Candidate home placeholder</h2>
      <Button label="View MavenTree Jobs" onClick={handleViewMavenTree}></Button>
    </div>
  )
}

export default Home