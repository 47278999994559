import { createAsyncThunk } from "@reduxjs/toolkit"
import { Api, ApiMethod } from "../../../../utils/Api"
import { processParams } from "../../../../utils/LazyParamsUtil"
import { Resume } from "../interface"

const endpoint = process.env.REACT_APP_JOB_API_URL

export const candidateRetrieveJobs = createAsyncThunk('candidate-job/retrieveJobs',
  async ({ lazyParams, companyCode }: { lazyParams: any, companyCode: string }, { rejectWithValue }) =>
    Api(ApiMethod.GET, endpoint, 'candidate/jobs/' + companyCode, processParams(lazyParams), rejectWithValue)
)

export const candidateRetrieveJob = createAsyncThunk('candidate-job/retrieveJob',
  async ({ jobId, companyCode }: { jobId: number, companyCode: string }, { rejectWithValue }) =>
    Api(ApiMethod.GET, endpoint, `candidate/jobs/${companyCode}/${jobId}`, undefined, rejectWithValue)
)

export const candidateRetriveResume = createAsyncThunk('candidate-job/retrieveResume',
  async (token: string, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'candidate/get-resume', { token }, rejectWithValue)
)

export const candidateUploadResume = createAsyncThunk('candidate-job/uploadResume',
  async ({ resumeData, token }: { resumeData: FormData, token: string | null | undefined }, { rejectWithValue }) =>
    Api(ApiMethod.POST, endpoint, 'candidate/upload-resume' + (token ? '?token=' + token : ''), resumeData, rejectWithValue)
)

export const candidateUpdateResume = createAsyncThunk('candidate-job/updateResume',
  async ({ resume, token }: { resume: Resume, token: string }, { rejectWithValue }) =>
    Api(ApiMethod.PUT, endpoint, 'candidate/update-resume?token=' + token, resume, rejectWithValue)
)