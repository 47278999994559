import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Branch, InterviewNode, QuestionType } from '../../../../../../CandidatePortal/scenes/Interview/Chatbot/interface';
//import * as getInterviewFlowAction from '../../../../../../store/interviewFlow/getInterviewFlowAction';
//import * as getExistingFlowListingAction from '../../../../../../store/interviewFlow/getExistingFlowListingAction';
//import * as importNodeAction from '../../../../../../store/interviewFlow/importNodeAction';

import { InterviewFlow } from '../../../interface';
import { Toast } from 'primereact/toast';
import { AppDispatch } from '../../../../../../store';
import { typesOfId } from '../../../InterviewSetup/enums';

interface ExistingFlowDialogProps {
  interviewFlow: InterviewFlow
  displayExistingFlowDialog: boolean
  onHide: any
  existingFlowDialogListing: InterviewFlow[],
  loading: boolean
  getExistingFlowsForTenant: (interviewFlowId: number) => any
  getInterviewNodesFor: (interviewFlowId: number) => any
  importInterviewFlowByInterviewFlowId: ({ id, typeOfId }: { id: number, typeOfId: string }) => any
  importNodes: (interviewNodes: InterviewNode[]) => any
}

const ExistingFlowDialog = (props: ExistingFlowDialogProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const {
    interviewFlow, displayExistingFlowDialog, onHide, existingFlowDialogListing, loading,
    getExistingFlowsForTenant, getInterviewNodesFor, importInterviewFlowByInterviewFlowId, importNodes } = props
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [checkedNodes, setCheckedNodes] = useState<InterviewNode[]>([]);
  const toast = useRef<any>()

  //On dialog show, query for all existing flows for tenant
  useEffect(() => {
    if (displayExistingFlowDialog) {
      setExpandedRows([])
      setCheckedNodes([])
      dispatch(getExistingFlowsForTenant(interviewFlow.id ? interviewFlow.id : 0))
    }
  }, [displayExistingFlowDialog])

  const onRowExpand = (event: any) => {
    //Query for nodes using event.data.id if interviewNodeList not yet loaded
    if (event.data.interviewNodeList === undefined)
      dispatch(getInterviewNodesFor(event.data.id))
  }
  const onRowCollapse = (event: any) => {
    //Should remove all checked nodes for this sub-tree on collapse?
  }

  const handleCheck = (checked: boolean, rowData: InterviewNode) => {
    if (checked) {
      setCheckedNodes(checkedNodes => [...checkedNodes, rowData]) //Append to list
    } else {
      setCheckedNodes(checkedNodes.filter(node => node.id !== rowData.id)) //Remove from list
    }
  }

  useEffect(() => {
    if (checkedNodes.length > 1) {
      //Check for duplicate nodes being added across different flows
      if (new Set(checkedNodes.map(node => node.name)).size !== checkedNodes.length) {
        toast.current.show({ severity: 'warn', summary: 'Duplicate Import', detail: "You may be attempting to import duplicated nodes, this may lead to unintended behaviour.", life: 3000 })
        setTimeout(() => setCheckedNodes(checkedNodes.filter(node => node !== checkedNodes.at(checkedNodes.length - 1))), 200); //Remove latest added node which should be the duplicated node
      }
    }
  }, [checkedNodes])

  const typeOfQuestionTemplate = (rowData: InterviewNode) => {
    return <span>{rowData.typeOfQuestion}</span>
  }

  const checkBoxTemplate = (rowData: InterviewNode) => {
    return <Checkbox onChange={(e:any) => handleCheck(e.checked || false, rowData)} 
      checked={checkedNodes.some(n => n.id === rowData.id)}></Checkbox>
  }

  const createdDateTemplate = (rowData: InterviewFlow) => {
    if (rowData.createdDate) {
      let date = new Date(rowData.createdDate)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    } else {
      return null
    }
  }

  const handleImportCheckedNodesBtn = (e: any) => {
    e.preventDefault();
    if (interviewFlow.interviewNodeList) {
      let interviewNodeListNames = interviewFlow.interviewNodeList.map(eachNode => eachNode.name);
      let checkedNodesListNames = checkedNodes.map(eachNode => eachNode.name);
      let intersectNames = interviewNodeListNames.filter((eachName: string) => checkedNodesListNames.includes(eachName));
      if (intersectNames.length > 0) {
        toast.current.show({ severity: 'warn', summary: 'Importing duplicate nodes', detail: `The nodes: ${intersectNames.join(', ')} already exist in the current flow.`, life: 3000 })
      } else {
        dispatch(importNodes(checkedNodes))
        onHide();
      }
    }
  }

  const removeCheckedNode = (nodeToRemove: InterviewNode) => {
    setCheckedNodes(checkedNodes.filter(node => node.id !== nodeToRemove.id)) //Remove from list
  }

  const footer = () => {
    return (
      <div className="flex grid">
        <div className="col-10 text-left align-self-end">
          {checkedNodes.map((node: InterviewNode, index: number) => {
            return (
              <Chip className="fadeinleft animation-duration-100 animation-iteration-1 mr-1" key={index} label={node.name} removable onRemove={() => removeCheckedNode(node)}></Chip>
            )
          })}
        </div>
        <div className="col-2">
          <Button className="mt-4" label={`Import (${checkedNodes.length})`} tooltip={"Import selected nodes"} disabled={checkedNodes.length === 0} onClick={handleImportCheckedNodesBtn} />
        </div>
      </div>
    )
  }

  const linkedNodesTemplate = (rowData: InterviewNode) => {
    let linkedNodes = [];
    switch (rowData.typeOfQuestion) {
      case (QuestionType.ConfirmationQuestion):
        // linkedNodes.push("Unfulfilled: ", rowData.flowToIfNo, ", Fulfilled: ", rowData.flowToIfYes)
        linkedNodes.push(`Unfulfilled: ${rowData.confirmationSetting.flowToIfNo}, Fulfilled: ${rowData.confirmationSetting.flowToIfYes}`)
        break
      case (QuestionType.OpenEndedQuestion):
        // linkedNodes.push("Next Intent: ", rowData.nextIntent)
        linkedNodes.push(`Next Intent: ${rowData.slotElicitationSetting.successStep}`)
        break
      case (QuestionType.DelegatedQuestion):
        // linkedNodes.push("Next Intent: ", rowData.nextIntent)
        linkedNodes.push(`Next Intent: ${rowData.confirmationSetting?.flowToIfYes}`)
        break
      case (QuestionType.ConditionalQuestion):
        linkedNodes.push(`Default Branch: ${rowData.conditionalSetting.defaultStep}`)
        rowData.conditionalSetting.branches.forEach((branch: Branch, index: number) => {
          linkedNodes.push(`Branch ${index}: ${branch.nextStep}, `)
        })
        break
      // case (QuestionType.BranchingQuestion):
      //   rowData.branches.forEach((branch: Branch, index: number) => {
      //     // linkedNodes.push("Branch " + index + ": ", branch.flowToIfFulfilled, ", ")
      //     linkedNodes.push(`Branch ${index}: ${branch.flowToIfFulfilled}, `)
      //   })
      //   break
    }
    return linkedNodes
  }


  const rowExpansionTemplate = (rowData: InterviewFlow) => {
    return (
      <Card>
        <h5> Nodes for {rowData.name} </h5>
        <DataTable loading={loading} value={rowData.interviewNodeList}>
          {/* <Column style={{ width: '4rem' }} field="id" header="id" sortable></Column> */}
          <Column field="typeOfQuestion" header="Type of Question" sortable body={typeOfQuestionTemplate}></Column>
          <Column field="name" header="Name" sortable></Column>
          <Column body={linkedNodesTemplate} header="Linked Node(s)" sortable />
          <Column headerStyle={{ width: '4rem' }} body={checkBoxTemplate}></Column>
        </DataTable>
      </Card>
    )
  }

  const handleImportFlowBtn = (interviewFlow: InterviewFlow) => {
    confirmDialog({
      className: "w-5",
      message: "You are about to import " + interviewFlow.name + ", this will overwrite your current flow. Are you sure you want to proceed?",
      header: "Confirm import",
      icon: "pi pi-exclamation-triangle",
      accept: () => confirmImport(interviewFlow),
      reject: () => { }
    }).show() 
  }

  const confirmImport = (interviewFlow: InterviewFlow) => {
    dispatch(importInterviewFlowByInterviewFlowId
      ({id: Number(interviewFlow.id), typeOfId: typesOfId[typesOfId.Id]}))
    onHide()
  }

  const importFlowTemplate = (rowData: InterviewFlow) => {
    return (
      <div>
        <Button icon="pi pi-download" tooltip="Import this flow" className="p-button-secondary mr-2" onClick={() => handleImportFlowBtn(rowData)}></Button>
        <ConfirmDialog />
      </div>
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog footer={footer} className="w-10" visible={displayExistingFlowDialog} onHide={onHide} header="Import from Existing Flows">
        <Card>
          <DataTable loading={loading} value={existingFlowDialogListing} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand} onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate} dataKey="id">
            <Column expander style={{ width: '3em' }} />
            {/* <Column field="id" style={{ width: '3em' }} header="id" sortable /> */}
            <Column field="name" header="Name" sortable />
            <Column field="createdDate" header="Created Date" sortable body={createdDateTemplate} />
            <Column field="interviewFlowEnum" header="Flow Type" sortable />
            <Column field="foreignKeyName" header="Flow for" sortable />
            <Column body={importFlowTemplate} headerStyle={{ width: '4rem' }} />
          </DataTable>
        </Card>
      </Dialog>
    </div >
  );
}

/*
const mapStateToProps = (state: any) => {
  return {
    existingFlowDialogListing: state.interviewFlow.existingFlowDialogListing,
    existingFlowDialogLoaded: state.interviewFlow.existingFlowDialogLoaded,
    existingFlowDialogListingNodeListLoaded: state.interviewFlow.existingFlowDialogListingNodeListLoaded
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getExistingFlowsForTenant: (interviewFlowId: number) => dispatch(getExistingFlowListingAction.getExistingFlowsForTenant(interviewFlowId)),
    getInterviewNodesFor: (interviewFlowId: number) => dispatch(importNodeAction.getInterviewNodesFor(interviewFlowId)),
    importInterviewFlowByInterviewFlowId: (interviewFlowId: number) => dispatch(getInterviewFlowAction.importInterviewFlowByInterviewFlowId(interviewFlowId)),
    importNodes: (interviewNodes: InterviewNode[]) => dispatch(importNodeAction.importNodes(interviewNodes))
  }
}
*/

export default ExistingFlowDialog