import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constants/routes/admin/menus";

import { FilterMatchMode } from "primereact/api";
import { AppDispatch } from "../../../../store";
import { retrieveApplications } from "../ApplicationAction";

interface ApplicationManagementProps {
}

const ApplicationManagement = (props: ApplicationManagementProps) => {
  const { } = props
  const toast = useRef<any>()
  const dt = useRef<any>(null)
  const history = useHistory()

  const dispatch = useDispatch<AppDispatch>()
  const { applicationList, totalRecords } = useSelector((state: any) => state.application)

  let filters: DataTableFilterMeta = {
    'resume.fullName': { value: null, matchMode: FilterMatchMode.EQUALS },
    'resume.email': { value: null, matchMode: FilterMatchMode.EQUALS },
    'job.title': { value: null, matchMode: FilterMatchMode.EQUALS },
    'job.id': { value: null, matchMode: FilterMatchMode.EQUALS },
    createdDate: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [rows, setRows] = useState(5)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: "createdDate",
    sortOrder: -1,
    filters
  })

  //Lazy loading
  useEffect(() => {
    dispatch(retrieveApplications(lazyParams))
  }, [lazyParams])

  const handleViewApplicationBtn = (rowData: any) => {
    history.push(`${ADMIN_ROUTES.ROUTE_APPLICATIONS_ADMIN}/${rowData.id}`)
  }

  // Custom Filter Details
  const [selectedDate, setSelectedDate] = useState(undefined)

  const onPage = (event: any) => {
    setRows(event.rows)
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    setLazyParams(_lazyParams)
  }

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event }
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams)
  }

  const onDateChange = (e: any) => {
    setSelectedDate(e.value)
    if (e.value[0] != null && e.value[1] != null) {
      let startDate = `${e.value[0].getFullYear()}-${e.value[0].getMonth() + 1}-${e.value[0].getDate()}`
      let endDate = `${e.value[1].getFullYear()}-${e.value[1].getMonth() + 1}-${e.value[1].getDate() + 1}`
      let _lazyParams = { ...lazyParams }
      _lazyParams.filters.createdDateLt = { 'value': startDate, matchMode: FilterMatchMode.EQUALS }
      _lazyParams.filters.createdDateGt = { 'value': endDate, matchMode: FilterMatchMode.EQUALS }
      setLazyParams(_lazyParams)
    }
  }

  const dateFilter = <Calendar value={selectedDate} readOnlyInput selectionMode="range" onChange={onDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="YYYY-MM-DD"></Calendar>

  const handleResetFilters = () => {
    setSelectedDate(undefined)
    dt.current.reset();
    let _lazyParams = { ...lazyParams }
    _lazyParams.filters = {}
    setLazyParams(_lazyParams)
  }

  const header = (
    <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={handleResetFilters} />
    </div>
  )

  const createdDateBodyTemplate = (rowData: any) => {
    let date = new Date(rowData.createdDate)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  const viewApplicationTemplate = (rowData: any) => {
    return (
      <div>
        <Button icon="pi pi-search" className="p-button-secondary mr-2" onClick={() => handleViewApplicationBtn(rowData)}></Button>
      </div>
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="datatable-doc-demo">
        <div className="card">
          <DataTable ref={dt} value={applicationList} first={lazyParams.first} rows={rows} totalRecords={totalRecords} header={header}
            onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder as any}
            onFilter={onFilter} filters={lazyParams.filters} filterDisplay="row"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 25]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            lazy paginator>
            <Column field="resume.fullName" header="Full Name" filter sortable></Column>
            <Column field="resume.email" header="E-Mail" filter sortable></Column>
            <Column field="job.title" header="Job Title" filter sortable></Column>
            <Column field="job.id" header="Job ID" filter sortable></Column>
            <Column field="createdDate" header="Application Date" body={createdDateBodyTemplate} filter filterElement={dateFilter} sortable></Column>
            <Column body={viewApplicationTemplate} headerStyle={{ width: '12em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default ApplicationManagement

