import { Message } from "@aws-sdk/client-lex-runtime-v2";
import { XYPosition } from "react-flow-renderer";
import { Job } from "../../../../AdminPortal/scenes/Job/interface";

export enum InterviewStatusEnum {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED"
}

export interface ChatSession {
  id?: string
  chatLogs?: ChatLog[]
}

export interface ChatLog {
  id?: number
  name: string
  message: string
  timestamp: string
  chatSessionId: string
}

export interface ChatbotDetails {
  botId: string
  botName: string
  botAliasId: string
  localeId: string
  interviewNodeList: InterviewNode[]
}

export interface InterviewSessionDetails {
  id?: number
  chatSession: ChatSession
  job: Job
  fullName: string
  status?: InterviewStatusEnum
  botId: string
  botAliasId: string
  localeId: string
}

export enum CreateInterviewStatus {
  success = 'success',
  fail = 'fail',
  neutral = 'neutral',
  loading = 'loading'
}

export enum InterviewDetailsStatus {
  success = 'success',
  fail = 'fail',
  neutral = 'neutral',
  loading = 'loading'
}
/**
 * Used for archiving conversations and displaying message bubbles/boxes
 */
export interface MessageDetails {
  message: Message
  header: string
  timeStamp: string
}


export type Question = {

  id?: number

  typeOfQuestion: string
  /**
   * Name of Intent, must match with what is provided to Lex.
   */
  name: string
  /**
   * The intent name matching with Lex to skip to within the provided queue or sequence of intents.
   * This skip will only occur when receiving a negative response.
   * Intents in the queue will be dequeued until the specified intent name has been reached.
   */
  // flowToIfNo: string
  // /**
  //  * The intent name matching with Lex to skip to within the provided queue or sequence of intents.
  //  * This skip will only occur when receiving a positive response.
  //  * Intents in the queue will be dequeued until the specified intent name has been reached.
  //  */
  // flowToIfYes: string
  /**
   * To inform the application if the current question is the first question of the session.
   * This will guide the application to start the session from this question.
   */
  isFirstQuestion: boolean
  /**
   * To inform the application if the current question will be the last question of the session.
   * This will guide the application to end the session upon completing this question.
   */
  isLastQuestion: boolean
  // /**
  //  * The example responses a user should respond with. 
  //  * This should be presented in the form of clickable chips
  //  */
  chips?: string[]
  multiChips?: boolean
  /**
   * TODO
   */
  confirmationSetting: ConfirmationSetting
}


//Discriminated Union https://www.typescriptlang.org/docs/handbook/2/narrowing.html
/** 
 * Yes-no type of questions, which would require information on how the conversation would flow if yes or no.
 */
export type ConfirmationQuestion = Question & {

  typeOfQuestion: "ConfirmationQuestion"
  /**
   * The intent name matching with Lex to skip to within the provided queue or sequence of intents.
   * This skip will only occur when receiving a negative response.
   * Intents in the queue will be dequeued until the specified intent name has been reached.
   */
  // flowToIfNo: string
  // /**
  //  * The intent name matching with Lex to skip to within the provided queue or sequence of intents.
  //  * This skip will only occur when receiving a positive response.
  //  * Intents in the queue will be dequeued until the specified intent name has been reached.
  //  */
  // flowToIfYes: string
  /**
   * The score to be given to the candidate for responding negatively.
   */
  scoreIfNo: number
  /**
   * The score to be given to the candidate for responding positively.
   */
  scoreIfYes: number
  /**
   * TODO
   */
  confirmationSetting: ConfirmationSetting
}

/**
 * Questions where control will be delegated to Lex to capture the appropriate response.
 */
export type DelegatedQuestion = Question & {

  typeOfQuestion: "DelegatedQuestion"
  /**
   * Name of Intent, must match with what is provided to Lex.
   */
  name: string
  /**
   * TODO
   */
  slotElicitationSetting: SlotElicitationSetting
  /**
   * TODO
   */
  confirmationSetting?: ConfirmationSetting
  validateCandidateInput: boolean
}

/**
 * Questions that expects open ended response.
 * Will capture a response text as it is without any NLU.
 */
export type OpenEndedQuestion = Question & {

  typeOfQuestion: "OpenEndedQuestion"
  /**
   * TODO
   */
  slotElicitationSetting: SlotElicitationSetting
  /**
   * The example responses a user should respond with. 
   * This should be presented in the form of clickable chips
   */
}

/** 
 * Questions with conditional statements, which would require information on the type of condition, and how the conversation
 * would flow if a condition is fulfilled or not
 */
export type ConditionalQuestion = Question & {

  typeOfQuestion: "ConditionalQuestion"

  /**
   * Contains the SlotElicitationSetting to be used in runtime to create and setup the Lex bot as needed.
   */
  slotElicitationSetting: SlotElicitationSetting

  /**
   * Contains the ConfirmationSetting for the intent which will be used to build on Lex during runtime.
   */
  confirmationSetting?: ConfirmationSetting

  /**
   * Contains the branching information with its conditional expressions to determine which branch to step into in runtime.
   */
  conditionalSetting: ConditionalSetting

  /**
   * A flag to be used in runtime to determine the default build of the intent.
   */
  validateCandidateInput: boolean
}

//NOTE: This should not be needed anymore as of Aug'22 Lex update
// /**
//  * Specific conditions that should be checked upon runtime.
//  */
// export interface ConditionDetails {
//   /**
//    * The slotName containing the response value from a candidate that is received from Lex.
//    * The containing value will be checked using the logic provided by the conditionType declared.
//    */
//   slotNameToCheck: string
//   /**
//    * The type of condition checking to perform in runtime.
//    */
//   conditionType: Conditions
//   /**
//    * The expected value to be compared to in runtime.
//    */
//   expectedValue: number | string
// }

/**
 * The collection of Branch objects with each branch containing the condition
 * expression, response, and its corresponding step action.
 */
export interface Branch {
  id: number
  /**
   * The name of the branch, will be automatically assigned by default.
   */
  name: string

  /**
   * The String representation of the expression to check. NOTE: Lex replaces
   * {SlotName} variables on runtime with the value of the slot name. Expressions
   * should then be "{SlotName} >= 3".
   
   * Expressions include the following:
   * Comparison Operators: (=, !, >=, <=),
   * Boolean Operators: (AND (&&) OR(||) NOT (!)),
   * Quantifier Operators: ({SlotName} CONTAINS "value").
   * 
   * NOTE: This expression is currently linear (e.g.: ConditionA AND ConditionB OR ConditionC...)
   * Design will have to be amended and algorithm for building Expression will need to be changed to fit more complicated
   * algorithm building. (e.g.: (ConditionA AND ConditionB) OR ConditionC...)
   */
  expression: Expression

  /**
   * The response to be given upon qualifying for this branch.
   */
  responses: string[]

  /**
   * The String representing the next intent name or dialog action to be taken
   * upon qualifying for this branch.
   */
  nextStep: string

  /**
   * The score to be used for tabulating after the interview has been completed.
   */
  branchScore: number
}

export interface Expression {
  id: number

  /**
   * Contains the expression query to be built in runtime
   */
  queries: ExpressionQuery[]

  /**
   * Contains the condition to be applied to all expression queries.
   */
  condition?: ConditionEnum

  /**
   * The referencing branch.
   */
  branch: Branch
}

export interface ExpressionQuery {
  /**
   * The slotName being used in order to reference back to the value of the slot in runtime
   */
  slotName: string

  /**
   * The conditional operator being used for comparing.
   */
  conditionOperator: ConditionArithmeticOperatorEnum | ConditionQuantifierOperatorEnum

  /**
   * The expected value to form the condition
   */
  value: string
}

/**
 * The in-built arithmetic operators supported by Lex
 */
export enum ConditionArithmeticOperatorEnum {
  EQUALS = "=",
  GREATER_THAN = ">",
  LESSER_THAN = "<",
  NOT_EQUALS = "!=",
  GREATER_THAN_OR_EQUALS = ">=",
  LESSER_THAN_OR_EQUALS = "<="
}

/**
 * The in-built quantifier operator supported by Lex
 */
export enum ConditionQuantifierOperatorEnum {
  CONTAINS = "CONTAINS"
}

/**
 * The in-built conditional operator supported by Lex
 */
export enum ConditionEnum {
  AND = "AND",
  OR = "OR"
}


export enum QuestionType {
  ConfirmationQuestion = "ConfirmationQuestion",
  OpenEndedQuestion = "OpenEndedQuestion",
  ConditionalQuestion = "ConditionalQuestion",
  DelegatedQuestion = "DelegatedQuestion"
}

/**
 *The ConfirmationSetting for the intent which will be used to build on Lex during runtime.
 */
export interface ConfirmationSetting {

  /**
   * The messages to prompt a user for a confirmation.
   */
  confirmationSettingQuestionPrompts: string[]

  /**
   * The response messages to show if candidate responds with a positive sentiment. Optional.
   */
  responseIfYes: string[]

  /**
   * The name of the next intent to step into if candidate responds with a positive sentiment.
   */
  flowToIfYes: string

  /**
   * The response messages to show if candidate responds with a negative sentiment. Optional.
   */
  responseIfNo: string[]

  /**
   * The name of the next intent to step into if candidate responds with a
   * negative sentiment.
   */
  flowToIfNo: string

  /**
   * The response messages to show if candidate response cannot be inferred. Optional.
   */
  responseIfFail: string[]

  /**
   * The name of the next intent to step into if candidate response cannot be inferred.
   */
  flowToIfFail: string
}

/**
 * Contains the SlotElicitationSetting to be used in runtime to create and setup the Lex bot as needed.
 */
export interface SlotElicitationSetting {

  /**
   * The String given to represent the name of this slot in the intent. Will be given by us as default
   */
  slotName: string

  /**
   * The String representation of the name of the slot type, whether it be an
   * Amazon Lex in-built slot type or custom slot type.
   */
  slotType: string

  /**
   * The messages to prompt a user for the slot values.
   */
  slotElicitationQuestionPrompts: string[]

  /**
   * The samples of utterances that a candidate is expected to respond with. Optional
   */
  sampleUtterances?: string[]

  /**
   * The response messages expressed upon successful capturing of the slot value. Optional
   */
  successResponse: string[]

  /**
   * The response messages expressed upon successful capturing of the slot value. Optional
   */
  successStep: string

  /**
   * The response messages expressed upon unsuccessful capturing of the slot value. Optional
   */
  failResponse: string[] //Should default?

  /**
   * The String value of the next intent name or dialog action to step into upon
   * unsuccessful capture of the slot value. Defaulted to re-prompt slot in backend.
   */
  failStep: string

  /**
   * Whether this slot should capture multiple values in a single utterance.
   */
  multipleValues: boolean
}

/**
 * The containing values of the custom slot type created by the tenant.
 */
export interface CustomSlotTypeValue {

  /**
   * The custom slot type value to be created for use as resolved value in runtime.
   */
  slotTypeValue: string

  /**
   * The synonyms which correspond to the slot type value which will be resolved into in runtime.
   * (e.g.: "Chi" can be a synonym of "Chinese", or "KR" and "JP" can correspond to Korean or Japanese) 
   */
  synonyms: string[]
}

/**
 * The custom slot type which will be used as slot types by the tenant.
 */
export interface CustomSlotType {

  /**
   * The name given to the custom slot type
   */
  slotTypeName: string

  /**
   * The custom slot type values corresponding to this slot type 
   * (e.g.: Races slot type can contain "Chinese", "Japanese", "Korean",...)
   */
  customSlotTypeValues: CustomSlotTypeValue[]

}

/**
 * Contains the branching information with its conditional expressions to determine which branch to step into in runtime.
 */
export interface ConditionalSetting {

  /**
   * The response messages to be expressed when the condition is defaulted. e.g.,
   * none of the conditions were fulfilled.
   */
  defaultBranchResponse: string[]

  /**
   * The String representation of the next intent name or dialog action type to
   * step into when defaulting.
   */
  defaultStep: string

  /**
   * The collection of Branch objects with each branch containing the condition
   * expression, response, and its corresponding step action.
   */
  branches: Branch[]

  /**
   * The score to be tabulated at the end of the interview.
   */
  defaultBranchScore: number
}

/**
 * The in-built slot type provided by Lex.
 */
export enum AmazonLexInbuiltSlotType {
  Alphanumeric = "AMAZON.AlphaNumeric",
  Cities = "AMAZON.City",
  Countries = "AMAZON.Country",
  Date = "AMAZON.Date",
  Duration = "AMAZON.Duration",
  Email = "AMAZON.EmailAddress",
  FreeText = "AMAZON.FreeFormInput",
  Numerical = "AMAZON.Number",
  Percentage = "AMAZON.Percentage",
  PhoneNumber = "AMAZON.PhoneNumber",
  State = "AMAZON.State",
  StreetName = "AMAZON.StreetName",
  Time = "AMAZON.Time",
  FirstName = "AMAZON.FirstName",
  LastName = "AMAZON.LastName"
}

// export enum DialogActions {
//   EndConveration = "EndConversation",
//   ConfirmIntent = "ConfirmIntent",
//   EvaluateConditional = "EvaluateConditional",
//   CloseIntent = "CloseIntent"
// }

/**
 * Discriminated Union type, refer to TypeScript docs.
 * https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html
 */
export type IntentDetails = ConfirmationQuestion | OpenEndedQuestion | ConditionalQuestion | DelegatedQuestion

export type InterviewNode = IntentDetails & {
  id?: number
  interviewFlowId?: number
  position: XYPosition
}