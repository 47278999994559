import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, ApiMethod } from '../../../utils/Api'
import { Lookup } from './interfaces'

const endpoint = process.env.REACT_APP_LOOKUP_API_URL

export const retrieveAllLookup = createAsyncThunk('lookup/retrieveAllLookup',
  async (_, { rejectWithValue }) => Api(ApiMethod.GET, endpoint, 'lookups', {}, rejectWithValue)
)

export const deleteLookups = createAsyncThunk('lookup/deleteLookups',
  async (lookups: Lookup[], { rejectWithValue }) => Api(ApiMethod.DELETE, endpoint, 'lookups', lookups, rejectWithValue)
)

export const createLookup = createAsyncThunk('lookup/createLookup',
  async (lookup: Lookup, { rejectWithValue }) => Api(ApiMethod.POST, endpoint, 'lookup', lookup, rejectWithValue)
)

export const updateLookup = createAsyncThunk('lookup/updateLookup',
  async (lookup: Lookup, { rejectWithValue }) => Api(ApiMethod.PUT, endpoint, 'lookup', lookup, rejectWithValue)
)

export const deleteLookup = createAsyncThunk('lookup/deleteLookup',
  async (lookup: Lookup, { rejectWithValue }) => Api(ApiMethod.DELETE, endpoint, 'lookup', lookup, rejectWithValue)
)