
import { MenuProps } from "primereact/menu"
import { MenuItemCommandEvent } from "primereact/menuitem"
import { SCOPES } from "../../scopes"
import * as jwtDecoder from "../../../constants/helpers/admin/jwtDecoder"
import { JWT_KEY, LOOKUPS_KEY } from "../../../constants/localStorageKeys"

interface NavigationItem {
  scope?: SCOPES
  label: string
  icon?: string
  link?: ADMIN_ROUTES
  items?: MenuProps[]
  command?(e: MenuItemCommandEvent): void
}

export enum ADMIN_ROUTES {
  ROUTE_LOGIN_ADMIN = "/login",
  ROUTE_HOMEPAGE_ADMIN = "/home",
  ROUTE_JOBS_ADMIN = "/jobs",
  ROUTE_USERS_ADMIN = "/users",
  ROUTE_LOOKUPS_ADMIN = "/lookups",
  ROUTE_TENANTS_ADMIN = "/tenants",
  ROUTE_TENANTS_ADMIN_USERSMANAGE = "/tenants/users",
  ROUTE_APPLICATIONS_ADMIN = "/applications",
  ROUTE_INTERVIEWS_ADMIN = "/interviews",
  ROUTE_TEMPLATES_ADMIN = "/templates",
}

const navigationItem = (item: NavigationItem, history?: any) => {
  if (item.scope === undefined || jwtDecoder.checkScopeExistsInJwt(item.scope))
    return [
      {
        label: item.label,
        icon: item.icon,
        items: item.items,
        command: (e: MenuItemCommandEvent) => {
          if (item.command) item.command(e)
          if (history && item.link) history.push(item.link)
        }
      } as MenuProps
    ]
  return []
}

export const menuItems = (history: any) => {
  return [
    ...navigationItem({
      scope: SCOPES.TENANT_ADMIN,
      label: 'Jobs',
      icon: 'pi pi-fw pi-user',
      items: [
        ...navigationItem({
          scope: SCOPES.JOB_READ,
          label: 'Manage Jobs',
          icon: 'pi pi-fw pi-user',
          link: ADMIN_ROUTES.ROUTE_JOBS_ADMIN,
        }, history)
      ]
    }),
    ...navigationItem({
      scope: SCOPES.APPLICATION_READ,
      label: 'Applications',
      icon: 'pi pi-fw pi-pencil',
      items: [
        ...navigationItem({
          scope: SCOPES.APPLICATION_READ,
          label: 'Manage Applications',
          icon: 'pi pi-fw pi-align-left',
          link: ADMIN_ROUTES.ROUTE_APPLICATIONS_ADMIN
        }, history),
        ...navigationItem({
          scope: SCOPES.INTERVIEW_READ,
          label: 'Manage Interviews',
          icon: 'pi pi-fw pi-list',
          link: ADMIN_ROUTES.ROUTE_INTERVIEWS_ADMIN
        }, history),
        ...navigationItem({
          scope: SCOPES.PORTAL_ADMIN,
          label: 'View Report',
          icon: 'pi pi-fw pi-align-right',
        }, history)
      ]
    }),
    ...navigationItem({
      scope: SCOPES.TENANT_ADMIN,
      label: 'Admininstrative',
      icon: 'pi pi-fw pi-user',
      items: [
        ...navigationItem({
          scope: SCOPES.USER_READ,
          label: 'Manage Users',
          icon: 'pi pi-fw pi-users',
          link: ADMIN_ROUTES.ROUTE_USERS_ADMIN
        }, history),
        ...navigationItem({
          scope: SCOPES.TENANT_ADMIN,
          label: 'Flow Template Listing',
          icon: 'pi pi-fw pi-sitemap',
          link: ADMIN_ROUTES.ROUTE_TEMPLATES_ADMIN
        }, history),
        ...navigationItem({
          scope: SCOPES.PORTAL_ADMIN,
          label: 'Manage Lookups',
          icon: 'pi pi-fw pi-table',
          link: ADMIN_ROUTES.ROUTE_LOOKUPS_ADMIN
        }, history)
      ]
    }),
    ...navigationItem({
      scope: SCOPES.PORTAL_ADMIN,
      label: 'Tenancy',
      icon: 'pi pi-fw pi-calendar',
      items: [
        ...navigationItem({
          scope: SCOPES.TENANT_READ,
          label: 'Manage Tenants',
          icon: 'pi pi-fw pi-pencil',
          link: ADMIN_ROUTES.ROUTE_TENANTS_ADMIN
        }, history),
        ...navigationItem({
          scope: SCOPES.USER_READ,
          label: 'Manage Tenant Users',
          icon: 'pi pi-fw pi-users',
          link: ADMIN_ROUTES.ROUTE_TENANTS_ADMIN_USERSMANAGE
        }, history)

      ]
    }),

  ]
}

export const avatarMenuItems = (history: any) => {
  return [
    ...navigationItem({
      label: 'Account Options',
      items: [
        ...navigationItem({
          label: 'Update',
          icon: 'pi pi-refresh'
        }),
        ...navigationItem({
          label: 'Logout',
          icon: 'pi pi-sign-out',
          link: ADMIN_ROUTES.ROUTE_LOGIN_ADMIN,
          command: () => {
            localStorage.removeItem(JWT_KEY)
            localStorage.removeItem(LOOKUPS_KEY)
            window.location.href = '/login'
          }
        }, history)
      ]
    }),
  ]
}